import * as React from "react";

import ModBlockHeader from "../blocks/ModBlockHeader";
import ModBlockTitle from "../blocks/ModBlockTitle";
import ModBlockRichText from "../blocks/ModBlockRichText";
import ModBlockImageText from "../blocks/ModBlockImageText";
import ModBlockSpacer from "../blocks/ModBlockSpacer";
import ModBlockImage from "../blocks/ModBlockImage";
import ModBlockTeaserSpot from "../blocks/ModBlockTeaserSpot";
import ModBlockVimeo from "../blocks/ModBlockVimeo";
import ModBlockSnippet from "../blocks/ModBlockSnippet";
import ModBlockGrid3 from "../blocks/ModBlockGrid3";
import ModBlockGrid4 from "../blocks/ModBlockGrid4";
import ModBlockGrid5 from "../blocks/ModBlockGrid5";
import ModBlockSlider from "../blocks/ModBlockSlider";
import ModEditBlock from "../common/ModEditBlock";
import RootState from "../../vo/RootState";

export interface ModTemplateBlockListProps {
  rootState: RootState;
  templateData: any[];
}

export default class ModTemplateBlockList extends React.Component<
  ModTemplateBlockListProps,
  {}
> {
  render() {
    const { templateData, rootState } = this.props;

    const blockList = templateData.map((_item, index) => {
      const typeKey = Object.keys(_item)[0];
      const content = _item[typeKey];
      return { typeKey, content };
    });

    let html = blockList.reduce((itemList, item, index) => {
      const { typeKey, content } = item;

      let blockMarkup = null;
      switch (typeKey) {
        case "blockHeader":
          blockMarkup = <ModBlockHeader key={index} content={content.block} />;
          break;
        case "blockTitle":
          blockMarkup = (
            <ModBlockTitle
              key={index}
              content={content.block}
              bgColor={content.bgColor}
            />
          );
          break;
        case "blockRichText":
          blockMarkup = (
            <ModBlockRichText key={index} content={content.block} />
          );
          break;
        case "blockImageText":
          blockMarkup = (
            <ModBlockImageText key={index} content={content.block} />
          );
          break;
        case "blockSpacer":
          blockMarkup = <ModBlockSpacer key={index} content={content.block} />;
          break;
        case "blockTeaserSpot":
          blockMarkup = (
            <ModBlockTeaserSpot key={index} content={content.block} />
          );
          break;
        // case 'blockVimeo':
        //   blockMarkup = <ModBlockVimeo key={index} content={content.block} />
        //   break
        case "blockSnippet":
          blockMarkup = <ModBlockSnippet key={index} content={content.block} />;
          break;
        case "blockGrid3":
          blockMarkup = <ModBlockGrid3 key={index} content={content.block} />;
          break;
        case "blockGrid4":
          blockMarkup = <ModBlockGrid4 key={index} content={content.block} />;
          break;
        case "blockGrid5":
          blockMarkup = <ModBlockGrid5 key={index} content={content.block} />;
          break;
        default:
          blockMarkup = null;
      }

      if (rootState.editMode && blockMarkup) {
        itemList.push(
          <ModEditBlock
            key={"_" + index}
            entityObject={content.block}
            title={typeKey}
            rootState={rootState}
          />
        );
      }
      itemList.push(blockMarkup);
      return itemList;
    }, []);

    return <div className="template-block-list">{html}</div>;
  }
}
