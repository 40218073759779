import * as React from 'react'
import { BlockQuote } from "../../interfaces/Interfaces";

export interface ModBlockQuoteProps {
  content: BlockQuote
}

export default class ModBlockQuote extends React.Component<ModBlockQuoteProps, {}> {

  render() {
    const { content } = this.props

    if (!(content._i18nCL && content._i18nCL.quote)) {
      return null
    }
    const i18n = content._i18nCL



    return (
      <div className={"block-quote"}>
        <div className={"centered"}>
          <h2>{i18n.quote}</h2>
          <h3>{i18n.source}</h3>
        </div>
      </div>
    )
  }
}