import * as React from 'react'
import RootState from "../../vo/RootState";
import ModSideBar from '../common/ModSideBar';
import ModBlockFlatFinder from '../blocks/ModBlockFlatFinder';

export interface ModTemplateFlatFinderProps {
  templateData: any[]
  currentLanguage: string
}

export default class ModTemplateFlatFinder extends React.Component<ModTemplateFlatFinderProps, {}> {

  render() {
    const { templateData, currentLanguage } = this.props

    return (
      <ModBlockFlatFinder templateData={templateData} currentLangauge={currentLanguage} />
    )
  }
}
