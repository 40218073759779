import * as React from 'react'
import ModImgTag from "../common/ModImgTag";
import { BlockGrid4 } from "../../interfaces/Interfaces";

export interface ModBlockGrid4Props {
  content: BlockGrid4
}

export default class ModBlockGrid4 extends React.Component<ModBlockGrid4Props, {}> {

  render() {
    const { content } = this.props

    const list = content.list.map((item, index) => {
      return (
        <div key={index} className="cell">
          <ModImgTag imgObject={item.image} width={256} height={256} />
        </div>
      )
    })
    return (
      <div className="block-grid-4">
        {list}
      </div>
    )
  }
}
