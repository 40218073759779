import * as React from 'react'
import { FormattedDate } from 'react-intl'

import ModBlockRichText from "./ModBlockRichText";
import ModBlockImage from "./ModBlockImage";

export interface ModBlockTimelineProps {
  content: any
}

export default class ModBlockTimeline extends React.Component<ModBlockTimelineProps, {}> {

  render() {
    const { content } = this.props

    let html = content.reduce((itemList, item, index) => {
      let image = <ModBlockImage content={item.image} width={356} height={356} linkObject={item.image.link} />
      let text = <ModBlockRichText content={item.text} />
      let date = (
        <div className="date">
          <h3>
            <FormattedDate
              value={item.date}
              year='numeric'
              month='long'
            />
          </h3>
        </div>
      )

      let blockMarkup = (
        <div key={index} className={'two-cells'}>
          {image}
          <div className="date-text">
            {date}
            {text}
          </div>
        </div>
      )

      itemList.push(blockMarkup)
      return itemList
    }, [])

    return (
      <div className={"block-timeline block-cell-rectangle"}>
        {html}
      </div>
    )
  }
}