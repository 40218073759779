import * as React from 'react'

import ModBlockRichText from "./ModBlockRichText";
import ModBlockImage from "./ModBlockImage";

export interface ModBlockSplitColumnProps {
  content: any[]
}

export default class ModBlockSplitColumn extends React.Component<ModBlockSplitColumnProps, {}> {

  render() {
    const { content } = this.props

    const blockList = content.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      return { typeKey, content }
    })

    let html = blockList.reduce((itemList, item, index) => {
      const { typeKey, content } = item

      let blockMarkup = null
      switch (typeKey) {
        case 'blockRichText':
          blockMarkup = (
            <ModBlockRichText
              key={index}
              content={content}
            />
          )
          break
        case 'blockImage':
          blockMarkup = (
            <ModBlockImage
              key={index}
              content={{ image: content.image }}
              width={544}
              height={736}
              linkObject={content.link}
            />
          )
          break

        default:
          blockMarkup = null
      }

      itemList.push(blockMarkup)
      return itemList
    }, [])

    return (
      <div className={"block-split-column"}>
        <div className={'max-two-columns'}>
          {html}
        </div>
      </div>
    )
  }
}