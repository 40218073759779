import * as React from 'react'
import { injectIntl } from 'react-intl'
import { FlatFinderEntry } from '../../interfaces/Interfaces'
import { NavigationNodeContext } from '../ModApp'
import ModDropdown from '../common/ModDropdown'
import ModRangeSlider from '../common/ModRangeSlider'
import { IsomorphicURLSearchParams } from "../../common/IsomorphicURL";
const url = require('url');

export interface ModBlockFlatFinderFiltersProps {
  entries: FlatFinderEntry[],
  onFilterChange(filteredEntries: FlatFinderEntry[])
  intl: any
}

enum FilterType {
  dropdown,
  slider
}

const FILTERS = [
  {
    name: 'property_type',
    type: FilterType.dropdown,
    is_number: false,
    title: 'Objekt-Typ'
  },
  {
    name: 'building_number',
    type: FilterType.dropdown,
    is_number: false,
    title: 'Gebäude'
  },
  {
    name: 'rooms',
    type: FilterType.dropdown,
    is_number: true,
    title: 'Zimmer'
  },
  {
    name: 'floor',
    type: FilterType.dropdown,
    is_number: true,
    title: 'Etagen'
  },
  {
    name: 'state_simplyfied',
    type: FilterType.dropdown,
    is_number: false,
    title: 'Verfügbarkeit'
  },
  {
    name: 'area',
    type: FilterType.slider,
    is_number: true,
    title: 'Fläche m²'
  },
  {
    name: 'rentalgross',
    type: FilterType.slider,
    is_number: true,
    title: 'Bruttomiete CHF/Mt'
  }
]

function ModBlockFlatFinderFilters(props: ModBlockFlatFinderFiltersProps) {
  const { location, router } = React.useContext(NavigationNodeContext)
  const { entries, onFilterChange } = props


  const [activeFilters, setActiveFilters] = React.useState(() => {

    let initialActiveFilters = {}
    if (location.href) {
      let fetchParams = new IsomorphicURLSearchParams(url.parse(location.href).search)
      fetchParams.forEach((value, key) => {

        const filter = FILTERS.find((filter) => {
          return filter.name == key
        })
        if (filter) {
          switch (filter.type) {
            case FilterType.dropdown: {
              initialActiveFilters[key] = {
                type: FilterType.dropdown,
                value: value
              }
              break;
            }
            case FilterType.slider: {
              const values = value.split('-')
              if (values.length == 2) {
                initialActiveFilters[key] = {
                  type: FilterType.slider,
                  valueFrom: values[0],
                  valueTo: values[1]
                }
              }
              break;
            }
            default:
          }
        }
      })
    }
    return initialActiveFilters
  })
  const [filterInputOpenKey, setFilterInputOpenKey] = React.useState(null)

  React.useEffect(() => {
    filterEntries()
  }, [activeFilters]);

  function filterEntries() {

    let queryParams = new URLSearchParams()
    let filteredEntries = entries
    Object.keys(activeFilters).forEach((key, index) => {
      const filter = activeFilters[key]
      switch (filter.type) {
        case FilterType.dropdown: {
          if (filter.value) {
            filteredEntries = filteredEntries.filter((entry) => {
              return entry[key] == filter.value
            })

            if (filter.value != '') {
              queryParams.append(key, filter.value)
            }
          }

          break;
        }
        case FilterType.slider: {
          if (filter.valueFrom && filter.valueFrom != '' && filter.valueTo && filter.valueTo != '') {
            filteredEntries = filteredEntries.filter((entry) => {
              return entry[key] >= filter.valueFrom && entry[key] <= filter.valueTo
            })

            queryParams.append(key, `${filter.valueFrom}-${filter.valueTo}`)
          }
          break;
        }
        default:
      }

      router.replaceSearchParams(queryParams)
      onFilterChange(filteredEntries)
    })
  }


  const sets = {}
  FILTERS.forEach((filter) => {
    sets[filter.name] = new Set()
  })

  entries.forEach(entry => {
    FILTERS.forEach((filter) => {
      if (entry[filter.name]) {
        sets[filter.name].add(entry[filter.name])
      }
    })
  });

  const filters = []
  FILTERS.forEach((filter, index) => {
    const filterObjects = Array.from(sets[filter.name])
    if (filter.is_number) {
      filterObjects.sort((a: number, b: number) => { return a - b })
    } else {
      filterObjects.sort()
    }

    switch (filter.type) {
      case FilterType.dropdown: {


        const options = filterObjects.map((object) => {
          if (filter.name == 'floor' && object == 0) {
            return {
              value: object.toString(),
              text: 'EG'
            }
          }
          return {
            value: object.toString(),
            text: object.toString()
          }
        })

        filters.push(<ModDropdown
          key={index}
          options={options}
          activeValue={activeFilters[filter.name] ? activeFilters[filter.name].value : null}
          title={filter.title}
          queryKey={filter.name}
          filterOpen={filterInputOpenKey == filter.name}
          onValueChange={onDropdownValueChange}
          onToggle={onDropdownToggle} />)
        break;
      }
      case FilterType.slider: {
        const options = filterObjects.map((object) => {
          return {
            value: Number(object),
            text: Number(object)
          }
        })

        filters.push(<ModRangeSlider
          key={index}
          options={options}
          activeValueFrom={activeFilters[filter.name] ? activeFilters[filter.name].valueFrom : null}
          activeValueTo={activeFilters[filter.name] ? activeFilters[filter.name].valueTo : null}
          title={filter.title}
          queryKey={filter.name}
          onValueChange={onRangeSliderValueChange} ></ModRangeSlider>)
        break;
      }
      default:
    }
  })


  function onDropdownValueChange(key: string, value?: string) {
    const filterObjects = {}
    filterObjects[key] = {
      type: FilterType.dropdown,
      value: value
    }

    setActiveFilters(Object.assign({}, activeFilters, filterObjects))
  }

  function onRangeSliderValueChange(key: string, valueFrom?: number, valueTo?: number) {
    const filterObjects = {}
    filterObjects[key] = {
      type: FilterType.slider,
      valueFrom: valueFrom,
      valueTo: valueTo
    }

    setActiveFilters(Object.assign({}, activeFilters, filterObjects))
  }

  function onDropdownToggle(key: string, open: boolean) {
    if (open) {
      setFilterInputOpenKey(key)
    } else {
      setFilterInputOpenKey(null)
    }
  }

  return (
    <div className="cell theme-color">
      {filters}
    </div>
  )
}

export default injectIntl(ModBlockFlatFinderFilters)