import * as React from "react";

import ModBlockImage from "./ModBlockImage";
import ModBlockVimeo from "./ModBlockVimeo";
import ModEditBlock from "../common/ModEditBlock";
import RootState from "../../vo/RootState";

export interface ModBlockCellMediaSquareProps {
  rootState: RootState;
  content: any[];
}

export default class ModBlockCellMediaSquare extends React.Component<
  ModBlockCellMediaSquareProps,
  {}
> {
  render() {
    const { content, rootState } = this.props;

    const typeKey = Object.keys(content)[0];

    let itemList = [];

    let blockMarkup = null;
    switch (typeKey) {
      case "blockImage":
        blockMarkup = (
          <ModBlockImage
            key={1}
            content={{ image: content[typeKey].image }}
            width={736}
            height={736}
            linkObject={content[typeKey].link}
          />
        );
        break;
      // case 'blockVimeo':
      //   blockMarkup = (
      //     <ModBlockVimeo key={1} content={content[typeKey]} autoplay={true} loop={true} />
      //   )
      //   break
      default:
        blockMarkup = null;
    }

    if (rootState.editMode && blockMarkup) {
      itemList.push(
        <ModEditBlock
          key={"_" + 0}
          entityObject={content[typeKey].block}
          title={typeKey}
          rootState={rootState}
        />
      );
    }

    itemList.push(blockMarkup);

    return <div className={"block-cell-media-square"}>{itemList}</div>;
  }
}
