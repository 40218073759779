import * as React from 'react'
import RootState from "../../vo/RootState";

export interface ModEditBlockProps {
  entityObject: {
    _id: string
    _type: string
  }
  title: string
  rootState: RootState
}


export default class ModEditBlock extends React.Component<ModEditBlockProps, {}> {

  render() {
    const { entityObject, title, rootState } = this.props

    if (!entityObject) {
      console.warn(`entityObject for ${title} is missing`)
      return null
    }

    return (
      <div className="edit-mode">
        <div className="edit-mode-img">
          <img className="" src={require("static/img/karmaLogo.png")} />
        </div>
        <div className="edit-mode-text">
          <p>{title}</p>
          <a
            href={rootState.publicConfig.caasEditorUrl + '/entries/' + entityObject._type + '/edit/' + entityObject._id}
            target="_blank" rel="noopener">EDIT</a>
        </div>
      </div>)
  }
}
