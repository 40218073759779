import * as React from 'react'
import { FlatFinderEntry } from '../../interfaces/Interfaces';
import { getEntityRanges } from '../../common/DraftJsToReact';

export interface ModIsometrySvgFlatProps {
  entries: FlatFinderEntry[]
  activeFlatEntry?: FlatFinderEntry
  flat: string
  floor: string
  building: string
  children?: React.ReactNode
  onFlatClick?: (FlatFinderEntry) => void
  onBuyFlatClick?: () => void
}

export interface ModIsometrySvgFlatState {
  isFlatActive: boolean
}

export default class ModIsometrySvgFlat extends React.Component<ModIsometrySvgFlatProps, ModIsometrySvgFlatState, {}> {
  render() {
    const invalidateChildren = (children) => {
      if (typeof children === 'string') {
        return { children }
      }
      else if (typeof children === 'undefined') {
        return null
      }
      return children
    }

    let children = invalidateChildren(this.props.children)


    const flatEntry = this.props.entries.find((entry) => {
      return entry.title == this.props.flat
    })

    const getStateSimplyfied = (entry) => {
      return entry.state_simplyfied
    }

    // const scrollToEntry = (entry) => {
    //   const myElement = document.getElementById('list-' + entry.title);
    //   const topPos = myElement.offsetTop;
    //   const headerHeight = document.getElementById('block-flat-finder__list__header').offsetHeight;
    //   document.getElementById('block-flat-finder__list__y-scroll').scrollTop = topPos - headerHeight;
    // }


    let onClickFn
    if (this.props.onBuyFlatClick) {
      onClickFn = (() => { this.props.onBuyFlatClick(); })
    } else {
      onClickFn = flatEntry ? (() => { this.props.onFlatClick(flatEntry); }) : null
    }
    const stateSimplyfied = flatEntry ? getStateSimplyfied(flatEntry) : null
    const title = this.props.activeFlatEntry ? this.props.activeFlatEntry.title : null

    return (
      <g id={this.props.flat}
        className={'isometry__flat isometry__flat--' + stateSimplyfied + (title === this.props.flat ? ' active' : '')}
        onClick={onClickFn}>
        {children}
      </g>
    )
  }
}
