import * as React from 'react'

import { Footer } from "../interfaces/Interfaces";
import ModAnchorTag from "./common/ModAnchorTag";
import ModImgTag from "./common/ModImgTag";
import ModRichText from "./common/ModRichText";

export interface ModFooterProps {
  content: Footer,
  currentLanguage: string
}

export default class ModFooter extends React.Component<ModFooterProps, {}> {

  render() {
    const { content, currentLanguage } = this.props

    if (!content) {
      return null
    }

    const i18n = content.i18n.hasOwnProperty(currentLanguage) ? content.i18n[currentLanguage] : null
    if (!i18n) {
      return null
    }

    return (
      <footer id="footer" className="centered">
        <ModRichText richText={i18n.text} />
      </footer>
    )
  }
}
