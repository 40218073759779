import * as React from 'react'
import ModLazyLoad, { ModLazyLoadState } from "../common/ModLazyLoad";
import { BlockSnippet } from "../../interfaces/Interfaces";
import ModLazyLoadView from "../common/ModLazyLoadView";

export interface ModBlockSnippetProps {
  content: BlockSnippet
}

export default class ModBlockSnippet extends ModLazyLoad<ModBlockSnippetProps, ModLazyLoadState> {

  render() {
    const { content } = this.props

    const createMarkup = (content) => {
      return { __html: content }
    }

    let className = ""
    let style = null
    if (content.dimension.aspectRatio) {
      className = "aspect-ratio"
      let aspectRatio = content.dimension.aspectRatio.split(':')
      style = {
        paddingBottom: ((Number(aspectRatio[1]) / Number(aspectRatio[0])) * 100) + '%'
      }
    }
    else if (content.dimension.fixedHeight) {
      style = {
        height: content.dimension.fixedHeight + 'px'
      }
    }

    let markup
    if (this.state.visible) {
      markup = <div className={className} style={style} dangerouslySetInnerHTML={createMarkup(content.snippet)} />
    }
    else {
      markup = (
        <div className="aspect-ratio" style={style}>
          <ModLazyLoadView rRef={(input) => {
            this.lazyComponent = input
          }} />
        </div>
      )
    }

    return (
      <div className="block-snippet centered">
        {markup}
      </div>
    )
  }
}
