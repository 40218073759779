import * as React from 'react'
import { RangeSliderOption } from '../../interfaces/Interfaces'
import Slider, { Range } from 'rc-slider';


export interface ModRangeSliderProps {
  options: RangeSliderOption[]
  activeValueFrom?: number,
  activeValueTo?: number,
  title: string
  queryKey: string
  onValueChange: (key: string, valueFrom: number, valueTo: number) => void
}


export default function ModRangeSlider(props: ModRangeSliderProps) {
  const { options, title, queryKey, activeValueFrom, activeValueTo, onValueChange } = props

  if (options.length <= 1) {
    return null
  }

  const rangeSliderStartValueMin = options[0].value
  const rangeSliderStartValueMax = options[options.length - 1].value
  const rangeSliderStartValue = Number(activeValueFrom) || rangeSliderStartValueMin
  const rangeSliderEndValue = Number(activeValueTo) || rangeSliderStartValueMax
  const [rangeSliderValue, setRangeSliderValue] = React.useState([rangeSliderStartValue, rangeSliderEndValue])

  function changeRangeSliderValue(event){
   setRangeSliderValue(event)
  }

  function changeValue(){
    if(rangeSliderValue[0] == rangeSliderStartValueMin && rangeSliderValue[1] == rangeSliderStartValueMax){
      onValueChange(queryKey, null, null)
    } else {
      onValueChange(queryKey, rangeSliderValue[0], rangeSliderValue[1])
    }
  }

  return (
    <div className="range-slider">
      <label htmlFor="rangeSlider">{title}: {rangeSliderValue[0]} – {rangeSliderValue[1]}</label>
      <Range min={rangeSliderStartValueMin} max={rangeSliderStartValueMax} step={1} value={rangeSliderValue} onChange={changeRangeSliderValue} onAfterChange={changeValue} />
    </div>
  )
}