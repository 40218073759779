import * as React from 'react'
import ModImgTag from "../common/ModImgTag";
import { BlockGrid5 } from "../../interfaces/Interfaces";

export interface ModBlockGrid5Props {
  content: BlockGrid5
}

export default class ModBlockGrid5 extends React.Component<ModBlockGrid5Props, {}> {

  render() {
    const { content } = this.props

    const list = content.list.map((item, index) => {
      return (
        <div key={index} className="cell">
          <ModImgTag imgObject={item.image} width={200} height={100} />
        </div>
      )
    })
    return (
      <div className="block-grid-5 centered">
        {list}
      </div>
    )
  }
}
