import * as docCookies from "mozilla-doc-cookies";

export const goldenRatio = 1.61803399

export function getGoldenRationByWidth(width: number) {
  return Math.round(width / goldenRatio)
}

export function isInt(data) {
  return data === parseInt(data, 10)
}

export function isEmptyObject(data) {
  return JSON.stringify(data) === "{}"
}

export function draftJsHasContent(obj) {
  if (obj && obj.blocks) {

    for (const block of obj.blocks) {
      if (block.text) return true
    }
  }
  return false
}

/**
 *
 * @param property
 * @returns {boolean}
 */
export function hasContent(property: any) {
  if (typeof property === 'string') {
    return property !== ''
  }

  if (property instanceof Array) {
    return property.length > 0
  }

  if (property instanceof Object) {
    for (let key in property) {
      if (property.hasOwnProperty(key))
        return true;
    }
  }


  return false
}

/**
 *
 * @param element
 * @param className
 */
export function addClassToElement(element, className) {
  if (element.classList)
    element.classList.add(className)
  else
    element.className += ' ' + className
}

/**
 *
 * @param element
 * @param className
 */
export function removeClassFromElement(element, className) {
  if (element.classList)
    element.classList.remove(className)
  else
    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
}

export function aspectRationToPercentHeight(aspectRatio: string): string {
  let wh = aspectRatio.split(':')
  const result = Number.parseFloat(wh[1]) / Number.parseFloat(wh[0]) * 100
  if (isNaN(result) || result <= 0) {
    throw new Error("wrong aspect ratio")
  }

  return result.toPrecision(5)
}

/**
 * removes slashes at the beginning and end of a string
 * @param value
 * @returns {string}
 */
export function stripSlashes(value: string) {
  if (typeof value !== 'string') return value
  return value.replace(/^\//, '').replace(/\/$/, '')
}

/**
 * If media is a mediaId string this function searches the media object in the mediaPool and returns this object.
 * otherwise the function just returns the media param
 * @param mediaPool
 * @param media might be an mediaId string or a media object
 * @returns {*}
 */
export function getMediaObjectById(mediaPool, media) {
  if (typeof media === 'string' && mediaPool && mediaPool.hasOwnProperty(media)) {
    let resultMedia = mediaPool[media]
    resultMedia.id = media
    return resultMedia
  }
  return media
}

export function addCloudinaryParamsToUrl(url: string, cloudinaryParams: string[]) {
  return url.replace(/\/(v\d+)/, '/' + cloudinaryParams.join(','))
}

/**
 * Checks if the initial request was https that a client used to connect to a proxy or load balancer
 */
export function isHttps(req): boolean {
  return req.headers['x-forwarded-proto'] === 'https'
}

/**
 * returns a random number between the specified values.
 */
export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

/**
 * Randomize array element order
 */
export function shuffleArray(array: Array<any>) {
  return array.sort(function () {
    return .5 - Math.random()
  })
}

export function getJsonCookie(cookieName: string) {
  if (typeof window !== 'undefined' && cookieName) {
    const object = docCookies.getItem(cookieName)
    if (object) {
      return JSON.parse(object)
    }
  }
  return null
}

export function setJsonCookie(cookieName, json) {
  if (json && typeof window !== 'undefined') {
    docCookies.setItem(cookieName, JSON.stringify(json), Infinity, '/')
  }
}

/**
 * magically pulls the correct lang objects out of i18n maps and stores them in __i18n_current_lang___ in the same struct.
 * @param obj
 * @param currentLanguage
 */
export function addCurrentLangRecursive(obj: object, currentLanguage: string) {
  // TODO make this pure
  if (!(typeof obj === 'object' && currentLanguage)) {
    return
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let item = obj[key]
      if (key === 'i18n') {
        obj['_i18nCL'] = item.hasOwnProperty(currentLanguage) ? item[currentLanguage] : null
      }
      if (item instanceof Array) {
        addCurrentLangRecursive(item, currentLanguage)
      }
      if (typeof item === 'object') {
        addCurrentLangRecursive(item, currentLanguage)
      }
    }
  }
}

/**
 *
 * @param array
 * @param sortKey
 * @returns {*}
 */
export function sortBy(array, sortKey) {
  return array.sort((a, b) => {
    let nameA = a[sortKey]
    let nameB = b[sortKey]
    if (typeof nameA === "string" && typeof nameB === "string") {
      nameA = nameA.toUpperCase();
      nameB = nameB.toUpperCase();
    }
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  })
}


export function defaultDynamicImports(moduleWrapper) {
  return moduleWrapper.default
}