import * as React from 'react'
import ModImgTag from '../common/ModImgTag'
import { BlockSlider } from "../../interfaces/Interfaces";
import ModIdangerousSwiper from '../common/ModIdangerousSwiper'
import ModLazyLoad from '../common/ModLazyLoad'
import { hasContent } from '../../common/Utils'
import { ModLazyLoadState } from "../common/ModLazyLoad";
import ModLazyLoadView from "../common/ModLazyLoadView";
import { RefObject } from "react";

const MAX_IMG_SIZE = 1000

export interface ModBlockSliderProps {
  content: BlockSlider
  index: number
}

export default class ModBlockSlider extends ModLazyLoad<ModBlockSliderProps, ModLazyLoadState> {

  myRef: RefObject<ModIdangerousSwiper>

  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      visible: false
    }
  }

  render() {
    const { content, index } = this.props
    if (!hasContent(content.list)) {
      return null
    }

    let inlineSlider = null
    if (this.state.visible) {
      inlineSlider = content.list.map((item, i) => {

        return (
          <div className="swiper-slide" key={i}>
            <div className="block-slider-img-container">
              <div className="aspect-ratio">
                <ModImgTag imgObject={item.image} width={1118} height={736} swiperLazy={true} />
                <div className="swiper-lazy-preloader" />
              </div>
            </div>
          </div>)
      })
      inlineSlider = (
        <ModIdangerousSwiper id={"block_slider_" + index}
          ref={this.myRef}
          hasNavigation={true}
          hasPagination={false}
          maxPaginationCount={10}
          hasSlideNumber={false}>
          {inlineSlider}
        </ModIdangerousSwiper>
      )
    }
    else {
      inlineSlider = <div className="aspect-ratio">
        <ModLazyLoadView rRef={(input) => {
          this.lazyComponent = input
        }} />
      </div>
    }


    return (
      <div className="block-slider stylized-focus">
        {inlineSlider}
      </div>
    )
  }
}
