export const maps = (() => {
  if (typeof google !== 'undefined') {
    return google.maps
  }
})()

export const googleAnalytics = (() => {
  if (typeof ga !== 'undefined') {
    return ga
  }
})()

export const reCAPTCHA = (() => {
  if (typeof grecaptcha !== 'undefined') {
    return grecaptcha
  }
})()
