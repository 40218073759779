import * as React from 'react'

export interface ModLazyLoadState {
  visible: boolean
}

/**
 * use the state visible to determine what components should be visible
 */
export default class ModLazyLoad<P, S extends ModLazyLoadState> extends React.Component<P, any> {

  lazyComponent: Element
  observer: IntersectionObserver

  constructor(props) {
    super(props)

    this.lazyComponent = null
    this.state = { visible: false }

    this.startObserve = this.startObserve.bind(this)
    this.stopObserve = this.stopObserve.bind(this)
    this.callBack = this.callBack.bind(this)

    if (typeof window !== 'undefined' && typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(this.callBack, {
        rootMargin: '100px',
        threshold: 0.1
      })
    }
  }

  componentDidMount() {
    this.startObserve()
  }

  componentDidUpdate() {
    this.startObserve()
  }

  componentWillUnmount() {
    this.stopObserve()
  }

  startObserve() {
    this.stopObserve()
    if (this.observer && this.lazyComponent) {
      this.observer.observe(this.lazyComponent)
    }
  }

  stopObserve() {
    if (this.observer && this.lazyComponent) {
      this.observer.unobserve(this.lazyComponent)
    }
  }

  callBack(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.setState({ visible: true })
      }
    })
  }

}
