import * as React from "react";
import { FormattedMessage } from "react-intl";

import ModAnchorTag from "./common/ModAnchorTag";
import ModImgTag from "./common/ModImgTag";
import NavLink from "../components/common/NavLink";
import { hasContent } from "../common/Utils";
import { NavigationNode, BlockAnchor } from "../interfaces/Interfaces";
import {
  isArticleActiveNow,
  searchNavigationNodeByUrl,
} from "../common/CmsUtils";
import RootState, { LoadingState } from "../vo/RootState";
import { offlineImage } from "../common/offlineMedia";
import { NavigationNodeContext } from "./ModApp";

export interface ModNavigationState {
  menuOpen: boolean;
  activeNodeId: string;
}

export interface ModNavigationProps {
  navigationTree: NavigationNode;
  rootState: RootState;
  currentLanguage: string;
  anchor: string;
  pathname: string;
}

export default class ModNavigation extends React.Component<
  ModNavigationProps,
  ModNavigationState
> {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      activeNodeId: null,
    };

    this.createNavigationNode = this.createNavigationNode.bind(this);
    this.createLanguageNodes = this.createLanguageNodes.bind(this);
    this.removeLanguageNavigationNode =
      this.removeLanguageNavigationNode.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleSubNavigation = this.toggleSubNavigation.bind(this);
  }

  onMouseDown(event) {
    event.preventDefault();
  }

  openMenu(event) {
    this.setState({ menuOpen: true });
  }

  closeMenu(event) {
    this.setState({
      menuOpen: false,
      activeNodeId: null,
    });
  }

  toggleSubNavigation(event) {
    const nodeId = event.target.dataset.id;
    this.setState({
      activeNodeId: nodeId == this.state.activeNodeId ? null : nodeId,
    });
  }

  createNavigationNode(
    rootState: RootState,
    nodes: NavigationNode[],
    pathname: string,
    anchor: string,
    currentLanguage: string
  ) {
    function isNodeVisible(node, i18n) {
      if (!i18n.showInMenu) {
        return false;
      }
      if (hasContent(node.children)) {
        return true;
      }
      if (node.content) {
        return isArticleActiveNow(node.content, currentLanguage);
      }
      return true;
    }

    if (nodes.length < 1) {
      return null;
    }
    return nodes.reduce((prevItem, item, index) => {
      if (!item._i18nCL) {
        return prevItem;
      }
      if (!isNodeVisible(item, item._i18nCL)) {
        return prevItem;
      }

      let anchorNavigation;
      if (pathname == item._i18nCL.relativeUrl) {
        anchorNavigation = this.createAnchorNavigation(
          rootState.content,
          pathname,
          anchor,
          "mobile-anchor-navigation"
        );
      }

      prevItem.push(
        <li key={index} onClick={this.closeMenu}>
          <NavLink to={item._i18nCL.relativeUrl}>{item._i18nCL.label}</NavLink>
          {anchorNavigation}
        </li>
      );

      return prevItem;
    }, []);
  }

  createLanguageNodes(
    rootNode: NavigationNode,
    currentLanguage: string,
    pathname: string
  ) {
    let languageNodes = [];
    Object.entries(rootNode.i18n).forEach((entry) => {
      const [langKey, lang] = entry;
      if (lang.label) {
        const fountNode = searchNavigationNodeByUrl(
          rootNode,
          pathname,
          currentLanguage
        );
        if (
          fountNode &&
          fountNode.i18n.hasOwnProperty(currentLanguage) &&
          fountNode.i18n[currentLanguage].label
        ) {
          languageNodes.push(
            <NavLink key={langKey} to={fountNode.i18n[langKey].relativeUrl}>
              {lang.label}
            </NavLink>
          );
        } else {
          languageNodes.push(
            <NavLink key={langKey} to={lang.relativeUrl}>
              {lang.label}
            </NavLink>
          );
        }
      }
    });

    return (
      <div className="navigation-meta">
        <FormattedMessage id="navigation.language" defaultMessage="Language" />:
        <div className="navigation-languages">{languageNodes}</div>
      </div>
    );
  }

  createAnchorNavigation(content, path, current_anchor: string, className) {
    if (!content) return;
    let anchors = [];
    if (content.article.content.hasOwnProperty("templateContent")) {
      const templateData = content.article.content.templateContent;
      anchors = templateData.content
        .filter((_item) => {
          const typeKey = Object.keys(_item)[0];
          return typeKey == "blockAnchor";
        })
        .map((_item) => {
          const typeKey = Object.keys(_item)[0];
          return _item[typeKey];
        });
    }

    if (anchors.length > 0) {
      const anchorNavigationList = anchors.map((anchor, index) => {
        return (
          <li
            key={index}
            className={current_anchor == "#" + anchor.anchor ? "active" : ""}
            onClick={this.closeMenu}
          >
            <NavLink to={path + "#" + anchor.anchor}>
              {anchor.navigation_title}
            </NavLink>
          </li>
        );
      });

      return <ul className={className}>{anchorNavigationList}</ul>;
    }
    return null;
  }

  removeLanguageNavigationNode(nodes, currentLanguage) {
    if (!(nodes instanceof Array)) {
      return null;
    }
    for (let key in nodes) {
      if (nodes.hasOwnProperty(key)) {
        let node = nodes[key];
        if (node.slug === currentLanguage) {
          return node;
        }
      }
    }
  }

  render() {
    const { menuOpen } = this.state;
    const { navigationTree, currentLanguage, pathname, anchor, rootState } =
      this.props;
    // const langNavigation = this.createLanguageNodes(navigationTree, currentLanguage, pathname)

    const socialMediaNew = rootState.websiteSettings.socialMedia.reduce(
      (prevItem, item, index) => {
        if (item.hasOwnProperty("image")) {
          prevItem.push(
            <ModAnchorTag key={index} linkObject={item.link}>
              <ModImgTag
                width={40}
                height={40}
                imgObject={item.image}
                transformation={"c_crop,r_max"}
              />
            </ModAnchorTag>
          );
        }
        return prevItem;
      },
      []
    );

    let mainNavigation = this.createNavigationNode(
      rootState,
      navigationTree.children,
      pathname,
      anchor,
      currentLanguage
    );
    let anchorNavigation = this.createAnchorNavigation(
      rootState.content,
      pathname,
      anchor,
      "anchor-navigation"
    );

    let loadingState = null;
    if (rootState.loadingState === LoadingState.loading) {
      loadingState = (
        <img
          alt="loading indicator"
          className="loading-state"
          src={require("static/img/loading.gif")}
        />
      );
    } else if (rootState.loadingState === LoadingState.offline) {
      loadingState = (
        <img alt="offline icon" className="loading-state" src={offlineImage} />
      );
    }

    return (
      <div id="navigation">
        <div className="centered">
          {/*{loadingState}*/}
          {/*{langNavigation}*/}
          <button
            id="btn-menu"
            type="button"
            className="no-border"
            onClick={this.openMenu}
            onMouseDown={this.onMouseDown}
          >
            <img
              src={require("static/img/svg/but_burger.svg")}
              alt="open menu"
            />
          </button>
          <div className={"main " + (menuOpen ? "open" : "closed")}>
            <button
              key="close-btn"
              id="btn-close-menu"
              type="button"
              className="no-border"
              onClick={this.closeMenu}
              onMouseDown={this.onMouseDown}
            >
              <img
                src={require("static/img/svg/but_close.svg")}
                alt="close menu"
              />
            </button>
            <ul className="main-navigation">{mainNavigation}</ul>
            {anchorNavigation}
          </div>
          <div className={"social-media"}>{socialMediaNew}</div>
        </div>
      </div>
    );
  }
}
