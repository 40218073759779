import * as React from 'react'

import { BlockHeader } from "../../interfaces/Interfaces";
import ModImgTag from "../common/ModImgTag";
import ModRichText from "../common/ModRichText";
import { draftJsHasContent } from "../../common/Utils";

export interface ModBlockHeaderProps {
  content: BlockHeader
}

export default class ModBlockHeader extends React.Component<ModBlockHeaderProps, {}> {

  render() {
    const { content } = this.props

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }
    const i18n = content._i18nCL

    let logo = null
    if (content.logo) {
      logo = <div className="logo">
        <ModImgTag imgObject={content.logo} width={60} height={60} imagesShouldWorkIfBrowserJsIsDisabled={true} />
      </div>
    }

    return (
      <div className="block-header aspect-ratio">
        <ModImgTag className="bg" imgObject={content.bgImage} />
        {logo}
        <ModRichText richText={i18n.text} />
      </div>
    )
  }
}