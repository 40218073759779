import * as React from 'react'
import { BlockAnchor } from "../../interfaces/Interfaces";

export interface ModBlockAnchorProps {
  content: BlockAnchor
}

export default class ModBlockAnchor extends React.Component<ModBlockAnchorProps, {}> {

  render() {
    const { content } = this.props

    return (
      <div className={"block-anchor"}>
        <a id={"anchor_" + content.anchor.toLowerCase()} />
      </div>


    )
  }
}