import * as React from 'react'
import { IsomorphicURLSearchParams } from "../../common/IsomorphicURL";

import { Link, NavigationNode } from "../../interfaces/Interfaces";
import ModSvg from "./ModSvg";
import NavLink from "./NavLink";
import { NavigationNodeContext } from "../ModApp"
import { getCurrentLanguageOrFallBackByPath } from "../../common/Languages";
import { findNavigationNodeById } from "../../common/CmsUtils";

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export interface ModAnchorTagProps {
  linkObject: Link
  hasIcon?: boolean
  className?: string
}

export default class ModAnchorTag extends React.Component<ModAnchorTagProps, {}> {

  static getLinkIconType(linkObject, hasIcon) {
    if (!hasIcon) return null

    let iconType = null
    if (linkObject.hasOwnProperty('url')) {
      if (linkObject.url === '') {
        iconType = <ModSvg url={require("static/img/svg/but_link_doc.svg")} id="icon" />
      }
      if (linkObject.url.startsWith('mailto:')) {
        iconType = <ModSvg url={require("static/img/svg/but_link_filled.svg")} id="icon" />
      }
      else {
        iconType = <ModSvg url={require("static/img/svg/but_link_external_filled.svg")} id="icon" />
      }
    }
    else if (linkObject.hasOwnProperty('navigationNode')) {
      iconType = <ModSvg url={require("static/img/svg/but_link_filled.svg")} id="icon" />
    }
    else if (linkObject.hasOwnProperty('overlay')) {
      iconType = <ModSvg url={require("static/img/svg/but_link_filled.svg")} id="icon" />
    }
    else if (linkObject.hasOwnProperty('document')) {
      if (IMAGE_TYPES.indexOf(linkObject.document.type) > -1) {
        iconType = <ModSvg url={require("static/img/svg/but_link_img.svg")} id="icon" />
      } else {
        iconType = <ModSvg url={require("static/img/svg/but_link_doc.svg")} id="icon" />
      }
    }
    return iconType
  }


  static invalidateChildren(children) {
    if (typeof children === 'string' || children instanceof Array) {
      return <span>{children}</span>
    }
    else if (typeof children === 'undefined') {
      return null
    }
    return children
  }


  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const { linkObject, hasIcon } = this.props

    let className = ""
    if (this.props.className) {
      className = this.props.className
    }

    try {
      const linkIcon = ModAnchorTag.getLinkIconType(linkObject, hasIcon)
      if (linkObject.hasOwnProperty('url')) {
        if (linkObject.url === '') {
          return children
        }
        if (linkObject.url.startsWith('mailto:')) {
          return <a className={"a-mail " + className} href={linkObject.url}>{linkIcon}{children}</a>
        }
        return <a className={"a-external " + className} href={linkObject.url} target="_blank"
          rel="noopener">{linkIcon}{children}</a>
      }
      else if (linkObject.hasOwnProperty('overlay') && typeof linkObject.overlay === 'string') {
        return <NavigationNodeContext.Consumer>
          {({ location, rootNavigationNode }) => {
            let urlSearchParams = new IsomorphicURLSearchParams(`overlay=${linkObject.overlay}`)
            return <NavLink className={"a-overlay " + className}
              to={{
                pathname: location.pathname,
                query: urlSearchParams
              }}>{children}</NavLink>
          }}
        </NavigationNodeContext.Consumer>
      }
      else if (linkObject.hasOwnProperty('internalLink')) {
        const internalLink = linkObject.internalLink

        return <NavigationNodeContext.Consumer>
          {({ location, rootNavigationNode }) => {

            let navigationNode: NavigationNode
            if (typeof internalLink.node === 'string') {
              navigationNode = findNavigationNodeById(internalLink.node, rootNavigationNode)
            }
            else {
              navigationNode = findNavigationNodeById(internalLink.node._id, rootNavigationNode)
            }

            const currentLanguage = getCurrentLanguageOrFallBackByPath(location.pathname)
            const i18n = navigationNode.i18n.hasOwnProperty(currentLanguage) ? navigationNode.i18n[currentLanguage] : null
            if (internalLink.anchor) {
              return <NavLink className={"a-internal " + className}
                to={{
                  pathname: i18n.relativeUrl,
                  hash: internalLink.anchor.toLowerCase(),
                }}>{linkIcon}{children}</NavLink>
            }
            return <NavLink className={"a-internal " + className} to={{
              pathname: i18n.relativeUrl
            }}>{linkIcon}{children}</NavLink>
          }}
        </NavigationNodeContext.Consumer>
      }

      else if (linkObject.hasOwnProperty('document') && typeof this.props.linkObject.document === 'object') {
        let mediaUrl = linkObject.document.media.url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.media) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.media.url
        }
        return <a className={"a-document " + className} href={mediaUrl}
          target="_blank" rel="noopener">{linkIcon}{children}</a>
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}
