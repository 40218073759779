import {stripSlashes} from "./Utils";
import {CloudinaryMedia, IStateController, NavigationNode, Page} from "../interfaces/Interfaces";

/**
 *
 * @param page
 * @param currentLanguage
 * @returns {boolean}
 */
export function isArticleActiveNow(page: Page, currentLanguage: string): boolean {
  if (!page) {
    return false
  }

  const now = new Date().getTime()
  if (page.expiryDate && now > new Date(page.expiryDate).getTime()) {
    return false
  }

  if (page.publishDate && now < new Date(page.publishDate).getTime()) {
    return false
  }

  const i18n = page.i18n.hasOwnProperty(currentLanguage) ? page.i18n[currentLanguage] : null
  return i18n && i18n.isLive
}


/**
 * searches an navigation node by his url and returns his id or null if not found
 * @param node
 * @param searchRelativeUrl
 * @param currentLanguage
 * @returns String|null
 */
export function searchNavigationNodeByUrl(node: NavigationNode, searchRelativeUrl: string, currentLanguage: string): NavigationNode | null {
  if (!node) return null

  if (currentLanguage) {
    // find by provided language
    const i18n = node.i18n.hasOwnProperty(currentLanguage) ? node.i18n[currentLanguage] : null
    if (i18n && stripSlashes(i18n.relativeUrl) === stripSlashes(searchRelativeUrl)) {
      return node
    }
  }
  else {
    const found = Object.entries(node.i18n).find((item) => {
      const [langKey, i18n] = item
      return stripSlashes(i18n.relativeUrl) === langKey + '/' + stripSlashes(searchRelativeUrl)
    })
    if (found) {
      return node
    }
  }

  for (let childNode of Object.values(node.children)) {
    const foundNode = searchNavigationNodeByUrl(childNode, searchRelativeUrl, currentLanguage)
    if (foundNode) {
      return foundNode
    }
  }
}


export function getArticleByNodeId(pagePool: Page[], nodeId: string): Page | null {
  if (!pagePool) {
    return null
  }
  for (let page of Object.values(pagePool)) {
    if (page.navigationNode === nodeId) {
      return page
    }
  }
  return null
}

/**
 * replaces media domain in the url field for nested results
 * @param voConfig
 * @param result
 */
export function replaceMediaDomainRecursive(voConfig: IStateController, result: object): void {
  if (!(typeof result === 'object')) {
    return
  }
  for (let object of Object.entries(result)) {
    const [key, item] = object
    if (key === 'media' &&
      (item as CloudinaryMedia).backend &&
      (item as CloudinaryMedia).backend.cloudinary &&
      (item as CloudinaryMedia).mimeType) {
      const media: CloudinaryMedia = item
      item.url = `https://${voConfig.publicConfig.mediaProxy}/${voConfig.publicConfig.cloudinaryCloudName}/image/upload/${media.backend.cloudinary.public_id}.${media.backend.cloudinary.format}`
    }
    if (item instanceof Array) {
      replaceMediaDomainRecursive(voConfig, item)
    }
    if (typeof item === 'object') {
      replaceMediaDomainRecursive(voConfig, item)
    }
  }
}

export function createNodes(navigationNode: NavigationNode, pagePool?: Page[], parentNode?: NavigationNode): void {
  Object.entries(navigationNode.i18n).forEach((item) => {
    const [langKey, i18n] = item
    if (parentNode && parentNode.i18n.hasOwnProperty(langKey)) {
      i18n.relativeUrl = parentNode.i18n[langKey].relativeUrl + '/' + i18n.slug
    }
    else {
      i18n.relativeUrl = '/' + i18n.slug
    }
  })
  if (pagePool) {
    navigationNode.content = getArticleByNodeId(pagePool, navigationNode._id)
  }
  navigationNode.children.forEach((childNode) => {
    createNodes(childNode, pagePool, navigationNode)
  })
}

export function findNavigationNodeById(id: string, rootNavigationNode: NavigationNode): NavigationNode {
  if (rootNavigationNode._id === id) {
    return rootNavigationNode
  }
  for (let childNode of Object.values(rootNavigationNode.children)) {
    const result = findNavigationNodeById(id, childNode)
    if (result) {
      return result
    }
  }
  return null
}