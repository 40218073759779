import * as isoFetch from "isomorphic-fetch";

export default class KarmaApiKit {
  endpoint: string;
  user: string;
  pwd: string;
  signature: string;
  tags: object;
  modelDefinitions: object;

  constructor(endpoint: string, user: string, pwd: string) {
    this.endpoint = endpoint;
    this.user = user;
    this.pwd = pwd;
    this.signature = null;
    this.tags = null;
    this.modelDefinitions = null;

    this.hasSession = this.hasSession.bind(this);
    this.signOut = this.signOut.bind(this);
    this.signIn = this.signIn.bind(this);
    this.getSignature = this.getSignature.bind(this);
    this.setSignature = this.setSignature.bind(this);
    this.query = this.query.bind(this);
    this.fetchTags = this.fetchTags.bind(this);
    this.fetchModelTypes = this.fetchModelTypes.bind(this);
    this.getContentIdByTag = this.getContentIdByTag.bind(this);
    this.flowQuery = this.flowQuery.bind(this);
  }

  hasSession() {
    return hasContent(this.signature);
  }

  getSignature() {
    return this.signature;
  }

  setSignature(signature) {
    this.signature = signature;
  }

  signOut() {
    this.signature = null;
    this.tags = null;
    this.modelDefinitions = null;
  }

  async fetchTags() {
    if (this.tags) {
      return this.tags;
    }
    if (!this.hasSession()) {
      return null;
    }
    let result = await this.query({
      mapList: {
        value: {
          all: {
            tag: "_tag",
          },
        },
        expression: {
          metarialize: {
            id: {},
          },
        },
      },
    });

    this.tags = result.reduce((prevItem, item) => {
      prevItem[item.value.tag] = item.value.model;
      return prevItem;
    }, {});
    return this.tags;
  }

  async fetchModelTypes() {
    if (this.modelDefinitions) {
      return this.modelDefinitions;
    }
    if (!this.hasSession()) {
      return null;
    }
    let result = await this.query({
      mapList: {
        value: {
          all: {
            tag: "_model",
          },
        },
        expression: {
          metarialize: {
            id: {},
          },
        },
      },
    });

    this.modelDefinitions = result.reduce((prevItem, item) => {
      prevItem[item.id] = item.value;
      return prevItem;
    }, {});
    return this.modelDefinitions;
  }

  getContentIdByTag(tag) {
    return this.tags[tag];
  }

  async signIn() {
    const url = this.endpoint + "/auth";
    const headers = new Headers();

    if (!hasContent(this.user)) {
      throw new CaasApiException("no user provided");
    }

    if (!hasContent(this.pwd)) {
      throw new CaasApiException("no pwd provided");
    }

    headers.append("X-Karma-Codec", "json");

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        username: this.user,
        password: this.pwd,
      }),
    };

    const response = await isoFetch(url, options);
    if (response.status !== 200) {
      throw new CaasApiException(response.status + " login was not successful");
    }
    this.signature = await response.json();
    await this.fetchTags();
    await this.fetchModelTypes();
    return this.signature;
  }

  /**
   * executes a karma query.
   * 1. checks if there is a signature
   * 2. tries to sign in if there is no signature provided
   * 3. tries to execute query
   * 4. tries to sign in if the signature is expired (403) and recalls query
   * @param query
   * @param retry after 403
   * @returns {Promise}
   */
  async query(query: any, retry = true) {
    if (!this.hasSession()) {
      await this.signIn();
    }

    if (!hasContent(this.signature)) {
      throw new CaasApiException("no signature provided");
    }

    const headers = new Headers();
    headers.append("X-Karma-Codec", "json");
    headers.append("X-Karma-Signature", this.signature);

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(query),
    };
    const response = await isoFetch(this.endpoint, options);
    const json = await response.json();

    if (response.status === 403 && retry) {
      // session is expired. try to sign in
      await this.signIn();
      return await this.query(query, false);
    }
    if (response.status === 200) {
      return json;
    } else {
      throw new CaasApiException("response fault", query, response.headers, {
        status: response.status,
        statusText: response.statusText,
        body: json,
      });
    }
  }

  async flowQuery(
    query: any,
    alternateTag: string = null
  ): Promise<{ result: any; nested: any }> {
    const tag = alternateTag
      ? alternateTag
      : query.graphFlow.start.newRef.model.tag;
    const result = await this.query(query);
    const typeId = this.tags[tag];
    let objectPool;
    let firstObjectId;
    try {
      objectPool = result[typeId];
      firstObjectId = Object.keys(objectPool)[0];
    } catch (e) {
      throw Error("flowQuery root object not found");
    }
    let nested = objectPool[firstObjectId];
    nested = this.resolveAllGraphFlowReferences(result, nested, typeId);
    nested["_id"] = firstObjectId;
    nested["_type"] = typeId;
    return { result, nested };
  }

  /**
   * recursively resolves all outgoing references starting by the traverseObj.
   * @param apiResult complete result object from a graphFlow query
   * @param traverseObj current object to analyze
   * @param karmaModelTypeId karma model type ID of traverseObj
   * @param path of traverseObj in the current karma model
   */
  resolveAllGraphFlowReferences(
    apiResult: object,
    traverseObj: string | object,
    karmaModelTypeId: string,
    path: (string | number)[] = []
  ) {
    if (!this.hasSession()) {
      return null;
    }

    function deepFind(obj, paths) {
      let current = obj;
      for (let i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }
      return current;
    }

    const modelDefinition = this.modelDefinitions[karmaModelTypeId]; // the complete model definition
    let modelDefinitionByPath = deepFind(modelDefinition, path);
    if (!modelDefinitionByPath) {
      // No Model Definition found for the key. May be the case by _id or _type keys
      return traverseObj;
    }
    let karmaPropertyType = Object.keys(modelDefinitionByPath)[0]; // Like string, struct, list etc.

    if (karmaPropertyType === "or") {
      if (isInt(traverseObj) || isEmptyObject(traverseObj)) {
        return traverseObj;
      }
      if (traverseObj.constructor === Object) {
        let resolvedOrType = false;
        for (let i = 0; i <= modelDefinitionByPath.or.length; i++) {
          const orItem = modelDefinitionByPath.or[i];
          const [type, object] = Object.entries(orItem)[0];
          if (type === "union") {
            let foundType = Object.keys(object).find((item) => {
              return traverseObj.hasOwnProperty(item);
            });
            if (foundType) {
              resolvedOrType = true;
              path = path.concat([karmaPropertyType], i);
              modelDefinitionByPath =
                modelDefinitionByPath[karmaPropertyType][i];
              karmaPropertyType = Object.keys(modelDefinitionByPath)[0];
              break;
            }
          }
        }
        if (!resolvedOrType) {
          return traverseObj;
        }
      } else {
        // TODO we don't know which or case it could be and therefore just abort. As or will be removed on future karma versions we just don't care
        return traverseObj;
      }
    }

    if (karmaPropertyType === "annotation") {
      path = path.concat([karmaPropertyType, "model"]);
      modelDefinitionByPath = modelDefinitionByPath[karmaPropertyType].model;
      karmaPropertyType = Object.keys(modelDefinitionByPath)[0];
    }

    if (karmaPropertyType === "optional") {
      path = path.concat(["optional"]);
      modelDefinitionByPath = modelDefinitionByPath[karmaPropertyType];
      karmaPropertyType = Object.keys(modelDefinitionByPath)[0];
    }

    if (karmaPropertyType === "unique") {
      path = path.concat(["unique"]);
      modelDefinitionByPath = modelDefinitionByPath[karmaPropertyType];
      karmaPropertyType = Object.keys(modelDefinitionByPath)[0];
    }

    if (
      traverseObj instanceof Array &&
      (karmaPropertyType === "list" || karmaPropertyType === "set")
    ) {
      // handles karma list property
      traverseObj = traverseObj.map((arrayItem) => {
        return this.resolveAllGraphFlowReferences(
          apiResult,
          arrayItem,
          karmaModelTypeId,
          path.concat([karmaPropertyType])
        );
      });
    } else if (
      typeof traverseObj === "object" &&
      (karmaPropertyType === "struct" || karmaPropertyType === "union")
    ) {
      // handles karma struct and union property
      for (let [key, value] of Object.entries(traverseObj)) {
        if (traverseObj.hasOwnProperty(key)) {
          traverseObj[key] = this.resolveAllGraphFlowReferences(
            apiResult,
            value,
            karmaModelTypeId,
            path.concat([karmaPropertyType, key])
          );
        }
      }
    } else if (typeof traverseObj === "object" && karmaPropertyType === "map") {
      // handles karma map and union property
      for (let mapKey of Object.keys(traverseObj)) {
        traverseObj[mapKey] = this.resolveAllGraphFlowReferences(
          apiResult,
          traverseObj[mapKey],
          karmaModelTypeId,
          path.concat([karmaPropertyType])
        );
      }
    } else if (typeof traverseObj === "string" && karmaPropertyType === "ref") {
      // handle karma references
      const refModelId = modelDefinitionByPath[karmaPropertyType];
      if (apiResult.hasOwnProperty(refModelId)) {
        let modelPool = apiResult[refModelId];
        if (modelPool.hasOwnProperty(traverseObj)) {
          let recordId = traverseObj;
          traverseObj = this.resolveAllGraphFlowReferences(
            apiResult,
            modelPool[traverseObj],
            refModelId
          );
          traverseObj["_id"] = recordId;
          traverseObj["_type"] = refModelId;
        }
      }
    }
    return traverseObj;
  }
}

export class CaasApiException extends Error {
  public responseHeaders: any;
  public body: any;
  public query: any;

  constructor(message: string, query?: any, responseHeaders?: any, body?: any) {
    super(message);

    this.responseHeaders = responseHeaders;
    this.body = body;
    this.query = query;
    this.message = message;
    this.name = "CaasApiException";
    Object.setPrototypeOf(this, CaasApiException.prototype);
  }

  toString() {
    super.toString();

    let query = "";
    if (this.query) {
      query += "QUERY:\n";
      query += JSON.stringify(this.query) + "\n";
    }
    let headers = "";
    if (this.responseHeaders) {
      headers += "HEADERS:\n";
      try {
        for (let pair of this.responseHeaders.entries()) {
          headers += pair[0] + ": " + pair[1] + "\n";
        }
      } catch (e) {}
    }

    if (this.body && this.body && this.body.body.humanReadableError) {
      let karmaError =
        "KARMA ERROR:\n" + this.body.body.humanReadableError.human;
      return (
        this.name + ": " + this.message + "\n" + query + headers + karmaError
      );
    }

    const body = this.body ? "BODY:\n" + JSON.stringify(this.body) : "";
    return this.name + ": " + this.message + "\n" + query + headers + body;
  }
}

function hasContent(property) {
  return property && property !== "";
}

export function isInt(data) {
  return data === parseInt(data, 10);
}

export function isEmptyObject(data) {
  return JSON.stringify(data) === "{}";
}
