import * as React from 'react'
import { injectIntl } from 'react-intl'
import ModImgTag from "../common/ModImgTag";
import { FlatFinderEntry } from '../../interfaces/Interfaces'
import ModSvg from '../common/ModSvg';

export interface ModBlockFlatFinderListProps {
  entries: FlatFinderEntry[]
  activeFlatEntry?: FlatFinderEntry
  onFlatClick: (FlatFinderEntry) => void
  intl: any
}

function ModBlockFlatFinderList(props: ModBlockFlatFinderListProps) {

  const { entries, onFlatClick, activeFlatEntry } = props

  const sortedEntries = entries.reduce((acc, item) => {
    const key = `${item.building.metropolitan} - ${item.building.adress}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const listBuildings = Object.keys(sortedEntries).map((building, key) => {
    return (
      <div key={key}>
        <div className={'table__building'}><strong>{building}</strong></div>
        {getEntries(sortedEntries[building])}
      </div>
    );
  })

  function getEntries(entries) {
    return entries.map((entry, index) => {
      let applicationUrl = null
      if (entry.url != "" && entry.url != null && entry.state != 'active') {
        applicationUrl = entry.url
      }

      const title = activeFlatEntry ? activeFlatEntry.title : null
      const img = (entry.isometry && entry.isometry != 'https://emonitor.ch/wp-content/uploads/2019/02/missing_file_icon.png') ?
        <div className={'table__el__more__left'}>
          <a href={entry.isometry} target="_blank"><img src={entry.isometry} alt={entry.title} /></a>
        </div> : null
      return <div key={index} id={"list-" + entry.title} className={'table__el table__el--' + entry.state_simplyfied + (title == entry.title ? ' active' : '')}>
        <div className="table__el__wrapper" onClick={(event) => { onFlatClick((title == entry.title && !(event.target instanceof HTMLAnchorElement)) ? null : entry) }}>
          <div className={'table__el__col table__el__col--title'}>{entry.title}</div>
          <div className={'table__el__col table__el__col--property_type'}>{entry.property_type}</div>
          <div className={'table__el__col table__el__col--floor'}>{entry.floor == '0' ? 'EG' : entry.floor}</div>
          <div className={'table__el__col table__el__col--rooms'}>{entry.rooms} </div>
          <div className={'table__el__col table__el__col--area'}>{entry.area}</div>
          <div className={'table__el__col table__el__col--rentalgross'}>{entry.state_simplyfied == 'Vermietet' ? '-' : entry.rentalgross}</div>
          <div className={'table__el__col table__el__col--move_in_date'}>{entry.move_in_date ? entry.move_in_date : '–'}</div>
          <div className={'table__el__col table__el__col--factsheet'}>{entry.factsheet ? <a href={entry.factsheet} target='_blank'><img className="icon" src={require("static/img/svg/pdf.svg")} id="icon" /></a> : '–'}</div>
          <div className={'table__el__col table__el__col--application'}>{entry.state_simplyfied == 'frei' && applicationUrl ? <a href={applicationUrl} target='_blank'>Bewerben</a> : entry.state_simplyfied}</div>
        </div>
        <div className={'table__el__more table__el__more--' + entry.state_simplyfied}>
          {img}
          <div className={'table__el__more__right'}>
            {entry.title != 0 && (<><span className="table__el__more__right__title">Wohnung: {entry.title} <br /></span></>)}
            {entry.move_in_date != 0 && (<><span className="table__el__more__right__move_in_date">Bezugs&shy;termin: {entry.property_type} <br /></span></>)}
            {entry.property_type != 0 && (<><span className="table__el__more__right__property_type">Typ: {entry.property_type} <br /></span></>)}
            {entry.basement_area != 0 && (<><span>Fläche Keller m²: {entry.basement_area} <br /></span></>)}
            {entry.terrace_area != 0 && (<><span>Fläche Terrasse m²: {entry.terrace_area} <br /></span></>)}
            {entry.loggia_area != 0 && (<><span>Fläche Loggia m²: {entry.loggia_area} <br /></span></>)}
            {entry.rentalgross_net && (<><span>Nettomiete CHF/Mt: {entry.rentalgross_net}  <br /></span></>)}
            {entry.incidental_costs && (<><span>Nebenkosten CHF/Mt: {entry.incidental_costs} <br /></span></>)}
            {entry.factsheet && (<><span className="table__el__more__right__factsheet">Grundriss PDF: <a href={entry.factsheet} target='_blank'><img className="icon" src={require("static/img/svg/pdf.svg")} id="icon" /></a><br /> </span></>)}
          </div>
        </div>
      </div>
    })
  }

  const [scrollBarWidth, setScrollBarWidth] = React.useState(20);

  return (
    <div className="block-flat-finder__list theme-color">
      <div className="block-flat-finder__list__scroll" id="block-flat-finder__list__scroll">
        <div className="table table--header" id="block-flat-finder__list__header">
          <div className="table__el">
            <div className={'table__el__col table__el__col--title'}>Wohnung</div>
            <div className={'table__el__col table__el__col--property_type'}>Typ</div>
            <div className={'table__el__col table__el__col--floor'}>Etage</div>
            <div className={'table__el__col table__el__col--rooms'}>Zimmer</div>
            <div className={'table__el__col table__el__col--area'}>Fläche<br />m²</div>
            <div className={'table__el__col table__el__col--rentalgross'}>Bruttomiete<br />CHF/Mt</div>
            <div className={'table__el__col table__el__col--move_in_date'}>Bezugs&shy;termin</div>
            <div className={'table__el__col table__el__col--factsheet'}>Grundriss<br />PDF</div>
            <div className={'table__el__col table__el__col--application'}>Bewerbung</div>
          </div>
        </div>

        <div className="block-flat-finder__list__y-scroll" id="block-flat-finder__list__y-scroll">
          <div className="table table--list">
            {/* {entries.length > 0 ? list : <div>Keine Objekte gefunden</div>} */}
            {listBuildings}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ModBlockFlatFinderList)