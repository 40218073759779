import * as React from 'react'

import { BlockTeaserSpot } from "../../interfaces/Interfaces";
import ModAnchorTag from "../common/ModAnchorTag";
import ModImgTag from "../common/ModImgTag";
import ModRichText from "../common/ModRichText";

export interface ModBlockTeaserSpotProps {
  content: BlockTeaserSpot
}

export default class ModBlockTeaserSpot extends React.Component<ModBlockTeaserSpotProps, {}> {

  render() {
    const { content } = this.props

    const list = content.list.map(function (item, index) {
      return (
        <div key={index} className="cell">
          <ModAnchorTag linkObject={item.link}>
            <ModImgTag imgObject={item.image} width={340} height={340} transformation={'c_crop,r_max'} />
          </ModAnchorTag>
          <ModRichText richText={item._i18nCL.text} />
        </div>
      )
    })

    return (
      <div className="block-teaser-spot centered">
        {list}
      </div>
    )
  }
}