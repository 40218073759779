import * as React from 'react'

import { BlockRichText } from "../../interfaces/Interfaces";
import { draftJsHasContent } from "../../common/Utils";
import ModRichText from "../common/ModRichText";

export interface ModBlockRichTextProps {
  content: BlockRichText
  className?: string
}

export default class ModBlockRichText extends React.Component<ModBlockRichTextProps, {}> {

  render() {
    const { content } = this.props
    const className = this.props.className ? 'block-rich-text' + ' ' + this.props.className : 'block-rich-text'

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }

    const i18n = content._i18nCL

    return (
      <div className={className}>
        <ModRichText richText={i18n.text} />
      </div>
    )
  }
}
