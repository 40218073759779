import * as React from 'react'

import NavLink from "./NavLink"
import ModBlockRichText from "../blocks/ModBlockRichText";
import ModBlockImage from "../blocks/ModBlockImage";
import { BlockRichText } from "../../interfaces/Interfaces";

export interface ModSideBarProps {
  templateData: any
  currentLanguage: string
  children?: React.ReactNode
}

export default class ModSideBar extends React.Component<ModSideBarProps, {}> {

  render() {
    const { templateData, currentLanguage, children } = this.props

    let blockLogo = (
      <div key={-4} className={'block-cell-small'}>
        <div className={'block-image'}>
          <div className={'logo image'}>
            <div className={'aspect-ratio'}>
              <NavLink to={`/${currentLanguage}`}>
                <img className={''} src={require("static/img/svg/icon_tuefwis_logo.svg")} width={356} height={356} alt={'Tüfwis'} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )

    let htmlSidebar = null
    if (templateData && templateData.sidebar) {
      const blockListSidebar = templateData.sidebar.map((_item, index) => {
        const typeKey = Object.keys(_item)[0]
        const content = _item[typeKey]
        return { typeKey, content }
      })

      htmlSidebar = blockListSidebar.reduce((itemList, item, index) => {
        const { typeKey, content } = item

        let blockMarkup = null
        switch (typeKey) {
          case 'blockRichText':
            let className = 'aspect-ratio'
            if ((content as BlockRichText).useThemeColor) {
              className += ' theme-color'
            }
            if ((content as BlockRichText).useWhiteTextColor) {
              className += ' text-color-white'
            }
            blockMarkup = <ModBlockRichText key={index} content={content} className={className} />
            break
          case 'blockImage':
            blockMarkup = <ModBlockImage key={index} content={{ image: content.image }} width={354} height={354} linkObject={content.link} />
            break
          default:
            blockMarkup = null
        }

        itemList.push(<div key={index} className={'block-cell-small'}>{blockMarkup}</div>)
        return itemList
      }, [])
    }

    return (
      <div className="sidebar">
        {blockLogo}
        {htmlSidebar}
        {children}
      </div>
    )
  }
}
