import * as React from 'react'
import { injectIntl } from 'react-intl'
import ModIsometrySvg from '../common/ModIsometrySvg'
import { FlatFinderEntry } from '../../interfaces/Interfaces'

export interface ModBlockFlatFinderIsometryProps {
  entries: FlatFinderEntry[]
  activeFlatEntry?: FlatFinderEntry
  onFlatClick: (flatEntry: FlatFinderEntry) => void
  onBuyFlatClick: () => void
  intl: any
}

function ModBlockFlatFinderIsometry(props: ModBlockFlatFinderIsometryProps) {
  const [currentBuilding, setCurrentBuilding] = React.useState({bulding: null, floor: null})

  React.useEffect(() => {
    if (props.activeFlatEntry){
      setCurrentBuilding({bulding: props.activeFlatEntry.building_number, floor: props.activeFlatEntry.floor});
    } else{
      setCurrentBuilding({bulding: null, floor: null});
    }
  }, [props.activeFlatEntry]);



  return (
    <div>
      <div className="isometry__map">
        <div className="isometry__signs">
          <div className={'isometry__sign isometry__sign--a' + (currentBuilding.bulding === "A" && currentBuilding.floor !== "5" ? ' active' : '')}>A</div>
          <div className={'isometry__sign isometry__sign--b' + (currentBuilding.bulding === "B" && currentBuilding.floor !== "5" ? ' active' : '')}>B</div>
          <div className={'isometry__sign isometry__sign--c' + (currentBuilding.bulding === "C" && currentBuilding.floor !== "5" ? ' active' : '')}>C</div>
          <div className={'isometry__sign isometry__sign--d' + (currentBuilding.bulding === "D" && currentBuilding.floor !== "5" ? ' active' : '')}>D</div>
          <div className={'isometry__sign isometry__sign--e' + (currentBuilding.bulding === "E" && currentBuilding.floor !== "5" ? ' ' : '')}>E</div>
        </div>
        <ModIsometrySvg entries={props.entries} activeFlatEntry={props.activeFlatEntry} onFlatClick={props.onFlatClick} onBuyFlatClick={props.onBuyFlatClick}></ModIsometrySvg>
        <img alt="background" className="isometry__map__background" src={require("static/img/isometry_background.jpg")} />
      </div>
    </div>
  )
}

export default injectIntl(ModBlockFlatFinderIsometry)