import * as React from "react";

import ModBlockRichText from "./ModBlockRichText";
import ModBlockImage from "./ModBlockImage";
import ModEditBlock from "../common/ModEditBlock";
import RootState from "../../vo/RootState";
import { BlockRichText } from "../../interfaces/Interfaces";
import ModBlockEmbed from "./ModBlockEmbed";

export interface ModBlockCellRectangleProps {
  rootState: RootState;
  content: any[];
}

export default class ModBlockCellRectangle extends React.Component<
  ModBlockCellRectangleProps,
  {}
> {
  render() {
    const { content, rootState } = this.props;

    const blockList = content.map((_item, index) => {
      const typeKey = Object.keys(_item)[0];
      const content = _item[typeKey];
      return { typeKey, content };
    });

    let html = blockList.reduce((itemList, item, index) => {
      const { typeKey, content } = item;

      let blockMarkup = null;
      switch (typeKey) {
        case "blockRichText":
          let className = "cell";
          if ((content as BlockRichText).useThemeColor) {
            className += " theme-color";
          }
          if ((content as BlockRichText).useWhiteTextColor) {
            className += " text-color-white";
          }
          blockMarkup = (
            <ModBlockRichText
              key={index}
              content={content}
              className={className}
            />
          );
          break;
        case "blockImage":
          blockMarkup = (
            <ModBlockImage
              key={index}
              content={{ image: content.image }}
              width={356}
              height={356}
              className={"cell"}
              linkObject={content.link}
            />
          );
          break;

        // case 'blockEmbed':
        //   blockMarkup = (<ModBlockEmbed key={index} content={content} />)
        //   break

        default:
          blockMarkup = null;
      }

      if (rootState.editMode && blockMarkup) {
        itemList.push(
          <ModEditBlock
            key={"_" + index}
            entityObject={content.block}
            title={typeKey}
            rootState={rootState}
          />
        );
      }
      itemList.push(blockMarkup);
      return itemList;
    }, []);

    return (
      <div className={"block-cell-rectangle"}>
        <div className={"max-three-cells"}>{html}</div>
      </div>
    );
  }
}
