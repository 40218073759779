import {
  IPublicSettings,
  NavigationNode,
  Content,
  Website,
  Overlay,
  BlockNews,
  Page,
} from "../interfaces/Interfaces";
import RootState from "../vo/RootState";
import { tags } from "../vo/Tags";
import { expression as e } from "@karma.run/sdk";
import { getCurrentLanguageOrFallBackByPath } from "../common/Languages";
import {
  isArticleActiveNow,
  searchNavigationNodeByUrl,
  replaceMediaDomainRecursive,
  createNodes,
} from "../common/CmsUtils";
import KarmaApiKit from "./KarmaApiKit";

export default class CmsController {
  rootState: RootState;
  caasApi: KarmaApiKit;

  constructor() {
    this.rootState = new RootState();
    this.caasApi = null;

    this.fetchAll = this.fetchAll.bind(this);
    this.injectConfig = this.injectConfig.bind(this);
    this.fetchDomain = this.fetchDomain.bind(this);
    this.fetchWebsiteSettings = this.fetchWebsiteSettings.bind(this);
    this.injectAppStateAndFetchContent =
      this.injectAppStateAndFetchContent.bind(this);
    this.fetchContentByNavigationNode =
      this.fetchContentByNavigationNode.bind(this);
    this.fetchContentByPath = this.fetchContentByPath.bind(this);
  }

  async fetchAll(
    path: string,
    host: string,
    publicSettings: IPublicSettings
  ): Promise<RootState> {
    this.rootState.publicConfig = publicSettings;
    const { caasEndpoint, user, pwd } = this.rootState.publicConfig;
    this.caasApi = new KarmaApiKit(caasEndpoint, user, pwd);
    await this.caasApi.signIn();

    this.rootState.modelDefinitions = await this.caasApi.fetchModelTypes();
    this.rootState.contentType = await this.caasApi.fetchTags();
    this.rootState.domain = await this.fetchDomain(host);
    if (typeof this.rootState.domain.serve === "string") {
      this.rootState.websiteSettings = await this.fetchWebsiteSettings(
        this.rootState.domain.serve
      );
    }
    this.rootState.content = await this.fetchContentByPath(
      this.rootState.websiteSettings.rootNavigationNode,
      path
    );
    return this.rootState;
  }

  async injectAppStateAndFetchContent(
    path: string,
    rootState: RootState,
    publicSettings: IPublicSettings
  ): Promise<RootState> {
    this.rootState = Object.assign(new RootState(), rootState);
    this.rootState.publicConfig = publicSettings;
    const { caasEndpoint, user, pwd } = this.rootState.publicConfig;
    this.caasApi = new KarmaApiKit(caasEndpoint, user, pwd);
    this.rootState.content = await this.fetchContentByPath(
      this.rootState.websiteSettings.rootNavigationNode,
      path
    );
    return this.rootState;
  }

  injectConfig(config: RootState) {
    this.rootState = config;
    const { caasEndpoint, user, pwd } = this.rootState.publicConfig;
    this.caasApi = new KarmaApiKit(caasEndpoint, user, pwd);
  }

  /**
   * @param host
   */
  async fetchDomain(host: string) {
    let currentDomain = null;
    if (typeof window !== "undefined") {
      // for local development only
      currentDomain = window.location.host;
    } else {
      currentDomain = host;
    }

    let result = await this.caasApi.query(e.all(e.tag(tags.domain)));

    const domainSetting = result.find((item) => {
      return item.domains.indexOf(currentDomain) > -1;
    });

    if (!domainSetting) {
      throw new Error(`domain ${currentDomain} is not specified`);
    }
    return domainSetting;
  }

  async fetchWebsiteSettings(_websiteId: string): Promise<Website> {
    if (!_websiteId) {
      return null;
    }

    const query = e.graphFlow(e.newRef(e.tag(tags.website), _websiteId), [
      {
        from: e.tag(tags.website),
        forward: [
          e.tag(tags.media),
          e.tag(tags.translations),
          e.tag(tags.footer),
          e.tag(tags.navigationNode),
          e.tag(tags.blockHeader),
          e.tag(tags.blockSpacer),
          e.tag(tags.blockRichText),
        ],
        backward: [],
      },
      {
        from: e.tag(tags.navigationNode),
        forward: [e.tag(tags.navigationNode)],
        backward: [e.tag(tags.page)],
      },
      {
        from: e.tag(tags.footer),
        forward: [e.tag(tags.media)],
        backward: [],
      },
      {
        from: e.tag(tags.blockHeader),
        forward: [e.tag(tags.media)],
        backward: [],
      },
    ]);
    const { result, nested } = await this.caasApi.flowQuery(query);
    const pagePool = result[this.rootState.contentType[tags.page]];
    replaceMediaDomainRecursive(this.rootState, nested);
    createNodes(nested.rootNavigationNode, pagePool);
    return nested;
  }

  async fetchContentByPath(
    navigationTree: NavigationNode,
    path: string
  ): Promise<Content> {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(path);
    const foundNode = searchNavigationNodeByUrl(
      navigationTree,
      path,
      currentLanguage
    );
    if (foundNode) {
      return await this.fetchContentByNavigationNode(
        navigationTree,
        foundNode,
        currentLanguage
      );
    }

    return null;
  }

  /**
   * fetch content and return a the result
   */
  async fetchContentByNavigationNode(
    navigationTree: NavigationNode,
    navigationNode: NavigationNode,
    currentLanguage: string
  ): Promise<Content> {
    if (
      !(
        navigationNode &&
        navigationNode.content &&
        navigationNode.content.content
      )
    ) {
      console.info(
        "fetchContentByNavigationNode: no content object provided in navigationNode"
      );
      return null;
    }

    let query: any;
    if (navigationNode.content.content.hasOwnProperty("templateBlockList")) {
      query = e.graphFlow(
        e.newRef(e.tag(tags.navigationNode), navigationNode._id),
        [
          {
            from: e.tag(tags.navigationNode),
            forward: [],
            backward: [e.tag(tags.page)],
          },
          {
            from: e.tag(tags.page),
            forward: [
              e.tag(tags.blockTitle),
              e.tag(tags.blockRichText),
              e.tag(tags.blockImageText),
              e.tag(tags.blockSpacer),
              e.tag(tags.blockSlider),
              e.tag(tags.blockHeader),
              e.tag(tags.blockGrid3),
              e.tag(tags.blockGrid4),
              e.tag(tags.blockGrid5),
              // e.tag(tags.blockSnippet),
              e.tag(tags.blockTeaserSpot),
              // e.tag(tags.blockVimeo),
              e.tag(tags.media),
            ],
            backward: [],
          },
          {
            from: e.tag(tags.blockRichText),
            forward: [e.tag(tags.media), e.tag(tags.blockTitle)],
            backward: [],
          },
          {
            from: e.tag(tags.blockImageText),
            forward: [e.tag(tags.media)],
            backward: [],
          },
          {
            from: e.tag(tags.blockSlider),
            forward: [e.tag(tags.media)],
            backward: [],
          },
          {
            from: e.tag(tags.blockTeaserSpot),
            forward: [e.tag(tags.media)],
            backward: [],
          },
          // {
          //   from: e.tag(tags.blockSnippet),
          //   forward: [
          //     e.tag(tags.media),
          //   ],
          //   backward: []
          // },
          {
            from: e.tag(tags.blockHeader),
            forward: [e.tag(tags.media)],
            backward: [],
          },
          {
            from: e.tag(tags.blockGrid3),
            forward: [e.tag(tags.media)],
            backward: [],
          },
          {
            from: e.tag(tags.blockGrid4),
            forward: [e.tag(tags.media)],
            backward: [],
          },
          {
            from: e.tag(tags.blockGrid5),
            forward: [e.tag(tags.media)],
            backward: [],
          },
        ]
      );
    } else if (navigationNode.content.content.hasOwnProperty("templateHome")) {
      query = e.graphFlow(
        e.newRef(e.tag(tags.navigationNode), navigationNode._id),
        [
          {
            from: e.tag(tags.navigationNode),
            forward: [],
            backward: [e.tag(tags.page)],
          },
          {
            from: e.tag(tags.page),
            forward: [
              e.tag(tags.blockRichText),
              e.tag(tags.blockImage),
              // e.tag(tags.blockVimeo),
              e.tag(tags.media),
            ],
            backward: [],
          },
          {
            from: e.tag(tags.blockRichText),
            forward: [e.tag(tags.media), e.tag(tags.blockTitle)],
            backward: [],
          },
          {
            from: e.tag(tags.blockImage),
            forward: [e.tag(tags.media)],
            backward: [],
          },
        ]
      );
    } else if (
      navigationNode.content.content.hasOwnProperty("templateContent")
    ) {
      query = e.graphFlow(
        e.newRef(e.tag(tags.navigationNode), navigationNode._id),
        [
          {
            from: e.tag(tags.navigationNode),
            forward: [],
            backward: [e.tag(tags.page)],
          },
          {
            from: e.tag(tags.page),
            forward: [
              e.tag(tags.blockRichText),
              e.tag(tags.blockImage),
              e.tag(tags.media),
            ],
            backward: [],
          },
          {
            from: e.tag(tags.blockRichText),
            forward: [e.tag(tags.media), e.tag(tags.blockTitle)],
            backward: [],
          },
          {
            from: e.tag(tags.blockImage),
            forward: [e.tag(tags.media)],
            backward: [],
          },
        ]
      );
    } else if (
      navigationNode.content.content.hasOwnProperty("templateFlatFinder")
    ) {
      query = e.graphFlow(
        e.newRef(e.tag(tags.navigationNode), navigationNode._id),
        [
          {
            from: e.tag(tags.navigationNode),
            forward: [],
            backward: [e.tag(tags.page)],
          },
          {
            from: e.tag(tags.page),
            forward: [e.tag(tags.media)],
            backward: [],
          },
        ]
      );
    } else {
      console.info("no template found");
      return null;
    }

    let { nested, result } = await this.caasApi.flowQuery(query, tags.page);

    const typeId = this.caasApi.tags[tags.page];
    const pages: { [key: string]: Page } = result[typeId];

    if (Object.entries(pages).length > 1) {
      // TODO: Quick Fix because there can be more than one Page objects in result
      const r = Object.entries(pages).reduce((accu, item) => {
        const [key, val] = item;
        if (val.navigationNode === navigationNode._id) {
          nested = val;
          accu[key] = val;
        }
        return accu;
      }, {});
      nested = this.caasApi.resolveAllGraphFlowReferences(
        result,
        nested,
        typeId
      );
      nested["_id"] = typeId;
      nested["_type"] = typeId;
      // TODO: Quick Fix
    }

    replaceMediaDomainRecursive(this.rootState, nested);
    const parsedResult: Content = {
      article: nested,
      navigationNodeId: navigationNode._id,
      articleType: "blockList",
    };

    if (isArticleActiveNow(parsedResult.article, currentLanguage)) {
      return parsedResult;
    }
    return null;
  }

  async fetchOverlay(overlayId): Promise<Overlay> {
    const query = e.graphFlow(e.newRef(e.tag(tags.overlay), overlayId), [
      {
        from: e.tag(tags.overlay),
        // forward: [e.tag(tags.blockSnippet), e.tag(tags.blockVimeo)],
        forward: [],
        backward: [],
      },
    ]);
    const { nested } = await this.caasApi.flowQuery(query);
    replaceMediaDomainRecursive(this.rootState, nested);
    return nested;
  }

  async fetchNews(): Promise<BlockNews[]> {
    const query = e.reverseList(
      e.memSort(
        e.mapList(
          e.mapList(e.all(e.tag(tags.blockNews)), e.metarialize(e.arg())),
          e.setField(
            "_type",
            tags.blockNews.toLowerCase(),
            e.setField(
              "_id",
              e.field("id", e.arg()),
              e.field("value", e.resolveAllRefs(e.arg()))
            )
          )
        ),
        e.field("date", e.arg())
      )
    );
    const result = await this.caasApi.query(query);
    replaceMediaDomainRecursive(this.rootState, result);
    return result;
  }
}
