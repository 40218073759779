import * as React from 'react'
import { injectIntl } from 'react-intl'
import ModBlockFlatFinderFilters from './ModBlockFlatFinderFilters'
import ModBlockFlatFinderIsometry from './ModBlockFlatFinderIsometry'
import ModBlockFlatFinderList from './ModBlockFlatFinderList'
import * as isoFetch from 'isomorphic-fetch'
import { FlatFinderEntry } from '../../interfaces/Interfaces'
import ModSideBar from '../common/ModSideBar'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

export interface ModBlockFlatFinderProps {
  templateData: any
  currentLanguage: string
  intl: any
}

function ModBlockFlatFinder(props: ModBlockFlatFinderProps) {
  const { templateData, currentLanguage } = props

  const [entries, setEntries] = React.useState([])
  const [filteredEntries, setFilteredEntries] = React.useState([])
  const [activeFlatEntry, setActiveFlatEntry] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const getTableWidth = () => {
    if (document.getElementById('block-flat-finder__list__y-scroll')) {
      document.getElementById('block-flat-finder__list__header').setAttribute(
        'style', 'width:' + document.getElementById('block-flat-finder__list__y-scroll').clientWidth + 'px'
      );
    }
  }

  React.useEffect(() => {
    const abortController = new AbortController();

    setIsError(false);
    setIsLoading(true);
    isoFetch('https://tuefwis-winkel.emonitor.ch/api/objects/?format=json&object=apartment&object=other', { signal: abortController.signal })
      .then(function (response) {
        return response.json()
      })
      .then((result: FlatFinderEntry[]) => {
        result = result.map((entry) => {
          entry.building_number = entry.building.title
          return entry
        })

        result = result.sort((a, b) => {
          if (a.title < b.title) { return -1; }
          if (a.title > b.title) { return 1; }
          return 0;
        })
        setFilteredEntries(result)
        setEntries(result)
        setIsLoading(false);
      })
      .catch(error => {
        //ignore if abort error
        if (error && error.code == 20) {
          return
        }
        setIsLoading(false);
        setIsError(true);
      })

    return () => {
      abortController.abort();
    };
  }, []);

  React.useEffect(() => {
    getTableWidth()
  }, [filteredEntries, isLoading]);


  React.useEffect(() => {
    if (activeFlatEntry) {
      const myElement = document.getElementById('list-' + activeFlatEntry.title);
      const topPos = myElement.offsetTop;
      const headerHeight = document.getElementById('block-flat-finder__list__header').offsetHeight;
      document.getElementById('block-flat-finder__list__y-scroll').scrollTop = topPos - headerHeight;
    }
  }, [activeFlatEntry]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', getTableWidth);
  }, [])

  function fetchResults() {


  }

  function updateFilteredEntries(entries: FlatFinderEntry[]) {

    setFilteredEntries(entries)
    if (activeFlatEntry) {
      const flatEntry = entries.find((entry) => {
        return activeFlatEntry.title == entry.title
      })
      if (!flatEntry) {
        setActiveFlatEntry(null)
      }
    }
  }

  function openBuyFlatLink() {
    if (typeof window !== 'undefined') {
      window.open("https://regimo-zuerich.ch/mieten/kaufen?place1=Winkel+Schweiz")
    }
  }

  let content
  let filter
  if (isLoading) {
    content = <img alt="loading indicator" className="loading-state" src={require("static/img/loading.gif")} />
  } else if (isError) {
    content = <div>there was an error.. please try again later</div>
  } else {
    filter = <ModBlockFlatFinderFilters entries={entries} onFilterChange={updateFilteredEntries} />
    content = <div className="block-flat-finder">
      <ModBlockFlatFinderIsometry entries={filteredEntries} activeFlatEntry={activeFlatEntry} onFlatClick={setActiveFlatEntry} onBuyFlatClick={openBuyFlatLink} />
      <ModBlockFlatFinderList entries={filteredEntries} activeFlatEntry={activeFlatEntry} onFlatClick={setActiveFlatEntry} />

      <div className="block-flat-finder__docs block-flat-finder__docs--mobile block-rich-text cell theme-color">
        <h3>Downloads</h3>
        <p>
          <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_B_200115.pdf")} target="_blank"><span>Vermietungspläne Keller Haus B</span></a><br />
          <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_C_200114.pdf")} target="_blank"><span>Vermietungspläne Keller Haus C</span></a><br />
          <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_D_200115.pdf")} target="_blank"><span>Vermietungspläne Keller Haus D</span></a><br />
          <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_E_200115.pdf")} target="_blank"><span>Vermietungspläne Keller Haus E</span></a><br />
          <a href={require("static/docs/2020-01-27_RegimoZH-Tuefwis_Baubeschrieb_Wohnen.pdf")} target="_blank"><span>Baubeschrieb Wohnen</span></a><br />
          <a href={require("static/docs/TW_Umgebungsplan_050220_final.pdf")} target="_blank"><span>Umgebungsplan</span></a><br />
        </p>
      </div>

      <div className="block-flat-finder__docs block-flat-finder__docs--mobile block-rich-text cell theme-color">
        <h3>Einstellplätze</h3>
        <ul>
          <li><p>Einstellplätze normal à CHF 110 /Mt</p></li>
          <li><p>Einstellplätze Elektroauto à CHF 180/Mt (inkl. CHF 30 Strom-Akonto)</p></li>
        </ul>
      </div>

    </div>
  }


  return (
    <div className="template-flat-finder">
      <ModSideBar
        templateData={templateData}
        currentLanguage={currentLanguage}
      >
        {filter}
        <div className="block-flat-finder__docs block-flat-finder__docs--desktop block-rich-text cell theme-color">
          <h3>Downloads</h3>
          <p>
            <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_B_200115.pdf")} target="_blank"><span>Vermietungspläne Keller Haus B</span></a><br />
            <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_C_200114.pdf")} target="_blank"><span>Vermietungspläne Keller Haus C</span></a><br />
            <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_D_200115.pdf")} target="_blank"><span>Vermietungspläne Keller Haus D</span></a><br />
            <a href={require("static/docs/Vermietungsplaene_Tuefwis_Keller_Haus_E_200115.pdf")} target="_blank"><span>Vermietungspläne Keller Haus E</span></a><br />
            <a href={require("static/docs/2020-01-27_RegimoZH-Tuefwis_Baubeschrieb_Wohnen.pdf")} target="_blank"><span>Baubeschrieb Wohnen</span></a><br />
            <a href={require("static/docs/TW_Umgebungsplan_050220_final.pdf")} target="_blank"><span>Umgebungsplan</span></a><br />
          </p>
        </div>

        <div className="block-flat-finder__docs block-flat-finder__docs--desktop block-rich-text cell theme-color">
          <h3>Einstellplätze</h3>
          <ul>
            <li><p>normal à CHF 110 /Mt</p></li>
            <li><p>Elektroauto à CHF 180/Mt (inkl. CHF 30 Strom-Akonto)</p></li>
            <br />
            <p>
              <a href={require("static/docs/Vermietungsplane_Tuefwis_Parkplaetze_200129.pdf")} target="_blank"><span>Vermietungspläne Einstellplätze</span></a><br />
            </p>
          </ul>
        </div>
      </ModSideBar>
      <div className="content">
        {content}
      </div>
    </div >

  )
}

export default injectIntl(ModBlockFlatFinder)