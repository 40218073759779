import * as React from 'react'
import { NavigationNodeContext } from "../ModApp";
import CmsRouter, { RouterLocation } from "../../control/CmsRouter";

export interface NavLinkState {
  [key: string]: any

  to: RouterLocation | string
  title?: string
  onClick?: (event: any) => void
}

export default class NavLink<P extends NavLinkState, S> extends React.Component<P, S> {

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event, router: CmsRouter) {
    event.preventDefault()
    router.push(this.props.to)
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  render() {
    let href: string
    if (typeof this.props.to === 'string') {
      href = this.props.to as string
    }
    else {
      let newLocation = this.props.to as RouterLocation
      href = newLocation.pathname
      if (newLocation.query) {
        href += '?' + newLocation.query.toString()
      }
      if (newLocation.hash) {
        href += '#' + newLocation.hash
      }
    }

    let _props: any = Object.assign({}, this.props, {
      href
    })
    delete _props.to

    return <NavigationNodeContext.Consumer>
      {({ router, location }) => {
        const className = (location.pathname === _props.href || location.pathname + '/' === _props.href) ? 'active' : ''
        let f = (event) => {
          this.handleClick(event, router)
        }
        return <a {..._props} onClick={f} className={className} />
      }}
    </NavigationNodeContext.Consumer>
  }
}
