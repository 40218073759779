import * as React from 'react'
import { BlockTitle } from "../../interfaces/Interfaces";

export interface ModBlockTitleProps {
  bgColor: string
  content: BlockTitle
}

export default class ModBlockTitle extends React.Component<ModBlockTitleProps, {}> {

  render() {
    const { content, bgColor } = this.props

    if (!(content._i18nCL && content._i18nCL.title)) {
      return null
    }
    const i18n = content._i18nCL

    let subTitle = i18n.subtitle ? <h3>{i18n.subtitle}</h3> : null
    const a = i18n.anchor ?
      <a id={"anchor_" + i18n.anchor.toLowerCase()} /> : null

    return (
      <div className={"block-title bg-color-" + bgColor}>
        <div className={"centered"}>
          {a}
          {subTitle}
          <h2>{i18n.title}</h2>
        </div>
      </div>
    )
  }
}