import * as React from 'react'

import ModBlockRichText from "./ModBlockRichText";
import ModBlockImage from "./ModBlockImage";
import ModEditBlock from "../common/ModEditBlock";
import RootState from "../../vo/RootState";

export interface ModBlockBadgeProps {
  rootState: RootState
  content: any[]
}

export default class ModBlockBadge extends React.Component<ModBlockBadgeProps, {}> {

  render() {
    const { content, rootState } = this.props

    const typeKey = Object.keys(content)[0]

    let itemList = []

    let blockMarkup = null
    switch (typeKey) {
      case 'blockImage':
        blockMarkup = (
          <ModBlockImage
            key={1}
            content={{ image: content[typeKey].image }}
            width={356}
            height={356}
            linkObject={content[typeKey].link}
          />
        )
        break
      default:
        blockMarkup = null
    }

    if (rootState.editMode && blockMarkup) {
      itemList.push(
        <ModEditBlock
          key={'_' + 0}
          entityObject={content[typeKey].block}
          title={typeKey}
          rootState={rootState}
        />)
    }

    itemList.push(blockMarkup)

    return (
      <div className={"block-badge"}>
        {itemList}
      </div>
    )
  }
}