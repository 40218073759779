import * as React from 'react'
import { DropdownOption } from '../../interfaces/Interfaces'

export interface ModDropdownProps {
  options: DropdownOption[]
  activeValue?: string
  title: string
  queryKey: string
  filterOpen: boolean
  onValueChange: (key: string, value: string) => void
  onToggle: (key: string, open: boolean) => void
}

export default function ModDropdown(props: ModDropdownProps) {
  const { options, activeValue, title, onValueChange, queryKey } = props

  const optionStartValue = activeValue || 'all'
  const [optionValue, setOptionValue] = React.useState(optionStartValue)


  function changeOptionValue(value) {
    setOptionValue(value)

    if (value === 'all'){
      onValueChange(queryKey, null)
    } else {
      onValueChange(queryKey, value || null)
    }
  }

  const filterElementOptions = options.map((item, index) => {
    return (
      <option key={index} value={item.value}>
        {item.text}
      </option>
    )
  })

  return (
    <div>
      <label>{title}</label>
      <select value={optionValue} onChange={(event) => {changeOptionValue(event.target.value) }}>
        <option value='all'>Alle</option>
        {filterElementOptions}
      </select>

    </div>
  )
}