import * as React from "react";

import NavLink from "../common/NavLink";
import ModBlockRichText from "../blocks/ModBlockRichText";
import ModBlockImage from "../blocks/ModBlockImage";
import ModBlockCellRectangle from "../blocks/ModBlockCellRectangle";
import ModBlockNews from "../blocks/ModBlockNews";
import ModBlockTimeline from "../blocks/ModBlockTimeline";
import ModBlockForm from "../blocks/ModBlockForm";
import ModSideBar from "../common/ModSideBar";
import RootState from "../../vo/RootState";
import { RouterLocation } from "../../control/CmsRouter";
import CmsController from "../../control/CmsController";
import { BlockRichText } from "../../interfaces/Interfaces";
import ModBlockSlider from "../blocks/ModBlockSlider";
import ModBlockQuote from "../blocks/ModBlockQuote";
import ModBlockSplitColumn from "../blocks/ModBlockSplitColumn";
import ModBlockAnchor from "../blocks/ModBlockAnchor";

export interface ModTemplateContentProps {
  rootState: RootState;
  templateData: any;
  currentLanguage: string;
  location: RouterLocation;
  caasHelper: CmsController;
}

export default class ModTemplateContent extends React.Component<
  ModTemplateContentProps,
  {}
> {
  render() {
    const { templateData, rootState, currentLanguage, location, caasHelper } =
      this.props;

    const blockListContent = templateData.content.map((_item, index) => {
      const typeKey = Object.keys(_item)[0];
      const content = _item[typeKey];
      return { typeKey, content };
    });

    let htmlContent = blockListContent.reduce((itemList, item, index) => {
      const { typeKey, content } = item;

      let blockMarkup = null;
      switch (typeKey) {
        case "blockImage":
          blockMarkup = (
            <div key={index} className={"block-cell-media-rectangle"}>
              <ModBlockImage
                content={{ image: content.image }}
                width={1118}
                height={736}
                linkObject={content.link}
              />
            </div>
          );
          break;
        case "blockCellRectangle":
          blockMarkup = (
            <ModBlockCellRectangle
              key={index}
              content={content}
              rootState={rootState}
            />
          );
          break;
        case "blockNews":
          blockMarkup = (
            <ModBlockNews
              key={index}
              rootState={rootState}
              caasHelper={caasHelper}
              currentLanguage={currentLanguage}
              location={location}
            />
          );
          break;
        case "blockTimeline":
          blockMarkup = <ModBlockTimeline key={index} content={content} />;
          break;
        // case "blockForm":
        //   blockMarkup = (
        //     <ModBlockForm
        //       key={index}
        //       content={content}
        //       requireReCaptcha={true}
        //       reCaptchaSiteKey={
        //         this.props.rootState.publicConfig.reCaptchaSiteKey
        //       }
        //     />
        //   );
        //   break;
        case "blockSlider":
          blockMarkup = (
            <ModBlockSlider key={index} content={content} index={index} />
          );
          break;
        case "blockSplitColumn":
          blockMarkup = <ModBlockSplitColumn key={index} content={content} />;
          break;
        case "blockQuote":
          blockMarkup = <ModBlockQuote key={index} content={content} />;
          break;
        case "blockAnchor":
          blockMarkup = <ModBlockAnchor key={index} content={content} />;
          break;
        case "blockRichText":
          blockMarkup = <ModBlockRichText key={index} content={content} />;
          break;
        default:
          blockMarkup = null;
      }

      itemList.push(blockMarkup);
      return itemList;
    }, []);

    return (
      <div className="template-content">
        <ModSideBar
          templateData={templateData}
          currentLanguage={currentLanguage}
        />
        <div className="content">{htmlContent}</div>
      </div>
    );
  }
}
