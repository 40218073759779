import {Content, Website} from "../interfaces/Interfaces";
import {hasContent} from "./Utils";

/**
 * siteName=Open graph site name
 * @returns {{siteName: string, title: string, description: string, keywords: string, shareImage_url: string, shareImage_secureUrl: string, shareImage_format: string, shareImage_width: string, shareImage_height: string}}
 */
export function createMetaFields(content: Content, webSiteSettings: Website, currentLanguage: string) {
  const fields = {
    'siteName': '',
    'title': '',
    'description': '',
    'keywords': '',
    'shareImage_url': '',
    'shareImage_secureUrl': '',
    'shareImage_format': '',
    'shareImage_width': 0,
    'shareImage_height': 0
  }

  if (webSiteSettings) {
    const i18nSite = webSiteSettings.i18n && webSiteSettings.i18n.hasOwnProperty(currentLanguage) ? webSiteSettings.i18n[currentLanguage] : null
    if (i18nSite) {
      fields.siteName = i18nSite.siteName
      fields.title = i18nSite.title
      fields.description = i18nSite.description
    }
    if (webSiteSettings.shareImage) {
      fields.shareImage_url = webSiteSettings.shareImage.media.backend.cloudinary.url
      fields.shareImage_secureUrl = webSiteSettings.shareImage.media.backend.cloudinary.secure_url
      fields.shareImage_format = 'image/' + webSiteSettings.shareImage.media.backend.cloudinary.format
      fields.shareImage_width = webSiteSettings.shareImage.media.backend.cloudinary.width
      fields.shareImage_height = webSiteSettings.shareImage.media.backend.cloudinary.height
    }
  }

  if (content && content.article) {
    const {article} = content
    const i18n = article.i18n && article.i18n.hasOwnProperty(currentLanguage) ? article.i18n[currentLanguage] : null
    if (i18n) {
      if (hasContent(i18n.title)) {
        fields.title += ' | ' + i18n.title
      }
      if (hasContent(i18n.description)) {
        fields.description = i18n.description
      }
    }
    if (article.shareImage) {
      try {
        fields.shareImage_url = article.shareImage.media.backend.cloudinary.url
        fields.shareImage_secureUrl = article.shareImage.media.backend.cloudinary.secure_url
        fields.shareImage_format = 'image/' + article.shareImage.media.backend.cloudinary.format
        fields.shareImage_width = article.shareImage.media.backend.cloudinary.width
        fields.shareImage_height = article.shareImage.media.backend.cloudinary.height
      } catch (e) {
      }
    }
  }

  return fields
}