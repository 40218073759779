import * as React from 'react'

import NavLink from "../common/NavLink"
import ModBlockBadge from "../blocks/ModBlockBadge";
import ModBlockCellSmall from "../blocks/ModBlockCellSmall";
import ModBlockCellRectangle from "../blocks/ModBlockCellRectangle";
import ModBlockCellMediaSquare from "../blocks/ModBlockCellMediaSquare";
import ModBlockCellMediaRectangle from "../blocks/ModBlockCellMediaRectangle";
import RootState from "../../vo/RootState";

export interface ModTemplateHomeProps {
  rootState: RootState
  templateData: any[]
  currentLanguage: string
}

export default class ModTemplateHome extends React.Component<ModTemplateHomeProps, {}> {

  render() {
    const { templateData, rootState, currentLanguage } = this.props

    const maxItem = templateData.length

    const blockList = templateData.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      return { typeKey, content, maxItem }
    })

    let blockLogo = (
      <div key={-4} className={'block-cell-small'}>
        <div className={'block-image'}>
          <div className={'logo image'}>
            <div className={'aspect-ratio'}>
              <NavLink to={`/${currentLanguage}`}>
                <img className={'cell'} src={require("static/img/svg/icon_tuefwis_logo.svg")} width={356} height={356} alt={'Tüfwis'} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )

    let desktopBlockCells = 0
    let desktopRow = []

    let blockBadgeHtml = null
    let blockCellMediaSquareHtml = null
    let blockCellMediaRectangleHtml = null
    let firstRow = true
    let addBlockAfter = false

    let desktopHtml = blockList.reduce((itemList, item, index) => {
      const { typeKey, content, maxItem } = item

      let blockMarkup = null
      let cellCount = 0

      switch (typeKey) {
        case 'blockBadge':
          blockBadgeHtml = <ModBlockBadge key={index} content={content} rootState={rootState} />
          break
        case 'blockCellSmall':
          blockMarkup = <ModBlockCellSmall key={index} content={content} rootState={rootState} />
          cellCount = 1
          break
        case 'blockCellRectangle':
          blockMarkup = <ModBlockCellRectangle key={index} content={content} rootState={rootState} />
          cellCount = 2
          break
        case 'blockCellMediaSquare':
          blockCellMediaSquareHtml = <ModBlockCellMediaSquare key={index} content={content} rootState={rootState} />
          cellCount = 4
          break
        case 'blockCellMediaRectangle':
          blockCellMediaRectangleHtml = <ModBlockCellMediaRectangle key={index} content={content} rootState={rootState} />
          cellCount = 6
          break
      }

      desktopBlockCells += cellCount

      // Append block logo
      if (index === 0) {
        desktopRow.push(blockLogo)
        desktopBlockCells++
      }
      else if (blockMarkup !== null || blockCellMediaSquareHtml !== null || blockCellMediaRectangleHtml !== null) {
        if (desktopBlockCells > 8) {
          itemList.push(desktopRow)
          firstRow = false
          desktopRow = []
          desktopBlockCells = 0
        }
        else if (desktopBlockCells < 4 && cellCount < 4) {
          desktopRow.push(blockMarkup)
        }
        else if (desktopBlockCells === 4) {
          if (desktopRow.length === 1) {
            desktopRow.push(blockMarkup)
            itemList.push(desktopRow)
            firstRow = false
            desktopRow = []
            desktopBlockCells = 0
          }
          if (desktopRow.length === 2) {
            desktopRow.push(blockMarkup)
            itemList.push(desktopRow)
            firstRow = false
            desktopRow = []
            desktopBlockCells = 0
          }
          else if (blockCellMediaSquareHtml !== null) {
            desktopRow.push(blockCellMediaSquareHtml)
            blockCellMediaSquareHtml = null
          }
        }
        else if (desktopBlockCells > 4) {
          if (desktopBlockCells < 8) {
            desktopRow.push(blockMarkup)
            if (blockCellMediaRectangleHtml !== null) {
              addBlockAfter = !firstRow
            }
            if (desktopRow.length === 2 && cellCount === 2 && desktopBlockCells === 6) {
              blockCellMediaSquareHtml = desktopRow[0]
            }
          }
          else if (desktopBlockCells === 8) {
            if (blockCellMediaSquareHtml !== null) {
              if (desktopRow.length === 1) {
                itemList.push(desktopRow)
                itemList.push(blockCellMediaSquareHtml)
              }
              else {
                if (desktopRow.length > 0) {
                  if (desktopRow[0].type.displayName === 'ModBlockCellMediaSquare') {
                    desktopRow.splice(0, 1)
                    addBlockAfter = !firstRow
                  }
                }
                desktopRow.push(blockMarkup)
                if (!addBlockAfter) {
                  itemList.push(<div key={index + '-four-cells'} className={'four-cells'}>{desktopRow}</div>)
                }
                itemList.push(blockCellMediaSquareHtml)
                if (addBlockAfter) {
                  itemList.push(<div key={index + '-four-cells'} className={'four-cells'}>{desktopRow}</div>)
                }
              }
              firstRow = false
              desktopRow = []
              desktopBlockCells = 0
              blockCellMediaSquareHtml = null
            }
            else if (blockCellMediaRectangleHtml !== null) {
              desktopRow.push(blockMarkup)
              if (!addBlockAfter) {
                itemList.push(<div key={index + '-two-rows'} className={'two-rows'}>{desktopRow}</div>)
              }
              itemList.push(blockCellMediaRectangleHtml)
              if (addBlockAfter) {
                itemList.push(<div key={index + '-two-rows'} className={'two-rows'}>{desktopRow}</div>)
              }
              firstRow = false
              desktopRow = []
              desktopBlockCells = 0
              blockCellMediaRectangleHtml = null
              addBlockAfter = false
            }
            else {
              desktopRow.push(blockMarkup)
              itemList.push(desktopRow)
              firstRow = false
              desktopRow = []
              desktopBlockCells = 0
            }
          }
        }
      }

      // Append block badge at first index
      if ((index + 1) === maxItem && blockBadgeHtml !== null) {
        if (desktopRow.length > 0) {
          itemList.push(desktopRow)
        }
        if (blockCellMediaSquareHtml !== null) {
          itemList.push(blockCellMediaSquareHtml)
        }
        if (blockCellMediaRectangleHtml !== null) {
          itemList.push(blockCellMediaRectangleHtml)
        }
        itemList.unshift(blockBadgeHtml)
      }

      return itemList
    }, [])

    let mobileFirstBlock = []

    let mobileHtml = blockList.reduce((itemList, item, index) => {
      const { typeKey, content, maxItem } = item

      let blockBadge = null
      let blockMarkup = null
      let cellCount = 0

      switch (typeKey) {
        case 'blockBadge':
          blockBadge = <ModBlockBadge key={index} content={content} rootState={rootState} />
          cellCount = 1
          break
        case 'blockCellSmall':
          blockMarkup = <ModBlockCellSmall key={index} content={content} rootState={rootState} />
          cellCount = 1
          break
        case 'blockCellRectangle':
          blockMarkup = <ModBlockCellRectangle key={index} content={content} rootState={rootState} />
          cellCount = 1
          break
        case 'blockCellMediaSquare':
          blockMarkup = <ModBlockCellMediaSquare key={index} content={content} rootState={rootState} />
          cellCount = 1
          break
        case 'blockCellMediaRectangle':
          blockMarkup = <ModBlockCellMediaRectangle key={index} content={content} rootState={rootState} />
          cellCount = 2
          break
        default:
          blockMarkup = null
      }

      if (blockBadge !== null) {
        itemList.unshift(blockBadge)
      }
      else {
        if (blockMarkup !== null) {
          itemList.push(blockMarkup)
        }
      }

      if ((index + 1) === maxItem) {
        mobileFirstBlock.unshift(blockLogo)
        itemList.unshift(mobileFirstBlock)
      }

      return itemList
    }, [])

    return (
      <div>
        <div className="template-home desktop">
          {desktopHtml}
        </div>
        <div className="template-home mobile">
          {mobileHtml}
        </div>
      </div>
    )
  }
}
