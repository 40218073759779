import {Content, Domain, IPublicSettings, IStateController, Website} from "../interfaces/Interfaces"

export enum LoadingState {
  idle = 'idle',
  loading = 'loading',
  offline = 'offline',
  reload = 'reload'
}

export default class implements IStateController {
  content?: Content
  websiteSettings: Website
  domain: Domain
  contentType: any
  modelDefinitions: { [key: string]: any }
  cookies: { cookie_consent_dismissed: boolean }
  editMode: boolean
  publicConfig: IPublicSettings
  loadingState: LoadingState

  constructor() {
    this.content = null
    this.contentType = null
    this.modelDefinitions = null
    this.websiteSettings = null
    this.publicConfig = null
    this.domain = null
    this.cookies = {
      cookie_consent_dismissed: false
    }
    this.editMode = false
    this.getContentIdByTag = this.getContentIdByTag.bind(this)
    this.loadingState = LoadingState.idle
  }

  getContentIdByTag(tag) {
    return this.contentType[tag]
  }
}
