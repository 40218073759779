import * as React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import ModEditBlock from "../common/ModEditBlock";
import ModBlockRichText from "./ModBlockRichText";
import ModBlockImage from "./ModBlockImage";
import CmsController from "../../control/CmsController";
import { BlockNews } from "../../interfaces/Interfaces";
import { addCurrentLangRecursive } from "../../common/Utils";
import { getCurrentLanguageOrFallBackByPath } from "../../common/Languages";
import RootState from "../../vo/RootState";
import { tags } from "../../vo/Tags";
import { RouterLocation } from "../../control/CmsRouter";

export interface ModBlockNewsProps {
  rootState: RootState;
  currentLanguage: string;
  caasHelper: CmsController;
  location: RouterLocation;
}

export interface ModBlockNewsState {
  newsContent: BlockNews[];
}

export default class ModBlockNews extends React.Component<
  ModBlockNewsProps,
  ModBlockNewsState
> {
  constructor(props) {
    super(props);

    this.state = {
      newsContent: null,
    };
    this.fetchNewsContent = this.fetchNewsContent.bind(this);
  }

  componentDidMount() {
    this.fetchNewsContent(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.location.pathname === nextProps.location.pathname;
  }

  fetchNewsContent(props: ModBlockNewsProps) {
    const { caasHelper, location } = props;

    const currentLanguage = getCurrentLanguageOrFallBackByPath(
      location.pathname
    );
    caasHelper
      .fetchNews()
      .then((result) => {
        addCurrentLangRecursive(result, currentLanguage);
        this.setState({
          newsContent: result,
        });
      })
      .catch((error) => {
        this.setState({
          newsContent: null,
        });
      });
  }

  render() {
    const { rootState, currentLanguage, location } = this.props;
    const { newsContent } = this.state;

    let html = null;

    let detailUrl = "";
    let detailSlug = "";
    let showDetail = false;

    if (
      location.query &&
      Object.keys(location.query).length > 0 &&
      location.query.get("slug")
    ) {
      showDetail = true;
      detailUrl = location.pathname;
      detailSlug = location.query.get("slug");
    } else {
      showDetail = location.href.indexOf("?slug=") > -1;
      let slugQuery = location.href.split("?slug=");
      if (showDetail && slugQuery.length > 0) {
        detailUrl = slugQuery[0];
        detailSlug = slugQuery[1];
      }
    }

    if (newsContent) {
      const content = newsContent;
      if (!showDetail) {
        html = content.reduce((itemList, item, index) => {
          let image = item.image ? (
            <ModBlockImage
              content={item.image}
              width={736}
              height={514}
              linkObject={item.image.link}
            />
          ) : null;
          let title = (
            <div className={"title"}>
              <h2>{item._i18nCL.title}</h2>
            </div>
          );
          let textOverview = <ModBlockRichText content={item.textOverview} />;

          let readMore = null;

          if (item.content && item.content.length > 0) {
            readMore = (
              <a
                className={"read-more"}
                href={detailUrl + "?slug=" + item._i18nCL.slug}
              >
                <FormattedMessage id="news.readMore" />
              </a>
            );
          }

          let date = (
            <div className="date">
              <FormattedDate
                value={item.date}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </div>
          );

          let blockMarkup = (
            <div key={index} className={"block-news"}>
              {image}
              {title}
              {date}
              {textOverview}
              {readMore}
            </div>
          );

          if (rootState.editMode && blockMarkup) {
            itemList.push(
              <ModEditBlock
                key={"_" + index}
                entityObject={item}
                title={tags.blockNews}
                rootState={rootState}
              />
            );
          }

          itemList.push(blockMarkup);
          return itemList;
        }, []);
      } else {
        let item = content.find((item) => {
          return item._i18nCL.slug === detailSlug;
        });
        if (item) {
          let image = item.image ? (
            <ModBlockImage
              content={item.image}
              width={736}
              height={514}
              linkObject={item.image.link}
            />
          ) : null;
          let title = (
            <div className={"title"}>
              <h2>{item._i18nCL.title}</h2>
            </div>
          );

          let date = (
            <div className="date">
              <FormattedDate
                value={item.date}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </div>
          );

          const content = item.content.map((item, index) => {
            const typeKey = Object.keys(item)[0];
            const blockContent = item[typeKey];

            switch (typeKey) {
              case "blockImage":
                return (
                  <ModBlockImage
                    key={index}
                    content={blockContent}
                    width={736}
                    height={514}
                    linkObject={blockContent.link}
                  />
                );
              case "blockRichText":
                return <ModBlockRichText key={index} content={blockContent} />;
              default:
                return null;
            }
          });

          html = (
            <div key={0} className={"block-news"}>
              {image}
              {title}
              {date}
              {content}
            </div>
          );
        }
      }
    }

    return <div className={"list-news"}>{html}</div>;
  }
}
