import * as React from 'react'
import ModImgTag from '../common/ModImgTag'
import ModAnchorTag from "../common/ModAnchorTag";
import { Link, Media } from "../../interfaces/Interfaces";

export interface ModBlockImageProps {
  content: {
    image: Media
  }
  className?: string
  width?: number
  height?: number
  imagesShouldWorkIfBrowserJsIsDisabled?: boolean
  linkObject?: Link
}

export default class ModBlockImage extends React.Component<ModBlockImageProps> {

  render() {
    const { content, className, width, height, imagesShouldWorkIfBrowserJsIsDisabled, linkObject } = this.props

    let html = (
      <ModImgTag
        imgObject={content.image}
        className={className}
        width={width}
        height={height}
        imagesShouldWorkIfBrowserJsIsDisabled={imagesShouldWorkIfBrowserJsIsDisabled}
      />
    )

    if (linkObject) {
      html = (
        <ModAnchorTag className="button" linkObject={linkObject}>{html}</ModAnchorTag>
      )
    }

    return (
      <div className="block-image">
        <div className="image">
          <div className="aspect-ratio">
            {html}
          </div>
        </div>
      </div>
    )
  }
}
