import * as React from 'react'
import { createReactComponents } from '../../common/DraftJsToReact'
import { convertFromRaw } from 'draft-js'
import { RichText } from "../../interfaces/Interfaces";

export interface ModRichTextProps {
  richText: RichText
}

export default class ModRichText extends React.Component<ModRichTextProps, {}> {

  constructor(props) {
    super(props)
  }

  render() {
    const { richText } = this.props
    return createReactComponents(convertFromRaw(richText))
  }
}
