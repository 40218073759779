import * as React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import * as objectFitImages from "object-fit-images";

import ModContent from "./ModContent";
import ModCookiePolicy from "./ModCookiePolicy";
import ModBreadCrumb from "./ModBreadCrumb";
import { createMetaFields } from "../common/MetaFields";
import { NavigationNode } from "../interfaces/Interfaces";
import ModNavigation from "./ModNavigation";
import ModFooter from "./ModFooter";

import { getCurrentLanguageOrFallBackByPath } from "../common/Languages";
import {
  addCurrentLangRecursive,
  getJsonCookie,
  setJsonCookie,
} from "../common/Utils";

import * as en from "react-intl/locale-data/en";
import * as de from "react-intl/locale-data/de";
import * as es from "react-intl/locale-data/es";
import RootState, { LoadingState } from "../vo/RootState";
import CmsController from "../control/CmsController";
import ModModalView from "./ModModalView";
import CmsRouter, {
  RouterLocation,
  RouterLocationExtended,
} from "../control/CmsRouter";

addLocaleData([...en, ...de, ...es]);

// TODO fix hot loader with ts
export interface ModAppProps {
  location: RouterLocationExtended;
  router: CmsRouter;
  APP_PROPS?: RootState;
}

const defaultContext: {
  location: RouterLocation;
  router: CmsRouter;
  rootNavigationNode: NavigationNode;
} = {
  router: null,
  location: {
    pathname: "",
    query: null,
  },
  rootNavigationNode: null,
};

const defaultNavigation: NavigationNode = null;

export const NavigationNodeContext = React.createContext(defaultContext);

export default class ModApp extends React.Component<ModAppProps, RootState> {
  caasHelper: CmsController;

  constructor(props) {
    super(props);

    this.caasHelper = new CmsController();
    const currentLanguage = getCurrentLanguageOrFallBackByPath(
      this.props.location.pathname
    );

    if (typeof window !== "undefined") {
      // runs in frontend
      if (window.hasOwnProperty("APP_PROPS")) {
        const rootState: RootState = Object.assign(
          new RootState(),
          window["APP_PROPS"]
        );
        rootState.cookies.cookie_consent_dismissed = !!getJsonCookie(
          "cookie_consent_dismissed"
        );
        addCurrentLangRecursive(rootState, currentLanguage);
        this.state = rootState;
        this.caasHelper.injectConfig(rootState);
      }
    } else if (this.props.APP_PROPS) {
      // runs in backend
      this.state = this.props.APP_PROPS;
      this.state.cookies.cookie_consent_dismissed = true;
      addCurrentLangRecursive(this.state, currentLanguage);
    }

    this.setStateAndCurrentLang = this.setStateAndCurrentLang.bind(this);
    this.setStateBy = this.setStateBy.bind(this);
    this.setHeadMetaInformation = this.setHeadMetaInformation.bind(this);
    this.hasUpdatedLocation = this.hasUpdatedLocation.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.onCloseModalView = this.onCloseModalView.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  onWindowResize() {
    if (typeof window !== "undefined") {
      objectFitImages();
    }
  }

  onCloseModalView() {
    this.props.router.push({
      hash: "",
      pathname: this.props.location.pathname,
      query: null,
    });
  }

  setCookie(key, value) {
    let o = {};
    o[key] = value;
    const cookies = Object.assign({}, this.state.cookies, o);
    this.setState({ cookies });
    setJsonCookie(key, value);
  }

  setStateAndCurrentLang(newState) {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(
      this.props.location.pathname
    );
    newState = Object.assign(JSON.parse(JSON.stringify(this.state)), newState); // TODO refactoring
    addCurrentLangRecursive(newState, currentLanguage);
    this.setState(newState);
  }

  setHeadMetaInformation() {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(
      this.props.location.pathname
    );
    const metaFields = createMetaFields(
      this.state.content,
      this.state.websiteSettings,
      currentLanguage
    );
    document.title = metaFields.title;
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects));
  }

  hasUpdatedLocation(lastProps, nextProps) {
    try {
      if (lastProps.location.pathname !== nextProps.location.pathname) {
        return true;
      }
    } catch (e) {}
    return false;
  }

  componentWillUpdate(nextProps: ModAppProps, nextState: RootState) {
    if (this.hasUpdatedLocation(this.props, nextProps)) {
      this.setState({
        loadingState: LoadingState.loading,
      });

      this.caasHelper
        .fetchContentByPath(
          this.state.websiteSettings.rootNavigationNode,
          nextProps.location.pathname
        )
        .then((content) => {
          this.setStateAndCurrentLang({
            loadingState: LoadingState.idle,
            content: content,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            loadingState: LoadingState.offline,
            content: null,
          });
        });
    }
  }

  componentDidMount() {
    this.toggleEditMode();
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
    this.scrollToHash("auto");
  }

  /**
   * activates edit mode which displays direct link to the karma editor
   */
  toggleEditMode() {
    if (typeof window !== "undefined") {
      let keysDown = {};
      let switchMode = false;

      function isValidKey(key) {
        return key === "Control" || key === "Shift" || key === "E";
      }

      document.addEventListener(
        "keydown",
        (event) => {
          if (isValidKey(event.key)) {
            keysDown[event.key] = true;
            if (Object.keys(keysDown).length === 3) {
              switchMode = true;
            }
          }
        },
        false
      );
      document.addEventListener(
        "keyup",
        (event) => {
          if (isValidKey(event.key)) {
            delete keysDown[event.key];
            if (switchMode) {
              switchMode = false;
              this.setState({ editMode: !this.state.editMode });
            }
          }
        },
        false
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    this.setHeadMetaInformation();
    this.onWindowResize();

    if (typeof window !== "undefined") {
      if (this.state && prevState.content !== this.state.content) {
        window.scrollTo(0, location.pageYOffset);
      }
    }

    this.scrollToHash("smooth");
  }

  scrollToHash(behavior) {
    if (typeof window !== "undefined") {
      if (this.props.location.hash && this.props.location.hash.length > 0) {
        const hash = this.props.location.hash.replace(/^#/g, "");
        const divBlock = document.getElementById("anchor_" + hash);
        const navigation = document.getElementById("navigation");
        if (divBlock && navigation) {
          const y =
            divBlock.getBoundingClientRect().top +
            window.pageYOffset -
            navigation.clientHeight;
          window.scrollTo({ top: y, behavior: behavior });
        }
      }
    }
  }

  render() {
    if (!this.state) {
      return (
        <div id="app">
          <p className="loading">loading</p>
        </div>
      );
    }

    const { content, websiteSettings } = this.state;
    const { rootNavigationNode } = websiteSettings;
    const currentLanguage = getCurrentLanguageOrFallBackByPath(
      this.props.location.pathname
    );
    const translations =
      websiteSettings.translations.translations[currentLanguage];

    let modalView;
    if (this.props.location.query && this.props.location.query.has("overlay")) {
      modalView = (
        <ModModalView
          onClose={this.onCloseModalView}
          caasHelper={this.caasHelper}
          location={this.props.location}
          navigationTree={rootNavigationNode}
        />
      );
    }

    return (
      <IntlProvider locale={currentLanguage} messages={translations}>
        <NavigationNodeContext.Provider
          value={{
            location: this.props.location,
            router: this.props.router,
            rootNavigationNode: rootNavigationNode,
          }}
        >
          <div id="app">
            {modalView}
            <ModNavigation
              navigationTree={rootNavigationNode}
              currentLanguage={currentLanguage}
              rootState={this.state}
              anchor={this.props.location.hash}
              pathname={this.props.location.pathname}
            />
            <div id="content-wrapper">
              <ModBreadCrumb
                navigationTree={rootNavigationNode}
                pathName={this.props.location.pathname}
              />
              <ModContent
                rootState={this.state}
                currentLanguage={currentLanguage}
                location={this.props.location}
                caasHelper={this.caasHelper}
              />
              <ModFooter
                content={this.state.websiteSettings.footer}
                currentLanguage={currentLanguage}
              />
              {/* <ModCookiePolicy
                websiteSettings={this.state.websiteSettings}
                setCookie={this.setCookie}
                cookies={this.state.cookies}
              /> */}
            </div>
          </div>
        </NavigationNodeContext.Provider>
      </IntlProvider>
    );
  }
}
