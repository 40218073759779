export const supportedLanguages = ['de']
export const defaultLanguage = 'de'

/**
 * Get language code by path
 * @param path
 */
export function getCurrentLanguageByPath (path: string): string {
  const regex = new RegExp(`^\/?(${supportedLanguages.join('|')})`, 'i')
  const match = regex.exec(path)
  if (!(match && match.length > 0)) {
    return "unknown"
  }
  return match[1]
}

/**
 * Fall Back Language is en
 * @param path
 */
export function getCurrentLanguageOrFallBackByPath (path: string): string {
  let currentLanguage = getCurrentLanguageByPath(path)
  return currentLanguage === 'unknown' ? defaultLanguage : currentLanguage
}