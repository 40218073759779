import * as React from 'react'
import ModImgTag from "../common/ModImgTag";
import ModRichText from "../common/ModRichText";
import { BlockGrid3 } from "../../interfaces/Interfaces";

export interface ModBlockGrid3Props {
  content: BlockGrid3
}

export default class ModBlockGrid3 extends React.Component<ModBlockGrid3Props, {}> {

  render() {
    const { content } = this.props

    const list = content.list.map((item, index) => {
      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL
      return (
        <div key={index} className="cell">
          <ModImgTag imgObject={item.image} width={340} height={170} />
          <ModRichText richText={i18n.text} />
        </div>
      )
    })
    return (
      <div className="block-grid-3">
        <div className="centered">
          {list}
        </div>
      </div>
    )
  }
}
