import * as React from 'react'
import Mod404 from "./Mod404";
import ModEditBlock from "./common/ModEditBlock";
import ModTemplateHome from "./templates/ModTemplateHome";
import ModTemplateContent from "./templates/ModTemplateContent";
import ModTemplateBlockList from "./templates/ModTemplateBlockList";
import RootState, { LoadingState } from "../vo/RootState";
import { offlineImage } from "../common/offlineMedia";
import { RouterLocation } from "../control/CmsRouter";
import CmsController from "../control/CmsController";
import ModTemplateFlatFinder from './templates/ModTemplateFlatFinder';

export interface ModTemplateBlockListProps {
  rootState: RootState
  currentLanguage: string
  location: RouterLocation
  caasHelper: CmsController
}

export default class ModContent extends React.Component<ModTemplateBlockListProps> {

  render() {
    const { rootState, currentLanguage, location, caasHelper } = this.props
    const { content } = rootState

    let contentHtml = null
    let editMode = null
    let className = 'centered'

    if (content && content.article) {
      const title = content.article.i18n.hasOwnProperty(currentLanguage) ? content.article.i18n[currentLanguage].title : 'Site'

      if (content.article.themeColor) {
        className = className + ' _' + content.article.themeColor
      }

      if (rootState.editMode) {
        editMode =
          <ModEditBlock
            entityObject={content.article}
            title={content.article._i18nCL.title}
            rootState={rootState}
          />
      }

      if (content.articleType === 'blockList') {
        if (content.article.content.hasOwnProperty('templateBlockList')) {
          contentHtml =
            <ModTemplateBlockList
              templateData={content.article.content.templateBlockList}
              rootState={rootState}
            />
        }
        else if (content.article.content.hasOwnProperty('templateHome')) {
          contentHtml =
            <ModTemplateHome
              templateData={content.article.content.templateHome}
              rootState={rootState}
              currentLanguage={currentLanguage}
            />
        }
        else if (content.article.content.hasOwnProperty('templateContent')) {
          contentHtml =
            <ModTemplateContent
              templateData={content.article.content.templateContent}
              rootState={rootState}
              currentLanguage={currentLanguage}
              location={location}
              caasHelper={caasHelper}
            />
        }
        else if (content.article.content.hasOwnProperty('templateFlatFinder')) {
          contentHtml =
            <ModTemplateFlatFinder
              templateData={content.article.content.templateContent}
              currentLanguage={currentLanguage}
            />
        }
      }
    }

    if (rootState.loadingState === LoadingState.offline) {
      contentHtml =
        <div id="content" className="loading-state">
          <img alt="offline icon" src={offlineImage} />
        </div>
    }
    else if (!contentHtml) {
      if (rootState.loadingState === LoadingState.loading) {
        contentHtml =
          <div id="content" className="loading-state">
            <img alt="loading indicator" src={require("static/img/loading.gif")} />
          </div>
      }
      else {
        contentHtml = <Mod404 rootState={rootState} templateData={rootState.websiteSettings.blocks404} />
      }
    }

    return (
      <div id="content" className={className}>
        {editMode}
        {contentHtml}
      </div>
    )
  }
}
