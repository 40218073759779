import * as React from 'react'

export interface ModSvgProps {
  url: string
  id: string
}

export default class ModSvg extends React.Component<ModSvgProps, {}> {

  render() {
    const { url, id } = this.props

    const href = `${url}#${id}`
    return (
      <svg>
        <use className="svg-use" xlinkHref={href} />
      </svg>
    )
  }
}
