import * as React from 'react'
import { hydrate } from 'react-dom'
import App from './App'

require('intersection-observer')
require('svg4everybody')()

declare var FRONTEND_HOT_RELOADING: string
if (FRONTEND_HOT_RELOADING === 'true') {
  require('../static/scss/index_inline.scss')
  require('../static/scss/index_external.scss')
}

hydrate(<App />, document.getElementById('app-wrapper'))
