export const tags = {
  navigationNode: "navigationNode",
  media: "media",
  footer: "footer",
  contactRequests: "contactRequests",
  blockRichText: "blockRichText",
  blockNews: "blockNews",
  blockTitle: "blockTitle",
  blockTeaserSpot: "blockTeaserSpot",
  blockSpacer: "blockSpacer",
  blockSlider: "blockSlider",
  blockImage: "blockImage",
  blockImageText: "blockImageText",
  blockHeader: "blockHeader",
  blockGrid3: "blockGrid3",
  blockGrid4: "blockGrid4",
  blockGrid5: "blockGrid5",
  // blockSnippet: 'blockSnippet',
  // blockVimeo: 'blockVimeo',
  website: "website",
  translations: "translations",
  domain: "domain",
  page: "page",
  location: "location",
  // blockForm: 'blockForm',
  store: "store",
  categories: "categories",
  product: "product",
  merchant: "merchant",
  order: "order",
  blockStores: "blockStores",
  overlay: "overlay",
};
