import * as React from 'react'
import ModIsometrySvgFlat from './ModIsometrySvgFlat'
import { FlatFinderEntry } from '../../interfaces/Interfaces'

export interface ModIsometrySvgProps {
  entries: FlatFinderEntry[]
  activeFlatEntry?: FlatFinderEntry
  onFlatClick: (FlatFinderEntry) => void
  onBuyFlatClick: () => void
}

export default class ModIsometrySvg extends React.Component<ModIsometrySvgProps, {}> {
  constructor(props) {
    super(props)

    this.updateActiveState = this.updateActiveState.bind(this)
  }

  updateActiveState(flatEntry){
    this.props.onFlatClick(flatEntry)
  }

  render () {
    const building = this.props.activeFlatEntry ? this.props.activeFlatEntry.building_number.substring(0, 1) : null
    const floor = this.props.activeFlatEntry ? this.props.activeFlatEntry.floor : null

    return (
      <svg className="isometry__map__svg" version="1.1" x="0px" y="0px" viewBox="0 0 2323.9 1580.1" width="100%" height="100%" preserveAspectRatio="none">
        <g id="B" className={'isometry__building ' + (building === "B" ? 'active' : '')}>
          <g id="B0" className={'isometry__floor ' + (floor === "0" ? 'active' : '')}>
              <ModIsometrySvgFlat flat="71553.06.0003" floor="0" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path30_5_" className="st0" d="M710.9,739.9l64.8,37.4L736,800.2l0,0l-24.5-14.2l-27.7,16l14.1,8.2l-4.6,2.7l-14.1-8.2l-27.7,16l-32.3-18.6l-6.2,3.6l-15.2-8.8l106-61.2L710.9,739.9L710.9,739.9z"/>
              <path id="path32_5_" className="st0" d="M683.7,802.1l27.7-16v27.3l-13.6,7.9v-11L683.7,802.1z"/>
              <path id="path34_5_" className="st0" d="M697.9,821.3l13.6-7.9l21.7,12.5l-27.7,16l-7.6-4.4L697.9,821.3z"/>
              <path id="path36_5_" className="st0" d="M733.1,825.9l-21.7-12.5v-27.3l24.5,14.2v30.6l-2.8-1.6V825.9z"/>
              <path id="path38_5_" className="st0" d="M733.1,829.2l-27.7,16V842l27.7-16V829.2z"/>
              <path id="path42_5_" className="st0" d="M705.4,842v3.3l-7.6-4.4v-3.3L705.4,842z"/>
              <path id="path44_5_" className="st0" d="M697.9,840.9l-1.8,1l-2.8-1.6v-27.3l4.6-2.7v11v16.3L697.9,840.9z"/>
              <path id="path46_5_" className="st0" d="M693.2,812.9v27.3l-14.1-8.2v-27.3L693.2,812.9z"/>
              <path id="path48_5_" className="st0" d="M679.1,804.8v27.3l-13.6,7.9v-11l-14.1-8.1L679.1,804.8z"/>
              <path id="path50_5_" className="st0" d="M665.5,840l13.6-7.9l14.1,8.2l2.8,1.6l4.7,2.7l-27.7,16l-7.6-4.4V840z"/>
              <path id="path52_5_" className="st0" d="M665.5,856.3l7.6,4.4v3.3l-7.6-4.4V856.3z"/>
              <path id="path54_5_" className="st0" d="M700.8,847.9l-27.7,16v-3.3l27.7-16V847.9z"/>
            </ModIsometrySvgFlat>
              <ModIsometrySvgFlat flat="71553.05.0002" floor="0" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path58_5_" className="st0" d="M415.5,813.1l80.9-46.7l16.3,9.4l3.3,1.9l-8.3,4.8l0,0l0,0l26.8,15.5l-4.7,2.7l0,0l20.3,11.7
              l-27.6,15.9l0,0l25.5,14.7h0l0,0l32.4-18.7l46.4,26.8l-39.6,22.9l-15.4-8.9l-28.4,16.4l15.4,8.9l-4.6,2.7L415.5,813.1z"/>
              <path id="path60_5_" className="st0" d="M587.3,874.1l39.6-22.9v30.6L590.1,903l-2.8-1.6V874.1z"/>
              <path id="path64_5_" className="st0" d="M594.9,905.8v3.3l-28.4,16.4v-3.3L594.9,905.8z"/>
              <path id="path66_5_" className="st0" d="M594.9,905.8l-28.4,16.4l-7.6-4.4V900l13-7.5l15.4,8.9l2.8,1.6L594.9,905.8z"/>
              <path id="path68_5_" className="st0" d="M571.9,892.5l-13,7.5v-9.5l-15.4-8.9l28.4-16.4V892.5z"/>
              <path id="path70_5_" className="st0" d="M571.9,892.5v-27.3l15.4,8.9v27.3L571.9,892.5z"/>
              <path id="path74_5_" className="st0" d="M415.5,813.1l138.7,80.1v30.6l-138.7-80.1V813.1z"/>
              <path id="path76_5_" className="st0" d="M554.3,923.8v-30.6l4.6-2.7v30.6L554.3,923.8z"/>
              <path id="path84_5_" className="st0" d="M558.9,917.8l7.6,4.4v3.3l-7.6-4.4V917.8z"/>
            </ModIsometrySvgFlat>
              <ModIsometrySvgFlat flat="71553.05.0001" floor="0" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path56_5_" className="st0" d="M619.1,802.2l32.3,18.6l14.1,8.1l-38.6,22.3l-46.4-26.8l32.4-18.7L619.1,802.2z"/>
              <path id="path82_5_" className="st0" d="M626.9,881.8v-30.6l38.6-22.3v30.6L626.9,881.8z"/>
            </ModIsometrySvgFlat>
            <g id="B0-Areas" className="isometry__areas">
              <path id="path24_5_" className="st0" d="M580.5,824.4l-32.4,18.7l-25.5-14.7l27.6-15.9l0,0l-20.4-11.8l4.7-2.7l0,0l-26.9-15.5
                l8.3-4.8l16.2-9.4l8-4.6l43.9,25.3v0l121.9-70.4l20.8,12l9.7-5.6l31.7,18.3L807,721l33.1,19.1l-64.4,37.2l-64.8-37.4l0,0
                l-7.1-4.1l-106,61.2l15.2,8.8L580.5,824.4L580.5,824.4z"/>
              <path id="path28_5_" className="st0" d="M540.2,763.7l-8,4.6L512.7,757l83.6-48.3l15.1,8.7l125.1-72.2l133.9,77.3l-30.3,17.5L807,721
                l-38.8,22.4l-31.7-18.3l-9.7,5.6l-20.8-12L584.1,789L540.2,763.7L540.2,763.7z"/>
              <path id="path86_5_" className="st0" d="M512.7,757l19.5,11.3l-16.2,9.4l-3.3-1.9V757z"/>
              <path id="path88_5_" className="st0" d="M840.1,740.1l30.3-17.5l20.8-12l99.9,57.7l-155.2,89.6L736,800.2l39.7-22.9L840.1,740.1z"/>
              <path id="path90_5_" className="st0" d="M835.9,857.9l155.2-89.6v30.6l-155.2,89.6V857.9z"/>
              <path id="path92_5_" className="st0" d="M736,830.9v-30.6l99.9,57.7v30.6L736,830.9z"/>
            </g>
            <g id="B0-Strokes" className="isometry__strokes">
              <path d="M1178.4,875.6L891.6,710l-0.3,0.6l-0.3-0.6l-20.5,11.8l-133.6-77.1l-0.3,0.6l-0.3-0.6l-124.7,72l-14.8-8.6l-0.3,0.6
                l-0.3-0.6l-83.6,48.3l0.3,0.6H512v17.6l-15.3-8.8l-0.3,0.6l-0.3-0.6l-80.9,46.7l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1
                l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l28.4-16.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l35.8-20.6l38.3-22.1
                l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1l0.4-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4
                l0.3-0.6h0l0,0l-0.3,0.6l99.9,57.7l0.3-0.6l0.3,0.6l14.8-8.6l160.1,92.4l0.3-0.6l0.3,0.6l77.4-44.7l-0.3-0.6l0.3-0.6l-18.5-10.7
                l14.9-8.6l19.2,11.1l0.3-0.6l0.3,0.6l73-42.1l-0.3-0.6L1178.4,875.6z M1085.2,906.5l-16.2,9.4l0.3,0.6l-0.3,0.6l18.5,10.7
                l-76.1,43.9l-159.1-91.9l139.1-80.3l-0.3-0.6h0.7v-29.5l185,106.8l-71.7,41.4l-19.2-11.1l-0.3,0.6L1085.2,906.5z M611.8,718.1
                l124.7-72l132.6,76.6l-29,16.7l-32.8-18.9L807,721l-0.3-0.6l-38.5,22.2l-31.4-18.1l-0.3,0.6l-0.3-0.6l-9.4,5.4l-20.5-11.8
                l-0.3,0.6l-0.3-0.6l-121.5,70.2l-43.5-25.1l0,0l0,0l-0.3,0.6l-0.3-0.6l-7.6,4.4L514,757l82.3-47.5l14.8,8.6l0.3-0.6L611.8,718.1z
                  M566.5,922.2L566.5,922.2L566.5,922.2L566.5,922.2z M558.9,890.5l0.3-0.6l-14.4-8.3l26.4-15.2v25.8l-11.6,6.7v-8.4H558.9z
                  M572.5,866.3l14.1,8.1v25.8l-14.1-8.1V866.3z M554.9,893.5l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V893.5z M559.6,919l6.2,3.6v1.7
                l-6.2-3.6V919z M726.8,730.7l0.3,0.6l9.4-5.4l31.4,18.1l0.3-0.6l0.3,0.6l38.5-22.2l31.8,18.4l-63.1,36.4l-64.5-37.2l0,0l-7.1-4.1
                l-0.3,0.6l-0.3-0.6l-106,61.2l0.3,0.6l-0.3,0.6l14.2,8.2l-31.4,18.2l0,0l0,0l-32.1,18.5l-2.7-1.5l-21.5-12.4l26.5-15.3l0,0l0,0
                l0,0l0,0l0,0l-0.3-0.5l0,0l0,0h0.6v0l-0.6,0l0.3-0.6l-19.4-11.2l3.7-2.1l0,0l0,0l-0.1-0.1l0.1,0.1l0,0l-0.4-0.6h0.7v0l-0.6,0l0,0
                l0,0l0,0l0.3-0.6L509,782.5l7.3-4.2l16.2-9.4l7.6-4.4l43.5,25.1l0.3-0.6l0.3,0.6l121.5-70.2l20.5,11.8L726.8,730.7z M825.9,864.5
                l9.4-5.4v10.8L825.9,864.5z M705.4,841.2l-6.9-4v-15.5l12.9-7.5l20.3,11.7L705.4,841.2z M736,800.2L736,800.2L736,800.2
                L736,800.2z M613.3,806.3l5.8-3.4l31.9,18.4l13.1,7.6l-37.3,21.5l-45-26L613.3,806.3z M665.5,828.9l0.3-0.6l-13.1-7.6l25.7-14.9
                v25.8l-12.3,7.1v-9.9H665.5z M679.8,805.9l12.8,7.4v25.8l-12.8-7.4V805.9z M693.2,812.2l-13.8-8l-0.3,0.6l-0.3-0.6L651.4,820
                l-31.9-18.4l-0.3,0.6l-0.3-0.6L613,805l-13.8-8l104.7-60.4l6.8,3.9l0,0l63.8,36.9L736,799.5l-24.2-14l-0.3,0.6l-0.3-0.6l-27.7,16
                l0.3,0.6l-0.3,0.6l13.1,7.6L693.2,812.2z M697.9,810.3l0.3-0.6l-13.1-7.6l25.7-14.9V813l-12.3,7.1v-9.9H697.9z M835.9,857.1
                l-98.5-56.9l38.7-22.4l64.4-37.2l30.3-17.5l20.5-11.8l98.5,56.9L835.9,857.1z M627.6,851.6l37.3-21.5v9.9v16.3v2.9l-37.3,21.5
                V851.6z M698.5,838.8l6.2,3.6v1.7l-6.2-3.6V838.8z M733.1,825.9l0.3-0.6L712.1,813v-25.8l23.2,13.4v29.1l-1.5-0.9v-2.9H733.1z
                  M736.7,801.4l97.9,56.5l-10.4,6l0.3,0.6l-0.3,0.6l11,6.4v15.9l-98.5-56.9V801.4z M836.5,858.3l153.9-88.9v29.1L851,879
                l-14.5-8.4V858.3z M530.9,768.3l-14.9,8.6l-2.6-1.5v-17.2L530.9,768.3z M512.4,776.3l0.3-0.6L512.4,776.3l2.3,1.3l-7.3,4.2
                l0.3,0.6H507v0l0.7,0l0,0l-0.5,0.4l0.5-0.4l-0.3,0.6l0,0l0,0l25.8,14.9l-3.7,2.2l0.3,0.6l0,0l0,0l0,0l-0.3,0.6l0.3-0.6l-0.3,0.6
                l0,0l0,0l19.3,11.2l-26.6,15.4l0.3,0.6l0,0l0,0l0,0l-0.2,0.4l-0.1,0.1l0,0l0,0l0,0l0,0l0,0l22.5,13l3,1.7l0.3-0.6l0,0l-0.3,0.6
                l0.3-0.6l0.3,0.6l0,0l0,0l32.1-18.5l45,26l-38.3,22.1l-15.1-8.7l-0.3,0.6l-0.3-0.6L543.1,881l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9
                l-137.4-79.3l79.6-45.9L512.4,776.3z M553.6,893.5v29.1l-137.4-79.3v-29.1L553.6,893.5z M594.2,908.7l-27.1,15.6v-1.7l27.1-15.6
                V908.7z M566.5,921.4l-6.9-4v-17l12.3-7.1L587,902l2.8,1.6l3.7,2.2L566.5,921.4z M590.1,902.3L588,901v-26.6l38.3-22.1v29.1
                L590.1,902.3z M666.2,859.2v-1.7l6.2,3.6v1.7L666.2,859.2z M700.1,847.5l-26.4,15.2V861l26.4-15.2V847.5z M673.1,859.9l-6.9-4
                v-15.5l13-7.5l13.8,8l2.8,1.6l3.7,2.1L673.1,859.9z M696.1,841.1l-2.2-1.3v-26.6l3.3-1.9v9.9h0.7l0,0h-0.7v16.3v2.9L696.1,841.1z
                  M706.1,842.3l26.4-15.2v1.7l-26.4,15.2V842.3z M836.5,872.2l13.1,7.6l-13.1,7.6V872.2z"/>
            </g>
          </g>
          <g id="B1" className={'isometry__floor ' + (floor === "1" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.07.0102" floor="1" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path88_6_" className="st0" d="M975.8,865.4l-38,21.9l-27.7-16l-10.3,6l-75.3-43.5l3.1-1.8l23.7-13.7l42.3-24.4l36,20.8l46.4,26.8l-20.8,12L975.8,865.4z"/>
              <path id="path90_6_" className="st0" d="M910.1,871.4l27.7,16l-10.3,6v15.4l-17.4-10.1V871.4z"/>
              <path id="path92_6_" className="st0" d="M824.5,833.9l75.3,43.5v27.3l-2.8,1.6l-72.4-41.8v-4.3v-3.3V833.9z"/>
              <path id="path94_6_" className="st0" d="M899.8,877.3l10.3-6v27.3l-10.3,6V877.3z"/>
              <path id="path96_6_" className="st0" d="M890.8,913.1v-3.3l27.7,16v3.3L890.8,913.1z"/>
              <path id="path98_6_" className="st0" d="M918.5,929.1v-3.3l9-5.2v3.3L918.5,929.1z"/>
              <path id="path100_6_" className="st0" d="M918.5,925.8l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1v11.9L918.5,925.8z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0101" floor="1" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path72_6_" className="st0" d="M1034.7,875.4l26.4,15.2l8.3-4.8l16.2,9.4l3.3,1.9l-77.4,44.7L959.9,912l10.3-6l-27.7-16
                l-10.3,6l-4.6-2.7l10.3-6l38-21.9l-20.6-11.9l20.8-12l35.5,20.5L1034.7,875.4z"/>
              <path id="path102_6_" className="st0" d="M959.9,942.6l-9,5.2v-3.3l9-5.2V942.6z"/>
              <path id="path104_6_" className="st0" d="M959.9,942.6V912l51.6,29.8v30.6L959.9,942.6z"/>
              <path id="path106_6_" className="st0" d="M959.9,939.3l-9,5.2l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1V939.3z"/>
              <path id="path108_6_" className="st0" d="M950.9,944.5v3.3l-27.7-16v-3.3L950.9,944.5z"/>
              <path id="path110_6_" className="st0" d="M1011.5,972.4v-30.6l77.4-44.7v30.6L1011.5,972.4z"/>
              <path id="path112_6_" className="st0" d="M927.5,893.3l4.6,2.7v27.3l-2.8,1.6l-1.8-1v-3.3v-11.9V893.3z"/>
              <path id="path114_6_" className="st0" d="M932.2,896l10.3-6v27.3l-10.3,6V896z"/>
              <path id="path116_6_" className="st0" d="M942.5,890l27.7,16l-10.3,6v15.4l-17.4-10.1L942.5,890z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0102" floor="1" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path78_6_" className="st0" d="M736,769.6l39.8-23l33.1-19.1l36.4,21l-15.1,8.7l63.4,36.6l-42.3,24.4l-27.7-16l-15.4,8.9
                l-4.6-2.7l15.4-8.9l-27.7-16l-15.4,8.9L736,769.6L736,769.6z"/>
              <path id="path118_6_" className="st0" d="M736,769.7l39.8,23v27.3l-2.8,1.6L736,800.3v0V769.7z"/>
              <path id="path120_6_" className="st0" d="M791.2,783.7l27.7,16l-15.4,8.9v9.5l-12.3-7.1V783.7z"/>
              <path id="path122_6_" className="st0" d="M791.2,811l12.3,7.1v17.8l-7.6,4.4l-27.7-16l4.7-2.7l2.8-1.6L791.2,811z"/>
              <path id="path124_6_" className="st0" d="M768.2,824.3l27.7,16v3.3l-27.7-16V824.3z"/>
              <path id="path126_6_" className="st0" d="M775.7,819.9v-27.3l15.4-8.9V811L775.7,819.9z"/>
              <path id="path128_6_" className="st0" d="M823.5,802.4l27.7,16l-23.7,13.7l-4.1-2.3L823.5,802.4z"/>
              <path id="path130_9_" className="st0" d="M823.5,802.4v27.3l-15.4,8.9v-27.3L823.5,802.4z"/>
              <path id="path132_6_" className="st0" d="M823.5,829.7l4.1,2.3l-3.1,1.8v23l-24-13.8l4.8-2.7l2.8-1.6L823.5,829.7z"/>
              <path id="path134_6_" className="st0" d="M808.1,811.3v27.3l-2.8,1.6l-1.8-1v-3.3v-17.8v-9.5L808.1,811.3z"/>
              <path id="path136_6_" className="st0" d="M800.5,843l24,13.8v3.3l-24-13.8V843z"/>
              <path id="path138_6_" className="st0" d="M795.9,840.3l7.6-4.4v3.3l-7.6,4.4V840.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0103" floor="1" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path56_6_" className="st0" d="M677.8,773.4l-123.3-71.2l41.8-24.1l15.2,8.8l31.5-18.2l18.6,10.8l0.9,0.5l-1.2,0.7
                l49.6,28.6l64.8,37.4l-39.8,23l-25.8-14.9l-27.7,16l15.4,8.9l-4.6,2.7L677.8,773.4z"/>
              <path id="path74_6_" className="st0" d="M733.2,798.6l-27.7,16v-3.3l27.7-16V798.6z"/>
              <path id="path76_6_" className="st0" d="M736,800.2l-2.8-1.6v-3.3l-23-13.3v-27.3l25.8,14.9l0,0V800.2z"/>
              <path id="path80_6_" className="st0" d="M705.4,811.3v3.3l-7.6-4.4V807L705.4,811.3z"/>
              <path id="path82_6_" className="st0" d="M710.2,782.1l23,13.3l-27.7,16l-7.6-4.4v-17.8L710.2,782.1z"/>
              <path id="path84_6_" className="st0" d="M710.2,782.1l-12.3,7.1v-9.5l-15.4-8.9l27.7-16V782.1z"/>
              <path id="path86_6_" className="st0" d="M693.2,782.3l4.6-2.7v30.6l-1.8,1l-2.8-1.6V782.3z"/>
              <path id="path320_6_" className="st1" d="M705.4,811.3l27.7-16"/>
              <path id="path22_6_" className="st0" d="M677.8,800.7v-27.3l15.5,8.9v27.3L677.8,800.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0102" floor="1" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path32_6_" className="st0" d="M415.5,782.5l72.6-41.9l46.4,26.8l-4.8,2.8l20.4,11.8l-27.6,15.9l25.6,14.8l32.4-18.7
                l46.4,26.8l-38.6,22.3l-15.4-8.9l-29.4,17l15.4,8.9l-4.6,2.7L415.5,782.5z"/>
              <path id="path36_6_" className="st0" d="M588.3,842.9l38.6-22.3v30.6l-35.7,20.6l-2.8-1.6L588.3,842.9z"/>
              <path id="path40_6_" className="st0" d="M595.9,874.6v3.3l-29.4,17v-3.3L595.9,874.6z"/>
              <path id="path42_6_" className="st0" d="M595.9,874.6l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L595.9,874.6z"/>
              <path id="path44_6_" className="st0" d="M572.9,861.3l-14,8.1v-9.5l-15.4-8.9l29.4-17V861.3z"/>
              <path id="path46_6_" className="st0" d="M415.5,782.5l138.7,80.1v30.6l-138.7-80.1V782.5z"/>
              <path id="path48_6_" className="st0" d="M554.3,893.2v-30.6l4.6-2.7v30.6L554.3,893.2z"/>
              <path id="path52_6_" className="st0" d="M588.3,842.9l0,27.3l-15.4-8.9V834L588.3,842.9z"/>
              <path id="path54_6_" className="st0" d="M558.9,887.2l7.6,4.4v3.3l-7.6-4.4V887.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0101" floor="1" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_6_" className="st0" d="M677.8,773.4v27.3l-12.3,7.1v-9.5l-15.4-8.9L677.8,773.4z"/>
              <path id="path26_6_" className="st0" d="M677.8,800.7l15.5,8.9l2.8,1.6l4.7,2.7l-27.7,16l-7.6-4.4v-17.8L677.8,800.7z"/>
              <path id="path28_6_" className="st0" d="M673.1,830l27.7-16v3.3l-27.7,16V830z"/>
              <path id="path30_6_" className="st0" d="M673.1,830v3.3l-7.6-4.4v-3.3L673.1,830z"/>
              <path id="path34_6_" className="st0" d="M626.9,820.6l-46.4-26.8l32.4-18.7l-25.6-14.8l-22,12.7L545,761.3l-10.4,6l-46.4-26.8
                l66.3-38.3l123.3,71.2l-27.7,16l15.4,8.9L626.9,820.6z"/>
              <path id="path50_6_" className="st0" d="M626.9,851.2v-30.6l38.6-22.3v30.6L626.9,851.2z"/>
            </ModIsometrySvgFlat>
            <g id="B1-Areas" className="isometry__areas">
              <path id="path38_6_" className="st0" d="M580.5,793.8l-32.4,18.7l-25.6-14.8l27.6-15.9l-20.4-11.8l4.8-2.8l10.4-6l20.4,11.8l22-12.7
                l25.6,14.8L580.5,793.8z"/>
              <path id="path60_6_" className="st0" d="M775.8,746.7l-64.8-37.4l0,0l-49.6-28.6l1.1-0.7h0l18.6-10.8l2.8-1.6l25.9,14.9l-9.4,5.4
                l23.7,13.7l0,0l12.3-7.1l0,0l31.7,18.3l20.3-11.7l169,97.6l-2.4,1.4l0,0L1002,827l72-41.6l30.8,17.8l-72,41.6l53.8,31.1l-1,0.6
                l-16.2,9.4l-8.3,4.8l-26.4-15.2l12.3-7.1l-23.2-13.4l-12.3,7.1L976,841.5l-46.4-26.8l15.1-8.7l-99.4-57.4l-36.4-21L775.8,746.7z"
                />
              <path id="path62_6_" className="st0" d="M957.5,798.6l-169-97.6l-20.3,11.7l-31.7-18.3l0,0l0,0h0l-12.3,7.1l-23.7-13.7l9.4-5.4
                L684,667.6l-2.8,1.6l-19.5-11.3l74.9-43.2l243.2,140.4l-13.3,7.7l13.3,7.7l29.9,17.3l34.1-19.7l30.3,17.5l-72,41.6L955.1,800
                L957.5,798.6L957.5,798.6z"/>
              <path id="path66_6_" className="st0" d="M944.7,806l-15.1,8.7l-36-20.8l-63.4-36.6l15.1-8.7L944.7,806z"/>
              <path id="path68_6_" className="st0" d="M1086.6,875.9l-53.8-31.1l72-41.6l73.4,42.4l-73,42.1l-19.5-11.3L1086.6,875.9z"/>
              <path id="path70_6_" className="st0" d="M1023.8,854.9l23.2,13.4l-12.3,7.1l-23.2-13.4L1023.8,854.9z"/>
              <path id="path140_6_" className="st0" d="M1178.1,845.6v30.6l-73,42.1v-30.6L1178.1,845.6z"/>
              <path id="path142_6_" className="st0" d="M1085.6,876.5l19.5,11.3v30.6l-16.2-9.4v-11.9l-3.3-1.9V876.5z"/>
              <path id="path144_6_" className="st0" d="M1085.6,876.5v18.7l-16.2-9.4L1085.6,876.5z"/>
              <path id="path146_6_" className="st0" d="M966.4,762.8l13.3-7.7v15.3L966.4,762.8z"/>
              <path id="path148_6_" className="st0" d="M661.6,657.9l19.5,11.3L662.5,680l-0.9-0.5L661.6,657.9z"/>
            </g>
            <g id="B1-Strokes" className="isometry__strokes">
              <path d="M1178.8,845.6h-0.7l0.3-0.6l-73.4-42.4l-30.8-17.8l-30.3-17.5l-0.3,0.6l-0.3-0.6l-33.7,19.5L980.3,770v-14.9h-0.7
                l0.3-0.6L736.8,614.1l-0.3,0.6l-0.3-0.6l-74.9,43.2l0.3,0.6l0,0H661v20.4l-17.6-10.2l-0.3,0.6l-0.3-0.6l-31.2,18l-14.8-8.6
                l-0.3,0.6l-0.3-0.6l-41.8,24.1L487.8,740l-72.6,41.9l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2
                l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20l38.3-22.1l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16
                l-0.3-0.6h0.7V814h-0.7l0.3-0.6l-3.7-2.1l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4l0.3-0.6h0l-0.3,0.6l35.9,20.7
                l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.3-4.2l0.5,0.3l-3.8,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6
                l23.6,13.6v4h0.7l-0.3,0.6l71.4,41.2l-5.2,3l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l0.5,0.3l-5.2,3
                l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l51.3,29.6l0.3-0.6l0.3,0.6l77.4-44.7l-0.3-0.6h0.7v-17.6l15.2,8.8
                l0.3-0.6l0.3,0.6l73-42.1l-0.3-0.6h0.7V845.6z M1177.4,875.8l-71.7,41.4v-29.1l71.7-41.4V875.8z M1088.2,927.3l-76.1,43.9v-29.1
                l76.1-43.9V909V927.3z M979,769.2l-11.3-6.5l11.3-6.5V769.2z M966.1,762.2l0.3,0.6l-0.3,0.6l13.3,7.7l29.9,17.3l0.3-0.6l0.3,0.6
                l33.7-19.5l29,16.7l-70.6,40.8L956.5,800l1.4-0.8l0,0l0,0l-0.3-0.6l0.3-0.6l-169-97.6l-0.3,0.6l-0.3-0.6l-20,11.5l-31.4-18.1
                l-0.3,0.6v-0.7h0l0,0.7l-0.3-0.6l0,0l0,0l-12,6.9l-22.3-12.9l8.4-4.8l-0.3-0.6l0.3-0.6L684.3,667l-0.3,0.6l-0.3-0.6l-2.5,1.4
                L663,657.9l73.5-42.4l241.9,139.6L966.1,762.2z M942.5,917.4L942.5,917.4L942.5,917.4L942.5,917.4z M919.2,926.2l7.7-4.4v1.7
                l-7.7,4.4V926.2z M698.2,779.1l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4h-0.7L698.2,779.1z M705.4,811.3l0.3-0.6l-7.2-4.2v-17
                l11.6-6.7l22.3,12.9v2.5l-26.4,15.2v-2.1H705.4z M733.2,795.3l0.3-0.6l-22.6-13.1v-25.8l24.5,14.1v29.1l-1.5-0.9v-2.9H733.2z
                  M662.7,680.6l0.1-0.1l0,0l18.6-10.8l0,0l2.5-1.4l24.6,14.2l-8.4,4.8l0.3,0.6l-0.3,0.6l23.7,13.7l0.3-0.6l0.3,0.6l0,0l0,0l12-6.9
                l31.4,18.1l0.3-0.6l0.3,0.6l20-11.5l167.6,96.8l-1.4,0.8l0.3,0.6l-0.3,0.6l46.9,27.1l0.3-0.6l0.3,0.6l71.6-41.4l29.4,17l-71,41
                l0.3,0.6l-0.3,0.6l52.8,30.5l0,0l0,0l-16.2,9.4l-7.9,4.6l-25-14.5l11.3-6.5l-0.3-0.6l0.3-0.6l-23.2-13.4l-0.3,0.6l-0.3-0.6
                l-12,6.9l-35.2-20.3l-45.4-26.2l14.1-8.2l-0.3-0.6l0.3-0.6L845.7,748l-36.4-21l-0.3,0.6l-0.3-0.6l-32.8,18.9l-64.5-37.2l0,0
                L662.7,680.6z M1176.8,845.6l-71.7,41.4l-18.2-10.5l0,0l-0.3-0.6l0.3-0.6l-52.8-30.5l70.6-40.8L1176.8,845.6z M941.8,917l-9,5.2
                v-25.8l9-5.2V917z M943.1,891.2l25.7,14.9l-9.3,5.4l0.3,0.6h-0.7v14.3l-16.1-9.3V891.2z M1011.5,941l-50.3-29l9.3-5.4l-0.3-0.6
                l0.3-0.6l-27.7-16l-0.3,0.6l-0.3-0.6l-10,5.8l-3.3-1.9l9.3-5.4l38-21.9l-0.3-0.6l0.3-0.6l-19.6-11.3l19.5-11.3l35.2,20.3l0,0
                l23.2,13.4l26.4,15.2l0.3-0.6l0.3,0.6l7.9-4.6l15.9,9.2l0,0l2.3,1.3L1011.5,941z M926.8,893.3v14.3l-16.1-9.3v-25.8l25.7,14.9
                l-9.3,5.4l0.3,0.6H926.8z M1070.7,885.8l14.2-8.2v16.4L1070.7,885.8z M1085.6,876.5l-0.1-0.2L1085.6,876.5L1085.6,876.5z
                  M1045.6,868.3l-11,6.3l-21.9-12.6l11-6.3L1045.6,868.3z M845.3,749.4l98.1,56.6l-13.8,8L894,793.4l0,0l-62.4-36L845.3,749.4z
                  M808.8,811.7l14.1-8.1v25.8l-14.1,8.1V811.7z M824.2,803.5l25.7,14.9l-22.3,12.9l-3.4-2V803.5z M828,832.6l23.7-13.7l42-24.3
                l35.6,20.6l45.4,26.2L954.8,853l0.3,0.6l-0.3,0.6l19.6,11.3l-36.6,21.1l-27.4-15.8l-0.3,0.6h0l-0.3-0.6l-10,5.8l-73.9-42.7
                L828,832.6z M900.4,877.7l9-5.2v25.8l-9,5.2V877.7z M776.1,747.3l32.8-18.9l35.1,20.3l-14.1,8.2l0.3,0.6l-0.3,0.6l62.4,36
                l-41,23.7l-27.4-15.8l-0.3,0.6l-0.3-0.6l-0.1,0.1l-0.1-0.2l-15.1,8.7l-3-1.7l14.4-8.3l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6
                l-0.3-0.6l-15.1,8.7l-38.4-22.2L776.1,747.3z M776.4,793l14.1-8.1v25.8l-14.1,8.1V793z M791.8,784.9l25.7,14.9l-14.4,8.3l0.3,0.6
                h-0.7v8.4l-11-6.3V784.9z M960.6,939.3v-11.9v-14.3l50.3,29v29.1l-50.3-29V939.3z M1088.9,897.1l0.3-0.6l-3-1.7v-17.2l18.2,10.5
                v29.1l-14.9-8.6v-11.5H1088.9z M679.8,669.2l-17.3,10l-0.2-0.1v-20L679.8,669.2z M566.5,891.6L566.5,891.6L566.5,891.6
                L566.5,891.6z M558.9,859.9l0.3-0.6l-14.4-8.3l27.4-15.8v25.8l-12.7,7.3v-8.4H558.9z M573.6,835.1l14.1,8.1l0,25.8l-14.1-8.1
                V835.1z M554.9,862.9l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V862.9z M559.6,888.4l6.2,3.6v1.7l-6.2-3.6V888.4z M554.5,703l121.9,70.4
                l-26.7,15.4l0.3,0.6l-0.3,0.6l14.4,8.3l-37.3,21.5l-45.1-26l31.4-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5
                l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8l-45.1-26L554.5,703z M692.6,808.5l-14.1-8.2v-25.8l14.1,8.2V808.5z M677.1,800.4l-11,6.3
                v-8.4h-0.7l0.3-0.6l-14.4-8.3l25.7-14.9V800.4z M548.1,811.8l-24.2-14l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2l3.8-2.2l10.1-5.8
                l20,11.6l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14l-31.4,18.2L548.1,811.8z M627.6,821l37.3-21.5v8.4v17.8v2.9l-37.3,21.5V821z
                  M666.2,826.8l6.2,3.6v1.7l-6.2-3.6V826.8z M488.2,741.3l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6
                l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-37.2,21.5l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6
                l14.4,8.3l-3.3,1.9l-137.4-79.3L488.2,741.3z M553.6,862.9V892l-137.4-79.3v-29.1L553.6,862.9z M595.2,877.5l-28.1,16.2V892
                l28.1-16.2V877.5z M566.5,890.8l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l3.7,2.2L566.5,890.8z M591.2,871.1l-2.2-1.3l0-26.6
                l37.2-21.5v29.1L591.2,871.1z M673.7,830.4l26.4-15.2v1.7l-26.4,15.2V830.4z M673.1,829.3l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6
                l3.7,2.1L673.1,829.3z M696.1,810.5l-2.2-1.3v-26.6l3.3-1.9v8.4V807v2.9L696.1,810.5z M698.5,809.9v-1.7l6.2,3.6v1.7L698.5,809.9
                z M736.7,770.8l38.4,22.2v26.6l-2.2,1.3l-36.3-20.9V770.8z M773.2,822.2l2.8-1.6l15.1-8.7l11.7,6.7v17l-6.9,4l-26.4-15.2
                L773.2,822.2z M795.2,840.7v1.7l-26.4-15.2v-1.7L795.2,840.7z M796.6,840.7l6.3-3.6v1.7l-6.3,3.6V840.7z M804.2,835.9v-17.8v-8.4
                l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V835.9z M805.6,840.8l2.8-1.6l15.1-8.7l2.7,1.6l-2.1,1.2l0.3,0.6h-0.7v21.8l-22-12.7L805.6,840.8
                z M823.9,857.2v1.7l-22.6-13.1v-1.7L823.9,857.2z M825.2,856.8V835l73.9,42.7v26.6l-2.2,1.3l-71.8-41.4v-4V856.8z M897.3,906.9
                l2.8-1.6l10-5.8l16.8,9.7v11.1l-8.3,4.8l-26.4-15.2L897.3,906.9z M917.9,926.2v1.7l-26.4-15.2V911L917.9,926.2z M927.5,920.6
                L927.5,920.6l0.7,0v-11.9v-14.3l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7v-2.9H927.5z M929.6,925.5l2.8-1.6l10-5.8l16.8,9.7V939l-8.3,4.8
                l-26.4-15.2L929.6,925.5z M923.8,931.4v-1.7l26.4,15.2v1.7L923.8,931.4z M951.6,944.9l7.7-4.4v1.7l-7.7,4.4V944.9z M611.8,687.5
                l31.2-18l18.2,10.5l-0.2,0.1l0.3,0.6h0l0,0v0l-0.3,0.6l49.6,28.6l63.8,36.9L736,768.9l-25.5-14.7l-0.3,0.6l-0.3-0.6l-27.7,16
                l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-15.1-8.7l-122.3-70.6l40.4-23.3l14.8,8.6l0.3-0.6L611.8,687.5z M736,800.2L736,800.2
                L736,800.2L736,800.2L736,800.2z"/>
            </g>
          </g>
          <g id="B2" className={'isometry__floor ' + (floor === "2" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.07.0204" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path122_7_" className="st0" d="M1022.4,784.7l-20.3,11.7l-46.9-27.1l37.8-21.8l0,0l16.6,9.6l34.1-19.7l134.4,77.6l-4.6,2.7
                l-15.5-9l-29.4,17l15.5,9l-63.4,36.6l-69.1-39.9l25.6-14.8l-19.9-11.5l20.3-11.8L1022.4,784.7z"/>
              <path id="path154_7_" className="st0" d="M1080.6,901.9v-30.6l63.4-36.6v30.6L1080.6,901.9z"/>
              <path id="path156_7_" className="st0" d="M1178.1,815v30.6l-1.8,1l-2.8-1.6v-27.3L1178.1,815z"/>
              <path id="path158_7_" className="st0" d="M1144,865.3V862l7.6,4.4v3.3L1144,865.3z"/>
              <path id="path160_7_" className="st0" d="M1151.6,869.7v-3.3l29.4-17v3.3L1151.6,869.7z"/>
              <path id="path162_7_" className="st0" d="M1151.6,866.4l-7.6-4.4v-17.9l13.9-8l15.5,9l2.8,1.6l4.7,2.7L1151.6,866.4z"/>
              <path id="path164_7_" className="st0" d="M1157.9,836l-13.9,8v-9.4l-15.5-9l29.4-17V836z"/>
              <path id="path166_7_" className="st0" d="M1173.5,845l-15.5-9v-27.3l15.5,9V845z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0203" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path102_7_" className="st0" d="M818.9,769.1l119-68.7l41.8,24.1l-13.3,7.7l13.3,7.7l13.3,7.7l-37.8,21.8l-25.6,14.8
                l-36-20.8l0,0l-42.3,24.4l-27.7-16l-15.4,8.9l-4.6-2.7L818.9,769.1z"/>
              <path id="path110_7_" className="st0" d="M966.4,732.2l13.3-7.7v15.3L966.4,732.2z"/>
              <path id="path112_7_" className="st0" d="M824.5,829.5l-24-13.8v-3.3l24,13.8V829.5z"/>
              <path id="path114_7_" className="st0" d="M824.5,826.2l-24-13.8l4.7-2.7l2.8-1.6l15.4-8.9l4.1,2.3l-3.1,1.8L824.5,826.2z"/>
              <path id="path116_7_" className="st0" d="M827.6,801.5l-4.1-2.3v-27.3l27.7,16L827.6,801.5z"/>
              <path id="path118_7_" className="st0" d="M823.6,771.8v27.3l-15.4,8.9v-27.3L823.6,771.8z"/>
              <path id="path120_7_" className="st0" d="M808.1,780.7V808l-2.8,1.6l-1.8-1v-3.3v-17.8V778L808.1,780.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0202" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path104_7_" className="st0" d="M851.3,787.8l42.3-24.4l36,20.8l0,0l46.4,26.8l-20.8,12l20.6,11.9l-38,21.9l-27.7-16
                l-10.3,6l-75.3-43.4l3.1-1.8L851.3,787.8z"/>
              <path id="path124_7_" className="st0" d="M910.1,840.7l27.7,16l-10.3,6v15.4l-17.4-10.1V840.7z"/>
              <path id="path128_7_" className="st0" d="M824.5,803.3l75.3,43.4V874l-2.8,1.6l-72.4-41.8v-4.3v-3.3V803.3z"/>
              <path id="path130_10_" className="st0" d="M899.8,846.7l10.3-6v27.3l-10.3,6V846.7z"/>
              <path id="path132_7_" className="st0" d="M890.8,882.5v-3.3l27.7,16v3.3L890.8,882.5z"/>
              <path id="path134_7_" className="st0" d="M918.5,898.5v-3.3l9-5.2v3.3L918.5,898.5z"/>
              <path id="path136_7_" className="st0" d="M918.5,895.2l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1V890L918.5,895.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0201" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path126_7_" className="st0" d="M937.8,856.8l38-21.9l-20.6-11.9l20.8-12l35.5,20.5l69.1,39.9l-69.1,39.9l-51.6-29.8l10.3-6
                l-27.7-16l-10.3,6l-4.6-2.7L937.8,856.8z"/>
              <path id="path138_7_" className="st0" d="M959.9,912l-9,5.2v-3.3l9-5.2V912z"/>
              <path id="path140_7_" className="st0" d="M959.9,912v-30.6l51.6,29.8v30.6L959.9,912z"/>
              <path id="path142_7_" className="st0" d="M959.9,908.7l-9,5.2l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1V908.7z"/>
              <path id="path144_7_" className="st0" d="M950.9,913.9v3.3l-27.7-16v-3.3L950.9,913.9z"/>
              <path id="path146_7_" className="st0" d="M1011.5,941.8v-30.6l69.1-39.9v30.6L1011.5,941.8z"/>
              <path id="path148_7_" className="st0" d="M927.5,862.7l4.6,2.7v27.3l-2.8,1.6l-1.8-1V890v-11.9V862.7z"/>
              <path id="path150_7_" className="st0" d="M932.2,865.4l10.3-6v27.3l-10.3,6V865.4z"/>
              <path id="path152_7_" className="st0" d="M942.5,859.4l27.7,16l-10.3,6v15.4l-17.4-10.1L942.5,859.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0202" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path62_7_" className="st0" d="M736,739l39.8-23l0,0l25.5-14.7l0,0l-18-10.4l20.3-11.7l0,0l48.7-28.1l0,0l85.5,49.3
                l-119,68.7l-27.7-16l-15.4,8.9L736,739L736,739z"/>
              <path id="path90_7_" className="st0" d="M736,739l39.8,23v27.3l-2.8,1.6L736,769.7v0V739z"/>
              <path id="path92_7_" className="st0" d="M768.2,797v-3.3l27.7,16v3.3L768.2,797z"/>
              <path id="path94_7_" className="st0" d="M795.9,809.7l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L795.9,809.7z"/>
              <path id="path96_7_" className="st0" d="M803.5,787.5l-12.3-7.1v-27.3l27.7,16l-15.4,8.9V787.5z"/>
              <path id="path98_7_" className="st0" d="M775.7,789.3V762l15.4-8.9v27.3L775.7,789.3z"/>
              <path id="path100_7_" className="st0" d="M795.9,809.7l7.6-4.4v3.3l-7.6,4.4V809.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0201" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path68_7_" className="st0" d="M736.5,663.9L667.4,624h0l69.1-39.9l50.9,29.4l-10.2,5.9l10.2,5.9l18.2,10.5l10.2-5.9
                l36.7,21.2l0,0l-48.7,28.1l-15.1-8.7l0,0l-20.3,11.7L736.5,663.9L736.5,663.9z"/>
              <path id="path78_7_" className="st0" d="M787.4,613.5v11.8l-10.2-5.9L787.4,613.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0203" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path42_7_" className="st0" d="M677.8,742.8l-123.3-71.2l41.8-24.1l15.2,8.8l55.9-32.3l0,0l69.1,39.9l-25.5,14.7l0,0l0,0
                l64.8,37.4L736,739l-24.5-14.2l-27.7,16l14.1,8.2l-4.6,2.7L677.8,742.8z"/>
              <path id="path58_7_" className="st0" d="M733.2,768l-27.7,16v-3.3l27.7-16L733.2,768z"/>
              <path id="path60_7_" className="st0" d="M736,769.6l-2.8-1.6v-3.3l-21.7-12.5v-27.3L736,739l0,0V769.6z"/>
              <path id="path66_7_" className="st0" d="M705.5,780.7v3.3l-7.6-4.4v-3.3L705.5,780.7z"/>
              <path id="path70_7_" className="st0" d="M711.5,752.2l21.7,12.5l-27.7,16l-7.6-4.4v-16.3L711.5,752.2z"/>
              <path id="path72_7_" className="st0" d="M711.5,752.2l-13.6,7.9v-11l-14.1-8.2l27.7-16V752.2z"/>
              <path id="path74_7_" className="st0" d="M693.2,751.7l4.6-2.7v30.6l-1.8,1l-2.8-1.6V751.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0201" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_7_" className="st0" d="M677.8,742.8v27.3l-12.3,7.1v-9.5l-15.4-8.9L677.8,742.8z"/>
              <path id="path26_7_" className="st0" d="M677.8,770.1l15.5,8.9l2.8,1.6l4.7,2.7l-27.7,16l-7.6-4.4v-17.8L677.8,770.1z"/>
              <path id="path28_7_" className="st0" d="M673.1,799.4l27.7-16v3.3l-27.7,16V799.4z"/>
              <path id="path30_7_" className="st0" d="M673.1,799.4v3.3l-7.6-4.4v-3.3L673.1,799.4z"/>
              <path id="path32_7_" className="st0" d="M488.2,709.9l66.3-38.3l123.3,71.2l-27.7,16l15.4,8.9L626.9,790l-46.4-26.8l32.4-18.7
                l-25.6-14.8l-22,12.7L545,730.7l-10.4,6L488.2,709.9z"/>
              <path id="path36_7_" className="st0" d="M626.9,790l38.6-22.3v30.6l-38.6,22.3V790z"/>
              <path id="path22_7_" className="st0" d="M677.8,770.1v-27.3l15.5,8.9v27.3L677.8,770.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0202" floor="2" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path34_7_" className="st0" d="M580.5,763.2l46.4,26.8l-38.6,22.3l-15.4-8.9l-29.4,17l15.4,8.9l-4.6,2.7l-138.7-80.1
                l72.6-41.9l46.4,26.8l-4.8,2.8l20.4,11.8l-27.6,15.9l25.6,14.8L580.5,763.2z"/>
              <path id="path40_7_" className="st0" d="M626.9,790v30.6l-35.7,20.6l-2.8-1.6l0-27.3L626.9,790z"/>
              <path id="path44_7_" className="st0" d="M595.9,844v3.3l-29.4,17V861L595.9,844z"/>
              <path id="path46_7_" className="st0" d="M595.9,844l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L595.9,844z"/>
              <path id="path48_7_" className="st0" d="M572.9,830.7l-14,8.1v-9.5l-15.4-8.9l29.4-17V830.7z"/>
              <path id="path50_7_" className="st0" d="M415.5,751.9l138.7,80.1v30.6l-138.7-80.1V751.9z"/>
              <path id="path52_7_" className="st0" d="M554.3,862.6v-30.6l4.6-2.7v30.6L554.3,862.6z"/>
              <path id="path54_7_" className="st0" d="M588.3,812.3l0,27.3l-15.4-8.9v-27.3L588.3,812.3z"/>
              <path id="path56_7_" className="st0" d="M558.9,856.6l7.6,4.4v3.3l-7.6-4.4V856.6z"/>
            </ModIsometrySvgFlat>
            <g id="B2-Areas" className="isometry__areas">
              <path id="path38_7_" className="st0" d="M612.9,744.5l-32.4,18.7l-32.4,18.7l-25.6-14.8l27.6-15.9l-20.4-11.8l4.8-2.8l10.4-6
                l20.4,11.8l22-12.7L612.9,744.5z"/>
              <path id="path82_7_" className="st0" d="M788.5,670.4l15.1,8.7l-20.3,11.7l0,0l18,10.4l-25.5,14.7l-64.8-37.4l25.5-14.7l31.7,18.3
                l0,0L788.5,670.4z"/>
              <path id="path108_7_" className="st0" d="M929.6,784.1l25.6-14.8l46.9,27.1l20.3-11.7l15.1,8.7l-20.3,11.8l19.9,11.5l-25.6,14.8
                L976,810.9L929.6,784.1z"/>
            </g>
            <g id="B02-Strokes" className="isometry__strokes">
              <path d="M1181.7,849.4h-0.7l0.3-0.6l-3.7-2.2l0.8-0.5l-0.3-0.6h0.7V815h-0.7l0.3-0.6L1044,736.8l-0.3,0.6l-0.3-0.6l-33.7,19.5
                l-16.2-9.4l-0.3,0.6l0,0l0.3-0.6l-13-7.5v-14.9h-0.7l0,0l0.3-0.6l-41.8-24.1l-85.5-49.3l-0.3,0.6h0l0,0l0.3-0.6l-36.7-21.2
                l-0.3,0.6l-0.3-0.6l-9.9,5.7L788,624.9v-11.4h-0.7l0.3-0.6l-50.9-29.4l-0.3,0.6l-0.3-0.6L667,623.4l0.3,0.6l0,0l-0.3-0.6
                l-55.6,32.1l-14.8-8.6l-0.3,0.6l-0.3-0.6l-41.8,24.1l-66.3,38.3l-72.6,41.9l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6
                l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7V844h-0.7l0.3-0.6l-3.7-2.2l34.7-20l38.3-22.1l7.2,4.2l0.3-0.6
                l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4l0.3-0.6h0
                l-0.3,0.6l35.9,20.7l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.2l0.3,0.6h-0.7v3.3
                h0.7l-0.3,0.6l23.6,13.6v4h0.7l-0.3,0.6l71.4,41.2l-5.2,3l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l0.5,0.3
                l-5.2,3l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l51.3,29.6l0.3-0.6h0l0.3,0.6l69.1-39.9l63.1-36.4l7.2,4.2
                l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7V849.4z M786.7,624.1l-8.2-4.7l8.2-4.7V624.1z M786,613.5l-9.2,5.3l0.3,0.6l-0.3,0.6
                l10.2,5.9l18.2,10.5l0.3-0.6l0.3,0.6l9.9-5.7l35.3,20.4l-47.4,27.4l-14.8-8.6l0,0l0,0l-0.3,0.6l-0.3-0.6l0.3,0.6l0,0l-0.3-0.6
                l-20,11.5l-31.4-18.1l0,0l0,0L668.7,624l67.8-39.1L786,613.5z M1177.4,845.2l-1.1,0.7l-2.2-1.3v-26.6l3.3-1.9V845.2z
                  M942.5,886.8L942.5,886.8L942.5,886.8L942.5,886.8z M558.9,829.3l0.3-0.6l-14.4-8.3l27.4-15.8v25.8l-12.7,7.3v-8.4H558.9z
                  M573.6,804.5l14.1,8.1l0,25.8l-14.1-8.1V804.5z M554.9,832.3l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V832.3z M559.6,857.7l6.2,3.6v1.7
                l-6.2-3.6V857.7z M566.5,861L566.5,861L566.5,861L566.5,861z M676.5,742.8l-26.7,15.4l0.3,0.6l-0.3,0.6l14.4,8.3l-37.3,21.5
                l-45.1-26l31.4-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8l-45.1-26
                l65-37.5L676.5,742.8z M665.5,767.7l0.3-0.6l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4H665.5z M678.5,744l14.1,8.2v25.8l-14.1-8.2
                V744z M523.9,767.2l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2l3.8-2.2l10.1-5.8l20,11.6l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14
                l-31.4,18.2l-32.1,18.5L523.9,767.2z M627.6,790.4l37.3-21.5v8.4v17.8v2.9l-37.3,21.5V790.4z M666.2,796.2l6.2,3.6v1.7l-6.2-3.6
                V796.2z M852.4,651.1L852.4,651.1L852.4,651.1L852.4,651.1L852.4,651.1L852.4,651.1z M736.5,664.7l31.4,18.1l0,0l0,0l0.3-0.6
                l0.3,0.6l-0.3-0.6l0.3,0.6l20-11.5l13.8,8L783,690.3l0.3,0.6l0,0l-0.4-0.5l0.4,0.6l-0.3,0.6l17,9.8l-24.2,14l-63.5-36.7
                L736.5,664.7z M788.5,670.4L788.5,670.4L788.5,670.4L788.5,670.4z M775.8,716.1l0.2,0.3L775.8,716.1L775.8,716.1z M705.5,780
                l-6.9-4v-15.5l12.9-7.5l20.4,11.8L705.5,780z M697.9,749l0.3-0.6l-13.1-7.6l25.7-14.9v25.8l-12.3,7.1V749H697.9z M733.2,764.7
                l0.3-0.6l-21.4-12.3V726l23.2,13.4v29.1l-1.5-0.8v-2.9H733.2z M937.9,701.2l40.4,23.3l-12.3,7.1l0.3,0.6l-0.3,0.6l13.3,7.7
                l12.3,7.1l-36.8,21.3l-25.2,14.6L894,762.8l0,0l0,0l-0.3,0.6l-0.3-0.6l0,0l0.3,0.6l0,0l-0.3-0.6l-42,24.2l-27.4-15.8l-0.3,0.6
                l-0.3-0.6l-15.1,8.7l-3.3-1.9l14.4-8.3L937.9,701.2z M967.7,732.2l11.3-6.5v13L967.7,732.2z M1172.8,843.9l-14.2-8.2v-25.8
                l14.2,8.2V843.9z M1157.3,835.7l-12.6,7.3v-8.2h-0.7l0.3-0.6l-14.5-8.4l27.4-15.8V835.7z M941.8,886.4l-9,5.2v-25.8l9-5.2V886.4z
                  M943.1,860.6l25.7,14.9l-9.3,5.4l0.3,0.6h-0.7v14.3l-16.1-9.3V860.6z M961.2,881.4l9.3-5.4l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6
                l-0.3-0.6l-10,5.8l-3.3-1.9l9.3-5.4l38-21.9l-0.3-0.6l0.3-0.6l-19.6-11.3l19.5-11.3l35.2,20.3l68.1,39.3l-67.8,39.1L961.2,881.4z
                  M926.8,862.7V877l-16.1-9.3v-25.8l25.7,14.9l-9.3,5.4l0.3,0.6H926.8z M824.2,798.7v-25.8l25.7,14.9l-22.3,12.9L824.2,798.7z
                  M808.8,781.1l14.1-8.1v25.8l-14.1,8.1V781.1z M1022.4,785.4l13.8,8l-19.3,11.2l0.3,0.6l-0.3,0.6l18.9,10.9l-24.2,14l-35.2-20.3
                l-45.4-26.2l24.2-14l46.5,26.9l0.3-0.6l0.3,0.6L1022.4,785.4z M900.4,847.1l9-5.2v25.8l-9,5.2V847.1z M802.8,778v8.4l-11-6.3
                v-25.8l25.7,14.9l-14.4,8.3l0.3,0.6H802.8z M776.4,762.4l14.1-8.1V780l-14.1,8.1V762.4z M828,802l23.7-13.7l42-24.2l35.6,20.6
                l0,0l0,0l45.4,26.2l-19.8,11.4l0.3,0.6l-0.3,0.6l19.6,11.3L937.8,856l-27.4-15.8l-0.3,0.6h0l-0.3-0.6l-10,5.8l-73.9-42.7L828,802
                z M919.2,895.6l7.7-4.4v1.7l-7.7,4.4V895.6z M960.6,908.7v-11.9v-14.3l50.3,29v29.1l-50.3-29V908.7z M1011.5,911.2L1011.5,911.2
                L1011.5,911.2L1011.5,911.2z M1012.1,911.6l67.8-39.1v29.1l-67.8,39.1V911.6z M1081.3,871.7l62.1-35.8v8.2v0V862v2.9l-62.1,35.8
                V871.7z M1144.7,863.2l6.2,3.6v1.7l-6.2-3.6V863.2z M1151.6,865.6l-6.9-4v-17.2l13.2-7.6l15.2,8.8l2.8,1.6l3.7,2.2L1151.6,865.6z
                  M1173.5,816.9l-15.2-8.8l-0.3,0.6h0l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.5,8.4l-62.1,35.8l-67.8-39.1l24.6-14.2l-0.3-0.6
                l0.3-0.6l-18.9-10.9l19.3-11.2l-0.3-0.6l0.3-0.6l-15.1-8.7l-0.3,0.6l-0.3-0.6l-20,11.6l-45.5-26.3l36.5-21.1l16.2,9.4l0.3-0.6
                l0.3,0.6l33.7-19.5l133.1,76.8L1173.5,816.9z M818.9,768.3l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7L737.3,739l38.8-22.4l-0.3-0.6
                l0.3,0.6l0,0l25.5-14.7l-0.3-0.6l0,0l0.3,0.6l-0.3-0.6l0,0l0,0l0,0l0.3-0.6l-17-9.8l19.3-11.2l-0.3-0.6l0,0l0.3,0.4l0.1,0.2
                l48.4-27.9l84.1,48.6L818.9,768.3z M710.6,678l0.3,0.6l0,0L710.6,678L710.6,678l0.3,0.6h-0.7v0l0.6,0l-0.3,0.6l0,0l0,0l0,0l0,0
                l63.8,36.8L736,738.3l-24.2-14l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l13.1,7.6l-3.3,1.9l-15.1-8.7l-122.3-70.6l40.4-23.3
                l14.8,8.6l0.3-0.6l0.3,0.6l55.6-32.1l67.8,39.1L710.6,678z M488.2,710.7l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4
                l0.3,0.6l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-37.2,21.5l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17l0.3,0.6
                l-0.3,0.6l14.4,8.3l-3.3,1.9l-137.4-79.3L488.2,710.7z M553.6,832.3v29.1l-137.4-79.3V753L553.6,832.3z M595.2,846.9l-28.1,16.2
                v-1.7l28.1-16.2V846.9z M566.5,860.2l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l0.3-0.6l-0.3,0.6l3.7,2.2L566.5,860.2z M591.2,840.5
                l-2.2-1.3l0-26.6l37.2-21.5v29.1L591.2,840.5z M673.7,799.8l26.4-15.2v1.7l-26.4,15.2V799.8z M673.1,798.7l-6.9-4v-17l11.6-6.7
                l15.1,8.7l2.8,1.6l3.7,2.1L673.1,798.7z M696.1,779.9l-2.2-1.3v-26.6l3.3-1.9v9.9v16.3v2.9L696.1,779.9z M698.5,779.3v-1.7
                l6.2,3.6v1.7L698.5,779.3z M706.1,781.1l26.4-15.2v1.7l-26.4,15.2V781.1z M736.7,740.2l38.4,22.2v26.6l-2.2,1.3l-36.3-20.9V740.2
                z M773.2,791.5l2.8-1.6l15.1-8.7l11.6,6.7v17l-6.9,4l-26.4-15.2L773.2,791.5z M795.2,810.1v1.7l-26.4-15.2v-1.7L795.2,810.1z
                  M796.6,810.1l6.2-3.6v1.7l-6.2,3.6V810.1z M804.1,805.3v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V805.3z M805.6,810.2l2.8-1.6
                l15.1-8.7l2.7,1.6l-2.1,1.2l0.3,0.6h-0.7v21.8l-22-12.7L805.6,810.2z M823.9,826.6v1.7l-22.6-13.1v-1.7L823.9,826.6z
                  M825.2,826.2v-21.8l73.9,42.7v26.6l-2.2,1.3l-71.8-41.4v-4V826.2z M897.3,876.2L897.3,876.2l2.8-1.6l-0.3-0.6l0.3,0.6l10-5.8
                l16.8,9.7v11.1l-8.3,4.8l-26.4-15.2L897.3,876.2z M917.9,895.6v1.7l-26.4-15.2v-1.7L917.9,895.6z M928.2,890v-11.9v-14.3l3.3,1.9
                v26.6l-2.2,1.3l-1.1-0.7V890z M929.6,894.9l2.8-1.6l10-5.8l16.8,9.7v11.1l-8.3,4.8l-26.4-15.2L929.6,894.9z M923.8,900.8v-1.7
                l26.4,15.2v1.7L923.8,900.8z M951.6,914.3l7.7-4.4v1.7l-7.7,4.4V914.3z M1180.4,852.3l-28.1,16.2v-1.7l28.1-16.2V852.3z
                  M736,769.6L736,769.6L736,769.6L736,769.6L736,769.6z"/>
            </g>
          </g>
          <g id="B3" className={'isometry__floor ' + (floor === "3" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.07.0304" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path122_9_" className="st0" d="M1022.4,755.6l-20.3,11.7l-46.9-27.1l37.8-21.8l0,0l16.6,9.6l34.1-19.7l134.4,77.6l-4.6,2.7
                l-15.5-9l-29.4,17l15.5,9l-63.4,36.6l-69.1-39.9l25.6-14.8l-19.9-11.5l20.3-11.8L1022.4,755.6z"/>
              <path id="path154_9_" className="st0" d="M1080.6,872.8v-30.6l63.4-36.6v30.6L1080.6,872.8z"/>
              <path id="path156_9_" className="st0" d="M1178.1,785.9v30.6l-1.8,1l-2.8-1.6v-27.3L1178.1,785.9z"/>
              <path id="path158_9_" className="st0" d="M1144,836.2v-3.3l7.6,4.4v3.3L1144,836.2z"/>
              <path id="path160_9_" className="st0" d="M1151.6,840.6v-3.3l29.4-17v3.3L1151.6,840.6z"/>
              <path id="path162_9_" className="st0" d="M1151.6,837.3l-7.6-4.4V815l13.9-8l15.5,9l2.8,1.6l4.7,2.7L1151.6,837.3z"/>
              <path id="path164_9_" className="st0" d="M1157.9,807l-13.9,8v-9.4l-15.5-9l29.4-17V807z"/>
              <path id="path166_9_" className="st0" d="M1173.5,815.9l-15.5-9v-27.3l15.5,9V815.9z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0303" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path102_9_" className="st0" d="M818.9,740l119-68.7l41.8,24.1l-13.3,7.7l13.3,7.7l13.3,7.7l-37.8,21.8L929.6,755l-36-20.8
                l0,0l-42.3,24.4l-27.7-16l-15.4,8.9l-4.6-2.7L818.9,740z"/>
              <path id="path110_9_" className="st0" d="M966.4,703.1l13.3-7.7v15.3L966.4,703.1z"/>
              <path id="path112_9_" className="st0" d="M824.5,800.4l-24-13.8v-3.3l24,13.8V800.4z"/>
              <path id="path114_9_" className="st0" d="M824.5,797.2l-24-13.8l4.7-2.7l2.8-1.6l15.4-8.9l4.1,2.3l-3.1,1.8L824.5,797.2z"/>
              <path id="path116_9_" className="st0" d="M827.6,772.4l-4.1-2.3v-27.3l27.7,16L827.6,772.4z"/>
              <path id="path118_9_" className="st0" d="M823.6,742.7V770l-15.4,8.9v-27.3L823.6,742.7z"/>
              <path id="path120_9_" className="st0" d="M808.1,751.6v27.3l-2.8,1.6l-1.8-1v-3.3v-17.8v-9.5L808.1,751.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0302" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path104_9_" className="st0" d="M851.3,758.7l42.3-24.4l36,20.8l0,0l46.4,26.8l-20.8,12l20.6,11.9l-38,21.9l-27.7-16
                l-10.3,6l-75.3-43.4l3.1-1.8L851.3,758.7z"/>
              <path id="path124_9_" className="st0" d="M910.1,811.7l27.7,16l-10.3,6v15.4L910.1,839V811.7z"/>
              <path id="path128_9_" className="st0" d="M824.5,774.2l75.3,43.4V845l-2.8,1.6l-72.4-41.8v-4.3v-3.3V774.2z"/>
              <path id="path130_12_" className="st0" d="M899.8,817.6l10.3-6V839l-10.3,6V817.6z"/>
              <path id="path132_9_" className="st0" d="M890.8,853.4v-3.3l27.7,16v3.3L890.8,853.4z"/>
              <path id="path134_9_" className="st0" d="M918.5,869.4v-3.3l9-5.2v3.3L918.5,869.4z"/>
              <path id="path136_9_" className="st0" d="M918.5,866.2l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1V861L918.5,866.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0301" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path126_9_" className="st0" d="M937.8,827.7l38-21.9l-20.6-11.9l20.8-12l35.5,20.5l69.1,39.9l-69.1,39.9l-51.6-29.8l10.3-6
                l-27.7-16l-10.3,6l-4.6-2.7L937.8,827.7z"/>
              <path id="path138_9_" className="st0" d="M959.9,882.9l-9,5.2v-3.3l9-5.2V882.9z"/>
              <path id="path140_9_" className="st0" d="M959.9,882.9v-30.6l51.6,29.8v30.6L959.9,882.9z"/>
              <path id="path142_9_" className="st0" d="M959.9,879.7l-9,5.2l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1V879.7z"/>
              <path id="path144_9_" className="st0" d="M950.9,884.9v3.3l-27.7-16v-3.3L950.9,884.9z"/>
              <path id="path146_9_" className="st0" d="M1011.5,912.7v-30.6l69.1-39.9v30.6L1011.5,912.7z"/>
              <path id="path148_9_" className="st0" d="M927.5,833.6l4.6,2.7v27.3l-2.8,1.6l-1.8-1V861v-11.9V833.6z"/>
              <path id="path150_9_" className="st0" d="M932.2,836.3l10.3-6v27.3l-10.3,6V836.3z"/>
              <path id="path152_9_" className="st0" d="M942.5,830.4l27.7,16l-10.3,6v15.4l-17.4-10.1L942.5,830.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0302" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path62_9_" className="st0" d="M736,710l39.8-23l0,0l25.5-14.7l0,0l-18-10.4l20.3-11.7l0,0l48.7-28.1l0,0l85.5,49.3
                l-119,68.7l-27.7-16l-15.4,8.9L736,710L736,710z"/>
              <path id="path90_9_" className="st0" d="M736,710l39.8,23v27.3l-2.8,1.6L736,740.6v0V710z"/>
              <path id="path92_9_" className="st0" d="M768.2,767.9v-3.3l27.7,16v3.3L768.2,767.9z"/>
              <path id="path94_9_" className="st0" d="M795.9,780.6l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L795.9,780.6z"/>
              <path id="path96_9_" className="st0" d="M803.5,758.5l-12.3-7.1V724l27.7,16l-15.4,8.9V758.5z"/>
              <path id="path98_9_" className="st0" d="M775.7,760.3v-27.3l15.4-8.9v27.3L775.7,760.3z"/>
              <path id="path100_9_" className="st0" d="M795.9,780.6l7.6-4.4v3.3l-7.6,4.4V780.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0301" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path68_9_" className="st0" d="M736.5,634.8l-69.1-39.9h0l69.1-39.9l50.9,29.4l-10.2,5.9l10.2,5.9l18.2,10.5l10.2-5.9
                l36.7,21.2l0,0l-48.7,28.1l-15.1-8.7l0,0l-20.3,11.7L736.5,634.8L736.5,634.8z"/>
              <path id="path78_9_" className="st0" d="M787.4,584.4v11.8l-10.2-5.9L787.4,584.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0303" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path42_9_" className="st0" d="M677.8,713.7l-123.3-71.2l41.8-24.1l15.2,8.8l55.9-32.3l0,0l69.1,39.9l-25.5,14.7l0,0l0,0
                l64.8,37.4L736,710l-24.5-14.2l-27.7,16l14.1,8.2l-4.6,2.7L677.8,713.7z"/>
              <path id="path58_9_" className="st0" d="M733.2,738.9l-27.7,16v-3.3l27.7-16L733.2,738.9z"/>
              <path id="path60_9_" className="st0" d="M736,740.6l-2.8-1.6v-3.3l-21.7-12.5v-27.3L736,710l0,0V740.6z"/>
              <path id="path66_9_" className="st0" d="M705.5,751.7v3.3l-7.6-4.4v-3.3L705.5,751.7z"/>
              <path id="path70_9_" className="st0" d="M711.5,723.1l21.7,12.5l-27.7,16l-7.6-4.4V731L711.5,723.1z"/>
              <path id="path72_9_" className="st0" d="M711.5,723.1l-13.6,7.9v-11l-14.1-8.2l27.7-16V723.1z"/>
              <path id="path74_9_" className="st0" d="M693.2,722.7l4.6-2.7v30.6l-1.8,1l-2.8-1.6V722.7z"/>
              <path id="path22_9_" className="st0" d="M677.8,741.1v-27.3l15.5,8.9V750L677.8,741.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0301" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_9_" className="st0" d="M677.8,713.7v27.3l-12.3,7.1v-9.5l-15.4-8.9L677.8,713.7z"/>
              <path id="path26_9_" className="st0" d="M677.8,741.1l15.5,8.9l2.8,1.6l4.7,2.7l-27.7,16l-7.6-4.4v-17.8L677.8,741.1z"/>
              <path id="path28_9_" className="st0" d="M673.1,770.4l27.7-16v3.3l-27.7,16V770.4z"/>
              <path id="path30_9_" className="st0" d="M673.1,770.4v3.3l-7.6-4.4V766L673.1,770.4z"/>
              <path id="path32_9_" className="st0" d="M488.2,680.9l66.3-38.3l123.3,71.2l-27.7,16l15.4,8.9l-38.6,22.3l-46.4-26.8l32.4-18.7
                l-25.6-14.8l-22,12.7L545,701.6l-10.4,6L488.2,680.9z"/>
              <path id="path36_9_" className="st0" d="M626.9,760.9l38.6-22.3v30.6l-38.6,22.3V760.9z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0302" floor="3" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path34_9_" className="st0" d="M580.5,734.2l46.4,26.8l-38.6,22.3l-15.4-8.9l-29.4,17l15.4,8.9l-4.6,2.7l-138.7-80.1
                l72.6-41.9l46.4,26.8l-4.8,2.8l20.4,11.8l-27.6,15.9l25.6,14.8L580.5,734.2z"/>
              <path id="path40_9_" className="st0" d="M626.9,760.9v30.6l-35.7,20.6l-2.8-1.6l0-27.3L626.9,760.9z"/>
              <path id="path44_9_" className="st0" d="M595.9,814.9v3.3l-29.4,17v-3.3L595.9,814.9z"/>
              <path id="path46_9_" className="st0" d="M595.9,814.9l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L595.9,814.9z"/>
              <path id="path48_9_" className="st0" d="M572.9,801.6l-14,8.1v-9.5l-15.4-8.9l29.4-17V801.6z"/>
              <path id="path50_9_" className="st0" d="M415.5,722.8l138.7,80.1v30.6l-138.7-80.1V722.8z"/>
              <path id="path52_9_" className="st0" d="M554.3,833.5v-30.6l4.6-2.7v30.6L554.3,833.5z"/>
              <path id="path54_9_" className="st0" d="M588.3,783.2l0,27.3l-15.4-8.9v-27.3L588.3,783.2z"/>
              <path id="path56_9_" className="st0" d="M558.9,827.5l7.6,4.4v3.3l-7.6-4.4V827.5z"/>
            </ModIsometrySvgFlat>
            <g id="B03-Areas" className="isometry__areas">
              <path id="path38_9_" className="st0" d="M612.9,715.4l-32.4,18.7l-32.4,18.7l-25.6-14.8l27.6-15.9l-20.4-11.8l4.8-2.8l10.4-6
                l20.4,11.8l22-12.7L612.9,715.4z"/>
              <path id="path82_9_" className="st0" d="M788.5,641.4l15.1,8.7l-20.3,11.7l0,0l18,10.4L775.8,687l-64.8-37.4l25.5-14.7l31.7,18.3l0,0
                L788.5,641.4z"/>
              <path id="path108_9_" className="st0" d="M929.6,755l25.6-14.8l46.9,27.1l20.3-11.7l15.1,8.7l-20.3,11.8l19.9,11.5l-25.6,14.8
                L976,781.8L929.6,755z"/>
            </g>
            <g id="B03-Strokes" className="isometry__strokes">
              <path d="M1181.7,820.3h-0.7l0.3-0.6l-3.7-2.2l0.8-0.5l-0.3-0.6h0.7v-30.6h-0.7l0.3-0.6L1044,707.7l-0.3,0.6l-0.3-0.6l-33.7,19.5
                l-16.2-9.4l-0.3,0.6l0,0l0.3-0.6l-13-7.5v-14.9h-0.7l0,0l0.3-0.6l-41.8-24.1l-85.5-49.3l-0.3,0.6h0l0,0l0.3-0.6l-36.7-21.2
                l-0.3,0.6l-0.3-0.6l-9.9,5.7L788,595.8v-11.4h-0.7l0.3-0.6l-50.9-29.4l-0.3,0.6l-0.3-0.6L667,594.3l0.3,0.6l0,0l-0.3-0.6
                l-55.6,32.1l-14.8-8.6l-0.3,0.6l-0.3-0.6L554.2,642l-66.3,38.3l-72.6,41.9l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6
                l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20l38.3-22.1l7.2,4.2l0.3-0.6
                l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4l0.3-0.6h0
                l-0.3,0.6l35.9,20.7l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.2l0.3,0.6h-0.7v3.3
                h0.7l-0.3,0.6l23.6,13.6v4h0.7l-0.3,0.6l71.4,41.2l-5.2,3l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l0.5,0.3
                l-5.2,3l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l51.3,29.6l0.3-0.6h0l0.3,0.6l69.1-39.9L1144,837l7.2,4.2
                l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7V820.3z M1180.4,823.2l-28.1,16.2v-1.7l28.1-16.2V823.2z M786.7,595l-8.2-4.7l8.2-4.7V595z
                  M776.8,589.7l0.3,0.6l-0.3,0.6l10.2,5.9l18.2,10.5l0.3-0.6l0.3,0.6l9.9-5.7l35.3,20.4l-47.4,27.4l-14.8-8.6l0,0l0,0l-0.3,0.6
                l-0.3-0.6l0.3,0.6l-0.3-0.6l-20,11.5l-31.4-18.1l0,0l0,0l-68.1-39.3l67.8-39.1l49.5,28.6L776.8,589.7z M1009.9,728.6l33.7-19.5
                l133.1,76.8l-3.3,1.9l-15.2-8.8l-0.3,0.6h0l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.5,8.4l-62.1,35.8l-67.8-39.1l24.6-14.2
                l-0.3-0.6l0.3-0.6l-18.9-10.9l19.3-11.2l-0.3-0.6l0.3-0.6l-15.1-8.7l-0.3,0.6l-0.3-0.6l-20,11.6l-45.5-26.3l36.5-21.1l16.2,9.4
                l0.3-0.6L1009.9,728.6z M1177.4,816.1l-1.1,0.7l-2.2-1.3V789l3.3-1.9V816.1z M685.1,711.8l25.7-14.9v25.8l-12.3,7.1V720h-0.7
                l0.3-0.6L685.1,711.8z M698.5,746.9v-15.5l12.9-7.5l20.4,11.8l-26.4,15.2L698.5,746.9z M698.5,748.5l6.2,3.6v1.7l-6.2-3.6V748.5z
                  M706.1,752.1l26.4-15.2v1.7l-26.4,15.2V752.1z M733.2,735.7l0.3-0.6l-21.4-12.3V697l23.2,13.4v29.1l-1.5-0.8v-2.9H733.2z
                  M775.9,687.3l-0.2-0.3l0,0l0,0v0v0L775.9,687.3z M736.5,635.6l31.4,18.1l0,0l0,0l0.3-0.6l0.3,0.6l-0.3-0.6l0.3,0.6l20-11.5
                l13.8,8L783,661.3l0.3,0.6l0,0l-0.4-0.6l0.4,0.6l-0.3,0.6l17,9.8l-24.2,14l-63.5-36.7L736.5,635.6z M776.1,687.6l-0.3-0.6l0,0
                L776.1,687.6L776.1,687.6l25.6-14.8l-0.3-0.6l0,0l0.4,0.6l-0.3-0.6l0,0l0,0l0,0l0.3-0.6l-17-9.8l19.3-11.2l-0.3-0.6l0,0l0.3,0.4
                l0.1,0.2l48.4-27.9l84.1,48.6l-117.7,67.9l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7L737.3,710L776.1,687.6z M852.4,622L852.4,622
                L852.4,622L852.4,622L852.4,622L852.4,622z M966.1,702.5l0.3,0.6l-0.3,0.6l13.3,7.7l12.3,7.1l-36.8,21.3l-25.2,14.6L894,733.7
                l0,0l0,0l-0.3,0.6l-0.3-0.6l0,0l-42,24.2l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l14.4-8.3l118.7-68.5l40.4,23.3
                L966.1,702.5z M1036.2,764.3l-19.3,11.2l0.3,0.6l-0.3,0.6l18.9,10.9l-24.2,14l-35.2-20.3L930.9,755l24.2-14l46.5,26.9l0.3-0.6
                l0.3,0.6l20-11.6L1036.2,764.3z M1172.8,814.8l-14.2-8.2v-25.8l14.2,8.2V814.8z M1157.3,806.6l-12.6,7.3v-8.2h-0.7l0.3-0.6
                l-14.5-8.4l27.4-15.8V806.6z M941.8,857.3l-9,5.2v-25.8l9-5.2V857.3z M943.1,831.5l25.7,14.9l-9.3,5.4l0.3,0.6h-0.7v14.3
                l-16.1-9.3V831.5z M961.2,852.3l9.3-5.4l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6l-0.3-0.6l-10,5.8l-3.3-1.9l9.3-5.4l38-21.9l-0.3-0.6
                l0.3-0.6l-19.6-11.3l19.5-11.3l35.2,20.3l68.1,39.3l-67.8,39.1L961.2,852.3z M893.4,734l0.2,0.3l0,0l0,0v0v0L893.4,734z
                  M808.8,752l14.1-8.1v25.8l-14.1,8.1V752z M824.2,743.9l25.7,14.9l-22.3,12.9l-3.4-2V743.9z M828,773l23.7-13.7l42-24.2
                l35.6,20.6l0,0l0,0l45.4,26.2l-19.8,11.4l0.3,0.6l-0.3,0.6l19.6,11.3l-36.6,21.1l-27.4-15.8l-0.3,0.6h0l-0.3-0.6l-10,5.8
                l-73.9-42.7L828,773z M910.8,812.8l25.7,14.9l-9.3,5.4l0.3,0.6h-0.7v14.3l-16.1-9.3V812.8z M802.8,748.9v8.4l-11-6.3v-25.8
                l25.7,14.9l-14.4,8.3l0.3,0.6H802.8z M790.5,751l-14.1,8.1v-25.8l14.1-8.1V751z M900.4,818l9-5.2v25.8l-9,5.2V818z M919.2,866.5
                l7.7-4.4v1.7l-7.7,4.4V866.5z M942.5,857.7L942.5,857.7L942.5,857.7L942.5,857.7z M951.6,885.2l7.7-4.4v1.7l-7.7,4.4V885.2z
                  M960.6,879.7v-11.9v-14.3l50.3,29v29.1l-50.3-29V879.7z M1011.5,882.1L1011.5,882.1L1011.5,882.1L1011.5,882.1z M1012.1,882.5
                l67.8-39.1v29.1l-67.8,39.1V882.5z M1081.3,842.6l62.1-35.8v8.2v17.9v2.9l-62.1,35.8V842.6z M1151.6,836.5l-6.9-4v-17.2l13.2-7.7
                l15.2,8.8l2.8,1.6l3.7,2.2L1151.6,836.5z M979,709.6l-11.3-6.5l11.3-6.5V709.6z M566.5,831.9L566.5,831.9L566.5,831.9
                L566.5,831.9z M558.9,800.2l0.3-0.6l-14.4-8.3l27.4-15.8v25.8l-12.7,7.3v-8.4H558.9z M573.6,775.4l14.1,8.1l0,25.8l-14.1-8.1
                V775.4z M554.9,803.3l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V803.3z M559.6,828.7l6.2,3.6v1.7l-6.2-3.6V828.7z M554.5,643.3l121.9,70.4
                l-26.7,15.4l0.3,0.6l-0.3,0.6l14.4,8.3l-37.3,21.5l-45.1-26l31.4-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5
                l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8l-45.1-26L554.5,643.3z M692.6,748.8l-14.1-8.2v-25.8l14.1,8.2V748.8z M665.5,738.7
                l0.3-0.6l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4H665.5z M548.1,752.1l-24.2-14l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2l3.8-2.2
                l10.1-5.8l20,11.6l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14l-31.4,18.2L548.1,752.1z M627.6,761.3l37.3-21.5v8.4V766v2.9l-37.3,21.5
                V761.3z M666.2,767.1l6.2,3.6v1.7l-6.2-3.6V767.1z M488.2,681.6l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6
                l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-37.2,21.5l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6
                l14.4,8.3l-3.3,1.9l-137.4-79.3L488.2,681.6z M553.6,803.3v29.1L416.2,753v-29.1L553.6,803.3z M595.2,817.8L567.1,834v-1.7
                l28.1-16.2V817.8z M566.5,831.1l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l3.7,2.2L566.5,831.1z M591.2,811.4l-2.2-1.3l0-26.6
                l37.2-21.5v29.1L591.2,811.4z M700.1,757.2l-26.4,15.2v-1.7l26.4-15.2V757.2z M673.1,769.6l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6
                l3.7,2.1L673.1,769.6z M696.1,750.8l-2.2-1.3V723l3.3-1.9v9.9v16.3v2.9L696.1,750.8z M736.7,711.1l38.4,22.2v26.6l-2.2,1.3
                l-36.3-20.9V711.1z M773.2,762.5l2.8-1.6l15.1-8.7l11.6,6.7v17l-6.9,4l-26.4-15.2L773.2,762.5z M795.2,781v1.7l-26.4-15.2v-1.7
                L795.2,781z M796.6,781l6.2-3.6v1.7l-6.2,3.6V781z M804.1,776.3v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V776.3z M805.3,780.6
                l0.3,0.6l2.8-1.6l15.1-8.7l2.7,1.6l-2.1,1.2l0.3,0.6h-0.7V796l-22-12.7l3.7-2.2L805.3,780.6z M823.9,797.5v1.7l-22.6-13.1v-1.7
                L823.9,797.5z M825.2,797.2v-21.8l73.9,42.7v26.6l-2.2,1.3l-71.8-41.4v-4V797.2z M897.3,847.2L897.3,847.2l2.8-1.6l-0.3-0.6
                l0.3,0.6l10-5.8l16.8,9.7v11.1l-8.3,4.8l-26.4-15.2L897.3,847.2z M917.9,866.5v1.7L891.5,853v-1.7L917.9,866.5z M928.2,861v-11.9
                v-14.3l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V861z M929.6,865.9l2.8-1.6l10-5.8l16.8,9.7v11.1l-8.3,4.8l-26.4-15.2L929.6,865.9z
                  M950.2,885.2v1.7l-26.4-15.2V870L950.2,885.2z M1144.7,835.8v-1.7l6.2,3.6v1.7L1144.7,835.8z M611.8,627.8l55.6-32.1l67.8,39.1
                L710.6,649l0.3,0.6v0l-0.3-0.6l0,0l0.3,0.6h-0.7v0l0.6,0l-0.3,0.6l0,0l0.3-0.5l-0.3,0.5l0,0l0,0l63.8,36.8L736,709.2l-24.2-14
                l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l13.1,7.6l-3.3,1.9l-15.1-8.7l-122.3-70.6l40.4-23.3l14.8,8.6l0.3-0.6L611.8,627.8z
                  M736,740.6L736,740.6L736,740.6L736,740.6L736,740.6z"/>
            </g>
          </g>
          <g id="B4" className={'isometry__floor ' + (floor === "4" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.07.0404" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path94_10_" className="st0" d="M1022.4,724.9l-20.3,11.8l-46.9-27.1l37.9-21.9l16.6,9.6l34.1-19.7l134.4,77.6l-97.5,56.3
                l-69.1-39.9l25.6-14.8l-19.9-11.5l20.3-11.8L1022.4,724.9z"/>
              <path id="path108_10_" className="st0" d="M1178.1,755.2v30.6l-1.8,1l-2.8-1.6v-24l-15.5,9l-13.9,8v6.1v17.9v3.3l-63.4,36.6v-30.6
                L1178.1,755.2z"/>
              <path id="path110_10_" className="st0" d="M1144,805.5v-3.3l7.6,4.4v3.3L1144,805.5z"/>
              <path id="path112_10_" className="st0" d="M1151.6,809.9v-3.3l29.4-17v3.3L1151.6,809.9z"/>
              <path id="path114_10_" className="st0" d="M1151.6,806.6l-7.6-4.4v-17.9l13.9-8l15.5,9l2.8,1.6l4.7,2.7L1151.6,806.6z"/>
              <path id="path116_10_" className="st0" d="M1157.9,776.2l-13.9,8v-6.1l13.9-8V776.2z"/>
              <path id="path120_10_" className="st0" d="M1173.5,785.2l-15.5-9v-6.1l15.5-9V785.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0403" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path84_10_" className="st0" d="M803.5,718.2l134.4-77.6l41.7,24.1l-13.3,7.7l13.3,7.7l13.4,7.7l-37.9,21.9l-25.6,14.8
                l-36-20.8l-57.7,33.3L803.5,718.2z"/>
              <path id="path88_10_" className="st0" d="M803.5,721.5v-3.3l32.4,18.7l-2.8,1.6l-9.5-5.5l-15.4-8.9v24l-2.8,1.6l-1.8-1v-3.3v-17.8
                V721.5z"/>
              <path id="path98_10_" className="st0" d="M966.4,672.3l13.3-7.7V680L966.4,672.3z"/>
              <path id="path100_10_" className="st0" d="M824.5,769.7l-23.9-13.8v-3.3l23.9,13.8V769.7z"/>
              <path id="path102_10_" className="st0" d="M824.5,766.4l-23.9-13.8l4.7-2.7l2.8-1.6l15.4-8.9l4.1,2.3l-3.1,1.8V766.4z"/>
              <path id="path104_10_" className="st0" d="M827.6,741.7l-4.1-2.3v-6.2l9.5,5.5L827.6,741.7z"/>
              <path id="path106_10_" className="st0" d="M823.6,733.1v6.2l-15.4,8.9v-24L823.6,733.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0402" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path96_10_" className="st0" d="M835.9,736.9l57.7-33.3l36,20.8l46.4,26.8l-20.8,12l20.6,11.9l-48.3,27.9l-103-59.5l3.1-1.8
                l5.4-3.1L835.9,736.9z"/>
              <path id="path124_10_" className="st0" d="M824.5,743.5l103,59.5v3.3l-17.4-10.1l-10.3-6v24l-2.8,1.6l-72.4-41.8v-4.4v-3.3
                L824.5,743.5z"/>
              <path id="path126_10_" className="st0" d="M890.8,822.7v-3.3l27.7,16v3.3L890.8,822.7z"/>
              <path id="path128_10_" className="st0" d="M918.5,838.7v-3.3l9-5.2v3.3L918.5,838.7z"/>
              <path id="path132_10_" className="st0" d="M918.5,835.4l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1v11.9L918.5,835.4z"/>
              <path id="path134_10_" className="st0" d="M910.1,808.3l-10.3,6v-24l10.3,6V808.3z"/>
              <path id="path136_10_" className="st0" d="M927.5,818.3l-17.4-10.1v-12.1l17.4,10.1V818.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.07.0401" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path118_10_" className="st0" d="M1080.6,811.5v30.6l-69.1,39.9v-30.6L1080.6,811.5z"/>
              <path id="path122_10_" className="st0" d="M927.5,802.9l48.3-27.9l-20.6-11.9l20.8-12l35.5,20.5l69.1,39.9l-69.1,39.9L927.5,802.9z"
                />
              <path id="path130_13_" className="st0" d="M927.5,833.5v-30.6l84,48.5V882l-51.6-29.8v-3.3V837v-12.1l-17.4-10.1l-10.3-6v24l-2.8,1.6
                L927.5,833.5z"/>
              <path id="path138_10_" className="st0" d="M959.9,848.9v3.3l-9,5.2v-3.3L959.9,848.9z"/>
              <path id="path140_10_" className="st0" d="M959.9,848.9l-9,5.2l-27.7-16l6.2-3.6l2.8-1.6l10.3-6l17.4,10.1V848.9z"/>
              <path id="path142_10_" className="st0" d="M950.9,854.1v3.3l-27.7-16v-3.3L950.9,854.1z"/>
              <path id="path144_10_" className="st0" d="M942.5,827l-10.3,6v-24l10.3,6V827z"/>
              <path id="path146_10_" className="st0" d="M959.9,837L942.5,827v-12.1l17.4,10.1V837z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0403" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path54_10_" className="st0" d="M693.2,691.9l-138.7-80.1l41.8-24.1l15.2,8.8l55.9-32.3h0l69.1,39.9l-25.6,14.8l64.8,37.4
                l-39.8,23h0l-10.4-6L693.2,691.9z"/>
              <path id="path56_10_" className="st0" d="M693.2,695.2v-3.3l32.4-18.7v3.3l-14.1,8.1l-13.6,7.9v7.8v16.3v3.3l-1.8,1l-2.8-1.6
                L693.2,695.2z"/>
              <path id="path58_10_" className="st0" d="M733.2,708.2l-27.7,16v-3.3l27.7-16V708.2z"/>
              <path id="path60_10_" className="st0" d="M736,709.8l-2.8-1.6v-3.3l-21.7-12.5v-7.8l14.1-8.1v-3.3l10.4,6V709.8z"/>
              <path id="path66_10_" className="st0" d="M711.5,692.4l21.7,12.5l-27.7,16l-7.6-4.4v-16.3L711.5,692.4z"/>
              <path id="path68_10_" className="st0" d="M711.5,684.6v7.8l-13.6,7.9v-7.8L711.5,684.6z"/>
              <path id="path70_10_" className="st0" d="M705.4,724.2l-7.6-4.4v-3.3l7.6,4.4V724.2z"/>
              <path id="path22_20_" className="st0" d="M677.8,710.3v-6.2l15.4-8.9v24L677.8,710.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0402" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path72_10_" className="st0" d="M783.3,631.1l20.4-11.8l48.8-28.1l85.5,49.4l-134.4,77.6l-67.5-39l39.8-23l25.6-14.8
                L783.3,631.1z"/>
              <path id="path76_10_" className="st0" d="M736,679.2l67.5,39v3.3l-12.3-7.1l-15.4-8.9v24l-2.8,1.6L736,709.8L736,679.2z"/>
              <path id="path78_10_" className="st0" d="M768.2,737.2v-3.3l27.7,16v3.3L768.2,737.2z"/>
              <path id="path80_10_" className="st0" d="M795.9,749.9l-27.7-16l4.8-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L795.9,749.9z"/>
              <path id="path82_10_" className="st0" d="M803.5,727.7l-12.3-7.1v-6.3l12.3,7.1V727.7z"/>
              <path id="path86_10_" className="st0" d="M803.5,748.8l-7.6,4.4v-3.3l7.6-4.4V748.8z"/>
              <path id="path90_10_" className="st0" d="M775.8,729.5v-24l15.4,8.9v6.3L775.8,729.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0401" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path64_10_" className="st0" d="M736.5,604.1l-69.1-39.9h0l69.1-39.9l50.9,29.4l-10.2,5.9l10.2,5.9l18.2,10.5l10.2-5.9
                l36.7,21.2l-48.8,28.1l-15.1-8.7l-20.4,11.8L736.5,604.1z"/>
              <path id="path74_10_" className="st0" d="M787.4,553.7v11.8l-10.2-5.9L787.4,553.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0402" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path32_10_" className="st0" d="M415.5,692.1l72.6-41.9l46.4,26.8l-4.8,2.8l20.4,11.8l-27.6,15.9l25.6,14.8l32.4-18.7
                l46.4,26.8l-72.6,41.9L415.5,692.1z"/>
              <path id="path36_10_" className="st0" d="M554.3,772.1l72.6-41.9v30.6l-35.7,20.6l-2.8-1.6v-24l-15.4,8.9l-14,8.1v6.2v17.8v3.3
                l-4.6,2.7V772.1z"/>
              <path id="path40_10_" className="st0" d="M595.9,784.2v3.3l-29.4,17v-3.3L595.9,784.2z"/>
              <path id="path42_10_" className="st0" d="M595.9,784.2l-29.4,17l-7.6-4.4V779l14-8.1l15.4,8.9l2.8,1.6L595.9,784.2z"/>
              <path id="path44_10_" className="st0" d="M572.9,770.9l-14,8.1v-6.2l14-8.1V770.9z"/>
              <path id="path46_10_" className="st0" d="M415.5,692.1l138.7,80.1v30.6l-138.7-80.1V692.1z"/>
              <path id="path50_10_" className="st0" d="M588.3,755.8v24l-15.4-8.9v-6.2L588.3,755.8z"/>
              <path id="path52_10_" className="st0" d="M558.9,796.8l7.6,4.4v3.3l-7.6-4.4V796.8z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.05.0401" floor="4" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path22_10_" className="st0" d="M677.8,710.3v-6.2l15.4-8.9v24L677.8,710.3z"/>
              <path id="path24_10_" className="st0" d="M677.8,704.1v6.2l-12.3,7.1v-6.2L677.8,704.1z"/>
              <path id="path26_10_" className="st0" d="M677.8,710.3l15.4,8.9l2.8,1.6l4.7,2.7l-27.7,16l-7.6-4.4v-17.8L677.8,710.3z"/>
              <path id="path28_10_" className="st0" d="M673.1,739.6l27.7-16v3.3l-27.7,16V739.6z"/>
              <path id="path30_10_" className="st0" d="M673.1,739.6v3.3l-7.6-4.4v-3.3L673.1,739.6z"/>
              <path id="path34_10_" className="st0" d="M626.9,730.2l-46.4-26.8l32.4-18.7l-25.6-14.8l-22,12.7L545,670.9l-10.4,6l-46.4-26.8
                l66.3-38.3l138.7,80.1L626.9,730.2z"/>
              <path id="path48_10_" className="st0" d="M626.9,760.8v-30.6l66.3-38.3v3.3l-15.4,8.9l-12.3,7.1v6.2v17.8v3.3L626.9,760.8z"/>
            </ModIsometrySvgFlat>
            <g id="B4-Areas" className="isometry__areas">
              <path id="path38_10_" className="st0" d="M580.5,703.4l-32.4,18.7l-25.6-14.8l27.6-15.9l-20.4-11.8l4.8-2.8l10.4-6l20.4,11.8l22-12.7
                l25.6,14.8L580.5,703.4z"/>
              <path id="path62_10_" className="st0" d="M775.8,656.3l-64.8-37.4l25.6-14.8l31.7,18.3l20.4-11.8l15.1,8.7l-20.4,11.8l18,10.4
                L775.8,656.3z"/>
              <path id="path92_10_" className="st0" d="M929.6,724.3l25.6-14.8l46.9,27.1l20.3-11.8l15.1,8.7l-20.3,11.8l19.9,11.5l-25.6,14.8
                L976,751.1L929.6,724.3z"/>
            </g>
            <g id="B4-Strokes" className="isometry__strokes">
              <path d="M1181.7,789.6h-0.7l0.3-0.6l-3.7-2.2l0.8-0.5l-0.3-0.6h0.7v-30.6h-0.7l0.3-0.6L1044,677l-0.3,0.6l-0.3-0.6l-33.7,19.5
                l-16.2-9.4l-0.3,0.6l0.3-0.6l-13-7.5v-14.9h-0.7l0.3-0.6L938.3,640l-85.5-49.4l-36.7-21.2l-0.3,0.6l-0.3-0.6l-9.9,5.7L788,565.1
                v-11.4h-0.7l0.3-0.6l-50.9-29.4l-0.3,0.6l-0.3-0.6L667,563.6l-55.6,32.1l-14.8-8.6l-0.3,0.6l-0.3-0.6l-41.8,24.1l-66.3,38.3
                l-72.6,41.9l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7
                v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20l38.3-22.1l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2
                l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4l0.3-0.6h0l-0.3,0.6l35.9,20.7l-3.8,2.2l0.3,0.6l0,0h-0.7v3.3h0.7l-0.3,0.6
                l27.7,16l0.3-0.6l0.3,0.6l7.3-4.2l0.5,0.3l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l23.6,13.6v4h0.7l-0.3,0.6l71.4,41.2l-5.2,3
                l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l8.7-5l0.5,0.3l-5.2,3l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6
                l0.3,0.6l8.7-5l51.3,29.6l0.3-0.6h0l0.3,0.6l69.1-39.9l63.1-36.4l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7V789.6z
                  M1180.4,792.5l-28.1,16.2V807l28.1-16.2V792.5z M786.7,554.8v9.5l-8.2-4.7L786.7,554.8z M786,553.7l-9.2,5.3l0.3,0.6l-0.3,0.6
                l10.2,5.9l18.2,10.5l0.3-0.6l0.3,0.6l9.9-5.7l35.3,20.4l-47.4,27.4l-14.8-8.6l-0.3,0.6l-0.3-0.6l-20,11.6l-31.4-18.1l-68.1-39.3
                l67.8-39.1L786,553.7z M991.7,687.7L954.8,709l-25.2,14.6L894,703l-0.3,0.6l-0.3-0.6l-57.4,33.1l-31-17.9l133.1-76.8l40.4,23.3
                l-12.3,7.1l0.3,0.6l-0.3,0.6l13.3,7.7l0.3-0.6l-0.3,0.6L991.7,687.7z M1009.3,697.8l0.3-0.6l0.3,0.6l33.7-19.5l133.1,76.8
                l-96.2,55.5l-67.8-39.1l24.6-14.2l-0.3-0.6l0.3-0.6l-18.9-10.9l19.3-11.2l-0.3-0.6l0.3-0.6l-15.1-8.7l-0.3,0.6l-0.3-0.6l-20,11.6
                l-45.5-26.3l36.5-21.1L1009.3,697.8z M1177.4,785.4l-1.1,0.7l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.5,9l0.3,0.6h0l-0.3-0.6l-13.9,8
                l0.3,0.6h-0.7v6.1v17.9v2.9l-62.1,35.8v-29.1l96.2-55.5V785.4z M1144.7,778.5l12.6-7.3v4.6l-12.6,7.3V778.5z M1172.8,784.1
                l-14.2-8.2v-5.4l14.2-8.2V784.1z M1151.6,805.8l-6.9-4v-17.2l13.2-7.6l15.2,8.8l2.8,1.6l3.7,2.2L1151.6,805.8z M691.9,691.9
                l-65,37.5l-45.1-26l31.4-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8
                l-45.1-26l65-37.5L691.9,691.9z M692.6,718.1l-14.1-8.1v-5.5l14.1-8.1V718.1z M666.2,711.6l11-6.3v4.7l-11,6.3V711.6z
                  M531.1,679.7l3.8-2.2l10.1-5.8l20,11.6l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14l-31.4,18.2l-32.1,18.5l-24.2-14l26.6-15.4l-0.3-0.6
                l0.3-0.6L531.1,679.7z M627.6,730.6l65-37.5v1.7l-15.1,8.7l-12.3,7.1l0.3,0.6h-0.7v6.2v17.8v2.9l-37.3,21.5V730.6z M666.2,736.4
                l6.2,3.6v1.7l-6.2-3.6V736.4z M698.5,716.2v-15.5l13-7.5l20.3,11.7l-26.4,15.2L698.5,716.2z M698.5,692.9l12.3-7.1v6.2l-12.3,7.1
                V692.9z M706.1,721.3l26.4-15.2v1.7l-26.4,15.2V721.3z M733.2,704.9l0.3-0.6L712.2,692v-7l13.8-8l-0.3-0.6h0.7v-2.1l9.1,5.2v29.1
                l-1.5-0.9v-2.9H733.2z M736,679.2L736,679.2L736,679.2L736,679.2z M768.2,622.4l0.3,0.6l20-11.6l13.8,8L783,630.6l0.3,0.6
                l-0.3,0.6l17,9.8l-24.2,14l-63.5-36.7l24.2-14l31.4,18.1L768.2,622.4z M936.6,640.6l-133.1,76.8l-66.2-38.2l38.8-22.4l25.6-14.8
                l-0.3-0.6l0.3-0.6l-17-9.8L804,620l-0.3-0.6l0,0l0.3,0.6l48.4-28L936.6,640.6z M791.8,715.5l11,6.3v4.7l-11-6.3V715.5z
                  M790.5,714.8v5.5l-14.1,8.1v-21.7L790.5,714.8z M796.6,750.3l6.3-3.6v1.7l-6.3,3.6V750.3z M928.8,802.9l47.3-27.3l-0.3-0.6
                l0.3-0.6l-19.6-11.3l19.5-11.3l35.2,20.3l68.1,39.3l-67.8,39.1L928.8,802.9z M955.2,710.3l46.5,26.9l0.3-0.6l0.3,0.6l20-11.6
                l13.8,8l-19.3,11.2l0.3,0.6l-0.3,0.6l18.9,10.9l-24.2,14l-35.2-20.3l-45.4-26.2L955.2,710.3z M929.3,724.9l45.4,26.2l-19.8,11.4
                l0.3,0.6l-0.3,0.6l19.6,11.3l-46.9,27.1l-101.7-58.7l2.1-1.2l-0.3-0.6l0,0l0.3,0.6l5.4-3.1l-0.3-0.6l0.3,0.6l2.8-1.6l57.4-33.1
                L929.3,724.9z M808.8,747.1v-21.7l14.1,8.1v5.5L808.8,747.1z M824.2,734.2l7.5,4.3l-4.1,2.3l-3.4-2V734.2z M910.8,797.3l16.1,9.3
                v10.6l-16.1-9.3V797.3z M909.4,796.5v11.4l-9,5.2v-21.7L909.4,796.5z M919.2,835.8l7.7-4.4v1.7l-7.7,4.4V835.8z M1011.5,851.4
                L1011.5,851.4L1011.5,851.4L1011.5,851.4z M1012.1,851.8l67.8-39.1v29.1l-67.8,39.1V851.8z M979,678.8l-11.3-6.5l11.3-6.5V678.8z
                  M611.8,597.1l55.6-32.1l67.8,39.1l-24.6,14.2l0.3,0.6l-0.3,0.6l63.8,36.9L736,678.5l-10.1-5.8l-0.3,0.6l-0.3-0.6l-32,18.5
                l-137.4-79.3l40.4-23.3l14.8,8.6l0.3-0.6L611.8,597.1z M488.2,650.9l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4
                l0.3,0.6l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-71.3,41.2l-137.4-79.3L488.2,650.9z M553.6,772.5v29.1
                l-137.4-79.3v-29.1L553.6,772.5z M559.6,798l6.2,3.6v1.7l-6.2-3.6V798z M566.5,801.2L566.5,801.2L566.5,801.2L566.5,801.2z
                  M559.6,773.1l12.7-7.3v4.7l-12.7,7.3V773.1z M587.7,778.6l-14.1-8.1V765l14.1-8.1V778.6z M595.2,787.1l-28.1,16.2v-1.7
                l28.1-16.2V787.1z M566.5,800.4l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l0.3-0.6l-0.3,0.6l3.7,2.2L566.5,800.4z M591.2,780.7
                l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.4,8.9l-14,8.1l0.3,0.6h-0.7v6.2v17.8v2.9l-3.3,1.9v-29.1l71.3-41.2v29.1L591.2,780.7z
                  M673.7,740l26.4-15.2v1.7l-26.4,15.2V740z M673.1,738.9l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6l3.7,2.2L673.1,738.9z
                  M696.1,720.1l-2.2-1.3v-23.7v-2.9l31-17.9v1.7l-13.8,8l-13.6,7.9l0.3,0.6h-0.7v7.8v16.3v2.9L696.1,720.1z M698.5,719.5v-1.7
                l6.2,3.6v1.7L698.5,719.5z M736.7,680.4l66.2,38.2v1.7l-11.3-6.5l-15.4-8.9l-0.3,0.6h-0.7v23.7l-2.2,1.3l-36.3-20.9V680.4z
                  M773.3,731.7l2.8-1.6l15.1-8.7l11.7,6.7v17l-6.9,4l-26.4-15.2L773.3,731.7z M795.2,750.3v1.7l-26.4-15.2v-1.7L795.2,750.3z
                  M804.2,745.5v-17.8v-6.3v-2.1l30.4,17.5l-1.5,0.9l-9.1-5.3l-15.4-8.9l-0.3,0.6h-0.7v23.7l-2.2,1.3l-1.1-0.7V745.5z M805.3,749.8
                l0.3,0.6l2.8-1.6l15.1-8.7l2.7,1.6l-2.1,1.2l0.3,0.6h-0.7v21.8l-22-12.7l3.7-2.2L805.3,749.8z M823.9,766.8v1.7l-22.6-13.1v-1.7
                L823.9,766.8z M825.2,766.4v-21.8l101.7,58.7v1.7l-16.4-9.5l-10.3-6l-0.3,0.6h-0.7v23.7l-2.2,1.3l-71.8-41.4v-4V766.4z
                  M897.3,816.4L897.3,816.4l2.8-1.6l-0.3-0.6l0.3,0.6l10-5.8l16.8,9.7v11.1l-8.3,4.8l-26.4-15.2L897.3,816.4z M917.9,835.8v1.7
                l-26.4-15.2v-1.7L917.9,835.8z M928.2,830.2v-11.9v-12.1v-2.1l82.6,47.7v29.1l-50.3-29v-2.9V837v-12.1h-0.7l0.3-0.6l-17.4-10.1
                l-0.3,0.6h0l0.3-0.6l-10.3-6l-0.3,0.6h-0.7v23.7l-2.2,1.3l-1.1-0.7V830.2z M942.5,827L942.5,827L942.5,827L942.5,827z
                  M959.2,825.3v10.6l-16.1-9.3V816L959.2,825.3z M932.8,831.8V810l9,5.2v11.4L932.8,831.8z M929.6,835.1l2.8-1.6l10-5.8l16.8,9.7
                v11.1l-8.3,4.8l-26.4-15.2L929.6,835.1z M923.8,841v-1.7l26.4,15.2v1.7L923.8,841z M951.6,854.5l7.7-4.4v1.7l-7.7,4.4V854.5z
                  M1144.7,805.1v-1.7l6.2,3.6v1.7L1144.7,805.1z"/>
            </g>
          </g>
          <g id="B5" className={'isometry__floor ' + (floor === "5" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.06.0503" floor="5" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path32_11_" className="st0" d="M736.5,573.5l-25.6,14.8l29.7,17.2l-39.8,23l0,0l0,0l12.1,7l-69.1,39.9l-110.9-64.1
                l134.4-77.6L736.5,573.5z"/>
              <path id="path34_11_" className="st0" d="M713,666l-69.1,39.9v-30.6l69.1-39.9L713,666L713,666z"/>
              <path id="path36_11_" className="st0" d="M643.9,675.3v30.6l-110.9-64.1v-30.6L643.9,675.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0502" floor="5" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path22_11_" className="st0" d="M700.9,628.4L700.9,628.4l76.2,44V703l-64.1-37v-30.6L700.9,628.4z"/>
              <path id="path26_11_" className="st0" d="M777.1,672.4l-76.2-44l39.8-23l35.1,20.3l25.6-14.8l-18-10.4l20.4-11.8l48.8-28.1l59.1,34.1
                L777.1,672.4z"/>
              <path id="path38_11_" className="st0" d="M777.1,672.4l134.4-77.6v30.6L777.1,703V672.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.06.0501" floor="5" building="B" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path28_11_" className="st0" d="M852.4,560.6l-48.8,28.1l-15.1-8.7l-20.4,11.8l-31.7-18.3l-69.1-39.9l69.1-39.9L852.4,560.6
                z"/>
            </ModIsometrySvgFlat>
            <g id="B4-Areas_1_" className="isometry__areas">
              <path id="path30_11_" className="st0" d="M788.5,580l15.1,8.7l-20.4,11.8l18,10.4l-25.6,14.8l-35.1-20.3l-29.7-17.2l25.6-14.8
                l31.7,18.3L788.5,580z"/>
            </g>
            <g id="B5-Strokes" className="isometry__strokes">
              <path d="M912.2,594.7h-0.7l0.3-0.6l-59.1-34.1l-116-67l-0.3,0.6l-0.3-0.6L667,533l-134.4,77.6l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6
                l110.9,64.1l0.3-0.6l0.3,0.6l68.8-39.7l63.8,36.8l0.3-0.6l0.3,0.6l134.4-77.6l-0.3-0.6h0.7V594.7z M910.8,625l-133.1,76.8v-29.1
                l133.1-76.8V625z M667.4,534.4l67.8,39.1l-24.6,14.2l0.3,0.6l-0.3,0.6l28.7,16.6l-38.8,22.4l0.3,0.6l0,0h0l-0.3-0.6l0.3,0.6h-0.6
                v0l0.6,0l0,0l0,0l-0.3,0.5l0,0l0,0l11.1,6.4l-67.8,39.1l-109.6-63.3L667.4,534.4z M804,589.4l48.4-28l57.7,33.3l-133.1,76.8
                l-74.9-43.2l38.4-22.2l34.8,20.1l0.3-0.6l0.3,0.6l25.6-14.8l-0.3-0.6l0.3-0.6l-17-9.8L804,589.4l-0.3-0.6l0,0L804,589.4z
                  M713,665.9L713,665.9L713,665.9L713,665.9z M802.4,588.8L783,600l0.3,0.6l-0.3,0.6l17,9.8l-24.2,14L741,604.8l-28.7-16.6
                l24.2-14l31.4,18.1l0.3-0.6l0.3,0.6l20-11.6L802.4,588.8z M644.5,675.6l67.8-39.1v29.1l-67.8,39.1V675.6z M851.1,560.6L803.7,588
                l-14.8-8.6l-0.3,0.6l-0.3-0.6l-20,11.6l-31.4-18.1l-68.1-39.3l67.8-39.1L851.1,560.6z M643.2,675.6v29.1l-109.6-63.3v-29.1
                L643.2,675.6z M713.6,665.6v-29.1l62.8,36.2v29.1L713.6,665.6z"/>
            </g>
          </g>
        </g>
        <g id="A" className={'isometry__building ' + (building === "A" ? 'active' : '')}>
            <g id="A0" className={'isometry__floor ' + (floor === "0" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="A4-0002" floor="0" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path24_2_" className="st0" d="M545,1418v7.2l-0.8,0.5v21.9l-47.3,27.3v-29.1L545,1418z"/>
              <path id="path26_2_" className="st0" d="M545,1418L545,1418l-48.2,27.8l-6.7-3.9l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l14.2-8.2
                l6.1,3.5l111.8-64.5l14.2,8.2l76,43.9l-12.7,7.3l-12.6-7.3l-52.5,30.3V1418z"/>
              <path id="path28_2_" className="st0" d="M500.8,1435.8l-10.7,6.2v13.4l-29.9-17.3v-25.8L500.8,1435.8z"/>
              <path id="path30_2_" className="st0" d="M460.2,1412.3v25.8l-10.7,6.2v-25.8L460.2,1412.3z"/>
              <path id="path32_2_" className="st0" d="M460.2,1438.1l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L460.2,1438.1z"/>
              <path id="path34_2_" className="st0" d="M449.5,1418.5v25.8l-2.8,1.6l-46.1-26.6v-16.4v-12.7L449.5,1418.5z"/>
              <path id="path36_2_" className="st0" d="M479.7,1473.7l10.4-6v3.3l-10.4,6V1473.7z"/>
              <path id="path38_2_" className="st0" d="M439.1,1450.3l40.6,23.4v3.3l-40.6-23.4V1450.3z"/>
              <path id="path40_2_" className="st0" d="M490.1,1471v-29.1l6.7,3.9v29.1L490.1,1471z"/>
              <path id="path42_2_" className="st0" d="M610.1,1380.4l12.7-7.3v7.2l-6.4,3.7L610.1,1380.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A4-0001" floor="0" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path86_2_" className="st0" d="M640.4,1510.4l-48.9-28.3V1453l48.9,28.3V1510.4z"/>
              <path id="path88_2_" className="st0" d="M640.4,1481.3l-48.9-28.3l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9l0.8-0.5l18.8-10.9v0
                l52.5-30.3l0,0l6.4-3.7l47.3-27.3l96.3,55.6L640.4,1481.3z"/>
              <path id="path90_2_" className="st0" d="M550.8,1429.6l10.7-6.2v25.8l-10.7,6.2V1429.6z"/>
              <path id="path92_2_" className="st0" d="M550.8,1429.6v25.8l-2.8,1.6l-3.9-2.2v-7.2v-21.9L550.8,1429.6z"/>
              <path id="path94_2_" className="st0" d="M550.8,1455.4l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L550.8,1455.4z"/>
              <path id="path96_2_" className="st0" d="M561.5,1449.2v-25.8l40.6,23.4l-10.7,6.2v13.4L561.5,1449.2z"/>
              <path id="path98_2_" className="st0" d="M540.5,1461.4l40.6,23.4v3.3l-40.6-23.4V1461.4z"/>
              <path id="path100_2_" className="st0" d="M581.1,1484.8l10.4-6v3.3l-10.4,6V1484.8z"/>
              <path id="path102_2_" className="st0" d="M766.4,1408.5v29.1l-126,72.7v-29.1L766.4,1408.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0002" floor="0" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry}  onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path50_2_" className="st0" d="M322.2,1309.5v29.1l-16.6,9.6v-29.1L322.2,1309.5z"/>
              <path id="path66_2_" className="st0" d="M255.5,1319.2l-46.1-26.6v-29.1l48.9,28.3v25.8L255.5,1319.2z"/>
              <path id="path68_2_" className="st0" d="M258.3,1317.5v-25.8l10.7-6.2v25.8L258.3,1317.5z"/>
              <path id="path70_2_" className="st0" d="M269,1311.4v-25.8l40.6,23.4l-10.7,6.2v13.4L269,1311.4z"/>
              <path id="path72_2_" className="st0" d="M298.9,1315.2l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l126-72.7l96.3,55.6l-25.8,14.9v0
                l-83.6,48.3l0,0l-16.6,9.6L298.9,1315.2z"/>
              <path id="path74_2_" className="st0" d="M269,1311.4l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L269,1311.4z"/>
              <path id="path76_2_" className="st0" d="M288.6,1347l10.4-6v3.3l-10.4,6V1347z"/>
              <path id="path78_2_" className="st0" d="M248,1323.5l40.6,23.4v3.3l-40.6-23.4V1323.5z"/>
              <path id="path80_1_" className="st0" d="M298.9,1344.3v-29.1l6.7,3.9v29.1L298.9,1344.3z"/>
              <path id="path82_2_" className="st0" d="M431.7,1246.3v21.8l-7,4l-18.8-10.9L431.7,1246.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0001" floor="0" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry}  onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path44_2_" className="st0" d="M414.7,1382l-14.2,8.2v12.7l-28.7-16.6v-3.3v-12.4v-13.4L414.7,1382z"/>
              <path id="path46_2_" className="st0" d="M532.6,1321l-111.8,64.5l-6.1-3.5l-42.9-24.8l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9
                l16.6-9.6l0,0l83.6-48.3l0,0l7-4l18.8-10.9l96.3,55.6L532.6,1321z"/>
              <path id="path52_2_" className="st0" d="M331.2,1333.8l10.7-6.2v25.8l-10.7,6.2V1333.8z"/>
              <path id="path54_2_" className="st0" d="M331.2,1333.8v25.8l-2.8,1.6l-3.9-2.2v-19.1v-9.9L331.2,1333.8z"/>
              <path id="path56_2_" className="st0" d="M331.2,1359.6l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L331.2,1359.6z"/>
              <path id="path58_2_" className="st0" d="M341.9,1353.5v-25.8l40.6,23.4l-10.7,6.2v13.4L341.9,1353.5z"/>
              <path id="path60_2_" className="st0" d="M320.9,1365.6l40.6,23.4v3.3l-40.6-23.4V1365.6z"/>
              <path id="path62_2_" className="st0" d="M361.5,1389.1l10.4-6v3.3l-10.4,6V1389.1z"/>
              <path id="path64_2_" className="st0" d="M546.8,1312.8v16.4l-14.2-8.2L546.8,1312.8z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0002" floor="0" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry}  onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path126_2_" className="st0" d="M324.1,1007.3L324.1,1007.3l52.5,30.3v0l18.8,10.9l38,21.9l-6.7,3.9l-10.7-6.2l-40.6,23.4
                l10.7,6.2l-43.8,25.3l-114.6-66.2l91.2-52.6L324.1,1007.3z"/>
              <path id="path130_3_" className="st0" d="M227.7,1056.8l114.6,66.2v29.1l-114.6-66.2V1056.8z"/>
              <path id="path132_2_" className="st0" d="M426.7,1074.3l6.7-3.9v29.1l-3.9,2.2l-2.8-1.6L426.7,1074.3z"/>
              <path id="path134_2_" className="st0" d="M386.1,1126.8l-12.6,7.3l-1.4-0.8l-6,3.5v1.6l-23.9,13.8V1123l43.8-25.3v13.4v12.4
                L386.1,1126.8z"/>
              <path id="path136_2_" className="st0" d="M416,1068.1l10.7,6.2v25.8l-10.7-6.2V1068.1z"/>
              <path id="path138_2_" className="st0" d="M375.4,1091.6l40.6-23.4v25.8l-29.9,17.3v-13.4L375.4,1091.6z"/>
              <path id="path140_2_" className="st0" d="M386.1,1111.2l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6V1111.2z"/>
              <path id="path142_2_" className="st0" d="M437.1,1106.1v3.3l-40.6,23.4v-3.3L437.1,1106.1z"/>
              <path id="path144_2_" className="st0" d="M386.1,1126.8v-3.3l10.4,6v3.3L386.1,1126.8z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0001" floor="0" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry}  onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path106_2_" className="st0" d="M397.6,908.1l114.6,66.2l-43.8,25.3l-10.7-6.2l-40.6,23.4l10.7,6.2l-6.7,3.9l-114.6-66.2
                L397.6,908.1z"/>
              <path id="path108_2_" className="st0" d="M421.1,1027l6.7-3.9v29.1l-6.7,3.9V1027z"/>
              <path id="path110_2_" className="st0" d="M512.2,1003.4l-41,23.7l-2.8-1.6v-25.8l43.8-25.3V1003.4z"/>
              <path id="path112_2_" className="st0" d="M306.4,960.8l114.6,66.2v29.1l-25.6-14.8v-14.5L343,996.4l-12.6,7.3l-24-13.8V960.8z"/>
              <path id="path114_2_" className="st0" d="M427.8,1023.1l-10.7-6.2l40.6-23.4v25.8l-29.9,17.3V1023.1z"/>
              <path id="path116_2_" className="st0" d="M427.8,1036.5l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6L427.8,1036.5z"/>
              <path id="path118_2_" className="st0" d="M457.7,993.4l10.7,6.2v25.8l-10.7-6.2V993.4z"/>
              <path id="path120_2_" className="st0" d="M427.8,1052.1v-3.3l10.4,6v3.3L427.8,1052.1z"/>
              <path id="path122_2_" className="st0" d="M438.1,1058.1v-3.3l40.6-23.4v3.3L438.1,1058.1z"/>
            </ModIsometrySvgFlat>
            <g id="A0-Areas" className="isometry__areas">
              <path id="path22_2_" className="st0" d="M545,1425.2v-7.2l0,0v-14.5l18.8,10.9L545,1425.2z"/>
              <path id="path48_2_" className="st0" d="M324.5,1339.9l-2.3-1.3v-29.1l18.8,10.9l-16.6,9.6V1339.9z"/>
              <path id="path84_2_" className="st0" d="M545,1403.5l52.5-30.3l12.6,7.3l6.2,3.6l-52.5,30.3L545,1403.5z"/>
              <path id="path104_2_" className="st0" d="M341.1,1320.4l-18.8-10.9l83.6-48.3l18.8,10.9L341.1,1320.4z"/>
              <path id="path124_2_" className="st0" d="M343,996.4l52.5,30.3l-18.8,10.9l-52.5-30.3l0,0l6.2-3.6L343,996.4z"/>
              <path id="path128_2_" className="st0" d="M376.7,1037.6L376.7,1037.6l18.8-10.9v21.8L376.7,1037.6z"/>
              <path id="path146_1_" className="st0" d="M366.2,1136.8l6-3.5l1.4,0.8l4.6,2.7l-6,3.5L366.2,1136.8z"/>
              <path id="path148_1_" className="st0" d="M366.2,1136.8l6,3.5v29.1l-6-3.5v-27.5V1136.8z"/>
              <path id="path150_1_" className="st0" d="M372.2,1169.3v-29.1l6-3.5v29.1L372.2,1169.3z"/>
              <path id="path152_1_" className="st0" d="M287.7,1160.2l4.2,2.4l-51.6,29.8l-60.5-34.9l76.6-44.2l56.3,32.5L287.7,1160.2z"/>
              <path id="path154_2_" className="st0" d="M291.8,1162.6l-4.2-2.4l25-14.4v29.1l-17.9,10.3v-0.3l-2.9-1.7L291.8,1162.6z"/>
              <path id="path156_2_" className="st0" d="M179.8,1157.5l60.5,34.9v29.1l-60.5-34.9V1157.5z"/>
              <path id="path158_2_" className="st0" d="M240.3,1192.4l51.6-29.8v20.6l-3.1-1.8l-6,3.5v12l-42.5,24.5V1192.4z"/>
              <path id="path160_2_" className="st0" d="M282.8,1184.9l6-3.5l3.1,1.8l2.9,1.7l-6,3.5L282.8,1184.9z"/>
              <path id="path162_2_" className="st0" d="M282.8,1184.9l6,3.5v29.1l-6-3.5v-17V1184.9z"/>
              <path id="path164_2_" className="st0" d="M288.8,1217.4v-29.1l6-3.5v29.1L288.8,1217.4z"/>
              <path id="path166_2_" className="st0" d="M133.8,1212.7v-29.1l6,3.5v29.1L133.8,1212.7z"/>
              <path id="path168_2_" className="st0" d="M133.8,1183.6l6-3.5l6,3.5l-6,3.5L133.8,1183.6z"/>
              <path id="path170_2_" className="st0" d="M139.9,1216.1v-29.1l6-3.5v29.1L139.9,1216.1z"/>
              <path id="path172_2_" className="st0" d="M107.9,1171.5v-29.1l6,3.5v29.1L107.9,1171.5z"/>
              <path id="path174_2_" className="st0" d="M107.9,1142.4l6-3.5l6,3.5l-6,3.5L107.9,1142.4z"/>
              <path id="path176_2_" className="st0" d="M113.9,1174.9v-29.1l6-3.5v29.1L113.9,1174.9z"/>
              <path id="path178_2_" className="st0" d="M156,1143.7v-29.1l6,3.5v29.1L156,1143.7z"/>
              <path id="path180_2_" className="st0" d="M156,1114.6l6-3.5l6,3.5l-6,3.5L156,1114.6z"/>
              <path id="path182_2_" className="st0" d="M162.1,1147.1v-29.1l6-3.5v29.1L162.1,1147.1z"/>
              <path id="path184_2_" className="st0" d="M177.4,1131.3v-29.1l6,3.5v29.1L177.4,1131.3z"/>
              <path id="path186_2_" className="st0" d="M177.4,1102.3l6-3.5l6,3.5l-6,3.5L177.4,1102.3z"/>
              <path id="path188_2_" className="st0" d="M183.4,1134.8v-29.1l6-3.5v29.1L183.4,1134.8z"/>
              <path id="path190_2_" className="st0" d="M322.3,1191.2v-29.1l6,3.5v29.1L322.3,1191.2z"/>
              <path id="path192_2_" className="st0" d="M322.3,1162.1l6-3.5l6,3.5l-6,3.5L322.3,1162.1z"/>
              <path id="path194_2_" className="st0" d="M328.3,1194.6v-29.1l6-3.5v29.1L328.3,1194.6z"/>
              <path id="path196_2_" className="st0" d="M156.6,1199.5v-29.1l6,3.5v29.1L156.6,1199.5z"/>
              <path id="path198_2_" className="st0" d="M156.6,1170.5l6-3.5l6,3.5l-6,3.5L156.6,1170.5z"/>
              <path id="path200_2_" className="st0" d="M162.6,1203v-29.1l6-3.5v29.1L162.6,1203z"/>
              <path id="path202_2_" className="st0" d="M187.7,1243.7v-29.1l6,3.5v29.1L187.7,1243.7z"/>
              <path id="path204_2_" className="st0" d="M187.7,1214.7l6-3.5l6,3.5l-6,3.5L187.7,1214.7z"/>
              <path id="path206_2_" className="st0" d="M193.7,1247.2v-29.1l6-3.5v29.1L193.7,1247.2z"/>
            </g>
            <g id="A0-Stokes" className="isometry__strokes">
              <g id="path208_2_">
                <path className="st0" d="M341.1,1320.4l83.6-48.3"/>
                <rect x="334.6" y="1295.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -597.1733 365.4521)" width="96.5" height="1.3"/>
              </g>
              <g id="path210_2_">
                <path className="st0" d="M341.1,1320.4L341.1,1320.4"/>
                <path d="M341.1,1320.4"/>
              </g>
              <g id="path212_2_">
                <path className="st0" d="M322.2,1309.5L322.2,1309.5"/>
                <path d="M322.2,1309.5"/>
              </g>
              <g id="path214_2_">
                <path className="st0" d="M424.7,1272.1L424.7,1272.1"/>
                <path d="M424.7,1272.1"/>
              </g>
              <g id="path216_2_">
                <path className="st0" d="M405.8,1261.3L405.8,1261.3"/>
              </g>
              <g id="path218_2_">
                <path className="st0" d="M322.2,1309.5l83.6-48.3"/>
                <rect x="315.8" y="1284.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -594.2589 354.5684)" width="96.5" height="1.3"/>
              </g>
              <g id="path220_2_">
                <path className="st0" d="M563.8,1414.3l52.5-30.3"/>
                <rect x="559.7" y="1398.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -620.8635 482.9325)" width="60.7" height="1.3"/>
              </g>
              <g id="path222_2_">
                <path className="st0" d="M563.8,1414.3L563.8,1414.3"/>
              </g>
              <g id="path224_2_">
                <path className="st0" d="M616.4,1384L616.4,1384"/>
                <path d="M616.4,1384"/>
              </g>
              <g id="path226_2_">
                <path className="st0" d="M512.2,974.3l-114.6-66.2"/>
                <rect x="454.2" y="875" transform="matrix(0.5 -0.866 0.866 0.5 -587.6742 864.5637)" width="1.3" height="132.4"/>
              </g>
              <g id="path228_2_">
                <path className="st0" d="M421.1,1027l6.7-3.9"/>
                <rect x="420.5" y="1024.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -455.8885 349.8548)" width="7.8" height="1.3"/>
              </g>
              <g id="path230_2_">
                <path className="st0" d="M471.2,1027.1l41-23.7"/>
                <rect x="468.1" y="1014.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -441.9646 382.2163)" width="47.3" height="1.3"/>
              </g>
              <g id="path232_2_">
                <path className="st0" d="M306.4,960.8l114.6,66.2"/>
                <rect x="363.1" y="927.7" transform="matrix(0.5 -0.866 0.866 0.5 -678.8357 811.9377)" width="1.3" height="132.4"/>
              </g>
              <g id="path234_2_">
                <path className="st0" d="M427.8,1036.5l29.9-17.3"/>
                <rect x="425.5" y="1027.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -454.8668 359.4043)" width="34.5" height="1.3"/>
              </g>
              <g id="path236_2_">
                <path className="st0" d="M397.6,908.1l-91.2,52.6"/>
                <rect x="299.4" y="933.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -420.2945 301.4733)" width="105.3" height="1.3"/>
              </g>
              <g id="path238_2_">
                <path className="st0" d="M427.8,1023.1l-10.7-6.2"/>
                <rect x="421.8" y="1013.8" transform="matrix(0.5 -0.866 0.866 0.5 -672.1197 875.8716)" width="1.3" height="12.4"/>
              </g>
              <g id="path240_2_">
                <path className="st0" d="M417.1,1016.9l40.6-23.4"/>
                <rect x="413.9" y="1004.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -444.223 353.6816)" width="46.9" height="1.3"/>
              </g>
              <g id="path242_2_">
                <path className="st0" d="M468.4,999.6l-10.7-6.2"/>
                <rect x="462.4" y="990.4" transform="matrix(0.5 -0.866 0.866 0.5 -631.5063 899.2972)" width="1.3" height="12.4"/>
              </g>
              <g id="path244_2_">
                <path className="st0" d="M468.4,999.6l43.8-25.3"/>
                <rect x="465" y="986.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -428.0184 377.7151)" width="50.6" height="1.3"/>
              </g>
              <g id="path246_2_">
                <path className="st0" d="M427.8,1048.9v3.3"/>
                <rect x="427.1" y="1048.9" width="1.3" height="3.3"/>
              </g>
              <g id="path248_2_">
                <path className="st0" d="M427.8,1052.1l10.4,6"/>
                <rect x="432.3" y="1049.2" transform="matrix(0.5001 -0.866 0.866 0.5001 -697.284 902.4466)" width="1.3" height="12"/>
              </g>
              <g id="path250_2_">
                <path className="st0" d="M438.1,1054.9v3.3"/>
                <rect x="437.5" y="1054.9" width="1.3" height="3.3"/>
              </g>
              <g id="path252_2_">
                <path className="st0" d="M427.8,1048.9l10.4,6"/>
                <rect x="432.3" y="1045.9" transform="matrix(0.5 -0.866 0.866 0.5 -694.4544 900.8866)" width="1.3" height="12"/>
              </g>
              <g id="path254_2_">
                <path className="st0" d="M478.8,1031.4v3.3"/>
                <rect x="478.1" y="1031.4" width="1.3" height="3.3"/>
              </g>
              <g id="path256_2_">
                <path className="st0" d="M438.1,1054.9l40.6-23.4"/>
                <rect x="435" y="1042.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -460.3878 369.3119)" width="46.9" height="1.3"/>
              </g>
              <g id="path258_2_">
                <path className="st0" d="M468.4,999.6v25.8"/>
                <rect x="467.7" y="999.6" width="1.3" height="25.8"/>
              </g>
              <g id="path260_2_">
                <path className="st0" d="M457.7,993.4v25.8"/>
                <rect x="457" y="993.4" width="1.3" height="25.8"/>
              </g>
              <g id="path262_2_">
                <path className="st0" d="M457.7,1019.2l10.7,6.2"/>
                <rect x="462.4" y="1016.2" transform="matrix(0.5 -0.866 0.866 0.5 -653.8465 912.1956)" width="1.3" height="12.4"/>
              </g>
              <g id="path264_2_">
                <path className="st0" d="M438.1,1058.1l40.6-23.4"/>
                <rect x="435" y="1045.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -462.0284 369.7519)" width="46.9" height="1.3"/>
              </g>
              <g id="path266_2_">
                <path className="st0" d="M421.1,1056l6.7-3.9"/>
                <rect x="420.5" y="1053.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -470.4357 353.7556)" width="7.8" height="1.3"/>
              </g>
              <g id="path268_2_">
                <path className="st0" d="M306.4,960.8v29.1"/>
                <rect x="305.8" y="960.8" width="1.3" height="29.1"/>
              </g>
              <g id="path270_2_">
                <path className="st0" d="M306.4,989.8l24,13.8"/>
                <rect x="317.7" y="982.9" transform="matrix(0.5 -0.866 0.866 0.5 -704.015 774.11)" width="1.3" height="27.7"/>
              </g>
              <g id="path272_2_">
                <path className="st0" d="M395.5,1041.3l25.6,14.8"/>
                <rect x="407.6" y="1033.9" transform="matrix(0.5 -0.866 0.866 0.5 -704.0176 877.9133)" width="1.3" height="29.5"/>
              </g>
              <g id="path274_2_">
                <path className="st0" d="M421.1,1027v29.1"/>
                <rect x="420.4" y="1027" width="1.3" height="29.1"/>
              </g>
              <g id="path276_2_">
                <path className="st0" d="M512.2,974.3v29.1"/>
                <rect x="511.6" y="974.3" width="1.3" height="29.1"/>
              </g>
              <g id="path278_2_">
                <path className="st0" d="M490.1,1441.9l10.7-6.2"/>
                <rect x="489.2" y="1438.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -653.4142 440.8951)" width="12.4" height="1.3"/>
              </g>
              <g id="path280_2_">
                <path className="st0" d="M460.2,1412.3v25.8"/>
                <rect x="459.5" y="1412.3" width="1.3" height="25.8"/>
              </g>
              <g id="path282_2_">
                <path className="st0" d="M460.2,1438.1l29.9,17.3"/>
                <rect x="474.4" y="1429.5" transform="matrix(0.5 -0.866 0.866 0.5 -1015.3707 1134.8579)" width="1.3" height="34.5"/>
              </g>
              <g id="path284_2_">
                <path className="st0" d="M449.5,1444.3l10.7-6.2"/>
                <rect x="448.6" y="1440.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -660.0385 420.892)" width="12.4" height="1.3"/>
              </g>
              <g id="path286_2_">
                <path className="st0" d="M500.8,1435.8l-40.6-23.4"/>
                <rect x="479.8" y="1400.6" transform="matrix(0.5 -0.866 0.866 0.5 -993.037 1128.1711)" width="1.3" height="46.9"/>
              </g>
              <g id="path288_2_">
                <path className="st0" d="M449.5,1418.5v25.8"/>
                <rect x="448.8" y="1418.5" width="1.3" height="25.8"/>
              </g>
              <g id="path290_2_">
                <path className="st0" d="M449.5,1418.5l10.7-6.2"/>
                <rect x="448.6" y="1414.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -647.1324 417.4313)" width="12.4" height="1.3"/>
              </g>
              <g id="path292_2_">
                <path className="st0" d="M490.1,1467.7l-10.4,6"/>
                <rect x="478.9" y="1470.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -670.7761 439.9026)" width="12" height="1.3"/>
              </g>
              <g id="path294_2_">
                <path className="st0" d="M439.1,1450.3l40.6,23.4"/>
                <rect x="458.7" y="1438.6" transform="matrix(0.5 -0.866 0.866 0.5 -1036.4224 1128.8253)" width="1.3" height="46.9"/>
              </g>
              <g id="path296_2_">
                <path className="st0" d="M449.5,1418.5l-48.9-28.3"/>
                <rect x="424.3" y="1376.1" transform="matrix(0.5 -0.866 0.866 0.5 -1003.7194 1070.2217)" width="1.3" height="56.5"/>
              </g>
              <g id="path298_2_">
                <path className="st0" d="M446.6,1445.9l-46.1-26.6"/>
                <rect x="422.9" y="1406" transform="matrix(0.5 -0.866 0.866 0.5 -1028.8942 1083.0974)" width="1.3" height="53.2"/>
              </g>
              <g id="path300_2_">
                <path className="st0" d="M479.7,1473.7v3.3"/>
                <rect x="479" y="1473.7" width="1.3" height="3.3"/>
              </g>
              <g id="path302_2_">
                <path className="st0" d="M490.1,1467.7v3.3"/>
                <rect x="489.4" y="1467.7" width="1.3" height="3.3"/>
              </g>
              <g id="path304_2_">
                <path className="st0" d="M439.1,1450.3v3.3"/>
                <rect x="438.4" y="1450.3" width="1.3" height="3.3"/>
              </g>
              <g id="path306_2_">
                <path className="st0" d="M490.1,1471l-10.4,6"/>
                <rect x="478.9" y="1473.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -672.4165 440.3425)" width="12" height="1.3"/>
              </g>
              <g id="path308_2_">
                <path className="st0" d="M496.8,1474.9l-6.7-3.9"/>
                <rect x="492.8" y="1469.1" transform="matrix(0.5 -0.866 0.866 0.5 -1028.8987 1163.7839)" width="1.3" height="7.8"/>
              </g>
              <g id="path310_2_">
                <path className="st0" d="M496.8,1445.8l-6.7-3.9"/>
                <rect x="492.8" y="1440" transform="matrix(0.5001 -0.866 0.866 0.5001 -1003.6864 1149.0833)" width="1.3" height="7.8"/>
              </g>
              <g id="path312_2_">
                <path className="st0" d="M496.8,1445.8v29.1"/>
                <rect x="496.1" y="1445.8" width="1.3" height="29.1"/>
              </g>
              <g id="path314_2_">
                <path className="st0" d="M479.7,1477l-40.6-23.4"/>
                <rect x="458.7" y="1441.8" transform="matrix(0.5 -0.866 0.866 0.5 -1039.2622 1130.4648)" width="1.3" height="46.9"/>
              </g>
              <g id="path316_2_">
                <path className="st0" d="M544.1,1447.6l-47.3,27.3"/>
                <rect x="493.1" y="1460.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -661.2532 456.4246)" width="54.7" height="1.3"/>
              </g>
              <g id="path318_2_">
                <path className="st0" d="M622.8,1373.1l-12.7,7.3"/>
                <rect x="609.1" y="1376.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -606.101 493.1137)" width="14.6" height="1.3"/>
              </g>
              <g id="path320_2_">
                <path className="st0" d="M545,1418l-48.2,27.8"/>
                <rect x="493.1" y="1431.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -646.5266 452.7035)" width="55.6" height="1.3"/>
              </g>
              <g id="path322_2_">
                <path className="st0" d="M622.8,1373.1v7.2"/>
                <rect x="622.1" y="1373.1" width="1.3" height="7.2"/>
              </g>
              <g id="path324_2_">
                <path className="st0" d="M400.5,1390.2l14.2-8.2"/>
                <rect x="399.4" y="1385.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -638.8202 389.8954)" width="16.4" height="1.3"/>
              </g>
              <g id="path326_2_">
                <path className="st0" d="M532.6,1321l-111.8,64.5"/>
                <rect x="412.1" y="1352.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -613.108 420.0351)" width="129.1" height="1.3"/>
              </g>
              <g id="path328_2_">
                <path className="st0" d="M420.8,1385.5l-6.1-3.5"/>
                <rect x="417.1" y="1380.3" transform="matrix(0.5 -0.866 0.866 0.5 -989.5231 1053.6749)" width="1.3" height="7"/>
              </g>
              <g id="path330_2_">
                <path className="st0" d="M322.2,1338.6l2.3,1.3"/>
                <rect x="322.7" y="1337.9" transform="matrix(0.5005 -0.8658 0.8658 0.5005 -997.928 948.9574)" width="1.3" height="2.6"/>
              </g>
              <g id="path332_2_">
                <path className="st0" d="M331.2,1359.6v-25.8"/>
                <rect x="330.6" y="1333.8" width="1.3" height="25.8"/>
              </g>
              <g id="path334_2_">
                <path className="st0" d="M331.2,1359.6l10.7-6.2"/>
                <rect x="330.4" y="1355.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -633.5448 350.3797)" width="12.4" height="1.3"/>
              </g>
              <g id="path336_2_">
                <path className="st0" d="M341.9,1353.5v-25.8"/>
                <rect x="341.3" y="1327.7" width="1.3" height="25.8"/>
              </g>
              <g id="path338_2_">
                <path className="st0" d="M331.2,1333.8l10.7-6.2"/>
                <rect x="330.4" y="1330.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -620.6378 346.9188)" width="12.4" height="1.3"/>
              </g>
              <g id="path340_2_">
                <path className="st0" d="M371.8,1370.7l-29.9-17.3"/>
                <rect x="356.2" y="1344.8" transform="matrix(0.5 -0.866 0.866 0.5 -1001.1556 990.0696)" width="1.3" height="34.5"/>
              </g>
              <g id="path342_2_">
                <path className="st0" d="M341.9,1327.7l40.6,23.4"/>
                <rect x="361.6" y="1315.9" transform="matrix(0.5 -0.866 0.866 0.5 -978.8259 983.389)" width="1.3" height="46.9"/>
              </g>
              <g id="path344_2_">
                <path className="st0" d="M371.8,1357.3l10.7-6.2"/>
                <rect x="371" y="1353.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -626.9203 370.3824)" width="12.4" height="1.3"/>
              </g>
              <g id="path346_2_">
                <path className="st0" d="M361.5,1389.1l-40.6-23.4"/>
                <rect x="340.5" y="1353.9" transform="matrix(0.5 -0.866 0.866 0.5 -1022.2244 984.1094)" width="1.3" height="46.9"/>
              </g>
              <g id="path348_2_">
                <path className="st0" d="M371.8,1383.1l-10.4,6"/>
                <rect x="360.7" y="1385.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -644.2819 369.39)" width="12" height="1.3"/>
              </g>
              <g id="path350_2_">
                <path className="st0" d="M371.8,1383.1v3.3"/>
                <rect x="371.2" y="1383.1" width="1.3" height="3.3"/>
              </g>
              <g id="path352_2_">
                <path className="st0" d="M371.8,1386.4l28.7,16.6"/>
                <rect x="385.5" y="1378.1" transform="matrix(0.5 -0.866 0.866 0.5 -1014.6959 1031.7277)" width="1.3" height="33.1"/>
              </g>
              <g id="path354_2_">
                <path className="st0" d="M320.9,1365.6v3.3"/>
                <rect x="320.2" y="1365.6" width="1.3" height="3.3"/>
              </g>
              <g id="path356_2_">
                <path className="st0" d="M371.8,1386.4l-10.4,6"/>
                <rect x="360.7" y="1388.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -645.9221 369.8298)" width="12" height="1.3"/>
              </g>
              <g id="path358_2_">
                <path className="st0" d="M361.5,1389.1v3.3"/>
                <rect x="360.8" y="1389.1" width="1.3" height="3.3"/>
              </g>
              <g id="path360_2_">
                <path className="st0" d="M324.5,1359l3.9,2.2"/>
                <rect x="325.8" y="1357.9" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -1014.7543 962.9532)" width="1.3" height="4.5"/>
              </g>
              <g id="path362_2_">
                <path className="st0" d="M324.5,1330l6.7,3.9"/>
                <rect x="327.2" y="1328" transform="matrix(0.5 -0.866 0.866 0.5 -989.5062 949.8272)" width="1.3" height="7.8"/>
              </g>
              <g id="path364_2_">
                <path className="st0" d="M320.9,1368.9l40.6,23.4"/>
                <rect x="340.5" y="1357.2" transform="matrix(0.5 -0.866 0.866 0.5 -1025.0786 985.7983)" width="1.3" height="46.9"/>
              </g>
              <g id="path366_2_">
                <path className="st0" d="M546.8,1312.8l-96.3-55.6"/>
                <rect x="498" y="1229.4" transform="matrix(0.5 -0.866 0.866 0.5 -863.5256 1074.3357)" width="1.3" height="111.2"/>
              </g>
              <g id="path368_2_">
                <path className="st0" d="M546.8,1312.8v16.4"/>
                <rect x="546.1" y="1312.8" width="1.3" height="16.4"/>
              </g>
              <g id="path370_2_">
                <path className="st0" d="M298.9,1315.2l10.7-6.2"/>
                <rect x="298.1" y="1311.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -615.6439 328.2683)" width="12.4" height="1.3"/>
              </g>
              <g id="path372_2_">
                <path className="st0" d="M269,1285.6v25.8"/>
                <rect x="268.4" y="1285.6" width="1.3" height="25.8"/>
              </g>
              <g id="path374_2_">
                <path className="st0" d="M269,1311.4l29.9,17.3"/>
                <rect x="283.3" y="1302.7" transform="matrix(0.5 -0.866 0.866 0.5 -1001.1631 905.9313)" width="1.3" height="34.5"/>
              </g>
              <g id="path376_2_">
                <path className="st0" d="M258.3,1317.5l10.7-6.2"/>
                <rect x="257.5" y="1313.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -622.2682 308.2653)" width="12.4" height="1.3"/>
              </g>
              <g id="path378_2_">
                <path className="st0" d="M309.6,1309l-40.6-23.4"/>
                <rect x="288.7" y="1273.8" transform="matrix(0.5 -0.866 0.866 0.5 -978.832 899.2409)" width="1.3" height="46.9"/>
              </g>
              <g id="path380_2_">
                <path className="st0" d="M258.3,1291.7v25.8"/>
                <rect x="257.7" y="1291.7" width="1.3" height="25.8"/>
              </g>
              <g id="path382_2_">
                <path className="st0" d="M258.3,1291.7l10.7-6.2"/>
                <rect x="257.5" y="1288" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -609.3614 304.8044)" width="12.4" height="1.3"/>
              </g>
              <g id="path384_2_">
                <path className="st0" d="M298.9,1341l-10.4,6"/>
                <rect x="287.8" y="1343.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -633.0054 327.276)" width="12" height="1.3"/>
              </g>
              <g id="path386_2_">
                <path className="st0" d="M248,1323.5l40.6,23.4"/>
                <rect x="267.6" y="1311.8" transform="matrix(0.5 -0.866 0.866 0.5 -1022.2235 899.9431)" width="1.3" height="46.9"/>
              </g>
              <g id="path388_2_">
                <path className="st0" d="M258.3,1291.7l-48.9-28.3"/>
                <rect x="233.2" y="1249.4" transform="matrix(0.5 -0.866 0.866 0.5 -989.5233 841.3438)" width="1.3" height="56.5"/>
              </g>
              <g id="path390_2_">
                <path className="st0" d="M255.5,1319.2l-46.1-26.6"/>
                <rect x="231.8" y="1279.3" transform="matrix(0.5 -0.866 0.866 0.5 -1014.7068 854.2494)" width="1.3" height="53.2"/>
              </g>
              <g id="path392_2_">
                <path className="st0" d="M288.6,1347v3.3"/>
                <rect x="287.9" y="1347" width="1.3" height="3.3"/>
              </g>
              <g id="path394_2_">
                <path className="st0" d="M298.9,1341v3.3"/>
                <rect x="298.3" y="1341" width="1.3" height="3.3"/>
              </g>
              <g id="path396_2_">
                <path className="st0" d="M248,1323.5v3.3"/>
                <rect x="247.3" y="1323.5" width="1.3" height="3.3"/>
              </g>
              <g id="path398_2_">
                <path className="st0" d="M298.9,1344.3l-10.4,6"/>
                <rect x="287.8" y="1346.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -634.6459 327.7159)" width="12" height="1.3"/>
              </g>
              <g id="path400_2_">
                <path className="st0" d="M305.7,1348.2l-6.7-3.9"/>
                <rect x="301.6" y="1342.3" transform="matrix(0.5001 -0.8659 0.8659 0.5001 -1014.6407 934.7093)" width="1.3" height="7.8"/>
              </g>
              <g id="path402_2_">
                <path className="st0" d="M305.7,1319.1l-6.7-3.9"/>
                <rect x="301.6" y="1313.3" transform="matrix(0.5 -0.866 0.866 0.5 -989.5059 920.316)" width="1.3" height="7.8"/>
              </g>
              <g id="path404_2_">
                <path className="st0" d="M305.7,1319.1v29.1"/>
                <rect x="305" y="1319.1" width="1.3" height="29.1"/>
              </g>
              <g id="path406_2_">
                <path className="st0" d="M288.6,1350.3l-40.6-23.4"/>
                <rect x="267.6" y="1315.1" transform="matrix(0.5 -0.866 0.866 0.5 -1025.0718 901.6063)" width="1.3" height="46.9"/>
              </g>
              <g id="path408_2_">
                <path className="st0" d="M322.2,1338.6l-16.6,9.6"/>
                <rect x="304.4" y="1342.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -629.9896 337.2935)" width="19.1" height="1.3"/>
              </g>
              <g id="path410_2_">
                <path className="st0" d="M431.7,1246.3v21.8"/>
                <rect x="431" y="1246.3" width="1.3" height="21.8"/>
              </g>
              <g id="path412_2_">
                <path className="st0" d="M209.4,1263.5v29.1"/>
                <rect x="208.7" y="1263.5" width="1.3" height="29.1"/>
              </g>
              <g id="path414_2_">
                <path className="st0" d="M209.4,1263.5l126-72.7"/>
                <rect x="199.6" y="1226.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -577.4036 300.9059)" width="145.5" height="1.3"/>
              </g>
              <g id="path416_2_">
                <path className="st0" d="M335.4,1190.7l96.3,55.6"/>
                <rect x="382.9" y="1163" transform="matrix(0.5 -0.866 0.866 0.5 -863.5262 941.4169)" width="1.3" height="111.2"/>
              </g>
              <g id="path418_2_">
                <path className="st0" d="M324.1,1007.3L324.1,1007.3"/>
                <path d="M324.1,1007.3"/>
              </g>
              <g id="path420_2_">
                <path className="st0" d="M376.7,1037.6L376.7,1037.6"/>
              </g>
              <g id="path422_2_">
                <path className="st0" d="M395.5,1026.7L343,996.4"/>
                <rect x="368.6" y="981.2" transform="matrix(0.5 -0.866 0.866 0.5 -691.4243 825.533)" width="1.3" height="60.7"/>
              </g>
              <g id="path424_2_">
                <path className="st0" d="M376.7,1037.6l18.8-10.9"/>
                <rect x="375.2" y="1031.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -464.6145 331.6338)" width="21.8" height="1.3"/>
              </g>
              <g id="path426_2_">
                <path className="st0" d="M545,1403.5v14.5"/>
                <rect x="544.3" y="1403.5" width="1.3" height="14.5"/>
              </g>
              <g id="path428_2_">
                <path className="st0" d="M597.5,1373.1l-52.5,30.3"/>
                <rect x="540.9" y="1387.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -617.9493 472.0484)" width="60.7" height="1.3"/>
              </g>
              <g id="path430_2_">
                <path className="st0" d="M550.8,1455.4v-25.8"/>
                <rect x="550.2" y="1429.6" width="1.3" height="25.8"/>
              </g>
              <g id="path432_2_">
                <path className="st0" d="M550.8,1455.4l10.7-6.2"/>
                <rect x="550" y="1451.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -651.9883 473.1046)" width="12.4" height="1.3"/>
              </g>
              <g id="path434_2_">
                <path className="st0" d="M561.5,1449.2v-25.8"/>
                <rect x="560.9" y="1423.4" width="1.3" height="25.8"/>
              </g>
              <g id="path436_2_">
                <path className="st0" d="M550.8,1429.6l10.7-6.2"/>
                <rect x="550" y="1425.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -639.082 469.6438)" width="12.4" height="1.3"/>
              </g>
              <g id="path438_2_">
                <path className="st0" d="M591.5,1466.5l-29.9-17.3"/>
                <rect x="575.8" y="1440.6" transform="matrix(0.5 -0.866 0.866 0.5 -974.2813 1228.1819)" width="1.3" height="34.5"/>
              </g>
              <g id="path440_2_">
                <path className="st0" d="M561.5,1423.4l40.6,23.4"/>
                <rect x="581.2" y="1411.7" transform="matrix(0.5 -0.866 0.866 0.5 -951.9459 1221.4958)" width="1.3" height="46.9"/>
              </g>
              <g id="path442_2_">
                <path className="st0" d="M591.5,1453l10.7-6.2"/>
                <rect x="590.6" y="1449.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -645.3641 493.1082)" width="12.4" height="1.3"/>
              </g>
              <g id="path444_2_">
                <path className="st0" d="M581.1,1484.8l-40.6-23.4"/>
                <rect x="560.1" y="1449.6" transform="matrix(0.5 -0.866 0.866 0.5 -995.3462 1222.2092)" width="1.3" height="46.9"/>
              </g>
              <g id="path446_2_">
                <path className="st0" d="M591.5,1478.8l-10.4,6"/>
                <rect x="580.3" y="1481.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -662.7255 492.1154)" width="12" height="1.3"/>
              </g>
              <g id="path448_2_">
                <path className="st0" d="M591.5,1453l48.9,28.3"/>
                <rect x="615.3" y="1438.9" transform="matrix(0.5 -0.866 0.866 0.5 -962.6365 1266.9784)" width="1.3" height="56.5"/>
              </g>
              <g id="path450_2_">
                <path className="st0" d="M591.5,1478.8v3.3"/>
                <rect x="590.8" y="1478.8" width="1.3" height="3.3"/>
              </g>
              <g id="path452_2_">
                <path className="st0" d="M591.5,1482.1l48.9,28.3"/>
                <rect x="615.3" y="1468" transform="matrix(0.5 -0.866 0.866 0.5 -987.8171 1281.5164)" width="1.3" height="56.5"/>
              </g>
              <g id="path454_2_">
                <path className="st0" d="M540.5,1461.4v3.3"/>
                <rect x="539.8" y="1461.4" width="1.3" height="3.3"/>
              </g>
              <g id="path456_2_">
                <path className="st0" d="M591.5,1482.1l-10.4,6"/>
                <rect x="580.3" y="1484.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -664.3662 492.5553)" width="12" height="1.3"/>
              </g>
              <g id="path458_2_">
                <path className="st0" d="M581.1,1484.8v3.3"/>
                <rect x="580.4" y="1484.8" width="1.3" height="3.3"/>
              </g>
              <g id="path460_2_">
                <path className="st0" d="M544.1,1454.8l3.9,2.2"/>
                <rect x="545.4" y="1453.7" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -987.8441 1201.0131)" width="1.3" height="4.5"/>
              </g>
              <g id="path462_2_">
                <path className="st0" d="M544.1,1425.7l6.7,3.9"/>
                <rect x="546.8" y="1423.8" transform="matrix(0.5 -0.866 0.866 0.5 -962.6283 1187.8932)" width="1.3" height="7.8"/>
              </g>
              <g id="path464_2_">
                <path className="st0" d="M540.5,1464.6l40.6,23.4"/>
                <rect x="560.1" y="1452.9" transform="matrix(0.5 -0.866 0.866 0.5 -998.1812 1223.8206)" width="1.3" height="46.9"/>
              </g>
              <g id="path466_2_">
                <path className="st0" d="M766.4,1408.5l-96.3-55.6"/>
                <rect x="717.6" y="1325.2" transform="matrix(0.5 -0.866 0.866 0.5 -836.6414 1312.4293)" width="1.3" height="111.2"/>
              </g>
              <g id="path468_2_">
                <path className="st0" d="M766.4,1408.5v29.1"/>
                <rect x="765.7" y="1408.5" width="1.3" height="29.1"/>
              </g>
              <g id="path470_2_">
                <path className="st0" d="M640.4,1481.3v29.1"/>
                <rect x="639.7" y="1481.3" width="1.3" height="29.1"/>
              </g>
              <g id="path472_2_">
                <path className="st0" d="M640.4,1481.3l126-72.7"/>
                <rect x="630.6" y="1444.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -628.5483 545.7623)" width="145.5" height="1.3"/>
              </g>
              <g id="path474_2_">
                <path className="st0" d="M640.4,1510.4l126-72.7"/>
                <rect x="630.6" y="1473.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -643.0955 549.6631)" width="145.5" height="1.3"/>
              </g>
              <g id="path476_2_">
                <path className="st0" d="M342.3,1123l-114.6-66.2"/>
                <rect x="284.3" y="1023.8" transform="matrix(0.5 -0.866 0.866 0.5 -801.4252 791.7709)" width="1.3" height="132.4"/>
              </g>
              <g id="path478_2_">
                <path className="st0" d="M433.5,1070.4l-6.7,3.9"/>
                <rect x="426.2" y="1071.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -478.8071 359.0457)" width="7.8" height="1.3"/>
              </g>
              <g id="path480_2_">
                <path className="st0" d="M386.1,1126.8l-12.6,7.3"/>
                <rect x="372.6" y="1129.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -514.6174 341.6911)" width="14.5" height="1.3"/>
              </g>
              <g id="path482_2_">
                <path className="st0" d="M366.2,1138.3l-23.9,13.8"/>
                <rect x="340.5" y="1144.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -525.4495 330.8629)" width="27.5" height="1.3"/>
              </g>
              <g id="path484_2_">
                <path className="st0" d="M416,1093.9l-29.9,17.3"/>
                <rect x="383.8" y="1101.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -497.8069 348.5806)" width="34.5" height="1.3"/>
              </g>
              <g id="path486_2_">
                <path className="st0" d="M227.7,1056.8l91.2-52.6"/>
                <rect x="220.6" y="1029.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -478.9321 274.9583)" width="105.3" height="1.3"/>
              </g>
              <g id="path488_2_">
                <path className="st0" d="M426.7,1074.3l-10.7-6.2"/>
                <rect x="420.7" y="1065" transform="matrix(0.5 -0.866 0.866 0.5 -716.9791 900.4869)" width="1.3" height="12.4"/>
              </g>
              <g id="path490_2_">
                <path className="st0" d="M416,1068.1l-40.6,23.4"/>
                <rect x="372.3" y="1079.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -487.1633 342.8581)" width="46.9" height="1.3"/>
              </g>
              <g id="path492_2_">
                <path className="st0" d="M386.1,1097.7l-10.7-6.2"/>
                <rect x="380.1" y="1088.5" transform="matrix(0.5 -0.866 0.866 0.5 -757.6052 877.1296)" width="1.3" height="12.4"/>
              </g>
              <g id="path494_2_">
                <path className="st0" d="M386.1,1097.7l-43.8,25.3"/>
                <rect x="338.9" y="1109.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -506.6775 331.1855)" width="50.6" height="1.3"/>
              </g>
              <g id="path496_2_">
                <path className="st0" d="M437.1,1106.1v3.3"/>
                <rect x="436.4" y="1106.1" width="1.3" height="3.3"/>
              </g>
              <g id="path498_2_">
                <path className="st0" d="M386.1,1123.5v3.3"/>
                <rect x="385.5" y="1123.5" width="1.3" height="3.3"/>
              </g>
              <g id="path500_2_">
                <path className="st0" d="M386.1,1123.5l10.4,6"/>
                <rect x="390.6" y="1120.5" transform="matrix(0.5 -0.866 0.866 0.5 -779.9369 902.1428)" width="1.3" height="12"/>
              </g>
              <g id="path502_2_">
                <path className="st0" d="M396.5,1129.5v3.3"/>
                <rect x="395.8" y="1129.5" width="1.3" height="3.3"/>
              </g>
              <g id="path504_2_">
                <path className="st0" d="M386.1,1126.8l10.4,6"/>
                <rect x="390.6" y="1123.8" transform="matrix(0.5 -0.866 0.866 0.5 -782.7769 903.7825)" width="1.3" height="12"/>
              </g>
              <g id="path506_2_">
                <path className="st0" d="M437.1,1106.1l-40.6,23.4"/>
                <rect x="393.4" y="1117.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -503.328 358.4882)" width="46.9" height="1.3"/>
              </g>
              <g id="path508_2_">
                <path className="st0" d="M416,1068.1v25.8"/>
                <rect x="415.4" y="1068.1" width="1.3" height="25.8"/>
              </g>
              <g id="path510_2_">
                <path className="st0" d="M426.7,1074.3v25.8"/>
                <rect x="426.1" y="1074.3" width="1.3" height="25.8"/>
              </g>
              <g id="path512_2_">
                <path className="st0" d="M416,1093.9l10.7,6.2"/>
                <rect x="420.7" y="1090.8" transform="matrix(0.5 -0.866 0.866 0.5 -739.3185 913.384)" width="1.3" height="12.4"/>
              </g>
              <g id="path514_2_">
                <path className="st0" d="M437.1,1109.3l-40.6,23.4"/>
                <rect x="393.4" y="1120.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -504.9685 358.9281)" width="46.9" height="1.3"/>
              </g>
              <g id="path516_2_">
                <path className="st0" d="M433.5,1099.5l-3.9,2.2"/>
                <rect x="429.3" y="1099.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -492.7531 363.5469)" width="4.5" height="1.3"/>
              </g>
              <g id="path518_2_">
                <path className="st0" d="M227.7,1056.8v29.1"/>
                <rect x="227" y="1056.8" width="1.3" height="29.1"/>
              </g>
              <g id="path520_2_">
                <path className="st0" d="M342.3,1152.1l-114.6-66.2"/>
                <rect x="284.3" y="1052.8" transform="matrix(0.5 -0.866 0.866 0.5 -826.6035 806.3016)" width="1.3" height="132.4"/>
              </g>
              <g id="path522_2_">
                <path className="st0" d="M433.5,1070.4v29.1"/>
                <rect x="432.8" y="1070.4" width="1.3" height="29.1"/>
              </g>
              <g id="path524_2_">
                <path className="st0" d="M342.3,1123v29.1"/>
                <rect x="341.6" y="1123" width="1.3" height="29.1"/>
              </g>
              <g id="path526_2_">
                <path className="st0" d="M287.7,1160.2l4.2,2.4"/>
                <rect x="289.1" y="1159" transform="matrix(0.5 -0.866 0.866 0.5 -860.9351 831.6951)" width="1.3" height="4.8"/>
              </g>
              <g id="path528_2_">
                <path className="st0" d="M179.8,1157.5l76.6-44.2"/>
                <rect x="173.8" y="1134.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -538.7827 261.4103)" width="88.4" height="1.3"/>
              </g>
              <g id="path530_2_">
                <path className="st0" d="M240.3,1192.4l-60.5-34.9"/>
                <rect x="209.3" y="1140" transform="matrix(0.5 -0.866 0.866 0.5 -912.5115 769.3281)" width="1.3" height="69.8"/>
              </g>
              <g id="path532_2_">
                <path className="st0" d="M291.8,1162.6l-51.6,29.8"/>
                <rect x="236.3" y="1176.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -553.4258 291.0763)" width="59.6" height="1.3"/>
              </g>
              <g id="path534_2_">
                <path className="st0" d="M291.8,1162.6v20.6"/>
                <rect x="291.2" y="1162.6" width="1.3" height="20.6"/>
              </g>
              <g id="path536_2_">
                <path className="st0" d="M240.3,1192.4v29.1"/>
                <rect x="239.6" y="1192.4" width="1.3" height="29.1"/>
              </g>
              <g id="path538_2_">
                <path className="st0" d="M282.8,1196.9l-42.5,24.5"/>
                <rect x="237" y="1208.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -569.8958 293.0547)" width="49.1" height="1.3"/>
              </g>
              <g id="path540_2_">
                <path className="st0" d="M240.3,1221.5l-60.5-34.9"/>
                <rect x="209.3" y="1169.1" transform="matrix(0.5 -0.866 0.866 0.5 -937.6967 783.8798)" width="1.3" height="69.8"/>
              </g>
              <g id="path542_2_">
                <path className="st0" d="M179.8,1157.5v29.1"/>
                <rect x="179.1" y="1157.5" width="1.3" height="29.1"/>
              </g>
              <g id="path544_2_">
                <path className="st0" d="M256.3,1113.3l56.3,32.5"/>
                <rect x="283.8" y="1097" transform="matrix(0.5 -0.866 0.866 0.5 -835.9312 811.145)" width="1.3" height="65"/>
              </g>
              <g id="path546_2_">
                <path className="st0" d="M312.7,1145.8v29.1"/>
                <rect x="312" y="1145.8" width="1.3" height="29.1"/>
              </g>
              <g id="path548_2_">
                <path className="st0" d="M287.7,1160.2l25-14.4"/>
                <rect x="285.7" y="1152.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -536.5837 304.8611)" width="28.9" height="1.3"/>
              </g>
              <g id="path550_2_">
                <path className="st0" d="M294.8,1185.2l17.9-10.3"/>
                <rect x="293.4" y="1179.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -549.6292 310.2626)" width="20.7" height="1.3"/>
              </g>
              <g id="path552_2_">
                <path className="st0" d="M133.8,1183.6v29.1"/>
                <rect x="133.2" y="1183.6" width="1.3" height="29.1"/>
              </g>
              <g id="path554_2_">
                <path className="st0" d="M139.9,1180.1l-6,3.5"/>
                <rect x="133.4" y="1181.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -572.9383 227.0233)" width="6.9" height="1.3"/>
              </g>
              <g id="path556_2_">
                <path className="st0" d="M133.8,1183.6l6,3.5"/>
                <rect x="136.2" y="1181.9" transform="matrix(0.5 -0.8661 0.8661 0.5 -958.1199 711.2432)" width="1.3" height="6.9"/>
              </g>
              <g id="path558_2_">
                <path className="st0" d="M133.8,1212.7l6,3.5"/>
                <rect x="136.2" y="1210.9" transform="matrix(0.5 -0.8661 0.8661 0.5 -983.3015 725.7827)" width="1.3" height="6.9"/>
              </g>
              <g id="path560_2_">
                <path className="st0" d="M139.9,1187.1v29.1"/>
                <rect x="139.2" y="1187.1" width="1.3" height="29.1"/>
              </g>
              <g id="path562_2_">
                <path className="st0" d="M139.9,1216.1l6-3.5"/>
                <rect x="139.4" y="1213.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -588.4155 234.3975)" width="6.9" height="1.3"/>
              </g>
              <g id="path564_2_">
                <path className="st0" d="M139.9,1187.1l6-3.5"/>
                <rect x="139.4" y="1184.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -573.8682 230.4968)" width="6.9" height="1.3"/>
              </g>
              <g id="path566_2_">
                <path className="st0" d="M145.9,1183.6v29.1"/>
                <rect x="145.2" y="1183.6" width="1.3" height="29.1"/>
              </g>
              <g id="path568_2_">
                <path className="st0" d="M145.9,1183.6l-6-3.5"/>
                <rect x="142.2" y="1178.4" transform="matrix(0.5 -0.8661 0.8661 0.5 -952.108 714.7141)" width="1.3" height="6.9"/>
              </g>
              <g id="path570_2_">
                <path className="st0" d="M107.9,1142.4v29.1"/>
                <rect x="107.2" y="1142.4" width="1.3" height="29.1"/>
              </g>
              <g id="path572_2_">
                <path className="st0" d="M113.9,1138.9l-6,3.5"/>
                <rect x="107.4" y="1140" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -555.8005 208.5173)" width="6.9" height="1.3"/>
              </g>
              <g id="path574_2_">
                <path className="st0" d="M107.9,1142.4l6,3.5"/>
                <rect x="110.3" y="1140.6" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -935.4344 668.2466)" width="1.3" height="6.9"/>
              </g>
              <g id="path576_2_">
                <path className="st0" d="M107.9,1171.5l6,3.5"/>
                <rect x="110.3" y="1169.7" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -960.617 682.7878)" width="1.3" height="6.9"/>
              </g>
              <g id="path578_2_">
                <path className="st0" d="M113.9,1145.9v29.1"/>
                <rect x="113.3" y="1145.9" width="1.3" height="29.1"/>
              </g>
              <g id="path580_2_">
                <path className="st0" d="M113.9,1174.9l6-3.5"/>
                <rect x="113.5" y="1172.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -571.2776 215.8923)" width="6.9" height="1.3"/>
              </g>
              <g id="path582_2_">
                <path className="st0" d="M113.9,1145.9l6-3.5"/>
                <rect x="113.5" y="1143.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -556.7304 211.9916)" width="6.9" height="1.3"/>
              </g>
              <g id="path584_2_">
                <path className="st0" d="M119.9,1142.4v29.1"/>
                <rect x="119.3" y="1142.4" width="1.3" height="29.1"/>
              </g>
              <g id="path586_2_">
                <path className="st0" d="M119.9,1142.4l-6-3.5"/>
                <rect x="116.3" y="1137.2" transform="matrix(0.5 -0.866 0.866 0.5 -929.3644 671.5898)" width="1.3" height="6.9"/>
              </g>
              <g id="path588_2_">
                <path className="st0" d="M156,1114.6v29.1"/>
                <rect x="155.4" y="1114.6" width="1.3" height="29.1"/>
              </g>
              <g id="path590_2_">
                <path className="st0" d="M162.1,1111.1l-6,3.5"/>
                <rect x="155.6" y="1112.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -535.4382 228.8721)" width="6.9" height="1.3"/>
              </g>
              <g id="path592_2_">
                <path className="st0" d="M156,1114.6l6,3.5"/>
                <rect x="158.4" y="1112.9" transform="matrix(0.5001 -0.866 0.866 0.5001 -887.2109 695.8362)" width="1.3" height="6.9"/>
              </g>
              <g id="path594_2_">
                <path className="st0" d="M156,1143.7l6,3.5"/>
                <rect x="158.4" y="1141.9" transform="matrix(0.5001 -0.866 0.866 0.5001 -912.3906 710.3726)" width="1.3" height="6.9"/>
              </g>
              <g id="path596_2_">
                <path className="st0" d="M162.1,1118.1v29.1"/>
                <rect x="161.4" y="1118.1" width="1.3" height="29.1"/>
              </g>
              <g id="path598_2_">
                <path className="st0" d="M162.1,1147.1l6-3.5"/>
                <rect x="161.6" y="1144.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -550.9156 236.2464)" width="6.9" height="1.3"/>
              </g>
              <g id="path600_2_">
                <path className="st0" d="M162.1,1118.1l6-3.5"/>
                <rect x="161.6" y="1115.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -536.3683 232.3457)" width="6.9" height="1.3"/>
              </g>
              <g id="path602_2_">
                <path className="st0" d="M168.1,1114.6v29.1"/>
                <rect x="167.4" y="1114.6" width="1.3" height="29.1"/>
              </g>
              <g id="path604_2_">
                <path className="st0" d="M168.1,1114.6l-6-3.5"/>
                <rect x="164.4" y="1109.4" transform="matrix(0.5 -0.866 0.866 0.5 -881.2351 699.3978)" width="1.3" height="6.9"/>
              </g>
              <g id="path606_2_">
                <path className="st0" d="M177.4,1102.3v29.1"/>
                <rect x="176.7" y="1102.3" width="1.3" height="29.1"/>
              </g>
              <g id="path608_2_">
                <path className="st0" d="M183.4,1098.8l-6,3.5"/>
                <rect x="176.9" y="1099.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -526.4082 237.8987)" width="6.9" height="1.3"/>
              </g>
              <g id="path610_2_">
                <path className="st0" d="M177.4,1102.3l6,3.5"/>
                <rect x="179.7" y="1100.5" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -865.9415 708.3621)" width="1.3" height="6.9"/>
              </g>
              <g id="path612_2_">
                <path className="st0" d="M177.4,1131.3l6,3.5"/>
                <rect x="179.7" y="1129.6" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -891.124 722.9033)" width="1.3" height="6.9"/>
              </g>
              <g id="path614_2_">
                <path className="st0" d="M183.4,1105.7v29.1"/>
                <rect x="182.7" y="1105.7" width="1.3" height="29.1"/>
              </g>
              <g id="path616_2_">
                <path className="st0" d="M183.4,1134.8l6-3.5"/>
                <rect x="182.9" y="1132.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -541.8854 245.2737)" width="6.9" height="1.3"/>
              </g>
              <g id="path618_2_">
                <path className="st0" d="M183.4,1105.7l6-3.5"/>
                <rect x="182.9" y="1103.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -527.3382 241.3729)" width="6.9" height="1.3"/>
              </g>
              <g id="path620_2_">
                <path className="st0" d="M189.4,1102.3v29.1"/>
                <rect x="188.8" y="1102.3" width="1.3" height="29.1"/>
              </g>
              <g id="path622_2_">
                <path className="st0" d="M189.4,1102.3l-6-3.5"/>
                <rect x="185.7" y="1097.1" transform="matrix(0.5 -0.866 0.866 0.5 -859.881 711.7057)" width="1.3" height="6.9"/>
              </g>
              <g id="path624_2_">
                <path className="st0" d="M372.2,1133.3l-6,3.5"/>
                <rect x="365.7" y="1134.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -518.3392 336.9647)" width="6.9" height="1.3"/>
              </g>
              <g id="path626_2_">
                <path className="st0" d="M366.2,1136.8l6,3.5"/>
                <rect x="368.5" y="1135" transform="matrix(0.5 -0.8661 0.8661 0.5 -801.3928 889.0093)" width="1.3" height="6.9"/>
              </g>
              <g id="path628_2_">
                <path className="st0" d="M366.2,1165.8l6,3.5"/>
                <rect x="368.5" y="1164.1" transform="matrix(0.5 -0.8661 0.8661 0.5 -826.5734 903.5483)" width="1.3" height="6.9"/>
              </g>
              <g id="path630_2_">
                <path className="st0" d="M372.2,1140.2v29.1"/>
                <rect x="371.5" y="1140.2" width="1.3" height="29.1"/>
              </g>
              <g id="path632_2_">
                <path className="st0" d="M372.2,1169.3l6-3.5"/>
                <rect x="371.7" y="1166.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -533.8163 344.3389)" width="6.9" height="1.3"/>
              </g>
              <g id="path634_2_">
                <path className="st0" d="M372.2,1140.2l6-3.5"/>
                <rect x="371.7" y="1137.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -519.2695 340.4383)" width="6.9" height="1.3"/>
              </g>
              <g id="path636_2_">
                <path className="st0" d="M378.2,1136.8v29.1"/>
                <rect x="377.5" y="1136.8" width="1.3" height="29.1"/>
              </g>
              <g id="path638_2_">
                <path className="st0" d="M322.3,1162.1v29.1"/>
                <rect x="321.6" y="1162.1" width="1.3" height="29.1"/>
              </g>
              <g id="path640_2_">
                <path className="st0" d="M328.3,1158.6l-6,3.5"/>
                <rect x="321.8" y="1159.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -536.8989 318.4111)" width="6.9" height="1.3"/>
              </g>
              <g id="path642_2_">
                <path className="st0" d="M322.3,1162.1l6,3.5"/>
                <rect x="324.6" y="1160.3" transform="matrix(0.5002 -0.8659 0.8659 0.5002 -845.1978 863.392)" width="1.3" height="6.9"/>
              </g>
              <g id="path644_2_">
                <path className="st0" d="M322.3,1191.2l6,3.5"/>
                <rect x="324.6" y="1189.4" transform="matrix(0.5001 -0.866 0.866 0.5001 -870.4136 878.0707)" width="1.3" height="6.9"/>
              </g>
              <g id="path646_2_">
                <path className="st0" d="M328.3,1165.6v29.1"/>
                <rect x="327.6" y="1165.6" width="1.3" height="29.1"/>
              </g>
              <g id="path648_2_">
                <path className="st0" d="M328.3,1194.6l6-3.5"/>
                <rect x="327.8" y="1192.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -552.3765 325.7856)" width="6.9" height="1.3"/>
              </g>
              <g id="path650_2_">
                <path className="st0" d="M328.3,1165.6l6-3.5"/>
                <rect x="327.8" y="1163.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -537.8297 321.8849)" width="6.9" height="1.3"/>
              </g>
              <g id="path652_2_">
                <path className="st0" d="M334.3,1162.1v29.1"/>
                <rect x="333.6" y="1162.1" width="1.3" height="29.1"/>
              </g>
              <g id="path654_2_">
                <path className="st0" d="M334.3,1162.1l-6-3.5"/>
                <rect x="330.6" y="1156.9" transform="matrix(0.5002 -0.8659 0.8659 0.5002 -839.1873 866.8634)" width="1.3" height="6.9"/>
              </g>
              <g id="path656_2_">
                <path className="st0" d="M288.8,1181.4l-6,3.5"/>
                <rect x="282.3" y="1182.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -553.6182 301.6989)" width="6.9" height="1.3"/>
              </g>
              <g id="path658_2_">
                <path className="st0" d="M282.8,1184.9l6,3.5"/>
                <rect x="285.1" y="1183.2" transform="matrix(0.5 -0.8661 0.8661 0.5 -884.7968 840.8594)" width="1.3" height="6.9"/>
              </g>
              <g id="path660_2_">
                <path className="st0" d="M282.8,1214l6,3.5"/>
                <rect x="285.1" y="1212.2" transform="matrix(0.5002 -0.8659 0.8659 0.5002 -909.8896 855.1071)" width="1.3" height="6.9"/>
              </g>
              <g id="path662_2_">
                <path className="st0" d="M288.8,1188.4v29.1"/>
                <rect x="288.1" y="1188.4" width="1.3" height="29.1"/>
              </g>
              <g id="path664_2_">
                <path className="st0" d="M288.8,1217.4l6-3.5"/>
                <rect x="288.3" y="1215" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -569.0955 309.0732)" width="6.9" height="1.3"/>
              </g>
              <g id="path666_2_">
                <path className="st0" d="M288.8,1188.4l6-3.5"/>
                <rect x="288.3" y="1186" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -554.5483 305.1724)" width="6.9" height="1.3"/>
              </g>
              <g id="path668_2_">
                <path className="st0" d="M156.6,1170.5v29.1"/>
                <rect x="155.9" y="1170.5" width="1.3" height="29.1"/>
              </g>
              <g id="path670_2_">
                <path className="st0" d="M162.6,1167l-6,3.5"/>
                <rect x="156.1" y="1168.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -563.3276 236.6307)" width="6.9" height="1.3"/>
              </g>
              <g id="path672_2_">
                <path className="st0" d="M156.6,1170.5l6,3.5"/>
                <rect x="158.9" y="1168.7" transform="matrix(0.5 -0.8661 0.8661 0.5 -935.3988 724.3605)" width="1.3" height="6.9"/>
              </g>
              <g id="path674_2_">
                <path className="st0" d="M156.6,1199.5l6,3.5"/>
                <rect x="158.9" y="1197.8" transform="matrix(0.5 -0.8661 0.8661 0.5 -960.5803 738.8999)" width="1.3" height="6.9"/>
              </g>
              <g id="path676_2_">
                <path className="st0" d="M162.6,1173.9v29.1"/>
                <rect x="161.9" y="1173.9" width="1.3" height="29.1"/>
              </g>
              <g id="path678_2_">
                <path className="st0" d="M162.6,1203l6-3.5"/>
                <rect x="162.1" y="1200.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -578.8047 244.005)" width="6.9" height="1.3"/>
              </g>
              <g id="path680_2_">
                <path className="st0" d="M162.6,1173.9l6-3.5"/>
                <rect x="162.1" y="1171.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -564.2574 240.1043)" width="6.9" height="1.3"/>
              </g>
              <g id="path682_2_">
                <path className="st0" d="M168.6,1170.5v29.1"/>
                <rect x="167.9" y="1170.5" width="1.3" height="29.1"/>
              </g>
              <g id="path684_2_">
                <path className="st0" d="M168.6,1170.5l-6-3.5"/>
                <rect x="164.9" y="1165.3" transform="matrix(0.5 -0.8661 0.8661 0.5 -929.3866 727.8318)" width="1.3" height="6.9"/>
              </g>
              <g id="path686_2_">
                <path className="st0" d="M187.7,1214.7v29.1"/>
                <rect x="187" y="1214.7" width="1.3" height="29.1"/>
              </g>
              <g id="path688_2_">
                <path className="st0" d="M193.7,1211.2l-6,3.5"/>
                <rect x="187.2" y="1212.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -581.2637 258.1158)" width="6.9" height="1.3"/>
              </g>
              <g id="path690_2_">
                <path className="st0" d="M187.7,1214.7l6,3.5"/>
                <rect x="190" y="1212.9" transform="matrix(0.5 -0.8661 0.8661 0.5 -958.1195 773.3865)" width="1.3" height="6.9"/>
              </g>
              <g id="path692_2_">
                <path className="st0" d="M187.7,1243.7l6,3.5"/>
                <rect x="190" y="1242" transform="matrix(0.5 -0.8661 0.8661 0.5 -983.301 787.926)" width="1.3" height="6.9"/>
              </g>
              <g id="path694_2_">
                <path className="st0" d="M193.7,1218.1v29.1"/>
                <rect x="193" y="1218.1" width="1.3" height="29.1"/>
              </g>
              <g id="path696_2_">
                <path className="st0" d="M193.7,1247.2l6-3.5"/>
                <rect x="193.2" y="1244.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -596.7413 265.4902)" width="6.9" height="1.3"/>
              </g>
              <g id="path698_2_">
                <path className="st0" d="M193.7,1218.1l6-3.5"/>
                <rect x="193.2" y="1215.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -582.194 261.5894)" width="6.9" height="1.3"/>
              </g>
              <g id="path700_2_">
                <path className="st0" d="M199.7,1214.7v29.1"/>
                <rect x="199" y="1214.7" width="1.3" height="29.1"/>
              </g>
              <g id="path702_2_">
                <path className="st0" d="M199.7,1214.7l-6-3.5"/>
                <rect x="196" y="1209.5" transform="matrix(0.5002 -0.8659 0.8659 0.5002 -952.0002 776.5775)" width="1.3" height="6.9"/>
              </g>
              <g id="path704_2_">
                <path className="st0" d="M468.4,1025.4l2.8,1.6"/>
                <rect x="469.1" y="1024.6" transform="matrix(0.5 -0.8661 0.8661 0.5 -653.8502 920.0535)" width="1.3" height="3.3"/>
              </g>
              <g id="path706_2_">
                <path className="st0" d="M471.2,1027.1l7.5,4.3"/>
                <rect x="474.3" y="1024.9" transform="matrix(0.5001 -0.866 0.866 0.5001 -653.835 925.8705)" width="1.3" height="8.7"/>
              </g>
              <g id="path708_2_">
                <path className="st0" d="M427.8,1023.1v13.4"/>
                <rect x="427.1" y="1023.1" width="1.3" height="13.4"/>
              </g>
              <g id="path710_2_">
                <path className="st0" d="M427.8,1036.5v12.4"/>
                <rect x="427.1" y="1036.5" width="1.3" height="12.4"/>
              </g>
              <g id="path712_2_">
                <path className="st0" d="M490.1,1467.7v-12.4"/>
                <rect x="489.4" y="1455.4" width="1.3" height="12.4"/>
              </g>
              <g id="path714_2_">
                <path className="st0" d="M490.1,1455.4v-13.4"/>
                <rect x="489.4" y="1441.9" width="1.3" height="13.4"/>
              </g>
              <g id="path716_2_">
                <path className="st0" d="M449.5,1444.3l-2.8,1.6"/>
                <rect x="446.4" y="1444.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -662.9018 418.0302)" width="3.3" height="1.3"/>
              </g>
              <g id="path718_2_">
                <path className="st0" d="M446.6,1445.9l-7.5,4.3"/>
                <rect x="438.5" y="1447.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -665.0941 415.8387)" width="8.7" height="1.3"/>
              </g>
              <g id="path720_2_">
                <path className="st0" d="M400.5,1390.2v12.7"/>
                <rect x="399.9" y="1390.2" width="1.3" height="12.7"/>
              </g>
              <g id="path722_2_">
                <path className="st0" d="M400.5,1402.9v16.4"/>
                <rect x="399.9" y="1402.9" width="1.3" height="16.4"/>
              </g>
              <g id="path724_2_">
                <path className="st0" d="M532.6,1321l14.2,8.2"/>
                <rect x="539" y="1316.9" transform="matrix(0.5 -0.866 0.866 0.5 -877.7276 1129.9423)" width="1.3" height="16.4"/>
              </g>
              <g id="path726_2_">
                <path className="st0" d="M546.8,1329.2l76,43.9"/>
                <rect x="584.1" y="1307.2" transform="matrix(0.5 -0.866 0.866 0.5 -877.7235 1181.9985)" width="1.3" height="87.8"/>
              </g>
              <g id="path728_2_">
                <path className="st0" d="M371.8,1357.3v13.4"/>
                <rect x="371.2" y="1357.3" width="1.3" height="13.4"/>
              </g>
              <g id="path730_2_">
                <path className="st0" d="M371.8,1370.7v12.4"/>
                <rect x="371.2" y="1370.7" width="1.3" height="12.4"/>
              </g>
              <g id="path732_2_">
                <path className="st0" d="M331.2,1359.6l-2.8,1.6"/>
                <rect x="328.2" y="1359.8" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -635.7648 346.9045)" width="3.3" height="1.3"/>
              </g>
              <g id="path734_2_">
                <path className="st0" d="M328.4,1361.3l-7.5,4.3"/>
                <rect x="320.3" y="1362.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -638.5998 345.3258)" width="8.7" height="1.3"/>
              </g>
              <g id="path736_2_">
                <path className="st0" d="M371.8,1357.3l42.9,24.8"/>
                <rect x="392.6" y="1344.9" transform="matrix(0.5 -0.866 0.866 0.5 -989.5255 1025.4272)" width="1.3" height="49.5"/>
              </g>
              <g id="path738_2_">
                <path className="st0" d="M324.5,1330v9.9"/>
                <rect x="323.8" y="1330" width="1.3" height="9.9"/>
              </g>
              <g id="path740_2_">
                <path className="st0" d="M324.5,1339.9v19.1"/>
                <rect x="323.8" y="1339.9" width="1.3" height="19.1"/>
              </g>
              <g id="path742_2_">
                <path className="st0" d="M341.1,1320.4l-16.6,9.6"/>
                <rect x="323.2" y="1324.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -618.3564 344.2763)" width="19.1" height="1.3"/>
              </g>
              <g id="path744_2_">
                <path className="st0" d="M532.6,1321l14.2-8.2"/>
                <rect x="531.5" y="1316.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -586.4586 446.6747)" width="16.4" height="1.3"/>
              </g>
              <g id="path746_2_">
                <path className="st0" d="M298.9,1341v-12.4"/>
                <rect x="298.3" y="1328.6" width="1.3" height="12.4"/>
              </g>
              <g id="path748_2_">
                <path className="st0" d="M298.9,1328.6v-13.4"/>
                <rect x="298.3" y="1315.2" width="1.3" height="13.4"/>
              </g>
              <g id="path750_2_">
                <path className="st0" d="M258.3,1317.5l-2.8,1.6"/>
                <rect x="255.3" y="1317.7" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -624.4883 304.8439)" width="3.3" height="1.3"/>
              </g>
              <g id="path752_2_">
                <path className="st0" d="M255.5,1319.2l-7.5,4.3"/>
                <rect x="247.4" y="1320.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -627.3233 303.2118)" width="8.7" height="1.3"/>
              </g>
              <g id="path754_2_">
                <path className="st0" d="M322.2,1309.5l-16.6,9.6"/>
                <rect x="304.4" y="1313.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -615.4424 333.3927)" width="19.1" height="1.3"/>
              </g>
              <g id="path756_2_">
                <path className="st0" d="M324.1,1007.3l52.5,30.3"/>
                <rect x="349.7" y="992.1" transform="matrix(0.5 -0.866 0.866 0.5 -710.2623 814.6618)" width="1.3" height="60.7"/>
              </g>
              <g id="path758_2_">
                <path className="st0" d="M343,996.4l-12.6,7.3"/>
                <rect x="329.4" y="999.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -455.1651 302.5994)" width="14.5" height="1.3"/>
              </g>
              <g id="path760_2_">
                <path className="st0" d="M330.4,1003.7l-6.2,3.6"/>
                <rect x="323.6" y="1004.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -459.1494 298.6166)" width="7.2" height="1.3"/>
              </g>
              <g id="path762_2_">
                <path className="st0" d="M395.5,1026.7v14.5"/>
                <rect x="394.8" y="1026.7" width="1.3" height="14.5"/>
              </g>
              <g id="path764_2_">
                <path className="st0" d="M395.5,1041.3v7.2"/>
                <rect x="394.8" y="1041.3" width="1.3" height="7.2"/>
              </g>
              <g id="path766_2_">
                <path className="st0" d="M545,1403.5l18.8,10.9"/>
                <rect x="553.7" y="1398" transform="matrix(0.5 -0.866 0.866 0.5 -942.958 1184.6125)" width="1.3" height="21.8"/>
              </g>
              <g id="path768_2_">
                <path className="st0" d="M591.5,1453v13.4"/>
                <rect x="590.8" y="1453" width="1.3" height="13.4"/>
              </g>
              <g id="path770_1_">
                <path className="st0" d="M591.5,1466.5v12.4"/>
                <rect x="590.8" y="1466.5" width="1.3" height="12.4"/>
              </g>
              <g id="path772_1_">
                <path className="st0" d="M550.8,1455.4l-2.8,1.6"/>
                <rect x="547.8" y="1455.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -654.8513 470.2428)" width="3.3" height="1.3"/>
              </g>
              <g id="path774_1_">
                <path className="st0" d="M548,1457l-7.5,4.3"/>
                <rect x="539.9" y="1458.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -657.0436 468.0513)" width="8.7" height="1.3"/>
              </g>
              <g id="path776_1_">
                <path className="st0" d="M544.1,1425.7v21.9"/>
                <rect x="543.5" y="1425.7" width="1.3" height="21.9"/>
              </g>
              <g id="path778_1_">
                <path className="st0" d="M544.1,1447.6v7.2"/>
                <rect x="543.5" y="1447.6" width="1.3" height="7.2"/>
              </g>
              <g id="path780_1_">
                <path className="st0" d="M545,1425.2l-0.8,0.5"/>
                <rect x="544.1" y="1424.8" transform="matrix(0.8655 -0.5009 0.5009 0.8655 -640.7342 464.4295)" width="1" height="1.3"/>
              </g>
              <g id="path782_1_">
                <path className="st0" d="M318.8,1004.2l5.3,3.1"/>
                <rect x="320.8" y="1002.7" transform="matrix(0.4998 -0.8661 0.8661 0.4998 -710.3179 781.5212)" width="1.3" height="6.1"/>
              </g>
              <g id="path784_1_">
                <path className="st0" d="M426.7,1100.1l2.8,1.6"/>
                <rect x="427.5" y="1099.3" transform="matrix(0.5 -0.8661 0.8661 0.5 -739.3361 921.3118)" width="1.3" height="3.3"/>
              </g>
              <g id="path786_1_">
                <path className="st0" d="M429.6,1101.7l7.5,4.3"/>
                <rect x="432.7" y="1099.5" transform="matrix(0.5 -0.866 0.866 0.5 -739.3196 927.1886)" width="1.3" height="8.7"/>
              </g>
              <g id="path788_1_">
                <path className="st0" d="M386.1,1097.7v13.4"/>
                <rect x="385.5" y="1097.7" width="1.3" height="13.4"/>
              </g>
              <g id="path790_1_">
                <path className="st0" d="M386.1,1111.2v12.4"/>
                <rect x="385.5" y="1111.2" width="1.3" height="12.4"/>
              </g>
              <g id="path792_1_">
                <path className="st0" d="M366.2,1136.8v1.6"/>
                <rect x="365.5" y="1136.8" width="1.3" height="1.6"/>
              </g>
              <g id="path794_1_">
                <path className="st0" d="M366.2,1138.3v27.5"/>
                <rect x="365.5" y="1138.3" width="1.3" height="27.5"/>
              </g>
              <g id="path796_1_">
                <path className="st0" d="M378.2,1136.8l-4.6-2.7"/>
                <rect x="375.2" y="1132.7" transform="matrix(0.5001 -0.866 0.866 0.5001 -795.3442 893.0999)" width="1.3" height="5.4"/>
              </g>
              <g id="path798_1_">
                <path className="st0" d="M373.5,1134.1l-1.4-0.8"/>
                <rect x="372.2" y="1132.9" transform="matrix(0.5004 -0.8658 0.8658 0.5004 -795.2414 889.1409)" width="1.3" height="1.6"/>
              </g>
              <g id="path800_1_">
                <path className="st0" d="M282.8,1184.9v12"/>
                <rect x="282.1" y="1184.9" width="1.3" height="12"/>
              </g>
              <g id="path802_1_">
                <path className="st0" d="M282.8,1196.9v17"/>
                <rect x="282.1" y="1196.9" width="1.3" height="17"/>
              </g>
              <g id="path804_1_">
                <path className="st0" d="M294.8,1184.9v0.3"/>
                <rect x="294.1" y="1184.9" width="1.3" height="0.3"/>
              </g>
              <g id="path806_1_">
                <path className="st0" d="M294.8,1185.2v28.8"/>
                <rect x="294.1" y="1185.2" width="1.3" height="28.8"/>
              </g>
              <g id="path808_1_">
                <path className="st0" d="M294.8,1184.9l-2.9-1.7"/>
                <rect x="292.6" y="1182.4" transform="matrix(0.5001 -0.866 0.866 0.5001 -878.7228 845.8951)" width="1.3" height="3.4"/>
              </g>
              <g id="path810_1_">
                <path className="st0" d="M291.8,1183.2l-3.1-1.8"/>
                <rect x="289.6" y="1180.5" transform="matrix(0.5001 -0.866 0.866 0.5001 -878.7389 842.4815)" width="1.3" height="3.6"/>
              </g>
              <g id="path812_1_">
                <path className="st0" d="M322.2,1309.5l18.8,10.9"/>
                <rect x="331" y="1304.1" transform="matrix(0.5 -0.866 0.866 0.5 -972.9526 944.6812)" width="1.3" height="21.8"/>
              </g>
              <g id="path814_1_">
                <path className="st0" d="M424.7,1272.1l-18.8-10.9"/>
                <rect x="414.6" y="1255.8" transform="matrix(0.5 -0.866 0.866 0.5 -889.3661 992.9411)" width="1.3" height="21.8"/>
              </g>
              <g id="path816_1_">
                <path className="st0" d="M424.7,1272.1l-83.6,48.3"/>
                <rect x="334.6" y="1295.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -597.1733 365.4521)" width="96.5" height="1.3"/>
              </g>
              <g id="path818_1_">
                <path className="st0" d="M431.7,1246.3l-25.8,14.9"/>
                <rect x="403.8" y="1253.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -571.1138 377.7053)" width="29.8" height="1.3"/>
              </g>
              <g id="path820">
                <path className="st0" d="M405.8,1261.3l-83.6,48.3"/>
                <rect x="315.8" y="1284.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -594.2591 354.5684)" width="96.5" height="1.3"/>
              </g>
              <g id="path822_1_">
                <path className="st0" d="M616.4,1384l-6.2-3.6"/>
                <rect x="612.6" y="1378.6" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -890.4254 1222.327)" width="1.3" height="7.2"/>
              </g>
              <g id="path824_1_">
                <path className="st0" d="M610.1,1380.4l-12.6-7.3"/>
                <rect x="603.1" y="1369.5" transform="matrix(0.5 -0.866 0.866 0.5 -890.4069 1211.2748)" width="1.3" height="14.5"/>
              </g>
              <g id="path826_1_">
                <path className="st0" d="M563.8,1414.3l-18.8,10.9"/>
                <rect x="543.5" y="1419.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -635.9605 467.8405)" width="21.8" height="1.3"/>
              </g>
              <g id="path828_1_">
                <path className="st0" d="M450.5,1257.2l-18.8,10.9"/>
                <rect x="430.2" y="1262" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -572.5463 390.0701)" width="21.8" height="1.3"/>
              </g>
              <g id="path830_1_">
                <path className="st0" d="M431.7,1268.1l-7,4"/>
                <rect x="424.1" y="1269.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -578.0124 384.6062)" width="8.1" height="1.3"/>
              </g>
              <g id="path832_1_">
                <path className="st0" d="M616.4,1384l-52.5,30.3"/>
                <rect x="559.7" y="1398.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -620.8632 482.9324)" width="60.7" height="1.3"/>
              </g>
              <g id="path834_1_">
                <path className="st0" d="M670.1,1353l-47.3,27.3"/>
                <rect x="619.1" y="1366" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -597.0167 506.7708)" width="54.7" height="1.3"/>
              </g>
              <g id="path836_1_">
                <path className="st0" d="M622.8,1380.3l-6.4,3.7"/>
                <rect x="615.9" y="1381.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -608.3887 495.4026)" width="7.4" height="1.3"/>
              </g>
              <g id="path838_1_">
                <path className="st0" d="M376.7,1037.6l18.8,10.9"/>
                <rect x="385.4" y="1032.2" transform="matrix(0.5 -0.866 0.866 0.5 -710.2686 855.9012)" width="1.3" height="21.8"/>
              </g>
              <g id="path840_1_">
                <path className="st0" d="M395.5,1048.5l38,21.9"/>
                <rect x="413.8" y="1037.5" transform="matrix(0.5 -0.866 0.866 0.5 -710.266 888.6814)" width="1.3" height="43.8"/>
              </g>
            </g>
          </g>
          <g id="A1" className={'isometry__floor ' + (floor === "1" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="A4-0102" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path100_1_" className="st0" d="M446.6,1416.9l-46.1-26.6v-29.1l48.9,28.3v25.8L446.6,1416.9z"/>
              <path id="path102_1_" className="st0" d="M449.5,1415.2v-25.8l10.7-6.2v25.8L449.5,1415.2z"/>
              <path id="path104_1_" className="st0" d="M460.2,1409v-25.8l40.6,23.4l-10.7,6.2v13.4L460.2,1409z"/>
              <path id="path106_1_" className="st0" d="M490.1,1412.9l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l14.2-8.2l6.1,3.5l111.8-64.5
                l14.2,8.2l76,43.9l-12.7,7.3l-12.6-7.3l-52.5,30.3v14.5l0,0l-48.2,27.8L490.1,1412.9z"/>
              <path id="path110_1_" className="st0" d="M545,1388.9v7.2l-0.8,0.5v21.9l-47.3,27.3v-29.1L545,1388.9z"/>
              <path id="path112_1_" className="st0" d="M460.2,1409l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L460.2,1409z"/>
              <path id="path114_1_" className="st0" d="M479.7,1444.6l10.4-6v3.3l-10.4,6V1444.6z"/>
              <path id="path116_1_" className="st0" d="M439.1,1421.2l40.6,23.4v3.3l-40.6-23.4V1421.2z"/>
              <path id="path118_1_" className="st0" d="M490.1,1441.9v-29.1l6.7,3.9v29.1L490.1,1441.9z"/>
              <path id="path120_1_" className="st0" d="M610.1,1351.3l12.7-7.3v7.2l-6.4,3.7L610.1,1351.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A4-0101" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path82_1_" className="st0" d="M640.4,1481.3l-48.9-28.3V1424l48.9,28.3V1481.3z"/>
              <path id="path84_1_" className="st0" d="M640.4,1452.2l-48.9-28.3l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9l0.8-0.5l18.8-10.9v0
                l52.5-30.3l0,0l6.4-3.7l47.3-27.3l96.3,55.6L640.4,1452.2z"/>
              <path id="path86_1_" className="st0" d="M550.8,1400.5l10.7-6.2v25.8l-10.7,6.2V1400.5z"/>
              <path id="path88_1_" className="st0" d="M550.8,1400.5v25.8l-2.8,1.6l-3.9-2.2v-7.2v-21.9L550.8,1400.5z"/>
              <path id="path90_1_" className="st0" d="M550.8,1426.3l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L550.8,1426.3z"/>
              <path id="path92_1_" className="st0" d="M561.5,1420.1v-25.8l40.6,23.4l-10.7,6.2v13.4L561.5,1420.1z"/>
              <path id="path94_1_" className="st0" d="M540.5,1432.3l40.6,23.4v3.3l-40.6-23.4V1432.3z"/>
              <path id="path96_1_" className="st0" d="M581.1,1455.7l10.4-6v3.3l-10.4,6V1455.7z"/>
              <path id="path98_1_" className="st0" d="M766.4,1379.5v29.1l-126,72.7v-29.1L766.4,1379.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0103" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path24_1_" className="st0" d="M320.1,1170.5l-79.3,45.8l-31.8-18.4l7.1-4.1l-15.3-8.9l-7.1,4.1l-59.8-34.5l22.7-13.1
                l27-15.6l16.9,9.8l39.8,23l29.6-17.1l24.9,14.4l14.8,8.6l3.4,1.9L320.1,1170.5z"/>
              <path id="path28_1_" className="st0" d="M200.7,1185l15.3,8.9l-7.1,4.1v17.7l-8.3-4.8L200.7,1185z"/>
              <path id="path30_1_" className="st0" d="M200.7,1210.8l8.3,4.8v8.1l-5.2,3l-15.3-8.9l2.3-1.3l2.8-1.6L200.7,1210.8z"/>
              <path id="path32_1_" className="st0" d="M200.7,1210.8l-7.1,4.1v-25.8l7.1-4.1V1210.8z"/>
              <path id="path34_1_" className="st0" d="M188.5,1217.8l15.3,8.9v3.3l-15.3-8.9V1217.8z"/>
              <path id="path36_1_" className="st0" d="M203.9,1226.7l5.2-3v3.3l-5.2,3V1226.7z"/>
              <path id="path38_1_" className="st0" d="M190.8,1216.5l-57-32.9v-29.1l59.8,34.5v25.8L190.8,1216.5z"/>
              <path id="path40_1_" className="st0" d="M215.6,1230.8l-6.6-3.8v-29.1l31.8,18.4L215.6,1230.8z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0102" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path22_1_" className="st0" d="M240.8,1216.3l79.3-45.8l11.2-6.4l4.1-2.3l96.3,55.6l-25.8,14.9v0l-83.6,48.3l0,0l-16.6,9.6
                l-6.7-3.9l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l6.2-3.6L240.8,1216.3z"/>
              <path id="path42_1_" className="st0" d="M309.6,1279.9l-10.7,6.2v13.4l-29.9-17.3v-25.8L309.6,1279.9z"/>
              <path id="path44_1_" className="st0" d="M269,1256.5v25.8l-10.7,6.2v-25.8L269,1256.5z"/>
              <path id="path46_1_" className="st0" d="M269,1282.3l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L269,1282.3z"/>
              <path id="path48_1_" className="st0" d="M258.3,1262.7v25.8l-2.8,1.6l-46.1-26.6v-29.1L258.3,1262.7z"/>
              <path id="path50_1_" className="st0" d="M288.6,1317.9l10.4-6v3.3l-10.4,6V1317.9z"/>
              <path id="path52_1_" className="st0" d="M248,1294.5l40.6,23.4v3.3l-40.6-23.4V1294.5z"/>
              <path id="path54_1_" className="st0" d="M298.9,1315.2v-29.1l6.7,3.9v29.1L298.9,1315.2z"/>
              <path id="path56_1_" className="st0" d="M305.7,1319.1V1290l16.6-9.6v29.1L305.7,1319.1z"/>
              <path id="path60_1_" className="st0" d="M431.7,1217.3v21.8l-7,4l-18.8-10.9L431.7,1217.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0101" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path122_1_" className="st0" d="M414.7,1353l-14.2,8.2v12.7l-28.7-16.6v-3.3v-12.4v-13.4L414.7,1353z"/>
              <path id="path124_1_" className="st0" d="M531.4,1291.9l-111.8,64.5l-6.1-3.5l-42.9-24.8l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9
                l16.6-9.6l0,0l83.6-48.3l0,0l7-4l18.8-10.9l96.3,55.6L531.4,1291.9z"/>
              <path id="path132_1_" className="st0" d="M330.1,1304.8l10.7-6.2v25.8l-10.7,6.2V1304.8z"/>
              <path id="path134_1_" className="st0" d="M331.2,1304.8v25.8l-2.8,1.6l-3.9-2.2v-19.1v-9.9L331.2,1304.8z"/>
              <path id="path136_1_" className="st0" d="M331.2,1330.6l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L331.2,1330.6z"/>
              <path id="path138_1_" className="st0" d="M340.8,1324.4v-25.8l40.6,23.4l-10.7,6.2v13.4L340.8,1324.4z"/>
              <path id="path140_1_" className="st0" d="M320.9,1336.5l40.6,23.4v3.3l-40.6-23.4V1336.5z"/>
              <path id="path142_1_" className="st0" d="M361.5,1360l10.4-6v3.3l-10.4,6V1360z"/>
              <path id="path144_1_" className="st0" d="M546.8,1283.7v16.4l-14.2-8.2L546.8,1283.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0102" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path80_2_" className="st0" d="M247.9,1032.5l100.4,58l25.2,14.5l4.6,2.7l-49.9,28.8l-7.1-4.1l-15.3,8.9l7.1,4.1l-3.4,1.9
                l-58.8-34l25.2-14.5l-19.7-11.4l6.8-3.9l-51.7-29.9l16.3-9.4l14.2-8.2L247.9,1032.5z"/>
              <path id="path130_2_" className="st0" d="M309.6,1164.4v-17.1l3.4-1.9v21L309.6,1164.4z"/>
              <path id="path146_2_" className="st0" d="M313,1145.3l-7.1-4.1l15.3-8.9v25.8l-8.3,4.8V1145.3z"/>
              <path id="path148_2_" className="st0" d="M321.2,1132.4l7.1,4.1v25.8l-7.1-4.1V1132.4z"/>
              <path id="path150_2_" className="st0" d="M328.3,1136.5l49.9-28.8v29.1l-47,27.2l-2.8-1.6L328.3,1136.5z"/>
              <path id="path152_2_" className="st0" d="M313,1163l8.3-4.8l7.1,4.1l2.8,1.6l0.2,0.1l-11.2,6.4l-7.2-4.2V1163z"/>
              <path id="path130_1_" className="st0" d="M309.6,1164.4v-17.1l3.4-1.9v21L309.6,1164.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0101" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path126_1_" className="st0" d="M244.9,1109.9l-44.4,25.6l-16.9-9.8l-27,15.6l-48.7-28.1l54.1-31.3l7.1,4.1l8.3-4.8l7.1-4.1
                l-7.1-4.1l33.9-19.6l0,0l51.7,29.9l-6.8,3.9l19.7,11.4l-25.2,14.5L244.9,1109.9z"/>
              <path id="path162_1_" className="st0" d="M107.9,1113.3l48.7,28.1l-22.7,13.1v2.8l-25.9-15V1113.3z"/>
              <path id="path164_1_" className="st0" d="M177.4,1073.2l7.1,4.1l-7.1,4.1V1073.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0102" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path62_1_" className="st0" d="M386.1,1097.7l-12.6,7.3l-25.2-14.5l37.8-21.8v13.4v12.4V1097.7z"/>
              <path id="path64_1_" className="st0" d="M227.7,1027.8l91.2-52.6l5.3,3.1l0,0l52.5,30.3v0l18.8,10.9l38,21.9l-6.7,3.9L416,1039
                l-40.6,23.4l10.7,6.2l-37.8,21.8l-100.4-58l-6.1,3.5L227.7,1027.8z"/>
              <path id="path66_1_" className="st0" d="M426.7,1045.2l6.7-3.9v29.1l-3.9,2.2l-2.8-1.6L426.7,1045.2z"/>
              <path id="path68_1_" className="st0" d="M416,1039l10.7,6.2v25.8l-10.7-6.2V1039z"/>
              <path id="path70_1_" className="st0" d="M375.4,1062.5L416,1039v25.8l-29.9,17.3v-13.4L375.4,1062.5z"/>
              <path id="path72_1_" className="st0" d="M386.1,1082.1l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6V1082.1z"/>
              <path id="path74_1_" className="st0" d="M437.1,1077v3.3l-40.6,23.4v-3.3L437.1,1077z"/>
              <path id="path76_1_" className="st0" d="M386.1,1097.7v-3.3l10.4,6v3.3L386.1,1097.7z"/>
              <path id="path78_1_" className="st0" d="M227.7,1044.2v-16.4l14.2,8.2L227.7,1044.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0101" floor="1" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path160_1_" className="st0" d="M395.5,997.7L343,967.3l-12.6,7.3l-24-13.8v-29.1l114.6,66.2v29.1l-25.6-14.8V997.7z"/>
              <path id="path166_1_" className="st0" d="M397.6,879.1l114.6,66.2l-43.8,25.3l-10.7-6.2l-40.6,23.4l10.7,6.2l-6.7,3.9l-114.6-66.2
                L397.6,879.1z"/>
              <path id="path168_1_" className="st0" d="M421.1,997.9l6.7-3.9v29.1l-6.7,3.9V997.9z"/>
              <path id="path170_1_" className="st0" d="M512.2,974.3l-41,23.7l-2.8-1.6v-25.8l43.8-25.3V974.3z"/>
              <path id="path172_1_" className="st0" d="M427.8,994l-10.7-6.2l40.6-23.4v25.8l-29.9,17.3V994z"/>
              <path id="path174_1_" className="st0" d="M427.8,1007.4l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6L427.8,1007.4z"/>
              <path id="path176_1_" className="st0" d="M457.7,964.4l10.7,6.2v25.8l-10.7-6.2V964.4z"/>
              <path id="path178_1_" className="st0" d="M427.8,1023.1v-3.3l10.4,6v3.3L427.8,1023.1z"/>
              <path id="path180_1_" className="st0" d="M438.1,1029.1v-3.3l40.6-23.4v3.3L438.1,1029.1z"/>
            </ModIsometrySvgFlat>
            <g id="A1-Areas" className="isometry__areas">
              <path id="path26_1_" className="st0" d="M269.8,1141.4l-29.6,17.1l-39.8-23l44.4-25.6l5.9,3.4l58.8,34l-14.8,8.6L269.8,1141.4z"/>
              <path id="path58_1_" className="st0" d="M322.2,1309.5v-29.1l18.8,10.9l-16.6,9.6v9.9L322.2,1309.5z"/>
              <path id="path108_1_" className="st0" d="M545,1388.9L545,1388.9v-14.5l18.8,10.9l-18.8,10.9V1388.9z"/>
              <path id="path128_1_" className="st0" d="M294.8,1155.8l14.8-8.6v17.1L294.8,1155.8z"/>
              <path id="path154_1_" className="st0" d="M616.4,1354.9l-52.5,30.3l-18.8-10.9l52.5-30.3l12.6,7.3L616.4,1354.9z"/>
              <path id="path156_1_" className="st0" d="M324.1,978.2L324.1,978.2l6.2-3.6l12.6-7.3l52.5,30.3l-18.8,10.9L324.1,978.2z"/>
              <path id="path158_1_" className="st0" d="M376.7,1008.5L376.7,1008.5l18.8-10.9v21.8L376.7,1008.5z"/>
              <path id="path182_1_" className="st0" d="M322.2,1280.4l83.6-48.3l18.8,10.9l-83.6,48.3L322.2,1280.4z"/>
            </g>
            <g id="A1-Strokes" className="isometry__strokes">
              <path className="st2" d="M767.1,1379.5h-0.7l0.3-0.6l-96.3-55.6l-0.3,0.6l-0.3-0.6l-46.3,26.7v-6.1h-0.7l0.3-0.6l-75.7-43.7v-16h-0.7
                l0.3-0.6l-96.3-55.6l-0.3,0.6l-0.3-0.6l-17.8,10.3v-20.6h-0.7l0.3-0.6l-95.5-55.1l42-24.2l-0.3-0.6h0.7v-29.1h-0.7l0.3-0.6
                l-3.6-2.1l11.3-6.5l10,5.8l0.3-0.6l0.3,0.6l40.6-23.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-6.5-3.8l2.9-1.7l-0.3-0.6h0.7v-29.1h-0.7
                l0.3-0.6l-37.6-21.7v-5.7l24.6,14.2l0.3-0.6l0.3,0.6l6.4-3.7l10,5.8l0.3-0.6l0.3,0.6l40.6-23.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6
                l-6.5-3.8l40-23.1l-0.3-0.6h0.7v-29.1h-0.7l0.3-0.6l-114.6-66.2l-0.3,0.6l-0.3-0.6l-91.2,52.6l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6
                l23,13.3l-4.9,2.8l-5-2.9l-0.3,0.6l-0.3-0.6l-91.2,52.6l0.3,0.6H227v16l-16,9.2l0.3,0.6l0,0l-0.3-0.6l-33.9,19.6l0.3,0.6h-0.7
                v7.8l-7.6,4.4l-6.7-3.9l-0.3,0.6l-0.3-0.6l-54.1,31.3l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6l25.6,14.8v25.8h0.7l-0.3,0.6l56,32.3
                l-1.3,0.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l15.3,8.9l0.3-0.6l0.3,0.6l4.8-2.8l5.3,3.1l-5.2,3l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6
                l45.1,26l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l6.4,3.7l0.3-0.6l0.3,0.6l16.2-9.4l1.6,0.9
                v18.7h0.7l-0.3,0.6l2.9,1.7l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l28,16.2v16h0.7l-0.3,0.6
                l45.1,26l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l6.4,3.7l0.3-0.6l0.3,0.6l46.3-26.7v6.1h0.7
                l-0.3,0.6l2.9,1.7l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l48.6,28.1l0.3-0.6l0.3,0.6l126-72.7
                l-0.3-0.6h0.7V1379.5z M178.1,1080.2v-5.8l5.1,2.9L178.1,1080.2z M168.8,1086.7l0.3-0.6l0.3,0.6l8.3-4.8l7.1-4.1l-0.3-0.6
                l0.3-0.6l-6.1-3.5l32.6-18.8l50.4,29.1l-5.8,3.4l0.3,0.6l-0.3,0.6l18.7,10.8l-23.8,13.8l-5.5-3.2l-0.3,0.6l-0.3-0.6l-44.1,25.4
                l-16.6-9.6l-0.3,0.6l-0.3-0.6l-26.7,15.4l-47.3-27.3l52.8-30.5L168.8,1086.7z M318.8,975.9l5,2.9l0,0l52.5,30.3l0.3-0.6l0,0
                l-0.3,0.6l18.8,10.9l37,21.3l-5.4,3.1l-10.4-6l-0.3,0.6l-0.3-0.6l-40.6,23.4l0.3,0.6l-0.3,0.6l9.7,5.6l-36.4,21l-100.1-57.8
                l-0.3,0.6l-0.3-0.6l-5.7,3.3l-12.9-7.4L318.8,975.9z M421.7,998.3l5.4-3.1v12.3v12.4v2.9l-5.4,3.1V998.3z M418.4,987.8l38.6-22.3
                v24.3l-28.6,16.5V994h-0.7l0.3-0.6L418.4,987.8z M467.7,995.2l-9.4-5.4v-24.3l9.4,5.4V995.2z M428.4,1020.9l9,5.2v1.7l-9-5.2
                V1020.9z M478.1,1005.2l-39.3,22.7v-1.7l39.3-22.7V1005.2z M438.1,1025l-9.7-5.6v-11.6l29.2-16.9l10.4,6l2.8,1.6l6.5,3.8
                L438.1,1025z M471.2,997.2l-2.2-1.3v-25l42.5-24.5v27.5L471.2,997.2z M510.9,945.2l-42.5,24.5l-10.4-6l-0.3,0.6l-0.3-0.6
                l-40.6,23.4l0.3,0.6l-0.3,0.6l9.7,5.6l-5.4,3.1l-113.3-65.4l89.8-51.9L510.9,945.2z M307.1,932.8l113.3,65.4v27.5l-24.2-14v-14.2
                h-0.7l0.3-0.6l-52.5-30.3l-0.3,0.6l-0.3-0.6l-12.3,7.1l-23.3-13.4V932.8z M432.8,1070l-3.2,1.9l-2.2-1.3v-25l5.4-3.1V1070z
                  M377.5,1136.4l-46.4,26.8l-2.2-1.3v-25l48.6-28V1136.4z M240.8,1215.5l-30.5-17.6l6.1-3.5l-0.3-0.6l0.3-0.6l-15.3-8.9l-0.3,0.6
                l-0.3-0.6l-6.7,3.9l-58.5-33.8l21.7-12.5l26.7-15.4l16.6,9.6l39.8,23l0.3-0.6l0.3,0.6l29.2-16.9l24.6,14.2l14.8,8.6l3.4,1.9
                l6.2,3.6L240.8,1215.5z M208.3,1197.9v16.5l-6.9-4v-24.3l13.3,7.7l-6.1,3.5l0.3,0.6H208.3z M308.9,1163.2l-12.8-7.4l12.8-7.4
                V1163.2z M327.6,1161.1l-5.7-3.3v-24.3l5.7,3.3V1161.1z M320.6,1157.8l-7,4v-16.5H313l0.3-0.6l-6.1-3.5l13.3-7.7V1157.8z
                  M312.3,1163v2.2l-2-1.2v-16.3l2-1.2V1163z M320.1,1169.7l-6.5-3.8v-2.6l7.6-4.4l6.7,3.9l2,1.2L320.1,1169.7z M250.4,1113.9
                l57.8,33.4l-13.5,7.8l-24.6-14.2l-0.3,0.6l-0.3-0.6l-29.2,16.9l-38.4-22.2l43.1-24.9L250.4,1113.9z M194.3,1189.4l5.7-3.3v24.3
                l-5.7,3.3V1189.4z M204.5,1227.1l3.8-2.2v1.7l-3.8,2.2V1227.1z M328.3,1135.7l-6.7-3.9l-0.3,0.6l-0.3-0.6l-15.3,8.9l0.3,0.6
                l-0.3,0.6l6.1,3.5l-2,1.2l-57.5-33.2l24.2-14l-0.3-0.6l0.3-0.6l-18.7-10.8l5.8-3.4l-0.3-0.6l0.3-0.6l-50.7-29.3l15.3-8.9
                l14.2-8.2l0,0l5.7-3.3L348,1091l25.2,14.5l3.6,2.1L328.3,1135.7z M426.1,1069.8l-9.4-5.4v-24.3l9.4,5.4V1069.8z M415.4,1064.4
                l-28.6,16.5v-12.3h-0.7l0.3-0.6l-9.7-5.6l38.6-22.3V1064.4z M386.8,1095.6l9,5.2v1.7l-9-5.2V1095.6z M373.5,1104.2l-23.8-13.8
                l35.8-20.7v12.3v12.4v2.9L373.5,1104.2z M436.4,1079.9l-39.3,22.7v-1.7l39.3-22.7V1079.9z M396.5,1099.7l-9.7-5.6v-11.6
                l29.2-16.9l10.4,6l2.8,1.6l6.5,3.8L396.5,1099.7z M378,1008.5l16.8-9.7v13.4v6.1L378,1008.5z M330.7,975.2l12.3-7.1l51.2,29.6
                l-17.5,10.1l-51.2-29.6L330.7,975.2z M240.5,1036l-12.2,7v-14.1L240.5,1036z M155.2,1141.4l-21.7,12.5l0.3,0.6h-0.7v1.7
                l-24.6-14.2v-27.5L155.2,1141.4z M134.5,1155.7l58.5,33.8v25l-2.2,1.3l-56.3-32.5v-25.8V1155.7z M191.2,1217.1L191.2,1217.1
                l2.8-1.6l-0.3-0.6l0.3,0.6l6.7-3.9l7.6,4.4v7.4l-4.5,2.6l-14-8.1L191.2,1217.1z M203.2,1227.1v1.7l-14-8.1v-1.7L203.2,1227.1z
                  M209.7,1223.7v-8.1v-16.5l29.8,17.2l-23.8,13.8l-6-3.4V1223.7z M216,1231.4l25.2-14.5l79.3-45.8l11.2-6.4l3.7-2.2l94.9,54.8
                l-24.8,14.3l0,0l-83.6,48.3l-16.2,9.4l-5.4-3.1l9.7-5.6l-0.3-0.6l0.3-0.6l-40.6-23.4l-0.3,0.6l-0.3-0.6l-10.4,6l-47.6-27.5
                L216,1231.4z M450.5,1228.9l94.9,54.8l-13.2,7.6l-111.5,64.4l-5.7-3.3l-41.9-24.2l9.7-5.6l-0.3-0.6l0.3-0.6l-40.6-23.4l-0.3,0.6
                l-0.3-0.6l-10.4,6l-5.4-3.1l15.6-9l83.6-48.3l7-4L450.5,1228.9z M670.1,1324.7l94.9,54.8l-124.7,72l-47.6-27.5l9.7-5.6l-0.3-0.6
                l0.3-0.6l-40.6-23.4l-0.3,0.6l-0.3-0.6l-10.4,6l-5.4-3.1l18.7-10.8l0,0l52.5-30.3l6.4-3.7L670.1,1324.7z M298.3,1286.1v12.3
                l-28.6-16.5v-24.3l38.6,22.3l-9.7,5.6l0.3,0.6H298.3z M259,1263.1l9.4-5.4v24.3l-9.4,5.4V1263.1z M289.2,1318.3l9-5.2v1.7l-9,5.2
                V1318.3z M341.1,1290.5l-17.5-10.1l82.3-47.5l17.5,10.1L341.1,1290.5z M306.3,1290.4l15.9-9.2l17.5,10.1l-15.6,9l0.3,0.6h-0.7
                v8.8l-1.3-0.7l-0.3,0.6l-0.3-0.6l-15.6,9V1290.4z M331.9,1305.1l9.4-5.4v24.3l-9.4,5.4V1305.1z M342.6,1299.7l38.6,22.3l-9.7,5.6
                l0.3,0.6h-0.7v12.3l-28.6-16.5V1299.7z M372.5,1354v-12.4v-12.3l40.9,23.6l-13.2,7.6l0.3,0.6h-0.7v11.5l-27.4-15.8V1354z
                  M460.8,1384.4l38.6,22.3l-9.7,5.6l0.3,0.6h-0.7v12.3l-28.6-16.5V1384.4z M450.1,1389.8l9.4-5.4v24.3l-9.4,5.4V1389.8z
                  M480.4,1445l9-5.2v1.7l-9,5.2V1445z M490.7,1438.7v-12.4V1414l5.4,3.1v27.5l-5.4-3.1V1438.7z M609.8,1351.9l5.2,3l-51.2,29.6
                l-17.5-10.1l51.2-29.6L609.8,1351.9z M551.5,1400.9l9.4-5.4v24.3l-9.4,5.4V1400.9z M562.2,1395.5l38.6,22.3l-9.7,5.6l0.3,0.6
                h-0.7v12.3l-28.6-16.5V1395.5z M592.1,1449.8v-12.4v-12.3l47.6,27.5v27.5l-47.6-27.5V1449.8z M622.1,1350.8l-5.8,3.3l-4.9-2.8
                l10.7-6.2V1350.8z M610.1,1350.6l-12.3-7.1l-0.3,0.6l-0.3-0.6l-52.5,30.3l0.3,0.6h-0.7v14.2l-47.5,27.4l-5.4-3.1l9.7-5.6
                l-0.3-0.6l0.3-0.6l-40.6-23.4l-0.3,0.6l-0.3-0.6l-10.4,6l-47.6-27.5l12.9-7.4l5.7,3.3l0.3-0.6l0.3,0.6l111.5-64.4l13.9,8l75,43.3
                L610.1,1350.6z M546.1,1299l-12.2-7l12.2-7V1299z M431,1238.6l-6.3,3.7l-17.5-10.1l23.8-13.8V1238.6z M210.1,1235.6l47.6,27.5v25
                l-2.2,1.3l-45.4-26.2V1235.6z M255.8,1290.7L255.8,1290.7l2.8-1.6l-0.3-0.6l0.3,0.6l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7
                L255.8,1290.7z M287.9,1318.3v1.7l-39.3-22.7v-1.7L287.9,1318.3z M299.6,1314.8v-2.9v-12.4v-12.3l5.4,3.1v27.5L299.6,1314.8z
                  M325.2,1310.8v-8.8l5.4,3.1v25l-2.2,1.3l-3.2-1.9V1310.8z M328.7,1332.8L328.7,1332.8l2.8-1.6l-0.3-0.6l0.3,0.6l10.4-6
                l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L328.7,1332.8z M360.8,1360.4v1.7l-39.3-22.7v-1.7L360.8,1360.4z M362.1,1360.4l9-5.2v1.7
                l-9,5.2V1360.4z M401.2,1373.8v-11.5l47.6,27.5v25l-2.2,1.3l-45.4-26.2V1373.8z M447,1417.4L447,1417.4l2.8-1.6l-0.3-0.6l0.3,0.6
                l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L447,1417.4z M439.8,1424.1v-1.7L479,1445v1.7L439.8,1424.1z M497.5,1417.1l47.8-27.6
                l-0.3-0.6h0.7v-13.4l16.8,9.7l-17.8,10.3l0.3,0.6l-0.3-0.6l-0.8,0.5l0.3,0.6h-0.7v21.5l-46,26.6V1417.1z M544.8,1418.5v-20.7
                l5.4,3.1v25l-2.2,1.3l-3.2-1.9V1418.5z M548.3,1428.5l2.8-1.6l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L548.3,1428.5z
                  M580.4,1456.1v1.7l-39.3-22.7v-1.7L580.4,1456.1z M581.8,1456.1l9-5.2v1.7l-9,5.2V1456.1z M641.1,1452.6l124.7-72v27.5
                l-124.7,72V1452.6z"/>
            </g>
          </g>
          <g id="A2" className={'isometry__floor ' + (floor === "2" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="A4-0202" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path100_3_" className="st0" d="M446.6,1387.8l-46.1-26.6v-29.1l48.9,28.3v25.8L446.6,1387.8z"/>
              <path id="path102_3_" className="st0" d="M449.5,1386.1v-25.8l10.7-6.2v25.8L449.5,1386.1z"/>
              <path id="path104_3_" className="st0" d="M460.2,1380v-25.8l40.6,23.4l-10.7,6.2v13.4L460.2,1380z"/>
              <path id="path106_3_" className="st0" d="M490.1,1383.8l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l14.2-8.2l6.1,3.5l111.8-64.5
                l14.2,8.2l76,43.9l-12.7,7.3l-12.6-7.3l-52.5,30.3v14.5l0,0l-48.2,27.8L490.1,1383.8z"/>
              <path id="path110_3_" className="st0" d="M545,1359.9v7.2l-0.8,0.5v21.9l-47.3,27.3v-29.1L545,1359.9z"/>
              <path id="path112_3_" className="st0" d="M460.2,1380l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L460.2,1380z"/>
              <path id="path114_3_" className="st0" d="M479.7,1415.6l10.4-6v3.3l-10.4,6V1415.6z"/>
              <path id="path116_3_" className="st0" d="M439.1,1392.1l40.6,23.4v3.3l-40.6-23.4V1392.1z"/>
              <path id="path118_3_" className="st0" d="M490.1,1412.9v-29.1l6.7,3.9v29.1L490.1,1412.9z"/>
              <path id="path120_3_" className="st0" d="M610.1,1322.2l12.7-7.3v7.2l-6.4,3.7L610.1,1322.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A4-0201" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path82_3_" className="st0" d="M640.4,1452.2l-48.9-28.3v-29.1l48.9,28.3V1452.2z"/>
              <path id="path84_3_" className="st0" d="M640.4,1423.1l-48.9-28.3l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9l0.8-0.5l18.8-10.9v0
                l52.5-30.3l0,0l6.4-3.7l47.3-27.3l96.3,55.6L640.4,1423.1z"/>
              <path id="path86_3_" className="st0" d="M550.8,1371.4l10.7-6.2v25.8l-10.7,6.2V1371.4z"/>
              <path id="path88_3_" className="st0" d="M550.8,1371.4v25.8l-2.8,1.6l-3.9-2.2v-7.2v-21.9L550.8,1371.4z"/>
              <path id="path90_3_" className="st0" d="M550.8,1397.2l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L550.8,1397.2z"/>
              <path id="path92_3_" className="st0" d="M561.5,1391.1v-25.8l40.6,23.4l-10.7,6.2v13.4L561.5,1391.1z"/>
              <path id="path94_3_" className="st0" d="M540.5,1403.2l40.6,23.4v3.3l-40.6-23.4V1403.2z"/>
              <path id="path96_3_" className="st0" d="M581.1,1426.7l10.4-6v3.3l-10.4,6V1426.7z"/>
              <path id="path98_3_" className="st0" d="M766.4,1350.4v29.1l-126,72.7v-29.1L766.4,1350.4z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0203" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path24_3_" className="st0" d="M320.1,1141.4l-79.3,45.8l-31.8-18.4l7.1-4.1l-15.3-8.9l-7.1,4.1l-59.8-34.5l22.7-13.1
                l27-15.6l16.9,9.8l39.8,23l29.6-17.1l24.9,14.4l14.8,8.6l3.4,1.9L320.1,1141.4z"/>
              <path id="path28_3_" className="st0" d="M200.7,1155.9l15.3,8.9l-7.1,4.1v17.7l-8.3-4.8L200.7,1155.9z"/>
              <path id="path30_3_" className="st0" d="M200.7,1181.7l8.3,4.8v8.1l-5.2,3l-15.3-8.9l2.3-1.3l2.8-1.6L200.7,1181.7z"/>
              <path id="path32_3_" className="st0" d="M200.7,1181.7l-7.1,4.1V1160l7.1-4.1V1181.7z"/>
              <path id="path34_3_" className="st0" d="M188.5,1188.8l15.3,8.9v3.3l-15.3-8.9V1188.8z"/>
              <path id="path36_3_" className="st0" d="M203.9,1197.6l5.2-3v3.3l-5.2,3V1197.6z"/>
              <path id="path38_3_" className="st0" d="M190.8,1187.4l-57-32.9v-29.1l59.8,34.5v25.8L190.8,1187.4z"/>
              <path id="path40_3_" className="st0" d="M215.6,1201.7l-6.6-3.8v-29.1l31.8,18.4L215.6,1201.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0202" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path22_3_" className="st0" d="M240.8,1187.2l79.3-45.8l11.2-6.4l4.1-2.3l96.3,55.6l-25.8,14.9v0l-83.6,48.3l0,0l-16.6,9.6
                l-6.7-3.9l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l6.2-3.6L240.8,1187.2z"/>
              <path id="path42_3_" className="st0" d="M309.6,1250.9l-10.7,6.2v13.4l-29.9-17.3v-25.8L309.6,1250.9z"/>
              <path id="path44_3_" className="st0" d="M269,1227.4v25.8l-10.7,6.2v-25.8L269,1227.4z"/>
              <path id="path46_3_" className="st0" d="M269,1253.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L269,1253.2z"/>
              <path id="path48_3_" className="st0" d="M258.3,1233.6v25.8l-2.8,1.6l-46.1-26.6v-29.1L258.3,1233.6z"/>
              <path id="path50_3_" className="st0" d="M288.6,1288.8l10.4-6v3.3l-10.4,6V1288.8z"/>
              <path id="path52_3_" className="st0" d="M248,1265.4l40.6,23.4v3.3l-40.6-23.4V1265.4z"/>
              <path id="path54_3_" className="st0" d="M298.9,1286.1V1257l6.7,3.9v29.1L298.9,1286.1z"/>
              <path id="path56_3_" className="st0" d="M305.7,1290v-29.1l16.6-9.6v29.1L305.7,1290z"/>
              <path id="path60_3_" className="st0" d="M431.7,1188.2v21.8l-7,4l-18.8-10.9L431.7,1188.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0201" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path122_3_" className="st0" d="M414.7,1323.9l-14.2,8.2v12.7l-28.7-16.6v-3.3v-12.4v-13.4L414.7,1323.9z"/>
              <path id="path124_3_" className="st0" d="M531.4,1262.8l-111.8,64.5l-6.1-3.5l-42.9-24.8l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9
                l16.6-9.6l0,0l83.6-48.3l0,0l7-4l18.8-10.9l96.3,55.6L531.4,1262.8z"/>
              <path id="path132_3_" className="st0" d="M330.1,1275.7l10.7-6.2v25.8l-10.7,6.2V1275.7z"/>
              <path id="path134_3_" className="st0" d="M331.2,1275.7v25.8l-2.8,1.6l-3.9-2.2v-19.1v-9.9L331.2,1275.7z"/>
              <path id="path136_3_" className="st0" d="M331.2,1301.5l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L331.2,1301.5z"/>
              <path id="path138_3_" className="st0" d="M340.8,1295.3v-25.8l40.6,23.4l-10.7,6.2v13.4L340.8,1295.3z"/>
              <path id="path140_3_" className="st0" d="M320.9,1307.5l40.6,23.4v3.3l-40.6-23.4V1307.5z"/>
              <path id="path142_3_" className="st0" d="M361.5,1330.9l10.4-6v3.3l-10.4,6V1330.9z"/>
              <path id="path144_3_" className="st0" d="M546.8,1254.6v16.4l-14.2-8.2L546.8,1254.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0202" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path80_3_" className="st0" d="M247.9,1003.4l100.4,58l25.2,14.5l4.6,2.7l-49.9,28.8l-7.1-4.1l-15.3,8.9l7.1,4.1l-3.4,1.9
                l-58.8-34l25.2-14.5l-19.7-11.4l6.8-3.9l-51.7-29.9l16.3-9.4l14.2-8.2L247.9,1003.4z"/>
              <path id="path130_5_" className="st0" d="M309.6,1135.3v-17.1l3.4-1.9v21L309.6,1135.3z"/>
              <path id="path146_3_" className="st0" d="M313,1116.3l-7.1-4.1l15.3-8.9v25.8l-8.3,4.8V1116.3z"/>
              <path id="path148_3_" className="st0" d="M321.2,1103.3l7.1,4.1v25.8l-7.1-4.1V1103.3z"/>
              <path id="path150_3_" className="st0" d="M328.3,1107.4l49.9-28.8v29.1l-47,27.2l-2.8-1.6L328.3,1107.4z"/>
              <path id="path152_3_" className="st0" d="M313,1133.9l8.3-4.8l7.1,4.1l2.8,1.6l0.2,0.1l-11.2,6.4l-7.2-4.2V1133.9z"/>
              <path id="path130_4_" className="st0" d="M309.6,1135.3v-17.1l3.4-1.9v21L309.6,1135.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0201" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path126_3_" className="st0" d="M244.9,1080.8l-44.4,25.6l-16.9-9.8l-27,15.6l-48.7-28.1l54.1-31.3l7.1,4.1l8.3-4.8l7.1-4.1
                l-7.1-4.1l33.9-19.6l0,0l51.7,29.9l-6.8,3.9l19.7,11.4l-25.2,14.5L244.9,1080.8z"/>
              <path id="path162_3_" className="st0" d="M107.9,1084.2l48.7,28.1l-22.7,13.1v2.8l-25.9-15V1084.2z"/>
              <path id="path164_3_" className="st0" d="M177.4,1044.1l7.1,4.1l-7.1,4.1V1044.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0202" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path62_3_" className="st0" d="M386.1,1068.7l-12.6,7.3l-25.2-14.5l37.8-21.8v13.4v12.4V1068.7z"/>
              <path id="path64_3_" className="st0" d="M227.7,998.7l91.2-52.6l5.3,3.1l0,0l52.5,30.3v0l18.8,10.9l38,21.9l-6.7,3.9L416,1010
                l-40.6,23.4l10.7,6.2l-37.8,21.8l-100.4-58l-6.1,3.5L227.7,998.7z"/>
              <path id="path66_3_" className="st0" d="M426.7,1016.1l6.7-3.9v29.1l-3.9,2.2l-2.8-1.6L426.7,1016.1z"/>
              <path id="path68_3_" className="st0" d="M416,1010l10.7,6.2v25.8l-10.7-6.2V1010z"/>
              <path id="path70_3_" className="st0" d="M375.4,1033.4L416,1010v25.8l-29.9,17.3v-13.4L375.4,1033.4z"/>
              <path id="path72_3_" className="st0" d="M386.1,1053l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6V1053z"/>
              <path id="path74_3_" className="st0" d="M437.1,1047.9v3.3l-40.6,23.4v-3.3L437.1,1047.9z"/>
              <path id="path76_3_" className="st0" d="M386.1,1068.7v-3.3l10.4,6v3.3L386.1,1068.7z"/>
              <path id="path78_3_" className="st0" d="M227.7,1015.1v-16.4l14.2,8.2L227.7,1015.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0201" floor="2" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path160_3_" className="st0" d="M395.5,968.6L343,938.2l-12.6,7.3l-24-13.8v-29.1l114.6,66.2v29.1l-25.6-14.8V968.6z"/>
              <path id="path166_3_" className="st0" d="M397.6,850l114.6,66.2l-43.8,25.3l-10.7-6.2l-40.6,23.4l10.7,6.2l-6.7,3.9l-114.6-66.2
                L397.6,850z"/>
              <path id="path168_3_" className="st0" d="M421.1,968.8l6.7-3.9V994l-6.7,3.9V968.8z"/>
              <path id="path170_3_" className="st0" d="M512.2,945.2l-41,23.7l-2.8-1.6v-25.8l43.8-25.3V945.2z"/>
              <path id="path172_3_" className="st0" d="M427.8,964.9l-10.7-6.2l40.6-23.4v25.8l-29.9,17.3V964.9z"/>
              <path id="path174_3_" className="st0" d="M427.8,978.4l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6L427.8,978.4z"/>
              <path id="path176_3_" className="st0" d="M457.7,935.3l10.7,6.2v25.8l-10.7-6.2V935.3z"/>
              <path id="path178_3_" className="st0" d="M427.8,994v-3.3l10.4,6v3.3L427.8,994z"/>
              <path id="path180_3_" className="st0" d="M438.1,1000v-3.3l40.6-23.4v3.3L438.1,1000z"/>
            </ModIsometrySvgFlat>
            <g id="A1-Areas" className="isometry__areas">
              <path id="path26_3_" className="st0" d="M269.8,1112.3l-29.6,17.1l-39.8-23l44.4-25.6l5.9,3.4l58.8,34l-14.8,8.6L269.8,1112.3z"/>
              <path id="path58_3_" className="st0" d="M322.2,1280.4v-29.1l18.8,10.9l-16.6,9.6v9.9L322.2,1280.4z"/>
              <path id="path108_3_" className="st0" d="M545,1359.9L545,1359.9v-14.5l18.8,10.9l-18.8,10.9V1359.9z"/>
              <path id="path128_3_" className="st0" d="M294.8,1126.8l14.8-8.6v17.1L294.8,1126.8z"/>
              <path id="path154_3_" className="st0" d="M616.4,1325.9l-52.5,30.3l-18.8-10.9l52.5-30.3l12.6,7.3L616.4,1325.9z"/>
              <path id="path156_3_" className="st0" d="M324.1,949.1L324.1,949.1l6.2-3.6l12.6-7.3l52.5,30.3l-18.8,10.9L324.1,949.1z"/>
              <path id="path158_3_" className="st0" d="M376.7,979.5L376.7,979.5l18.8-10.9v21.8L376.7,979.5z"/>
              <path id="path182_3_" className="st0" d="M322.2,1251.4l83.6-48.3l18.8,10.9l-83.6,48.3L322.2,1251.4z"/>
            </g>
            <g id="A2-Stroke" className="isometry__strokes">
              <g id="path184_3_">
                <path className="st0" d="M240.8,1187.2l79.3-45.8"/>

                  <rect x="234.7" y="1163.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -544.8866 296.5274)" className="st2" width="91.6" height="1.3"/>
              </g>
              <g id="path186_3_">
                <path className="st0" d="M563.8,1356.2l52.5-30.3"/>

                  <rect x="559.7" y="1340.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -591.7698 475.1312)" className="st2" width="60.7" height="1.3"/>
              </g>
              <g id="path188_3_">
                <path className="st0" d="M341.1,1262.2l83.6-48.3"/>

                  <rect x="334.6" y="1237.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -568.0797 357.6508)" className="st2" width="96.5" height="1.3"/>
              </g>
              <g id="path190_3_">
                <path className="st0" d="M341.1,1262.2L341.1,1262.2"/>
                <path className="st2" d="M341.1,1262.2"/>
              </g>
              <g id="path192_3_">
                <path className="st0" d="M424.7,1214L424.7,1214"/>
                <path className="st2" d="M424.7,1214"/>
              </g>
              <g id="path194_3_">
                <path className="st0" d="M193.7,1185.8l7.1-4.1"/>

                  <rect x="193.1" y="1183.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -565.7866 257.4691)" className="st2" width="8.1" height="1.3"/>
              </g>
              <g id="path196_3_">
                <path className="st0" d="M188.5,1188.8l15.3,8.9"/>

                  <rect x="195.5" y="1184.3" transform="matrix(0.5 -0.866 0.866 0.5 -935.2451 766.524)" className="st2" width="1.3" height="17.7"/>
              </g>
              <g id="path198_3_">
                <path className="st0" d="M209,1194.6l-5.2,3"/>

                  <rect x="203.5" y="1195.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -570.7418 263.7482)" className="st2" width="6" height="1.3"/>
              </g>
              <g id="path200_3_">
                <path className="st0" d="M200.7,1181.7l8.3,4.8"/>

                  <rect x="204.2" y="1179.3" transform="matrix(0.5001 -0.866 0.866 0.5001 -922.9779 769.3508)" className="st2" width="1.3" height="9.6"/>
              </g>
              <g id="path202_3_">
                <path className="st0" d="M240.3,1129.4l29.6-17.1"/>

                  <rect x="238" y="1120.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -526.5814 277.9754)" className="st2" width="34.2" height="1.3"/>
              </g>
              <g id="path204_3_">
                <path className="st0" d="M200.7,1155.9v25.8"/>
                <rect x="200.1" y="1155.9" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path206_3_">
                <path className="st0" d="M216.1,1164.8l-15.3-8.9"/>

                  <rect x="207.7" y="1151.5" transform="matrix(0.5 -0.866 0.866 0.5 -900.6924 760.6759)" className="st2" width="1.3" height="17.7"/>
              </g>
              <g id="path208_3_">
                <path className="st0" d="M190.8,1187.4l-57-32.9"/>

                  <rect x="161.7" y="1138.1" transform="matrix(0.5 -0.866 0.866 0.5 -932.9147 726.0753)" className="st2" width="1.3" height="65.8"/>
              </g>
              <g id="path210_3_">
                <path className="st0" d="M203.9,1200.9l-15.3-8.9"/>

                  <rect x="195.5" y="1187.6" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -938.106 768.2181)" className="st2" width="1.3" height="17.7"/>
              </g>
              <g id="path212_3_">
                <path className="st0" d="M215.6,1201.7l-6.6-3.8"/>

                  <rect x="211.7" y="1196" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -932.9437 783.8679)" className="st2" width="1.3" height="7.7"/>
              </g>
              <g id="path214_3_">
                <path className="st0" d="M209,1197.9l-5.2,3"/>

                  <rect x="203.5" y="1198.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -572.3827 264.1882)" className="st2" width="6" height="1.3"/>
              </g>
              <g id="path216_3_">
                <path className="st0" d="M193.7,1160l-59.8-34.5"/>

                  <rect x="163.1" y="1108.2" transform="matrix(0.5 -0.866 0.866 0.5 -907.7288 713.163)" className="st2" width="1.3" height="69.1"/>
              </g>
              <g id="path218_3_">
                <path className="st0" d="M209,1168.8l7.1-4.1"/>

                  <rect x="208.5" y="1166.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -555.2527 262.8694)" className="st2" width="8.1" height="1.3"/>
              </g>
              <g id="path220_3_">
                <path className="st0" d="M193.7,1160v25.8"/>
                <rect x="193" y="1160" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path222_3_">
                <path className="st0" d="M193.7,1160l7.1-4.1"/>

                  <rect x="193.1" y="1157.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -552.8802 254.0084)" className="st2" width="8.1" height="1.3"/>
              </g>
              <g id="path224_3_">
                <path className="st0" d="M240.8,1187.2l-31.8-18.4"/>

                  <rect x="224.2" y="1159.7" transform="matrix(0.5 -0.866 0.866 0.5 -907.7291 783.7724)" className="st2" width="1.3" height="36.7"/>
              </g>
              <g id="path226_3_">
                <path className="st0" d="M209,1194.6v3.3"/>
                <rect x="208.3" y="1194.6" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path228_3_">
                <path className="st0" d="M203.9,1197.6v3.3"/>
                <rect x="203.2" y="1197.6" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path230_3_">
                <path className="st0" d="M188.5,1188.8v3.3"/>
                <rect x="187.9" y="1188.8" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path232_3_">
                <path className="st0" d="M298.9,1257l10.7-6.2"/>

                  <rect x="298.1" y="1253.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -586.5504 320.4671)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path234_3_">
                <path className="st0" d="M269,1227.4v25.8"/>
                <rect x="268.4" y="1227.4" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path236_3_">
                <path className="st0" d="M269,1253.2l29.9,17.3"/>

                  <rect x="283.3" y="1244.6" transform="matrix(0.5 -0.866 0.866 0.5 -950.8135 876.887)" className="st2" width="1.3" height="34.5"/>
              </g>
              <g id="path238_3_">
                <path className="st0" d="M258.3,1259.4l10.7-6.2"/>

                  <rect x="257.5" y="1255.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -593.1749 300.4641)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path240_3_">
                <path className="st0" d="M309.6,1250.9l-40.6-23.4"/>

                  <rect x="288.7" y="1215.7" transform="matrix(0.5 -0.866 0.866 0.5 -928.4718 870.1649)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path242_3_">
                <path className="st0" d="M258.3,1233.6v25.8"/>
                <rect x="257.7" y="1233.6" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path244_3_">
                <path className="st0" d="M258.3,1233.6l10.7-6.2"/>

                  <rect x="257.5" y="1229.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -580.2679 297.0032)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path246_3_">
                <path className="st0" d="M298.9,1282.8l-10.4,6"/>

                  <rect x="287.8" y="1285.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -603.912 319.4747)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path248_3_">
                <path className="st0" d="M248,1265.4l40.6,23.4"/>

                  <rect x="267.6" y="1253.7" transform="matrix(0.5 -0.866 0.866 0.5 -971.8641 870.8685)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path250_3_">
                <path className="st0" d="M258.3,1233.6l-48.9-28.3"/>

                  <rect x="233.2" y="1191.2" transform="matrix(0.5 -0.866 0.866 0.5 -939.1635 812.2685)" className="st2" width="1.3" height="56.5"/>
              </g>
              <g id="path252_3_">
                <path className="st0" d="M255.5,1261l-46.1-26.6"/>

                  <rect x="231.8" y="1221.1" transform="matrix(0.5 -0.866 0.866 0.5 -964.3468 825.1738)" className="st2" width="1.3" height="53.2"/>
              </g>
              <g id="path254_3_">
                <path className="st0" d="M288.6,1288.8v3.3"/>
                <rect x="287.9" y="1288.8" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path256_3_">
                <path className="st0" d="M298.9,1282.8v3.3"/>
                <rect x="298.3" y="1282.8" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path258_3_">
                <path className="st0" d="M248,1265.4v3.3"/>
                <rect x="247.3" y="1265.4" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path260_3_">
                <path className="st0" d="M298.9,1286.1l-10.4,6"/>

                  <rect x="287.8" y="1288.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -605.5524 319.9146)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path262_3_">
                <path className="st0" d="M305.7,1290l-6.7-3.9"/>

                  <rect x="301.6" y="1284.2" transform="matrix(0.5001 -0.8659 0.8659 0.5001 -964.2854 905.6417)" className="st2" width="1.3" height="7.8"/>
              </g>
              <g id="path264_3_">
                <path className="st0" d="M305.7,1260.9l-6.7-3.9"/>

                  <rect x="301.6" y="1255.1" transform="matrix(0.5 -0.866 0.866 0.5 -939.1474 891.2429)" className="st2" width="1.3" height="7.8"/>
              </g>
              <g id="path266_3_">
                <path className="st0" d="M305.7,1260.9v29.1"/>
                <rect x="305" y="1260.9" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path268_3_">
                <path className="st0" d="M288.6,1292.1l-40.6-23.4"/>

                  <rect x="267.6" y="1256.9" transform="matrix(0.5 -0.866 0.866 0.5 -974.7118 872.5308)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path270_3_">
                <path className="st0" d="M322.2,1280.4l-16.6,9.6"/>

                  <rect x="304.4" y="1284.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -600.8962 329.4922)" className="st2" width="19.1" height="1.3"/>
              </g>
              <g id="path272_3_">
                <path className="st0" d="M431.7,1188.2v21.8"/>
                <rect x="431" y="1188.2" className="st2" width="1.3" height="21.8"/>
              </g>
              <g id="path274_3_">
                <path className="st0" d="M209.4,1205.3v29.1"/>
                <rect x="208.7" y="1205.3" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path276_3_">
                <path className="st0" d="M335.4,1132.6l96.3,55.6"/>

                  <rect x="382.9" y="1104.8" transform="matrix(0.5 -0.866 0.866 0.5 -813.1663 912.3415)" className="st2" width="1.3" height="111.2"/>
              </g>
              <g id="path278_3_">
                <path className="st0" d="M433.5,1012.2l-6.7,3.9"/>

                  <rect x="426.2" y="1013.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -449.7136 351.2445)" className="st2" width="7.8" height="1.3"/>
              </g>
              <g id="path280_3_">
                <path className="st0" d="M386.1,1068.7l-12.6,7.3"/>

                  <rect x="372.6" y="1071.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -485.5239 333.8899)" className="st2" width="14.5" height="1.3"/>
              </g>
              <g id="path282_3_">
                <path className="st0" d="M416,1035.8l-29.9,17.3"/>

                  <rect x="383.8" y="1043.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -468.7136 340.7794)" className="st2" width="34.5" height="1.3"/>
              </g>
              <g id="path284_3_">
                <path className="st0" d="M227.7,998.7l91.2-52.6"/>

                  <rect x="220.6" y="971.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -449.8386 267.1571)" className="st2" width="105.3" height="1.3"/>
              </g>
              <g id="path286_3_">
                <path className="st0" d="M426.7,1016.1L416,1010"/>

                  <rect x="420.7" y="1006.9" transform="matrix(0.5 -0.866 0.866 0.5 -666.6205 871.4137)" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path288_3_">
                <path className="st0" d="M416,1010l-40.6,23.4"/>

                  <rect x="372.3" y="1021" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -458.0698 335.0568)" className="st2" width="46.9" height="1.3"/>
              </g>
              <g id="path290_3_">
                <path className="st0" d="M386.1,1039.6l-10.7-6.2"/>

                  <rect x="380.1" y="1030.3" transform="matrix(0.5 -0.866 0.866 0.5 -707.2443 848.0524)" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path292_3_">
                <path className="st0" d="M386.1,1039.6l-37.8,21.8"/>

                  <rect x="345.4" y="1049.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -476.3026 324.6653)" className="st2" width="43.6" height="1.3"/>
              </g>
              <g id="path294_3_">
                <path className="st0" d="M437.1,1047.9v3.3"/>
                <rect x="436.4" y="1047.9" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path296_3_">
                <path className="st0" d="M386.1,1065.4v3.3"/>
                <rect x="385.5" y="1065.4" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path298_3_">
                <path className="st0" d="M386.1,1065.4l10.4,6"/>

                  <rect x="390.6" y="1062.4" transform="matrix(0.5001 -0.866 0.866 0.5001 -729.5638 872.9887)" className="st2" width="1.3" height="12"/>
              </g>
              <g id="path300_3_">
                <path className="st0" d="M396.5,1071.4v3.3"/>
                <rect x="395.8" y="1071.4" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path302_3_">
                <path className="st0" d="M386.1,1068.7l10.4,6"/>
                <rect x="390.6" y="1065.7" transform="matrix(0.5 -0.866 0.866 0.5 -732.4172 874.7073)" className="st2" width="1.3" height="12"/>
              </g>
              <g id="path304_3_">
                <path className="st0" d="M437.1,1047.9l-40.6,23.4"/>

                  <rect x="393.4" y="1059" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -474.2348 350.687)" className="st2" width="46.9" height="1.3"/>
              </g>
              <g id="path306_3_">
                <path className="st0" d="M416,1010v25.8"/>
                <rect x="415.4" y="1010" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path308_3_">
                <path className="st0" d="M426.7,1016.1v25.8"/>
                <rect x="426.1" y="1016.1" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path310_3_">
                <path className="st0" d="M416,1035.8l10.7,6.2"/>

                  <rect x="420.7" y="1032.7" transform="matrix(0.5 -0.866 0.866 0.5 -688.9709 884.3871)" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path312_3_">
                <path className="st0" d="M437.1,1051.2l-40.6,23.4"/>

                  <rect x="393.4" y="1062.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -475.8752 351.1269)" className="st2" width="46.9" height="1.3"/>
              </g>
              <g id="path314_3_">
                <path className="st0" d="M433.5,1041.3l-3.9,2.2"/>

                  <rect x="429.3" y="1041.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -463.6599 355.7457)" className="st2" width="4.5" height="1.3"/>
              </g>
              <g id="path316_3_">
                <path className="st0" d="M227.7,998.7v16.4"/>
                <rect x="227" y="998.7" className="st2" width="1.3" height="16.4"/>
              </g>
              <g id="path318_3_">
                <path className="st0" d="M433.5,1012.2v29.1"/>
                <rect x="432.8" y="1012.3" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path320_3_">
                <path className="st0" d="M241.9,1006.9l-14.2-8.2"/>

                  <rect x="234.1" y="994.6" transform="matrix(0.5 -0.866 0.866 0.5 -751.0646 704.7043)" className="st2" width="1.3" height="16.4"/>
              </g>
              <g id="path322_3_">
                <path className="st0" d="M348.4,1061.4l-100.4-58"/>
                <rect x="297.5" y="974.4" transform="matrix(0.5 -0.866 0.866 0.5 -745.0085 774.398)" className="st2" width="1.3" height="116"/>
              </g>
              <g id="path324_3_">
                <path className="st0" d="M241.9,1006.9l6.1-3.5"/>
                <rect x="241.4" y="1004.5" transform="matrix(0.866 -0.5 0.5 0.866 -469.7977 257.1416)" className="st2" width="7" height="1.3"/>
              </g>
              <g id="path326_3_">
                <path className="st0" d="M550.8,1397.2v-25.8"/>
                <rect x="550.2" y="1371.4" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path328_3_">
                <path className="st0" d="M550.8,1397.2l10.7-6.2"/>

                  <rect x="550" y="1393.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -622.8945 465.3033)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path330_3_">
                <path className="st0" d="M561.5,1391.1v-25.8"/>
                <rect x="560.9" y="1365.3" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path332_3_">
                <path className="st0" d="M550.8,1371.4l10.7-6.2"/>

                  <rect x="550" y="1367.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -609.9885 461.8426)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path334_3_">
                <path className="st0" d="M591.5,1408.3l-29.9-17.3"/>

                  <rect x="575.8" y="1382.4" transform="matrix(0.5 -0.866 0.866 0.5 -923.9261 1199.1432)" className="st2" width="1.3" height="34.5"/>
              </g>
              <g id="path336_3_">
                <path className="st0" d="M561.5,1365.3l40.6,23.4"/>

                  <rect x="581.2" y="1353.5" transform="matrix(0.5 -0.866 0.866 0.5 -901.5855 1192.4194)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path338_3_">
                <path className="st0" d="M591.5,1394.9l10.7-6.2"/>

                  <rect x="590.6" y="1391.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -616.2706 485.307)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path340_3_">
                <path className="st0" d="M581.1,1426.7l-40.6-23.4"/>

                  <rect x="560.1" y="1391.5" transform="matrix(0.5 -0.866 0.866 0.5 -944.9863 1193.1337)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path342_3_">
                <path className="st0" d="M591.5,1420.7l-10.4,6"/>

                  <rect x="580.3" y="1423" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -633.632 484.3142)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path344_3_">
                <path className="st0" d="M591.5,1394.9l48.9,28.3"/>

                  <rect x="615.3" y="1380.8" transform="matrix(0.5 -0.866 0.866 0.5 -912.277 1237.9034)" className="st2" width="1.3" height="56.5"/>
              </g>
              <g id="path346_3_">
                <path className="st0" d="M591.5,1420.7v3.3"/>
                <rect x="590.8" y="1420.7" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path348_3_">
                <path className="st0" d="M591.5,1424l48.9,28.3"/>

                  <rect x="615.3" y="1409.8" transform="matrix(0.5 -0.866 0.866 0.5 -937.4576 1252.4414)" className="st2" width="1.3" height="56.5"/>
              </g>
              <g id="path350_3_">
                <path className="st0" d="M540.5,1403.2v3.3"/>
                <rect x="539.8" y="1403.2" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path352_3_">
                <path className="st0" d="M591.5,1424l-10.4,6"/>

                  <rect x="580.3" y="1426.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -635.2727 484.7541)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path354_3_">
                <path className="st0" d="M581.1,1426.7v3.3"/>
                <rect x="580.4" y="1426.7" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path356_3_">
                <path className="st0" d="M544.1,1396.6l3.9,2.2"/>

                  <rect x="545.4" y="1395.5" transform="matrix(0.5001 -0.866 0.866 0.5001 -937.4391 1171.6531)" className="st2" width="1.3" height="4.5"/>
              </g>
              <g id="path358_3_">
                <path className="st0" d="M544.1,1367.6l6.7,3.9"/>

                  <rect x="546.8" y="1365.6" transform="matrix(0.5 -0.866 0.866 0.5 -912.2698 1158.8201)" className="st2" width="1.3" height="7.8"/>
              </g>
              <g id="path360_3_">
                <path className="st0" d="M540.5,1406.5l40.6,23.4"/>

                  <rect x="560.1" y="1394.8" transform="matrix(0.5 -0.866 0.866 0.5 -947.8262 1194.7733)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path362_3_">
                <path className="st0" d="M766.4,1350.4l-96.3-55.6"/>
                <rect x="717.6" y="1267" transform="matrix(0.5 -0.866 0.866 0.5 -786.2798 1283.33)" className="st2" width="1.3" height="111.2"/>
              </g>
              <g id="path364_3_">
                <path className="st0" d="M766.4,1350.4v29.1"/>
                <rect x="765.7" y="1350.4" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path366_3_">
                <path className="st0" d="M640.4,1423.1v29.1"/>
                <rect x="639.7" y="1423.1" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path368_3_">
                <path className="st0" d="M640.4,1423.1l126-72.7"/>

                  <rect x="630.6" y="1386.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -599.4548 537.9611)" className="st2" width="145.5" height="1.3"/>
              </g>
              <g id="path370_3_">
                <path className="st0" d="M640.4,1452.2l126-72.7"/>

                  <rect x="630.6" y="1415.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -614.0021 541.8619)" className="st2" width="145.5" height="1.3"/>
              </g>
              <g id="path372_3_">
                <path className="st0" d="M322.2,1280.4l2.3,1.3"/>

                  <rect x="322.7" y="1279.8" transform="matrix(0.5005 -0.8658 0.8658 0.5005 -947.5839 919.9092)" className="st2" width="1.3" height="2.6"/>
              </g>
              <g id="path374_3_">
                <path className="st0" d="M405.8,1203.1l-83.6,48.3"/>

                  <rect x="315.8" y="1226.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -565.1654 346.7671)" className="st2" width="96.5" height="1.3"/>
              </g>
              <g id="path376_3_">
                <path className="st0" d="M490.1,1383.8l10.7-6.2"/>

                  <rect x="489.2" y="1380" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -624.3207 433.0938)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path378_3_">
                <path className="st0" d="M460.2,1354.2v25.8"/>
                <rect x="459.5" y="1354.2" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path380_3_">
                <path className="st0" d="M460.2,1380l29.9,17.3"/>

                  <rect x="474.4" y="1371.3" transform="matrix(0.5 -0.866 0.866 0.5 -965.0105 1105.7819)" className="st2" width="1.3" height="34.5"/>
              </g>
              <g id="path382_3_">
                <path className="st0" d="M449.5,1386.1l10.7-6.2"/>

                  <rect x="448.6" y="1382.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -630.9451 413.0908)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path384_3_">
                <path className="st0" d="M500.8,1377.6l-40.6-23.4"/>

                  <rect x="479.8" y="1342.4" transform="matrix(0.5 -0.866 0.866 0.5 -942.6708 1099.068)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path386_3_">
                <path className="st0" d="M449.5,1360.3v25.8"/>
                <rect x="448.8" y="1360.3" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path388_3_">
                <path className="st0" d="M449.5,1360.3l10.7-6.2"/>

                  <rect x="448.6" y="1356.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -618.0388 409.63)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path390_3_">
                <path className="st0" d="M490.1,1409.6l-10.4,6"/>

                  <rect x="478.9" y="1411.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -641.6824 432.1013)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path392_3_">
                <path className="st0" d="M439.1,1392.1l40.6,23.4"/>

                  <rect x="458.7" y="1380.4" transform="matrix(0.5 -0.866 0.866 0.5 -986.0681 1099.7767)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path394_3_">
                <path className="st0" d="M449.5,1360.3l-48.9-28.3"/>

                  <rect x="424.3" y="1318" transform="matrix(0.5 -0.866 0.866 0.5 -953.3599 1041.1469)" className="st2" width="1.3" height="56.5"/>
              </g>
              <g id="path396_3_">
                <path className="st0" d="M446.6,1387.8l-46.1-26.6"/>

                  <rect x="422.9" y="1347.9" transform="matrix(0.5 -0.866 0.866 0.5 -978.5444 1054.0675)" className="st2" width="1.3" height="53.2"/>
              </g>
              <g id="path398_3_">
                <path className="st0" d="M479.7,1415.6v3.3"/>
                <rect x="479" y="1415.6" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path400_3_">
                <path className="st0" d="M490.1,1409.6v3.3"/>
                <rect x="489.4" y="1409.6" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path402_3_">
                <path className="st0" d="M439.1,1392.1v3.3"/>
                <rect x="438.4" y="1392.1" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path404_3_">
                <path className="st0" d="M490.1,1412.9l-10.4,6"/>

                  <rect x="478.9" y="1415.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -643.3231 432.5413)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path406_3_">
                <path className="st0" d="M496.8,1416.7l-6.7-3.9"/>

                  <rect x="492.8" y="1410.9" transform="matrix(0.5 -0.866 0.866 0.5 -978.5392 1134.7092)" className="st2" width="1.3" height="7.8"/>
              </g>
              <g id="path408_3_">
                <path className="st0" d="M496.8,1387.7l-6.7-3.9"/>

                  <rect x="492.8" y="1381.8" transform="matrix(0.5001 -0.866 0.866 0.5001 -953.3301 1120.014)" className="st2" width="1.3" height="7.8"/>
              </g>
              <g id="path410_3_">
                <path className="st0" d="M496.8,1387.7v29.1"/>
                <rect x="496.1" y="1387.7" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path412_3_">
                <path className="st0" d="M479.7,1418.8l-40.6-23.4"/>

                  <rect x="458.7" y="1383.7" transform="matrix(0.5 -0.866 0.866 0.5 -988.9089 1101.4169)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path414_3_">
                <path className="st0" d="M544.1,1389.4l-47.3,27.3"/>

                  <rect x="493.1" y="1402.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -632.1597 448.6234)" className="st2" width="54.7" height="1.3"/>
              </g>
              <g id="path416_3_">
                <path className="st0" d="M622.8,1314.9l-12.7,7.3"/>

                  <rect x="609.1" y="1317.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -577.0076 485.3125)" className="st2" width="14.6" height="1.3"/>
              </g>
              <g id="path418_3_">
                <path className="st0" d="M545,1359.9l-48.2,27.8"/>

                  <rect x="493.1" y="1373.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -617.4331 444.9023)" className="st2" width="55.6" height="1.3"/>
              </g>
              <g id="path420_3_">
                <path className="st0" d="M622.8,1314.9v7.2"/>
                <rect x="622.1" y="1314.9" className="st2" width="1.3" height="7.2"/>
              </g>
              <g id="path422_3_">
                <path className="st0" d="M400.5,1332.1l14.2-8.2"/>

                  <rect x="399.4" y="1327.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -609.7267 382.0942)" className="st2" width="16.4" height="1.3"/>
              </g>
              <g id="path424_3_">
                <path className="st0" d="M532.6,1262.8l-111.8,64.5"/>

                  <rect x="412.1" y="1294.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -584.0145 412.2339)" className="st2" width="129.1" height="1.3"/>
              </g>
              <g id="path426_3_">
                <path className="st0" d="M420.8,1327.4l-6.1-3.5"/>
                <rect x="417.1" y="1322.1" transform="matrix(0.5 -0.866 0.866 0.5 -939.1634 1024.5996)" className="st2" width="1.3" height="7"/>
              </g>
              <g id="path428_3_">
                <path className="st0" d="M200.5,1106.5l44.4-25.6"/>

                  <rect x="197.1" y="1093" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -517.2969 258.1359)" className="st2" width="51.3" height="1.3"/>
              </g>
              <g id="path430_3_">
                <path className="st0" d="M240.3,1129.4l-39.8-23"/>
                <rect x="219.7" y="1095" transform="matrix(0.5 -0.866 0.866 0.5 -857.9921 749.8337)" className="st2" width="1.3" height="45.9"/>
              </g>
              <g id="path432_3_">
                <path className="st0" d="M294.8,1126.8l-24.9-14.4"/>

                  <rect x="281.6" y="1105.1" transform="matrix(0.5 -0.866 0.866 0.5 -828.4142 804.2863)" className="st2" width="1.3" height="28.8"/>
              </g>
              <g id="path434_3_">
                <path className="st0" d="M309.6,1118.2l-14.8,8.6"/>

                  <rect x="293.6" y="1121.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -521.0498 301.7745)" className="st2" width="17.1" height="1.3"/>
              </g>
              <g id="path436_3_">
                <path className="st0" d="M309.6,1118.2v17.1"/>
                <rect x="308.9" y="1118.2" className="st2" width="1.3" height="17.1"/>
              </g>
              <g id="path438_3_">
                <path className="st0" d="M331.2,1301.5v-25.8"/>
                <rect x="330.6" y="1275.7" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path440_3_">
                <path className="st0" d="M331.2,1301.5l10.7-6.2"/>

                  <rect x="330.4" y="1297.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -604.4514 342.5785)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path442_3_">
                <path className="st0" d="M341.9,1295.3v-25.8"/>
                <rect x="341.3" y="1269.5" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path444_3_">
                <path className="st0" d="M331.2,1275.7l10.7-6.2"/>

                  <rect x="330.4" y="1271.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -591.5443 339.1176)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path446_3_">
                <path className="st0" d="M371.8,1312.6l-29.9-17.3"/>

                  <rect x="356.2" y="1286.7" transform="matrix(0.5 -0.866 0.866 0.5 -950.8056 961.0282)" className="st2" width="1.3" height="34.5"/>
              </g>
              <g id="path448_3_">
                <path className="st0" d="M341.9,1269.5l40.6,23.4"/>

                  <rect x="361.6" y="1257.8" transform="matrix(0.5 -0.866 0.866 0.5 -928.4661 954.3137)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path450_3_">
                <path className="st0" d="M371.8,1299.1l10.7-6.2"/>

                  <rect x="371" y="1295.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -597.8269 362.5811)" className="st2" width="12.4" height="1.3"/>
              </g>
              <g id="path452_3_">
                <path className="st0" d="M361.5,1330.9l-40.6-23.4"/>

                  <rect x="340.5" y="1295.7" transform="matrix(0.5 -0.866 0.866 0.5 -971.8651 955.0348)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path454_3_">
                <path className="st0" d="M371.8,1324.9l-10.4,6"/>

                  <rect x="360.7" y="1327.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -615.1885 361.5888)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path456_3_">
                <path className="st0" d="M371.8,1324.9v3.3"/>
                <rect x="371.2" y="1324.9" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path458_3_">
                <path className="st0" d="M371.8,1328.2l28.7,16.6"/>

                  <rect x="385.5" y="1319.9" transform="matrix(0.5 -0.866 0.866 0.5 -964.3538 1002.7224)" className="st2" width="1.3" height="33.1"/>
              </g>
              <g id="path460_3_">
                <path className="st0" d="M320.9,1307.5v3.3"/>
                <rect x="320.2" y="1307.5" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path462_3_">
                <path className="st0" d="M371.8,1328.2l-10.4,6"/>

                  <rect x="360.7" y="1330.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -616.8289 362.0287)" className="st2" width="12" height="1.3"/>
              </g>
              <g id="path464_3_">
                <path className="st0" d="M361.5,1330.9v3.3"/>
                <rect x="360.8" y="1330.9" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path466_3_">
                <path className="st0" d="M324.5,1300.9l3.9,2.2"/>

                  <rect x="325.8" y="1299.8" transform="matrix(0.5001 -0.866 0.866 0.5001 -964.3209 933.6277)" className="st2" width="1.3" height="4.5"/>
              </g>
              <g id="path468_3_">
                <path className="st0" d="M324.5,1271.8l6.7,3.9"/>

                  <rect x="327.2" y="1269.9" transform="matrix(0.5 -0.866 0.866 0.5 -939.1477 920.7542)" className="st2" width="1.3" height="7.8"/>
              </g>
              <g id="path470_3_">
                <path className="st0" d="M320.9,1310.7l40.6,23.4"/>
                <rect x="340.5" y="1299" transform="matrix(0.5 -0.866 0.866 0.5 -974.7181 956.7219)" className="st2" width="1.3" height="46.9"/>
              </g>
              <g id="path472_3_">
                <path className="st0" d="M546.8,1254.6l-96.3-55.6"/>

                  <rect x="498" y="1171.3" transform="matrix(0.5 -0.866 0.866 0.5 -813.1658 1045.2604)" className="st2" width="1.3" height="111.2"/>
              </g>
              <g id="path474_3_">
                <path className="st0" d="M546.8,1254.6v16.4"/>
                <rect x="546.1" y="1254.6" className="st2" width="1.3" height="16.4"/>
              </g>
              <g id="path476_3_">
                <path className="st0" d="M313,1116.3l-3.4,1.9"/>

                  <rect x="309.3" y="1116.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -517.2057 305.6172)" className="st2" width="3.9" height="1.3"/>
              </g>
              <g id="path478_3_">
                <path className="st0" d="M313,1116.3l-7.1-4.1"/>

                  <rect x="308.8" y="1110.2" transform="matrix(0.5001 -0.866 0.866 0.5001 -810.2111 825.0052)" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path480_3_">
                <path className="st0" d="M321.2,1103.3l-15.3,8.9"/>

                  <rect x="304.7" y="1107.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -512.1602 305.4954)" className="st2" width="17.7" height="1.3"/>
              </g>
              <g id="path482_3_">
                <path className="st0" d="M328.3,1107.4l-7.1-4.1"/>

                  <rect x="324.1" y="1101.3" transform="matrix(0.5001 -0.866 0.866 0.5001 -794.8761 833.8593)" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path484_3_">
                <path className="st0" d="M378.2,1078.6l-49.9,28.8"/>

                  <rect x="324.4" y="1092.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -499.4562 323.3604)" className="st2" width="57.6" height="1.3"/>
              </g>
              <g id="path486_3_">
                <path className="st0" d="M313,1133.9l8.3-4.8"/>

                  <rect x="312.3" y="1130.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -523.5752 310.4473)" className="st2" width="9.6" height="1.3"/>
              </g>
              <g id="path488_3_">
                <path className="st0" d="M321.2,1129.1v-25.8"/>
                <rect x="320.6" y="1103.3" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path490_3_">
                <path className="st0" d="M328.3,1133.2l-7.1-4.1"/>

                  <rect x="324.1" y="1127.1" transform="matrix(0.5 -0.866 0.866 0.5 -817.2451 846.8745)" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path492_3_">
                <path className="st0" d="M328.3,1133.2v-25.8"/>
                <rect x="327.6" y="1107.4" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path494_3_">
                <path className="st0" d="M378.2,1107.7l-47,27.2"/>

                  <rect x="327.5" y="1120.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -513.4022 327.8614)" className="st2" width="54.3" height="1.3"/>
              </g>
              <g id="path496_3_">
                <path className="st0" d="M378.2,1078.6v29.1"/>
                <rect x="377.5" y="1078.6" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path498_3_">
                <path className="st0" d="M250.8,1084.2l25.2-14.5"/>

                  <rect x="248.8" y="1076.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -503.489 276.2385)" className="st2" width="29.1" height="1.3"/>
              </g>
              <g id="path500_3_">
                <path className="st0" d="M211.3,1024.5l51.7,29.9"/>

                  <rect x="236.5" y="1009.6" transform="matrix(0.5 -0.866 0.866 0.5 -781.5993 725.162)" className="st2" width="1.3" height="59.7"/>
              </g>
              <g id="path502_3_">
                <path className="st0" d="M256.3,1058.3l19.7,11.4"/>

                  <rect x="265.4" y="1052.6" transform="matrix(0.5 -0.866 0.866 0.5 -788.394 762.3917)" className="st2" width="1.3" height="22.7"/>
              </g>
              <g id="path504_3_">
                <path className="st0" d="M263.1,1054.4l-6.8,3.9"/>

                  <rect x="255.7" y="1055.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -493.6753 271.6273)" className="st2" width="7.9" height="1.3"/>
              </g>
              <g id="path506_3_">
                <path className="st0" d="M563.8,1356.2l52.5-30.3"/>

                  <rect x="559.7" y="1340.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -591.77 475.1313)" className="st2" width="60.7" height="1.3"/>
              </g>
              <g id="path508_3_">
                <path className="st0" d="M545,1345.3v14.5"/>
                <rect x="544.3" y="1345.3" className="st2" width="1.3" height="14.5"/>
              </g>
              <g id="path510_3_">
                <path className="st0" d="M597.5,1315l-52.5,30.3"/>

                  <rect x="540.9" y="1329.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -588.8559 464.2472)" className="st2" width="60.7" height="1.3"/>
              </g>
              <g id="path512_3_">
                <path className="st0" d="M324.1,949.1L324.1,949.1"/>
                <path className="st2" d="M324.1,949.1"/>
              </g>
              <g id="path514_3_">
                <path className="st0" d="M376.7,979.5L376.7,979.5"/>
              </g>
              <g id="path516_3_">
                <path className="st0" d="M395.5,968.6L343,938.2"/>

                  <rect x="368.6" y="923.1" transform="matrix(0.5 -0.866 0.866 0.5 -641.0649 796.4584)" className="st2" width="1.3" height="60.7"/>
              </g>
              <g id="path518_3_">
                <path className="st0" d="M376.7,979.5l18.8-10.9"/>

                  <rect x="375.2" y="973.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -435.5211 323.8325)" className="st2" width="21.8" height="1.3"/>
              </g>
              <g id="path520_3_">
                <path className="st0" d="M107.9,1084.2l48.7,28.1"/>

                  <rect x="131.6" y="1070.2" transform="matrix(0.5 -0.866 0.866 0.5 -885.0217 663.6768)" className="st2" width="1.3" height="56.2"/>
              </g>
              <g id="path522_3_">
                <path className="st0" d="M156.6,1112.3l27-15.6"/>

                  <rect x="154.5" y="1103.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -529.7895 233.2722)" className="st2" width="31.2" height="1.3"/>
              </g>
              <g id="path524_3_">
                <path className="st0" d="M183.6,1096.7l16.9,9.8"/>

                  <rect x="191.4" y="1091.8" transform="matrix(0.5 -0.866 0.866 0.5 -857.9868 717.1071)" className="st2" width="1.3" height="19.5"/>
              </g>
              <g id="path526_3_">
                <path className="st0" d="M211.3,1024.5l-33.9,19.6"/>

                  <rect x="174.8" y="1033.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -491.4083 236.0017)" className="st2" width="39.2" height="1.3"/>
              </g>
              <g id="path528_3_">
                <path className="st0" d="M177.4,1044.1l7.1,4.1"/>

                  <rect x="180.3" y="1042.1" transform="matrix(0.5 -0.866 0.866 0.5 -815.5291 679.7467)" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path530_3_">
                <path className="st0" d="M162.1,1053l7.1,4.1"/>

                  <rect x="164.9" y="1050.9" transform="matrix(0.5001 -0.866 0.866 0.5001 -830.8293 670.8032)" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path532_3_">
                <path className="st0" d="M162.1,1053l-54.1,31.3"/>

                  <rect x="103.7" y="1067.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -516.5259 210.8936)" className="st2" width="62.5" height="1.3"/>
              </g>
              <g id="path534_3_">
                <path className="st0" d="M107.9,1084.2v29.1"/>
                <rect x="107.2" y="1084.2" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path536_3_">
                <path className="st0" d="M107.9,1113.3l25.9,15"/>
                <rect x="120.2" y="1105.8" transform="matrix(0.5 -0.866 0.866 0.5 -910.2084 665.1107)" className="st2" width="1.3" height="30"/>
              </g>
              <g id="path538_3_">
                <path className="st0" d="M177.4,1044.1v8.1"/>
                <rect x="176.7" y="1044.1" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path540_3_">
                <path className="st0" d="M244.9,1080.8l5.9,3.4"/>
                <rect x="247.2" y="1079.1" transform="matrix(0.5 -0.866 0.866 0.5 -813.58 755.8553)" className="st2" width="1.3" height="6.8"/>
              </g>
              <g id="path542_3_">
                <path className="st0" d="M512.2,916.2L397.6,850"/>

                  <rect x="454.2" y="816.9" transform="matrix(0.5 -0.866 0.866 0.5 -537.3146 835.4888)" className="st2" width="1.3" height="132.4"/>
              </g>
              <g id="path544_3_">
                <path className="st0" d="M421.1,968.8l6.7-3.9"/>

                  <rect x="420.5" y="966.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -426.795 342.0536)" className="st2" width="7.8" height="1.3"/>
              </g>
              <g id="path546_3_">
                <path className="st0" d="M471.2,968.9l41-23.7"/>

                  <rect x="468.1" y="956.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -412.8712 374.415)" className="st2" width="47.3" height="1.3"/>
              </g>
              <g id="path548_3_">
                <path className="st0" d="M306.4,902.6l114.6,66.2"/>

                  <rect x="363.1" y="869.5" transform="matrix(0.5 -0.866 0.866 0.5 -628.476 782.8625)" className="st2" width="1.3" height="132.4"/>
              </g>
              <g id="path550_3_">
                <path className="st0" d="M427.8,978.4l29.9-17.3"/>

                  <rect x="425.5" y="969.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -425.7734 351.603)" className="st2" width="34.5" height="1.3"/>
              </g>
              <g id="path552_3_">
                <path className="st0" d="M397.6,850l-91.2,52.6"/>

                  <rect x="299.4" y="875.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -391.201 293.6721)" className="st2" width="105.3" height="1.3"/>
              </g>
              <g id="path554_3_">
                <path className="st0" d="M427.8,964.9l-10.7-6.2"/>

                  <rect x="421.8" y="955.7" transform="matrix(0.5 -0.866 0.866 0.5 -621.7588 846.7944)" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path556_3_">
                <path className="st0" d="M417.1,958.7l40.6-23.4"/>

                  <rect x="413.9" y="946.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -415.1296 345.8804)" className="st2" width="46.9" height="1.3"/>
              </g>
              <g id="path558_3_">
                <path className="st0" d="M468.4,941.5l-10.7-6.2"/>

                  <rect x="462.4" y="932.2" transform="matrix(0.5 -0.866 0.866 0.5 -581.1461 870.2209)" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path560_3_">
                <path className="st0" d="M468.4,941.5l43.8-25.3"/>

                  <rect x="465" y="928.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -398.9249 369.9138)" className="st2" width="50.6" height="1.3"/>
              </g>
              <g id="path562_3_">
                <path className="st0" d="M427.8,990.7v3.3"/>
                <rect x="427.1" y="990.7" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path564_3_">
                <path className="st0" d="M427.8,994l10.4,6"/>

                  <rect x="432.3" y="991" transform="matrix(0.5001 -0.866 0.866 0.5001 -646.9263 873.375)" className="st2" width="1.3" height="12"/>
              </g>
              <g id="path566_3_">
                <path className="st0" d="M438.1,996.7v3.3"/>
                <rect x="437.5" y="996.7" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path568_3_">
                <path className="st0" d="M427.8,990.7l10.4,6"/>
                <rect x="432.3" y="987.7" transform="matrix(0.5 -0.866 0.866 0.5 -644.0947 871.8114)" className="st2" width="1.3" height="12"/>
              </g>
              <g id="path570_3_">
                <path className="st0" d="M478.8,973.3v3.3"/>
                <rect x="478.1" y="973.3" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path572_3_">
                <path className="st0" d="M438.1,996.7l40.6-23.4"/>

                  <rect x="435" y="984.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -431.2943 361.5107)" className="st2" width="46.9" height="1.3"/>
              </g>
              <g id="path574_3_">
                <path className="st0" d="M468.4,941.5v25.8"/>
                <rect x="467.7" y="941.5" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path576_3_">
                <path className="st0" d="M457.7,935.3v25.8"/>
                <rect x="457" y="935.3" className="st2" width="1.3" height="25.8"/>
              </g>
              <g id="path578_3_">
                <path className="st0" d="M457.7,961.1l10.7,6.2"/>
                <rect x="462.4" y="958" transform="matrix(0.5 -0.866 0.866 0.5 -603.4862 883.1193)" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path580_3_">
                <path className="st0" d="M438.1,1000l40.6-23.4"/>

                  <rect x="435" y="987.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -432.935 361.9507)" className="st2" width="46.9" height="1.3"/>
              </g>
              <g id="path582_3_">
                <path className="st0" d="M421.1,997.9l6.7-3.9"/>

                  <rect x="420.5" y="995.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -441.3423 345.9543)" className="st2" width="7.8" height="1.3"/>
              </g>
              <g id="path584_3_">
                <path className="st0" d="M306.4,902.6v29.1"/>
                <rect x="305.8" y="902.6" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path586_3_">
                <path className="st0" d="M306.4,931.7l24,13.8"/>

                  <rect x="317.7" y="924.8" transform="matrix(0.5 -0.866 0.866 0.5 -653.6555 745.0352)" className="st2" width="1.3" height="27.7"/>
              </g>
              <g id="path588_3_">
                <path className="st0" d="M395.5,983.1l25.6,14.8"/>

                  <rect x="407.6" y="975.7" transform="matrix(0.5 -0.866 0.866 0.5 -653.6577 848.8377)" className="st2" width="1.3" height="29.5"/>
              </g>
              <g id="path590_3_">
                <path className="st0" d="M421.1,968.8v29.1"/>
                <rect x="420.4" y="968.8" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path592_3_">
                <path className="st0" d="M512.2,916.2v29.1"/>
                <rect x="511.6" y="916.2" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path594_3_">
                <path className="st0" d="M193.7,1185.8l-2.8,1.6"/>

                  <rect x="190.6" y="1185.9" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -567.288 254.8954)" className="st2" width="3.3" height="1.3"/>
              </g>
              <g id="path596_3_">
                <path className="st0" d="M190.8,1187.4l-2.3,1.3"/>

                  <rect x="188.3" y="1187.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -568.9699 254.2867)" className="st2" width="2.7" height="1.3"/>
              </g>
              <g id="path598_3_">
                <path className="st0" d="M133.8,1125.4l22.7-13.1"/>

                  <rect x="132.1" y="1118.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -540.311 222.7546)" className="st2" width="26.2" height="1.3"/>
              </g>
              <g id="path600_3_">
                <path className="st0" d="M209,1194.6v-8.1"/>
                <rect x="208.3" y="1186.5" className="st2" width="1.3" height="8.1"/>
              </g>
              <g id="path602_3_">
                <path className="st0" d="M209,1186.5v-17.7"/>
                <rect x="208.3" y="1168.8" className="st2" width="1.3" height="17.7"/>
              </g>
              <g id="path604_3_">
                <path className="st0" d="M133.8,1125.4v2.8"/>
                <rect x="133.2" y="1125.4" className="st2" width="1.3" height="2.8"/>
              </g>
              <g id="path606_3_">
                <path className="st0" d="M133.8,1128.3v26.2"/>
                <rect x="133.2" y="1128.3" className="st2" width="1.3" height="26.2"/>
              </g>
              <g id="path608_3_">
                <path className="st0" d="M298.9,1282.8v-12.4"/>
                <rect x="298.3" y="1270.5" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path610_3_">
                <path className="st0" d="M298.9,1270.5V1257"/>
                <rect x="298.3" y="1257" className="st2" width="1.3" height="13.4"/>
              </g>
              <g id="path612_3_">
                <path className="st0" d="M258.3,1259.4l-2.8,1.6"/>

                  <rect x="255.3" y="1259.5" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -595.4268 297.0611)" className="st2" width="3.3" height="1.3"/>
              </g>
              <g id="path614_3_">
                <path className="st0" d="M255.5,1261l-7.5,4.3"/>

                  <rect x="247.4" y="1262.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -598.2299 295.4106)" className="st2" width="8.7" height="1.3"/>
              </g>
              <g id="path616_3_">
                <path className="st0" d="M322.2,1251.4l-16.6,9.6"/>

                  <rect x="304.4" y="1255.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -586.3489 325.5915)" className="st2" width="19.1" height="1.3"/>
              </g>
              <g id="path618_3_">
                <path className="st0" d="M318.8,946.1l5.3,3.1"/>

                  <rect x="320.8" y="944.5" transform="matrix(0.4998 -0.8661 0.8661 0.4998 -659.9512 752.4341)" className="st2" width="1.3" height="6.1"/>
              </g>
              <g id="path620_3_">
                <path className="st0" d="M426.7,1041.9l2.8,1.6"/>

                  <rect x="427.5" y="1041.1" transform="matrix(0.5002 -0.8659 0.8659 0.5002 -688.9363 891.9459)" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path622_3_">
                <path className="st0" d="M429.6,1043.6l7.5,4.3"/>

                  <rect x="432.7" y="1041.4" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -688.9757 898.2246)" className="st2" width="1.3" height="8.7"/>
              </g>
              <g id="path624_3_">
                <path className="st0" d="M386.1,1039.6v13.4"/>
                <rect x="385.5" y="1039.6" className="st2" width="1.3" height="13.4"/>
              </g>
              <g id="path626_3_">
                <path className="st0" d="M386.1,1053v12.4"/>
                <rect x="385.5" y="1053" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path628_3_">
                <path className="st0" d="M591.5,1394.9v13.4"/>
                <rect x="590.8" y="1394.9" className="st2" width="1.3" height="13.4"/>
              </g>
              <g id="path630_3_">
                <path className="st0" d="M591.5,1408.3v12.4"/>
                <rect x="590.8" y="1408.3" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path632_3_">
                <path className="st0" d="M550.8,1397.2l-2.8,1.6"/>

                  <rect x="547.8" y="1397.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -625.7578 462.4416)" className="st2" width="3.3" height="1.3"/>
              </g>
              <g id="path634_3_">
                <path className="st0" d="M548,1398.9l-7.5,4.3"/>

                  <rect x="539.9" y="1400.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -627.9504 460.2501)" className="st2" width="8.7" height="1.3"/>
              </g>
              <g id="path636_3_">
                <path className="st0" d="M544.1,1367.6v21.9"/>
                <rect x="543.5" y="1367.6" className="st2" width="1.3" height="21.9"/>
              </g>
              <g id="path638_3_">
                <path className="st0" d="M544.1,1389.4v7.2"/>
                <rect x="543.5" y="1389.4" className="st2" width="1.3" height="7.2"/>
              </g>
              <g id="path640_3_">
                <path className="st0" d="M322.2,1251.4L322.2,1251.4"/>
                <path className="st2" d="M322.2,1251.4"/>
              </g>
              <g id="path642_3_">
                <path className="st0" d="M490.1,1409.6v-12.4"/>
                <rect x="489.4" y="1397.2" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path644_3_">
                <path className="st0" d="M490.1,1397.2v-13.4"/>
                <rect x="489.4" y="1383.8" className="st2" width="1.3" height="13.4"/>
              </g>
              <g id="path646_3_">
                <path className="st0" d="M449.5,1386.1l-2.8,1.6"/>

                  <rect x="446.4" y="1386.3" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -633.1879 409.5427)" className="st2" width="3.3" height="1.3"/>
              </g>
              <g id="path648_3_">
                <path className="st0" d="M446.6,1387.8l-7.5,4.3"/>

                  <rect x="438.5" y="1389.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -636.0001 408.0374)" className="st2" width="8.7" height="1.3"/>
              </g>
              <g id="path650_3_">
                <path className="st0" d="M400.5,1332.1v12.7"/>
                <rect x="399.9" y="1332.1" className="st2" width="1.3" height="12.7"/>
              </g>
              <g id="path652_3_">
                <path className="st0" d="M400.5,1344.8v16.4"/>
                <rect x="399.9" y="1344.8" className="st2" width="1.3" height="16.4"/>
              </g>
              <g id="path654_3_">
                <path className="st0" d="M532.6,1262.8l14.2,8.2"/>

                  <rect x="539" y="1258.7" transform="matrix(0.5 -0.866 0.866 0.5 -827.3673 1100.8661)" className="st2" width="1.3" height="16.4"/>
              </g>
              <g id="path656_3_">
                <path className="st0" d="M546.8,1271l76,43.9"/>

                  <rect x="584.1" y="1249.1" transform="matrix(0.5 -0.866 0.866 0.5 -827.3638 1152.9235)" className="st2" width="1.3" height="87.8"/>
              </g>
              <g id="path658_3_">
                <path className="st0" d="M371.8,1299.1v13.4"/>
                <rect x="371.2" y="1299.1" className="st2" width="1.3" height="13.4"/>
              </g>
              <g id="path660_3_">
                <path className="st0" d="M371.8,1312.6v12.4"/>
                <rect x="371.2" y="1312.6" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path662_3_">
                <path className="st0" d="M331.2,1301.5l-2.8,1.6"/>

                  <rect x="328.2" y="1301.6" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -606.7033 339.1218)" className="st2" width="3.3" height="1.3"/>
              </g>
              <g id="path664_3_">
                <path className="st0" d="M328.4,1303.1l-7.5,4.3"/>

                  <rect x="320.3" y="1304.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -609.5063 337.5246)" className="st2" width="8.7" height="1.3"/>
              </g>
              <g id="path666_3_">
                <path className="st0" d="M371.8,1299.1l42.9,24.8"/>

                  <rect x="392.6" y="1286.8" transform="matrix(0.5 -0.866 0.866 0.5 -939.1656 996.3516)" className="st2" width="1.3" height="49.5"/>
              </g>
              <g id="path668_3_">
                <path className="st0" d="M324.5,1271.8v9.9"/>
                <rect x="323.8" y="1271.8" className="st2" width="1.3" height="9.9"/>
              </g>
              <g id="path670_3_">
                <path className="st0" d="M324.5,1281.7v19.1"/>
                <rect x="323.8" y="1281.8" className="st2" width="1.3" height="19.1"/>
              </g>
              <g id="path672_3_">
                <path className="st0" d="M341.1,1262.2l-16.6,9.6"/>

                  <rect x="323.2" y="1266.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -589.2629 336.4751)" className="st2" width="19.1" height="1.3"/>
              </g>
              <g id="path674_3_">
                <path className="st0" d="M532.6,1262.8l14.2-8.2"/>

                  <rect x="531.5" y="1258.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -557.3652 438.8734)" className="st2" width="16.4" height="1.3"/>
              </g>
              <g id="path676_3_">
                <path className="st0" d="M313,1116.3v17.7"/>
                <rect x="312.3" y="1116.3" className="st2" width="1.3" height="17.7"/>
              </g>
              <g id="path678_3_">
                <path className="st0" d="M313,1133.9v3.3"/>
                <rect x="312.3" y="1133.9" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path680_3_">
                <path className="st0" d="M328.3,1133.2l2.8,1.6"/>
                <rect x="329" y="1132.4" transform="matrix(0.5 -0.866 0.866 0.5 -817.2303 852.5266)" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path682_3_">
                <path className="st0" d="M331.1,1134.8l0.2,0.1"/>

                  <rect x="330.6" y="1134.8" transform="matrix(0.5 -0.866 0.866 0.5 -817.2416 854.3213)" className="st2" width="1.3" height="0.2"/>
              </g>
              <g id="path684_3_">
                <path className="st0" d="M373.5,1075.9l4.6,2.7"/>

                  <rect x="375.2" y="1074.6" transform="matrix(0.5001 -0.866 0.866 0.5001 -744.9872 864.0294)" className="st2" width="1.3" height="5.4"/>
              </g>
              <g id="path686_3_">
                <path className="st0" d="M211.3,1024.5l16.3-9.4"/>

                  <rect x="210.1" y="1019.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -480.7769 246.6303)" className="st2" width="18.9" height="1.3"/>
              </g>
              <g id="path688_3_">
                <path className="st0" d="M545,1345.3l18.8,10.9"/>

                  <rect x="553.7" y="1339.9" transform="matrix(0.5 -0.866 0.866 0.5 -892.5974 1155.5358)" className="st2" width="1.3" height="21.8"/>
              </g>
              <g id="path690_3_">
                <path className="st0" d="M563.8,1356.2L563.8,1356.2"/>
              </g>
              <g id="path692_3_">
                <path className="st0" d="M616.4,1325.9L616.4,1325.9"/>
                <path className="st2" d="M616.4,1325.9"/>
              </g>
              <g id="path694_3_">
                <path className="st0" d="M324.1,949.1l52.5,30.3"/>
                <rect x="349.7" y="934" transform="matrix(0.5 -0.866 0.866 0.5 -659.9029 785.587)" className="st2" width="1.3" height="60.7"/>
              </g>
              <g id="path696_3_">
                <path className="st0" d="M343,938.2l-12.6,7.3"/>

                  <rect x="329.4" y="941.2" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -426.0717 294.7982)" className="st2" width="14.5" height="1.3"/>
              </g>
              <g id="path698_3_">
                <path className="st0" d="M330.4,945.5l-6.2,3.6"/>

                  <rect x="323.6" y="946.7" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -430.0559 290.8154)" className="st2" width="7.2" height="1.3"/>
              </g>
              <g id="path700_3_">
                <path className="st0" d="M395.5,968.6v14.5"/>
                <rect x="394.8" y="968.6" className="st2" width="1.3" height="14.5"/>
              </g>
              <g id="path702_3_">
                <path className="st0" d="M395.5,983.1v7.2"/>
                <rect x="394.8" y="983.1" className="st2" width="1.3" height="7.2"/>
              </g>
              <g id="path704_3_">
                <path className="st0" d="M184.4,1048.2l-7.1,4.1"/>

                  <rect x="176.8" y="1049.6" transform="matrix(0.8662 -0.4998 0.4998 0.8662 -500.6494 230.9775)" className="st2" width="8.1" height="1.3"/>
              </g>
              <g id="path706_3_">
                <path className="st0" d="M177.4,1052.3l-8.3,4.8"/>

                  <rect x="168.5" y="1054" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -504.4125 228.1676)" className="st2" width="9.6" height="1.3"/>
              </g>
              <g id="path708_3_">
                <path className="st0" d="M211.3,1024.5L211.3,1024.5"/>
                <polygon className="st2" points="210.7,1024.8 210.7,1024.8 211.9,1024.2 				"/>
              </g>
              <g id="path710_3_">
                <path className="st0" d="M468.4,967.3l2.8,1.6"/>

                  <rect x="469.1" y="966.4" transform="matrix(0.5 -0.8661 0.8661 0.5 -603.4888 890.9754)" className="st2" width="1.3" height="3.3"/>
              </g>
              <g id="path712_3_">
                <path className="st0" d="M471.2,968.9l7.5,4.3"/>

                  <rect x="474.3" y="966.7" transform="matrix(0.5001 -0.866 0.866 0.5001 -603.4779 896.7999)" className="st2" width="1.3" height="8.7"/>
              </g>
              <g id="path714_3_">
                <path className="st0" d="M427.8,964.9v13.4"/>
                <rect x="427.1" y="964.9" className="st2" width="1.3" height="13.4"/>
              </g>
              <g id="path716_3_">
                <path className="st0" d="M427.8,978.4v12.4"/>
                <rect x="427.1" y="978.4" className="st2" width="1.3" height="12.4"/>
              </g>
              <g id="path718_3_">
                <path className="st0" d="M294.8,1126.8l14.8,8.6"/>

                  <rect x="301.5" y="1122.5" transform="matrix(0.5 -0.866 0.866 0.5 -828.4016 827.1973)" className="st2" width="1.3" height="17.1"/>
              </g>
              <g id="path720_3_">
                <path className="st0" d="M431.7,1188.2l-25.8,14.9"/>

                  <rect x="403.8" y="1195" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -542.0204 369.9041)" className="st2" width="29.8" height="1.3"/>
              </g>
              <g id="path722_3_">
                <path className="st0" d="M405.8,1203.1l-83.6,48.3"/>

                  <rect x="315.8" y="1226.6" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -565.1656 346.7672)" className="st2" width="96.5" height="1.3"/>
              </g>
              <g id="path724_3_">
                <path className="st0" d="M209.4,1205.3l6.2-3.6"/>

                  <rect x="208.9" y="1202.9" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -573.638 267.7861)" className="st2" width="7.2" height="1.3"/>
              </g>
              <g id="path726_3_">
                <path className="st0" d="M215.6,1201.7l25.2-14.5"/>

                  <rect x="213.7" y="1193.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -566.9911 274.4309)" className="st2" width="29.1" height="1.3"/>
              </g>
              <g id="path728_3_">
                <path className="st0" d="M563.8,1356.2l-18.8,10.9"/>

                  <rect x="543.5" y="1361" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -606.8671 460.0392)" className="st2" width="21.8" height="1.3"/>
              </g>
              <g id="path730_3_">
                <path className="st0" d="M545,1367.1l-0.8,0.5"/>

                  <rect x="544.1" y="1366.6" transform="matrix(0.8655 -0.5009 0.5009 0.8655 -611.6088 456.6098)" className="st2" width="1" height="1.3"/>
              </g>
              <g id="path732_3_">
                <path className="st0" d="M322.2,1251.4l18.8,10.9"/>
                <rect x="331" y="1245.9" transform="matrix(0.5 -0.866 0.866 0.5 -922.5931 915.6064)" className="st2" width="1.3" height="21.8"/>
              </g>
              <g id="path734_3_">
                <path className="st0" d="M424.7,1214l-18.8-10.9"/>

                  <rect x="414.6" y="1197.7" transform="matrix(0.5 -0.866 0.866 0.5 -839.0157 963.9148)" className="st2" width="1.3" height="21.8"/>
              </g>
              <g id="path736_3_">
                <path className="st0" d="M405.8,1203.1L405.8,1203.1"/>
              </g>
              <g id="path738_3_">
                <path className="st0" d="M424.7,1214l-83.6,48.3"/>

                  <rect x="334.6" y="1237.4" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -568.0797 357.6508)" className="st2" width="96.5" height="1.3"/>
              </g>
              <g id="path740_3_">
                <path className="st0" d="M348.4,1061.4l25.2,14.5"/>

                  <rect x="360.3" y="1054.1" transform="matrix(0.5 -0.866 0.866 0.5 -745.0045 846.907)" className="st2" width="1.3" height="29.1"/>
              </g>
              <g id="path742_3_">
                <path className="st0" d="M227.7,1015.1l14.2-8.2"/>

                  <rect x="226.6" y="1010.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -474.3195 253.0851)" className="st2" width="16.4" height="1.3"/>
              </g>
              <g id="path744_3_">
                <path className="st0" d="M616.4,1325.9l-6.2-3.6"/>

                  <rect x="612.6" y="1320.4" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -840.0626 1193.2466)" className="st2" width="1.3" height="7.2"/>
              </g>
              <g id="path746_3_">
                <path className="st0" d="M610.1,1322.2l-12.6-7.3"/>

                  <rect x="603.1" y="1311.3" transform="matrix(0.5 -0.866 0.866 0.5 -840.0475 1182.2002)" className="st2" width="1.3" height="14.5"/>
              </g>
              <g id="path748_3_">
                <path className="st0" d="M309.6,1135.3l3.4,1.9"/>

                  <rect x="310.6" y="1134.3" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -828.4305 837.8005)" className="st2" width="1.3" height="3.9"/>
              </g>
              <g id="path750_3_">
                <path className="st0" d="M313,1137.2l7.2,4.2"/>

                  <rect x="315.9" y="1135.2" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -828.4246 843.8745)" className="st2" width="1.3" height="8.3"/>
              </g>
              <g id="path752_3_">
                <path className="st0" d="M320.1,1141.4l11.2-6.4"/>

                  <rect x="319.3" y="1137.5" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -525.7451 315.6617)" className="st2" width="12.9" height="1.3"/>
              </g>
              <g id="path754_3_">
                <path className="st0" d="M331.3,1134.9l4.1-2.3"/>

                  <rect x="331" y="1133.1" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -522.5218 318.8838)" className="st2" width="4.7" height="1.3"/>
              </g>
              <g id="path756_3_">
                <path className="st0" d="M670.1,1294.8l-47.3,27.3"/>

                  <rect x="619.1" y="1307.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -567.9227 498.9694)" className="st2" width="54.7" height="1.3"/>
              </g>
              <g id="path758_3_">
                <path className="st0" d="M622.8,1322.1l-6.4,3.7"/>

                  <rect x="615.9" y="1323.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -579.2952 487.6013)" className="st2" width="7.4" height="1.3"/>
              </g>
              <g id="path760_3_">
                <path className="st0" d="M450.5,1199.1l-18.8,10.9"/>

                  <rect x="430.2" y="1203.8" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -543.4526 382.2688)" className="st2" width="21.8" height="1.3"/>
              </g>
              <g id="path762_3_">
                <path className="st0" d="M431.7,1209.9l-7,4"/>

                  <rect x="424.1" y="1211.3" transform="matrix(0.8658 -0.5003 0.5003 0.8658 -548.9184 376.8049)" className="st2" width="8.1" height="1.3"/>
              </g>
              <g id="path764_3_">
                <path className="st0" d="M376.7,979.5l18.8,10.9"/>
                <rect x="385.4" y="974" transform="matrix(0.5 -0.866 0.866 0.5 -659.9083 826.825)" className="st2" width="1.3" height="21.8"/>
              </g>
              <g id="path766_3_">
                <path className="st0" d="M395.5,990.3l38,21.9"/>
                <rect x="413.8" y="979.4" transform="matrix(0.5 -0.866 0.866 0.5 -659.906 859.6058)" className="st2" width="1.3" height="43.8"/>
              </g>
              <g id="path768_3_">
                <path className="st0" d="M309.6,1118.2l-58.8-34"/>

                  <rect x="279.5" y="1067.2" transform="matrix(0.5 -0.866 0.866 0.5 -813.5898 793.2433)" className="st2" width="1.3" height="67.9"/>
              </g>
            </g>
          </g>
          <g id="A3" className={'isometry__floor ' + (floor === "3" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="A4-0302" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path100_4_" className="st0" d="M446.6,1358.7l-46.1-26.6V1303l48.9,28.3v25.8L446.6,1358.7z"/>
              <path id="path102_4_" className="st0" d="M449.5,1357.1v-25.8l10.7-6.2v25.8L449.5,1357.1z"/>
              <path id="path104_4_" className="st0" d="M460.2,1350.9v-25.8l40.6,23.4l-10.7,6.2v13.4L460.2,1350.9z"/>
              <path id="path106_4_" className="st0" d="M490.1,1354.7l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l14.2-8.2l6.1,3.5l111.8-64.5
                l14.2,8.2l76,43.9l-12.7,7.3l-12.6-7.3l-52.5,30.3v14.5l0,0l-48.2,27.8L490.1,1354.7z"/>
              <path id="path110_4_" className="st0" d="M545,1330.8v7.2l-0.8,0.5v21.9l-47.3,27.3v-29.1L545,1330.8z"/>
              <path id="path112_4_" className="st0" d="M460.2,1350.9l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L460.2,1350.9z"/>
              <path id="path114_4_" className="st0" d="M479.7,1386.5l10.4-6v3.3l-10.4,6V1386.5z"/>
              <path id="path116_4_" className="st0" d="M439.1,1363l40.6,23.4v3.3l-40.6-23.4V1363z"/>
              <path id="path118_4_" className="st0" d="M490.1,1383.8v-29.1l6.7,3.9v29.1L490.1,1383.8z"/>
              <path id="path120_4_" className="st0" d="M610.1,1293.2l12.7-7.3v7.2l-6.4,3.7L610.1,1293.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A4-0301" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path82_4_" className="st0" d="M640.4,1423.1l-48.9-28.3v-29.1l48.9,28.3V1423.1z"/>
              <path id="path84_4_" className="st0" d="M640.4,1394.1l-48.9-28.3l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9l0.8-0.5l18.8-10.9v0
                l52.5-30.3l0,0l6.4-3.7l47.3-27.3l96.3,55.6L640.4,1394.1z"/>
              <path id="path86_4_" className="st0" d="M550.8,1342.4l10.7-6.2v25.8l-10.7,6.2V1342.4z"/>
              <path id="path88_4_" className="st0" d="M550.8,1342.4v25.8l-2.8,1.6l-3.9-2.2v-7.2v-21.9L550.8,1342.4z"/>
              <path id="path90_4_" className="st0" d="M550.8,1368.2l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L550.8,1368.2z"/>
              <path id="path92_4_" className="st0" d="M561.5,1362v-25.8l40.6,23.4l-10.7,6.2v13.4L561.5,1362z"/>
              <path id="path94_4_" className="st0" d="M540.5,1374.1l40.6,23.4v3.3l-40.6-23.4V1374.1z"/>
              <path id="path96_4_" className="st0" d="M581.1,1397.6l10.4-6v3.3l-10.4,6V1397.6z"/>
              <path id="path98_4_" className="st0" d="M766.4,1321.3v29.1l-126,72.7v-29.1L766.4,1321.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0303" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path24_4_" className="st0" d="M320.1,1112.3l-79.3,45.8l-31.8-18.4l7.1-4.1l-15.3-8.9l-7.1,4.1l-59.8-34.5l22.7-13.1
                l27-15.6l16.9,9.8l39.8,23l29.6-17.1l24.9,14.4l14.8,8.6l3.4,1.9L320.1,1112.3z"/>
              <path id="path28_4_" className="st0" d="M200.7,1126.8l15.3,8.9l-7.1,4.1v17.7l-8.3-4.8L200.7,1126.8z"/>
              <path id="path30_4_" className="st0" d="M200.7,1152.6l8.3,4.8v8.1l-5.2,3l-15.3-8.9l2.3-1.3l2.8-1.6L200.7,1152.6z"/>
              <path id="path32_4_" className="st0" d="M200.7,1152.6l-7.1,4.1v-25.8l7.1-4.1V1152.6z"/>
              <path id="path34_4_" className="st0" d="M188.5,1159.7l15.3,8.9v3.3l-15.3-8.9V1159.7z"/>
              <path id="path36_4_" className="st0" d="M203.9,1168.5l5.2-3v3.3l-5.2,3V1168.5z"/>
              <path id="path38_4_" className="st0" d="M190.8,1158.3l-57-32.9v-29.1l59.8,34.5v25.8L190.8,1158.3z"/>
              <path id="path40_4_" className="st0" d="M215.6,1172.7l-6.6-3.8v-29.1l31.8,18.4L215.6,1172.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0302" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path22_4_" className="st0" d="M240.8,1158.1l79.3-45.8l11.2-6.4l4.1-2.3l96.3,55.6l-25.8,14.9v0l-83.6,48.3l0,0l-16.6,9.6
                l-6.7-3.9l10.7-6.2l-40.6-23.4l-10.7,6.2l-48.9-28.3l6.2-3.6L240.8,1158.1z"/>
              <path id="path42_4_" className="st0" d="M309.6,1221.8l-10.7,6.2v13.4l-29.9-17.3v-25.8L309.6,1221.8z"/>
              <path id="path44_4_" className="st0" d="M269,1198.3v25.8l-10.7,6.2v-25.8L269,1198.3z"/>
              <path id="path46_4_" className="st0" d="M269,1224.1l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6L269,1224.1z"/>
              <path id="path48_4_" className="st0" d="M258.3,1204.5v25.8l-2.8,1.6l-46.1-26.6v-29.1L258.3,1204.5z"/>
              <path id="path50_4_" className="st0" d="M288.6,1259.8l10.4-6v3.3l-10.4,6V1259.8z"/>
              <path id="path52_4_" className="st0" d="M248,1236.3l40.6,23.4v3.3l-40.6-23.4V1236.3z"/>
              <path id="path54_4_" className="st0" d="M298.9,1257V1228l6.7,3.9v29.1L298.9,1257z"/>
              <path id="path56_4_" className="st0" d="M305.7,1260.9v-29.1l16.6-9.6v29.1L305.7,1260.9z"/>
              <path id="path60_4_" className="st0" d="M431.7,1159.1v21.8l-7,4l-18.8-10.9L431.7,1159.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0301" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path122_4_" className="st0" d="M414.7,1294.8l-14.2,8.2v12.7l-28.7-16.6v-3.3v-12.4v-13.4L414.7,1294.8z"/>
              <path id="path124_4_" className="st0" d="M531.4,1233.8l-111.8,64.5l-6.1-3.5l-42.9-24.8l10.7-6.2l-40.6-23.4l-10.7,6.2l-6.7-3.9
                l16.6-9.6l0,0l83.6-48.3l0,0l7-4l18.8-10.9l96.3,55.6L531.4,1233.8z"/>
              <path id="path132_4_" className="st0" d="M330.1,1246.6l10.7-6.2v25.8l-10.7,6.2V1246.6z"/>
              <path id="path134_4_" className="st0" d="M331.2,1246.6v25.8l-2.8,1.6l-3.9-2.2v-19.1v-9.9L331.2,1246.6z"/>
              <path id="path136_4_" className="st0" d="M331.2,1272.4l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L331.2,1272.4z"/>
              <path id="path138_4_" className="st0" d="M340.8,1266.2v-25.8l40.6,23.4l-10.7,6.2v13.4L340.8,1266.2z"/>
              <path id="path140_4_" className="st0" d="M320.9,1278.4l40.6,23.4v3.3l-40.6-23.4V1278.4z"/>
              <path id="path142_4_" className="st0" d="M361.5,1301.8l10.4-6v3.3l-10.4,6V1301.8z"/>
              <path id="path144_4_" className="st0" d="M546.8,1225.6v16.4l-14.2-8.2L546.8,1225.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0302" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path80_4_" className="st0" d="M247.9,974.3l100.4,58l25.2,14.5l4.6,2.7l-49.9,28.8l-7.1-4.1l-15.3,8.9l7.1,4.1l-3.4,1.9
                l-58.8-34l25.2-14.5l-19.7-11.4l6.8-3.9l-51.7-29.9l16.3-9.4l14.2-8.2L247.9,974.3z"/>
              <path id="path130_7_" className="st0" d="M309.6,1106.2v-17.1l3.4-1.9v21L309.6,1106.2z"/>
              <path id="path146_4_" className="st0" d="M313,1087.2l-7.1-4.1l15.3-8.9v25.8l-8.3,4.8V1087.2z"/>
              <path id="path148_4_" className="st0" d="M321.2,1074.3l7.1,4.1v25.8l-7.1-4.1V1074.3z"/>
              <path id="path150_4_" className="st0" d="M328.3,1078.3l49.9-28.8v29.1l-47,27.2l-2.8-1.6L328.3,1078.3z"/>
              <path id="path152_4_" className="st0" d="M313,1104.8l8.3-4.8l7.1,4.1l2.8,1.6l0.2,0.1l-11.2,6.4l-7.2-4.2V1104.8z"/>
              <path id="path130_6_" className="st0" d="M309.6,1106.2v-17.1l3.4-1.9v21L309.6,1106.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0301" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path126_4_" className="st0" d="M244.9,1051.8l-44.4,25.6l-16.9-9.8l-27,15.6l-48.7-28.1l54.1-31.3l7.1,4.1l8.3-4.8l7.1-4.1
                l-7.1-4.1l33.9-19.6l0,0l51.7,29.9l-6.8,3.9l19.7,11.4l-25.2,14.5L244.9,1051.8z"/>
              <path id="path162_4_" className="st0" d="M107.9,1055.2l48.7,28.1l-22.7,13.1v2.8l-25.9-15V1055.2z"/>
              <path id="path164_4_" className="st0" d="M177.4,1015l7.1,4.1l-7.1,4.1V1015z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0302" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path62_4_" className="st0" d="M386.1,1039.6l-12.6,7.3l-25.2-14.5l37.8-21.8v13.4v12.4V1039.6z"/>
              <path id="path64_4_" className="st0" d="M227.7,969.6l91.2-52.6l5.3,3.1l0,0l52.5,30.3v0l18.8,10.9l38,21.9l-6.7,3.9l-10.7-6.2
                l-40.6,23.4l10.7,6.2l-37.8,21.8l-100.4-58l-6.1,3.5L227.7,969.6z"/>
              <path id="path66_4_" className="st0" d="M426.7,987.1l6.7-3.9v29.1l-3.9,2.2l-2.8-1.6L426.7,987.1z"/>
              <path id="path68_4_" className="st0" d="M416,980.9l10.7,6.2v25.8l-10.7-6.2V980.9z"/>
              <path id="path70_4_" className="st0" d="M375.4,1004.3l40.6-23.4v25.8l-29.9,17.3v-13.4L375.4,1004.3z"/>
              <path id="path72_4_" className="st0" d="M386.1,1023.9l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6V1023.9z"/>
              <path id="path74_4_" className="st0" d="M437.1,1018.8v3.3l-40.6,23.4v-3.3L437.1,1018.8z"/>
              <path id="path76_4_" className="st0" d="M386.1,1039.6v-3.3l10.4,6v3.3L386.1,1039.6z"/>
              <path id="path78_4_" className="st0" d="M227.7,986v-16.4l14.2,8.2L227.7,986z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A1-0301" floor="3" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path160_4_" className="st0" d="M395.5,939.5L343,909.2l-12.6,7.3l-24-13.8v-29.1l114.6,66.2v29.1L395.5,954V939.5z"/>
              <path id="path166_4_" className="st0" d="M397.6,820.9l114.6,66.2l-43.8,25.3l-10.7-6.2l-40.6,23.4l10.7,6.2l-6.7,3.9l-114.6-66.2
                L397.6,820.9z"/>
              <path id="path168_4_" className="st0" d="M421.1,939.7l6.7-3.9v29.1l-6.7,3.9V939.7z"/>
              <path id="path170_4_" className="st0" d="M512.2,916.2l-41,23.7l-2.8-1.6v-25.8l43.8-25.3V916.2z"/>
              <path id="path172_4_" className="st0" d="M427.8,935.8l-10.7-6.2l40.6-23.4V932l-29.9,17.3V935.8z"/>
              <path id="path174_4_" className="st0" d="M427.8,949.3l29.9-17.3l10.7,6.2l2.8,1.6l7.5,4.3l-40.6,23.4l-10.4-6L427.8,949.3z"/>
              <path id="path176_4_" className="st0" d="M457.7,906.2l10.7,6.2v25.8l-10.7-6.2V906.2z"/>
              <path id="path178_4_" className="st0" d="M427.8,964.9v-3.3l10.4,6v3.3L427.8,964.9z"/>
              <path id="path180_4_" className="st0" d="M438.1,970.9v-3.3l40.6-23.4v3.3L438.1,970.9z"/>
            </ModIsometrySvgFlat>
            <g id="A3-Areas" className="isometry__areas">
              <path id="path26_4_" className="st0" d="M269.8,1083.3l-29.6,17.1l-39.8-23l44.4-25.6l5.9,3.4l58.8,34l-14.8,8.6L269.8,1083.3z"/>
              <path id="path58_4_" className="st0" d="M322.2,1251.4v-29.1l18.8,10.9l-16.6,9.6v9.9L322.2,1251.4z"/>
              <path id="path108_4_" className="st0" d="M545,1330.8L545,1330.8v-14.5l18.8,10.9L545,1338V1330.8z"/>
              <path id="path128_4_" className="st0" d="M294.8,1097.7l14.8-8.6v17.1L294.8,1097.7z"/>
              <path id="path154_4_" className="st0" d="M616.4,1296.8l-52.5,30.3l-18.8-10.9l52.5-30.3l12.6,7.3L616.4,1296.8z"/>
              <path id="path156_4_" className="st0" d="M324.1,920L324.1,920l6.2-3.6l12.6-7.3l52.5,30.3l-18.8,10.9L324.1,920z"/>
              <path id="path158_4_" className="st0" d="M376.7,950.4L376.7,950.4l18.8-10.9v21.8L376.7,950.4z"/>
              <path id="path182_4_" className="st0" d="M322.2,1222.3l83.6-48.3l18.8,10.9l-83.6,48.3L322.2,1222.3z"/>
            </g>
            <g id="A3-Stroke" className="isometry__strokes">
              <path className="st2" d="M767.1,1321.3h-0.7l0.3-0.6l-96.3-55.6l-0.3,0.6l-0.3-0.6l-46.3,26.7v-6.1h-0.7l0.3-0.6l-75.7-43.7v-16h-0.7
                l0.3-0.6l-96.3-55.6l-0.3,0.6l-0.3-0.6l-17.8,10.3v-20.6h-0.7l0.3-0.6l-95.5-55.1l42-24.2l-0.3-0.6h0.7v-29.1h-0.7l0.3-0.6
                l-3.6-2.1l11.3-6.5l10,5.8l0.3-0.6l0.3,0.6l40.6-23.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-6.5-3.8l2.9-1.7l-0.3-0.6h0.7v-29.1h-0.7
                l0.3-0.6l-37.6-21.7v-5.7l24.6,14.2l0.3-0.6l0.3,0.6l6.4-3.7l10,5.8l0.3-0.6l0.3,0.6l40.6-23.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6
                l-6.5-3.8l40-23.1l-0.3-0.6h0.7v-29.1h-0.7l0.3-0.6l-114.6-66.2l-0.3,0.6l-0.3-0.6L306.1,873l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6
                l23,13.3l-4.9,2.8l-5-2.9l-0.3,0.6l-0.3-0.6L227.3,969l0.3,0.6H227v16l-16,9.2l0.3,0.6l0,0l-0.3-0.6l-33.9,19.6l0.3,0.6h-0.7v7.8
                l-7.6,4.4l-6.7-3.9l-0.3,0.6l-0.3-0.6l-54.1,31.3l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6l25.6,14.8v25.9h0.7l-0.3,0.6l56,32.3l-1.3,0.8
                l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l15.3,8.9l0.3-0.6l0.3,0.6l4.8-2.8l5.3,3.1l-5.2,3l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6l45.1,26
                l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l6.4,3.7l0.3-0.6l0.3,0.6l16.2-9.4l1.6,0.9v18.7h0.7
                l-0.3,0.6l2.9,1.7l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l28,16.2v16h0.7l-0.3,0.6l45.1,26
                l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l6.4,3.7l0.3-0.6l0.3,0.6l46.3-26.7v6.1h0.7l-0.3,0.6
                l2.9,1.7l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l48.6,28.1l0.3-0.6l0.3,0.6l126-72.7l-0.3-0.6
                h0.7V1321.3z M168.8,1028.5l0.3-0.6l0.3,0.6l8.3-4.8l7.1-4.1l-0.3-0.6l0.3-0.6l-6.1-3.5l32.6-18.8l50.4,29.1l-5.8,3.4l0.3,0.6
                l-0.3,0.6l18.7,10.8l-23.8,13.8l-5.5-3.2l-0.3,0.6l-0.3-0.6l-44.1,25.4l-16.6-9.6l-0.3,0.6l-0.3-0.6l-26.7,15.4l-47.3-27.3
                l52.8-30.5L168.8,1028.5z M183.1,1019.1l-5.1,2.9v-5.8L183.1,1019.1z M318.8,917.8l5,2.9l0,0l52.5,30.3l0.3-0.6l0,0l-0.3,0.6
                l18.8,10.9l37,21.3l-5.4,3.1l-10.4-6l-0.3,0.6l-0.3-0.6l-40.6,23.4l0.3,0.6l-0.3,0.6l9.7,5.6l-36.4,21l-100.1-57.8l-0.3,0.6
                l-0.3-0.6l-5.7,3.3l-12.9-7.4L318.8,917.8z M432.8,1011.9l-3.2,1.9l-2.2-1.3v-25l5.4-3.1V1011.9z M436.4,1021.7l-39.3,22.7v-1.7
                l39.3-22.7V1021.7z M377.5,1078.2l-46.4,26.8l-2.2-1.3v-25l48.6-28V1078.2z M208.3,1168.5l-3.8,2.2v-1.7l3.8-2.2V1168.5z
                  M240.8,1157.4l-30.5-17.6l6.1-3.5l-0.3-0.6l0.3-0.6l-15.3-8.9l-0.3,0.6l-0.3-0.6l-6.7,3.9l-58.5-33.8l21.7-12.5l26.7-15.4
                l16.6,9.6l39.8,23l0.3-0.6l0.3,0.6l29.2-16.9l24.6,14.2l14.8,8.6l3.4,1.9l6.2,3.6L240.8,1157.4z M208.3,1139.8v16.5l-6.9-4V1128
                l13.3,7.7l-6.1,3.5l0.3,0.6H208.3z M307.2,1083.1l13.3-7.7v24.3l-7,4v-16.5H313l0.3-0.6L307.2,1083.1z M308.9,1105.1l-12.8-7.4
                l12.8-7.4V1105.1z M327.6,1103l-5.7-3.3v-24.3l5.7,3.3V1103z M312.3,1104.8v2.2l-2-1.2v-16.3l2-1.2V1104.8z M320.1,1111.6
                l-6.5-3.8v-2.6l7.6-4.4l6.7,3.9l0,0l2,1.2L320.1,1111.6z M250.4,1055.7l57.8,33.4l-13.5,7.8l-24.6-14.2l-0.3,0.6l-0.3-0.6
                l-29.2,16.9l-38.4-22.2l43.1-24.9L250.4,1055.7z M194.3,1131.3l5.7-3.3v24.3l-5.7,3.3V1131.3z M328.3,1077.6l-6.7-3.9l-0.3,0.6
                l-0.3-0.6l-15.3,8.9l0.3,0.6l-0.3,0.6l6.1,3.5l-2,1.2l-57.5-33.2l24.2-14l-0.3-0.6l0.3-0.6l-18.7-10.8l5.8-3.4l-0.3-0.6l0.3-0.6
                l-50.7-29.3l15.3-8.9l14.2-8.2l0,0l5.7-3.3l100.1,57.8l25.2,14.5l3.6,2.1L328.3,1077.6z M426.1,1011.7l-9.4-5.4V982l9.4,5.4
                V1011.7z M415.4,1006.3l-28.6,16.5v-12.3h-0.7l0.3-0.6l-9.7-5.6l38.6-22.3V1006.3z M386.8,1037.5l9,5.2v1.7l-9-5.2V1037.5z
                  M373.5,1046.1l-23.8-13.8l35.8-20.7v12.3v12.4v2.9L373.5,1046.1z M396.5,1041.5l-9.7-5.6v-11.6l29.2-16.9l10.4,6l2.8,1.6
                l6.5,3.8L396.5,1041.5z M378,950.4l16.8-9.7V954v6.1L378,950.4z M421.7,940.1l5.4-3.1v12.3v12.4v2.9l-5.4,3.1V940.1z
                  M418.4,929.7l38.6-22.3v24.3l-28.6,16.5v-12.3h-0.7l0.3-0.6L418.4,929.7z M467.7,937l-9.4-5.4v-24.3l9.4,5.4V937z M428.4,962.8
                l9,5.2v1.7l-9-5.2V962.8z M478.1,947.1l-39.3,22.7V968l39.3-22.7V947.1z M438.1,966.9l-9.7-5.6v-11.6l29.2-16.9l10.4,6l2.8,1.6
                l6.5,3.8L438.1,966.9z M471.2,939.1l-2.2-1.3v-25l42.5-24.5v27.5L471.2,939.1z M510.9,887.1l-42.5,24.5l-10.4-6l-0.3,0.6
                l-0.3-0.6l-40.6,23.4l0.3,0.6l-0.3,0.6l9.7,5.6l-5.4,3.1l-113.3-65.4l89.8-51.9L510.9,887.1z M307.1,874.7l113.3,65.4v27.5
                l-24.2-14v-14.2h-0.7l0.3-0.6l-52.5-30.3l-0.3,0.6l-0.3-0.6l-12.3,7.1l-23.3-13.4V874.7z M330.7,917l12.3-7.1l51.2,29.6
                l-17.5,10.1L325.5,920L330.7,917z M240.5,977.8l-12.2,7v-14.1L240.5,977.8z M155.2,1083.2l-21.7,12.5l0.3,0.6h-0.7v1.7
                l-24.6-14.2v-27.5L155.2,1083.2z M134.5,1099.2v-1.7l58.5,33.8v25l-2.2,1.3l-56.3-32.5V1099.2z M191.2,1158.9L191.2,1158.9
                l2.8-1.6l-0.3-0.6l0.3,0.6l6.7-3.9l7.6,4.4v7.4l-4.5,2.6l-14-8.1L191.2,1158.9z M203.2,1168.9v1.7l-14-8.1v-1.7L203.2,1168.9z
                  M209.7,1165.6v-8.1v-16.5l29.8,17.2l-23.8,13.8l-6-3.4V1165.6z M216,1173.2l25.2-14.5l79.3-45.8l11.2-6.4l0,0l3.7-2.2l94.9,54.8
                l-24.8,14.3l0,0l-83.6,48.3l-16.2,9.4l-5.4-3.1l9.7-5.6l-0.3-0.6l0.3-0.6l-40.6-23.4l-0.3,0.6l-0.3-0.6l-10.4,6l-47.6-27.5
                L216,1173.2z M450.5,1170.8l94.9,54.8l-13.2,7.6l-111.5,64.4l-5.7-3.3l-41.9-24.2l9.7-5.6l-0.3-0.6l0.3-0.6l-40.6-23.4l-0.3,0.6
                l-0.3-0.6l-10.4,6l-5.4-3.1l15.6-9l83.6-48.3l7-4L450.5,1170.8z M298.3,1228v12.3l-28.6-16.5v-24.3l38.6,22.3l-9.7,5.6l0.3,0.6
                H298.3z M259,1204.9l9.4-5.4v24.3l-9.4,5.4V1204.9z M289.2,1260.1l9-5.2v1.7l-9,5.2V1260.1z M341.1,1232.4l-17.5-10.1l82.3-47.5
                l17.5,10.1L341.1,1232.4z M306.3,1232.2l15.9-9.2l17.5,10.1l-15.6,9l0.3,0.6h-0.7v8.8l-1.3-0.7l-0.3,0.6l-0.3-0.6l-15.6,9V1232.2
                z M331.9,1247l9.4-5.4v24.3l-9.4,5.4V1247z M342.6,1241.6l38.6,22.3l-9.7,5.6l0.3,0.6h-0.7v12.3l-28.6-16.5V1241.6z
                  M372.5,1295.9v-12.4v-12.3l40.9,23.6l-13.2,7.6l0.3,0.6h-0.7v11.5l-27.4-15.8V1295.9z M460.8,1326.2l38.6,22.3l-9.7,5.6l0.3,0.6
                h-0.7v12.3l-28.6-16.5V1326.2z M450.1,1331.7l9.4-5.4v24.3l-9.4,5.4V1331.7z M480.4,1386.9l9-5.2v1.7l-9,5.2V1386.9z
                  M490.7,1380.5v-12.4v-12.3l5.4,3.1v27.5l-5.4-3.1V1380.5z M609.8,1293.8l5.2,3l-51.2,29.6l-17.5-10.1l51.2-29.6L609.8,1293.8z
                  M551.5,1342.7l9.4-5.4v24.3l-9.4,5.4V1342.7z M562.2,1337.3l38.6,22.3l-9.7,5.6l0.3,0.6h-0.7v12.3l-28.6-16.5V1337.3z
                  M592.1,1391.6v-12.4V1367l47.6,27.5v27.5l-47.6-27.5V1391.6z M640.4,1393.3l-47.6-27.5l9.7-5.6l-0.3-0.6l0.3-0.6l-40.6-23.4
                l-0.3,0.6l-0.3-0.6l-10.4,6l-5.4-3.1l18.7-10.8l0,0l52.5-30.3l6.4-3.7l47-27.1l94.9,54.8L640.4,1393.3z M622.1,1292.7l-5.8,3.3
                l-4.9-2.8l10.7-6.2V1292.7z M610.1,1292.4l-12.3-7.1l-0.3,0.6l-0.3-0.6l-52.5,30.3l0.3,0.6h-0.7v14.2l-47.5,27.4l-5.4-3.1
                l9.7-5.6l-0.3-0.6l0.3-0.6l-40.6-23.4l-0.3,0.6l-0.3-0.6l-10.4,6l-47.6-27.5l12.9-7.4l5.7,3.3l0.3-0.6l0.3,0.6l111.5-64.4l13.9,8
                l75,43.3L610.1,1292.4z M546.1,1240.8l-12.2-7l12.2-7V1240.8z M431,1180.5l-6.3,3.7l-17.5-10.1l23.8-13.8V1180.5z M210.1,1177.4
                l47.6,27.5v25l-2.2,1.3l-45.4-26.2V1177.4z M255.8,1232.5L255.8,1232.5l2.8-1.6l-0.3-0.6l0.3,0.6l10.4-6l29.2,16.9v11.6l-9.7,5.6
                l-39.3-22.7L255.8,1232.5z M287.9,1260.1v1.7l-39.3-22.7v-1.7L287.9,1260.1z M299.6,1256.7v-2.9v-12.4v-12.3l5.4,3.1v27.5
                L299.6,1256.7z M325.2,1252.7v-8.8l5.4,3.1v25l-2.2,1.3l-3.2-1.9V1252.7z M328.7,1274.6L328.7,1274.6l2.8-1.6l-0.3-0.6l0.3,0.6
                l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L328.7,1274.6z M360.8,1302.2v1.7l-39.3-22.7v-1.7L360.8,1302.2z M362.1,1302.2l9-5.2
                v1.7l-9,5.2V1302.2z M401.2,1315.7v-11.5l47.6,27.5v25l-2.2,1.3l-45.4-26.2V1315.7z M447,1359.3L447,1359.3l2.8-1.6l-0.3-0.6
                l0.3,0.6l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L447,1359.3z M439.8,1365.9v-1.7l39.3,22.7v1.7L439.8,1365.9z M497.5,1359
                l47.8-27.6l-0.3-0.6h0.7v-13.4l16.8,9.7l-17.8,10.3l0.3,0.6l-0.3-0.6l-0.8,0.5l0.3,0.6h-0.7v21.5l-46,26.6V1359z M544.8,1360.3
                v-20.7l5.4,3.1v25l-2.2,1.3l-3.2-1.9V1360.3z M548.3,1370.4l2.8-1.6l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L548.3,1370.4z
                  M580.4,1398v1.7l-39.3-22.7v-1.7L580.4,1398z M581.8,1398l9-5.2v1.7l-9,5.2V1398z M641.1,1394.4l124.7-72v27.5l-124.7,72V1394.4
                z"/>
            </g>
          </g>
          <g id="A4" className={'isometry__floor ' + (floor === "4" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="A2-0403" floor="4" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path24" className="st0" d="M320.1,1083.2l-79.3,45.8l-31.8-18.4l7.1-4.1l-15.3-8.9l-7.1,4.1l-59.8-34.5l22.7-13.1l27-15.6
                l16.9,9.8l39.8,23l29.6-17.1l24.9,14.4l14.8,8.6l3.4,1.9L320.1,1083.2z"/>
              <path id="path48" className="st0" d="M200.7,1097.8l15.3,8.9l-7.1,4.1v17.7l-8.3-4.8L200.7,1097.8z"/>
              <path id="path50" className="st0" d="M200.7,1123.6l8.3,4.8v8.1l-5.2,3l-15.3-8.9l2.3-1.3l2.8-1.6L200.7,1123.6z"/>
              <path id="path52" className="st0" d="M200.7,1123.6l-7.1,4.1v-25.8l7.1-4.1V1123.6z"/>
              <path id="path54" className="st0" d="M188.5,1130.6l15.3,8.9v3.3l-15.3-8.9V1130.6z"/>
              <path id="path56" className="st0" d="M203.9,1139.5l5.2-3v3.3l-5.2,3V1139.5z"/>
              <path id="path58" className="st0" d="M190.8,1129.3l-57-32.9v-29.1l59.8,34.5v25.8L190.8,1129.3z"/>
              <path id="path60" className="st0" d="M215.6,1143.6l-6.6-3.8v-29.1l31.8,18.4L215.6,1143.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0402" floor="4" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path38" className="st0" d="M275.9,1011.5l-19.7-11.4l6.8-3.9l-51.7-29.9l36.6-21.1l130.3,75.2l-49.9,28.8l-7.1-4.1
                l-15.3,8.9l7.1,4.1l-3.4,1.9l-58.8-34L275.9,1011.5z"/>
              <path id="path40" className="st0" d="M313,1058.1l-7.1-4.1l15.3-8.9v25.8l-8.3,4.8V1058.1z"/>
              <path id="path42" className="st0" d="M321.2,1045.2l7.1,4.1v25.8l-7.1-4.1V1045.2z"/>
              <path id="path44" className="st0" d="M328.3,1049.2l49.9-28.8v29.1l-47,27.2l-2.8-1.6L328.3,1049.2z"/>
              <path id="path46" className="st0" d="M313,1075.8l8.3-4.8l7.1,4.1l2.8,1.6l0.2,0.1l-11.2,6.4l-7.2-4.2V1075.8z"/>
              <path id="path118" className="st1" d="M309.6,1060v17.1"/>
              <path id="path32" className="st0" d="M309.6,1077.2V1060l3.4-1.9v21L309.6,1077.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0401" floor="4" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path28" className="st0" d="M244.9,1022.7l-44.4,25.6l-16.9-9.8l-27,15.6l-48.7-28.1l54.1-31.3l7.1,4.1l8.3-4.8l7.1-4.1
                l-7.1-4.1l33.9-19.6l0,0l51.7,29.9l-6.8,3.9l19.7,11.4l-25.2,14.5L244.9,1022.7z"/>
              <path id="path34" className="st0" d="M107.9,1026.1l48.7,28.1l-22.7,13.1v2.8l-25.9-15V1026.1z"/>
              <path id="path36" className="st0" d="M177.4,986l7.1,4.1l-7.1,4.1V986z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0401" floor="4" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path62" className="st0" d="M420.8,1298.3l-48.9-28.3v-25.8l-29.9-17.3l-10.7-6.2v22.5l-2.8,1.6l-3.9-2.2v-19.1v-9.9
                l96.3,55.6V1298.3z"/>
              <path id="path64" className="st0" d="M331.2,1243.3v-22.5l10.7,6.2v10.2L331.2,1243.3z"/>
              <path id="path66" className="st0" d="M331.2,1243.3l10.7-6.2l29.9,17.3v12.4l-10.4,6l-40.6-23.4l7.5-4.3L331.2,1243.3z"/>
              <path id="path68" className="st0" d="M341.9,1237.2V1227l29.9,17.3v10.2L341.9,1237.2z"/>
              <path id="path70" className="st0" d="M320.9,1249.3l40.6,23.4v3.3l-40.6-23.4L320.9,1249.3z"/>
              <path id="path72" className="st0" d="M361.5,1272.8l10.4-6v3.3l-10.4,6V1272.8z"/>
              <path id="path74" className="st0" d="M420.8,1269.2l-96.3-55.6l16.6-9.6l0,0l83.6-48.3l0,0l7-4l18.8-10.9l96.3,55.6L420.8,1269.2z"/>
              <path id="path76" className="st0" d="M546.8,1196.5v29.1l-126,72.7v-29.1L546.8,1196.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A3-0402" floor="4" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path22" className="st0" d="M240.8,1129l79.3-45.8l11.2-6.4l4.1-2.3l96.3,55.6l-25.8,14.9v0l-83.6,48.3v0l-16.6,9.6
                l-96.3-55.6l6.2-3.6L240.8,1129z"/>
              <path id="path82" className="st0" d="M322.2,1193.2v29.1l-16.6,9.6v-29.1L322.2,1193.2z"/>
              <path id="path84" className="st0" d="M255.5,1202.9l-46.1-26.6v-29.1l96.3,55.6v29.1l-6.7-3.9v-3.3v-12.4v-10.2l-29.9-17.3l-10.7-6.2
                v22.5L255.5,1202.9z"/>
              <path id="path86" className="st0" d="M298.9,1224.7v3.3l-10.4,6v-3.3L298.9,1224.7z"/>
              <path id="path88" className="st0" d="M298.9,1224.7l-10.4,6l-40.6-23.4l7.5-4.3l2.8-1.6l10.7-6.2l29.9,17.3L298.9,1224.7z"/>
              <path id="path90" className="st0" d="M298.9,1212.3l-29.9-17.3v-10.2l29.9,17.3V1212.3z"/>
              <path id="path92" className="st0" d="M269,1195.1l-10.7,6.2v-22.5l10.7,6.2V1195.1z"/>
              <path id="path94" className="st0" d="M248,1207.2l40.6,23.4v3.3l-40.6-23.4V1207.2z"/>
              <path id="path96" className="st0" d="M431.7,1130v21.7l-7,4l-18.8-10.9L431.7,1130z"/>
            </ModIsometrySvgFlat>
            <g id="A4-Areas" className="isometry__areas">
              <path id="path26" className="st0" d="M240.3,1071.3l-39.8-23l44.4-25.6l5.9,3.4l58.8,34l-14.8,8.6l-24.9-14.4L240.3,1071.3z"/>
              <path id="path30" className="st0" d="M294.8,1068.6l14.8-8.6v17.1L294.8,1068.6z"/>
              <path id="path78" className="st0" d="M424.7,1155.8l-83.6,48.3l-18.8-10.9l83.6-48.3L424.7,1155.8z"/>
              <path id="path80" className="st0" d="M324.5,1223.6l-2.3-1.3v-29.1l18.8,10.9l-16.6,9.6V1223.6z"/>
            </g>
            <g id="A4-Strokes" className="isometry__strokes">
              <path d="M547.4,1196.5h-0.7l0.3-0.6l-96.3-55.6l-0.3,0.6l-0.3-0.6l-17.8,10.3V1130h-0.7l0.3-0.6l-95.5-55.1l42-24.2l-0.3-0.6h0.7
                v-29.1h-0.7l0.3-0.6l-130.3-75.2l-0.3,0.6l-0.3-0.6L211,965.8l0.3,0.6l0,0l-0.3-0.6l-33.9,19.6l0.3,0.6h-0.7v7.8l-7.6,4.4
                l-6.7-3.9l-0.3,0.6l-0.3-0.6l-54.1,31.3l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6l25.6,14.8v25.8h0.7l-0.3,0.6l56,32.3l-1.3,0.8l0.3,0.6
                h-0.7v3.3h0.7l-0.3,0.6l15.3,8.9l0.3-0.6l0.3,0.6l4.8-2.8l5.3,3.1l-5.2,3l0.3,0.6h-0.7v29.1h0.7l-0.3,0.6l45.1,26l-6.5,3.8
                l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l6.4,3.7l0.3-0.6l0.3,0.6l16.2-9.4l1.6,0.9v18.7h0.7l-0.3,0.6
                l2.9,1.7l-6.5,3.8l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l40.6,23.4l0.3-0.6l0.3,0.6l10-5.8l48.6,28.1l0.3-0.6l0.3,0.6l126-72.7l-0.3-0.6
                h0.7V1196.5z M546.1,1225.2l-124.7,72v-27.5l124.7-72V1225.2z M328.7,1245.5L328.7,1245.5l2.8-1.6l-0.3-0.6l0.3,0.6l10.4-6
                l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7L328.7,1245.5z M341.3,1236.8l-9.4,5.4V1222l9.4,5.4V1236.8z M342.6,1236.8v-8.6l28.6,16.5
                v8.6L342.6,1236.8z M360.8,1273.1v1.7l-39.3-22.7v-1.7L360.8,1273.1z M362.1,1273.1l9-5.2v1.7l-9,5.2V1273.1z M372.5,1269.7v-2.9
                v-12.4v-10.2h-0.7l0.3-0.6l-29.9-17.3l-10.7-6.2l-0.3,0.6h-0.7v22.1l-2.2,1.3l-3.2-1.9v-18.7v-8.8l94.9,54.8v27.5L372.5,1269.7z
                  M210.1,1148.3l94.9,54.8v27.5l-5.4-3.1v-2.9v-12.4v-10.2h-0.7l0.3-0.6l-29.9-17.3l-10.7-6.2l-0.3,0.6h-0.7v22.1l-2.2,1.3
                l-45.4-26.2V1148.3z M183.1,990l-5.1,2.9v-5.8L183.1,990z M169.4,999.5l8.3-4.8l7.1-4.1l-0.3-0.6l0.3-0.6l-6.1-3.5l32.6-18.8
                l50.4,29.1l-5.8,3.4l0.3,0.6l-0.3,0.6l18.7,10.8l-23.8,13.8l-5.5-3.2l-0.3,0.6l-0.3-0.6l-44.1,25.4l-16.6-9.6l-0.3,0.6l-0.3-0.6
                l-26.7,15.4l-47.3-27.3l52.8-30.5l6.7,3.9l0.3-0.6L169.4,999.5z M377.5,1049.1l-46.4,26.8l-2.2-1.3v-25l48.6-28V1049.1z
                  M320.1,1082.5l-6.5-3.8v-2.6l7.6-4.4l6.7,3.9l0,0l2,1.2L320.1,1082.5z M240.8,1128.3l-30.5-17.6l6.1-3.5l-0.3-0.6l0.3-0.6
                l-15.3-8.9l-0.3,0.6l-0.3-0.6l-6.7,3.9l-58.5-33.8l21.7-12.5l26.7-15.4l16.6,9.6l39.8,23l0.3-0.6l0.3,0.6l29.2-16.9l24.6,14.2
                l14.8,8.6l3.4,1.9l6.2,3.6L240.8,1128.3z M208.3,1110.7v16.5l-6.9-4v-24.3l13.3,7.7l-6.1,3.5l0.3,0.6H208.3z M194.3,1102.2
                l5.7-3.3v24.3l-5.7,3.3V1102.2z M244.9,1023.5l5.5,3.2l57.8,33.4l-13.5,7.8l-24.6-14.2l-0.3,0.6l-0.3-0.6l-29.2,16.9l-38.4-22.2
                L244.9,1023.5z M307.2,1054l13.3-7.7v24.3l-7,4v-16.5H313l0.3-0.6L307.2,1054z M327.6,1073.9l-5.7-3.3v-24.3l5.7,3.3V1073.9z
                  M312.3,1075.8v2.2l-2.4-1.4l-13.8-8l13.8-8l2.4-1.4V1075.8z M204.5,1139.8l3.8-2.2v1.7l-3.8,2.2V1139.8z M376.8,1020.4l-48.6,28
                l-6.7-3.9l-0.3,0.6l-0.3-0.6l-15.3,8.9l0.3,0.6l-0.3,0.6l6.1,3.5l-2,1.2l-57.5-33.2l24.2-14l-0.3-0.6l0.3-0.6l-18.7-10.8l5.8-3.4
                l-0.3-0.6l0.3-0.6l-50.7-29.3l35.3-20.4L376.8,1020.4z M155.2,1054.2l-21.7,12.5l0.3,0.6h-0.7v1.7l-24.6-14.2v-27.5L155.2,1054.2
                z M134.5,1070.1v-1.7l58.5,33.8v25l-2.2,1.3l-56.3-32.5V1070.1z M191.2,1129.8L191.2,1129.8l2.8-1.6l-0.3-0.6l0.3,0.6l6.7-3.9
                l7.6,4.4v7.4l-4.5,2.6l-14-8.1L191.2,1129.8z M203.2,1139.8v1.7l-14-8.1v-1.7L203.2,1139.8z M209.7,1136.5v-8.1v-16.5l29.8,17.2
                l-23.8,13.8l-6-3.4V1136.5z M216,1144.2l25.2-14.5l79.3-45.8l11.2-6.4l3.7-2.2l94.9,54.8l-24.8,14.3l0,0l-83.6,48.3l0,0l0,0
                l-16.2,9.4l-94.9-54.8L216,1144.2z M341.1,1203.3l-17.5-10.1l82.3-47.5l17.5,10.1L341.1,1203.3z M306.3,1203.2l15.9-9.2
                l17.5,10.1l-15.6,9l0.3,0.6h-0.7v8.8l-1.3-0.7l-0.3,0.6l-0.3-0.6l-15.6,9V1203.2z M420.8,1268.5l-94.9-54.8l15.6-9l83.6-48.3l7-4
                l18.5-10.7l94.9,54.8L420.8,1268.5z M431,1151.4l-6.3,3.7l-17.5-10.1l23.8-13.8V1151.4z M268.4,1194.7l-9.4,5.4v-20.2l9.4,5.4
                V1194.7z M269.7,1186.1l28.6,16.5v8.6l-28.6-16.5V1186.1z M255.8,1203.5l2.8-1.6l10.4-6l29.2,16.9v11.6l-9.7,5.6l-39.3-22.7
                L255.8,1203.5z M287.9,1231.1v1.7l-39.3-22.7v-1.7L287.9,1231.1z M289.2,1231.1l9-5.2v1.7l-9,5.2V1231.1z"/>
            </g>
          </g>
          <g id="A5" className={'isometry__floor ' + (floor === "5" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="A2-0501" floor="5" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path40_8_" className="st0" d="M183.6,1009.5l-27,15.6L107.9,997l103.4-59.7l0,0l51.7,29.9l-6.8,3.9l19.7,11.3l-25.2,14.5
                l-5.9-3.4l-44.4,25.6L183.6,1009.5z"/>
              <path id="path42_8_" className="st0" d="M107.9,997l48.7,28.1l-22.7,13.1v2.8l-25.9-15v-29H107.9z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0502" floor="5" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path44_8_" className="st0" d="M275.9,982.4L256.2,971l6.8-3.9l-51.7-29.9l36.6-21.1l130.3,75.2l-68.6,39.6l-58.8-34
                L275.9,982.4z"/>
              <path id="path46_8_" className="st0" d="M309.6,1030.9l68.6-39.6v29.1l-47,27.2l-2.8-1.6v-22.6l-7.1,4.1l-8.3,4.8v14.4v3.3l-3.4-1.9
                V1030.9z"/>
              <path id="path48_8_" className="st0" d="M328.3,1023.4v22.5l-7.1-4.1v-14.4L328.3,1023.4z"/>
              <path id="path50_8_" className="st0" d="M321.2,1027.5v14.4l-8.3,4.8v-14.4L321.2,1027.5z"/>
              <path id="path52_8_" className="st0" d="M312.9,1046.7l8.3-4.8l7.1,4.1l2.8,1.6l2.3,1.3l-13.3,7.7v-2.5l-7.2-4.2L312.9,1046.7
                L312.9,1046.7z"/>
              <path id="path54_8_" className="st0" d="M320.1,1056.6l13.3-7.7v3.3l-13.3,7.7V1056.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="A2-0504" floor="5" building="A" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onBuyFlatClick={this.props.onBuyFlatClick}>
              <path id="path22_8_" className="st0" d="M193.6,1098.5l7.1-4.1l8.3,4.8v8.1l-5.2,3l-15.3-8.9l2.3-1.3L193.6,1098.5z"/>
              <path id="path24_8_" className="st0" d="M200.7,1094.5l-7.1,4.1v-22.5l7.1,4.1V1094.5z"/>
              <path id="path26_8_" className="st0" d="M188.5,1101.5l15.3,8.9v3.3l-15.3-8.9V1101.5z"/>
              <path id="path28_8_" className="st0" d="M203.8,1110.4l5.2-3v3.3l-5.2,3V1110.4z"/>
              <path id="path30_8_" className="st0" d="M209,1099.2l-8.3-4.8V1080l8.3,4.8V1099.2z"/>
              <path id="path32_8_" className="st0" d="M190.8,1100.2l-57-32.9v-29.1l107,61.8v29.1l-31.8-18.4v-3.3v-8.1v-14.4l-8.3-4.8l-7.1-4.1
                v22.5L190.8,1100.2z"/>
              <path id="path34_8_" className="st0" d="M320.1,1083.2l-79.3,45.8v-29.1l79.3-45.8v2.5v3.3V1083.2z"/>
              <path id="path36_8_" className="st0" d="M240.8,1099.9l-107-61.8l22.7-13.1l27-15.6l16.9,9.8l39.8,23l29.6-17.1l24.9,14.4l14.8,8.6
                l3.4,1.9l7.2,4.2L240.8,1099.9z"/>
            </ModIsometrySvgFlat>
            <g id="A5-Areas" className="isometry__areas">
              <path id="path38_8_" className="st0" d="M269.8,1025.1l-29.6,17.1l-39.8-23l44.4-25.6l5.9,3.4l58.8,34l-14.8,8.6L269.8,1025.1z"/>
              <path id="path56_8_" className="st0" d="M309.6,1030.9v17.1l-14.8-8.6L309.6,1030.9z"/>
            </g>
            <g id="A5-Strokes" className="isometry__strokes">
              <path d="M378.8,991.3h-0.6l0.3-0.6l-130.3-75.2l-0.4,0.6l-0.3-0.5L211,936.7l0.3,0.6l-0.3-0.6l-103.4,59.7l0.3,0.6h-0.7v29.1h0.7
                l-0.3,0.6l25.6,14.8v25.7h0.7l-0.4,0.7l56,32.3l-1.3,0.7l0.3,0.6h-0.7v3.3h0.6l-0.3,0.5l15.3,8.9l0.3-0.5h0.1l0.3,0.6l5-2.9
                l31.4,18.2l0.3-0.6l0.3,0.6l79.3-45.8l-0.3-0.6h0.7v-22.9l13-7.5l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-1.3-0.7l46-26.6l-0.3-0.6h0.7
                V991.3z M377.5,1020l-46.2,26.8l-2.2-1.3v-22.1h-0.7l-0.3-0.6l-7.1,4.1l0.3,0.6l-0.3-0.6l-8.3,4.8l0.3,0.6h-0.7v14.4v2.1l-2-1.1
                v-16.5l67.2-38.8V1020z M194.3,1077.2l5.8,3.3v13.5l-5.8,3.3V1077.2z M208.4,1098.2l-7-4v-12.9l7,4V1098.2z M204.5,1110.8
                l3.9-2.2v1.8l-3.9,2.2V1110.8z M191.2,1100.8l-0.3-0.6l0,0l0.3,0.5l2.8-1.6l-0.3-0.6l0.3,0.6l6.7-3.9l7.7,4.5v7.3l-4.5,2.6
                l-14-8.1L191.2,1100.8z M203.2,1110.8v1.7l-14-8.1v-1.7L203.2,1110.8z M209.7,1110.2v-2.8v-8.1H209l0.1-0.1h0.6v-14.4h-0.6
                l0.3-0.5l-8.3-4.8l-7.1-4.1l-0.3,0.6H193v22.1l-2.2,1.3l-56.2-32.5V1041v-1.7l105.7,61v27.6L209.7,1110.2z M248,916.9l128.8,74.4
                l-67.3,38.8l-57.4-33.2l24.1-13.9l-0.3-0.6l0.3-0.6l-18.6-10.8l5.7-3.3l-0.3-0.6l0.3-0.6l-50.7-29.3L248,916.9z M327.7,1024.6
                v20.2l-5.8-3.3v-13.5L327.7,1024.6z M313.6,1032.7l7-4v12.9l-7,4V1032.7z M312.6,1050.6L312.6,1050.6l6.1,3.6l-77.9,45l-105.6-61
                l21.6-12.5l-0.3-0.5h0l0.3,0.5l26.7-15.4l16.6,9.6l0,0l39.7,22.9l0.3-0.6l0.3,0.6l29.3-16.9l24.6,14.2l0,0l14.8,8.6l0.4-0.7h0.1
                l-0.4,0.7L312.6,1050.6z M294.8,1039.4L294.8,1039.4L294.8,1039.4L294.8,1039.4z M296.1,1039.6l12.9-7.5v14.9L296.1,1039.6z
                  M250.5,997.6L250.5,997.6l57.8,33.3l-13.5,7.9l-24.6-14.2l-0.3,0.6l-0.3-0.6l-29.3,16.9l-38.4-22.2l43.1-24.9L250.5,997.6z
                  M261.7,967.2l-5.8,3.3l0.3,0.5l-0.3,0.5l18.7,10.8l-23.8,13.8l-5.5-3.2l-0.3,0.6l-0.3-0.6l-44.2,25.5l-16.5-9.6l-0.4,0.7
                l-0.4-0.7l-26.6,15.4L109.3,997l102.1-58.9L261.7,967.2z M155.2,1025.1l-21.8,12.6l0.3,0.5l0,0h-0.6v1.7l-24.6-14.2v-27.6
                L155.2,1025.1z M319.5,1082.8l-78,45v-27.6l78-45v1.3v3.3V1082.8z M320.8,1057l12-6.9v1.8l-12,6.9V1057z M320.8,1055.5v-1.3h-0.6
                l0.3-0.5l-6.9-4v-2.5l7.7-4.5l6.7,3.9l0.3-0.6l-0.3,0.6l2.8,1.6l0.3-0.5l0,0l-0.3,0.6l1.3,0.7L320.8,1055.5z"/>
            </g>
          </g>
        </g>
        <g id="C" className={'isometry__building ' + (building === "C" ? 'active' : '')}>
            <g id="C0" className={'isometry__floor ' + (floor === "0" ? 'active' : '')}>
              <ModIsometrySvgFlat flat="C1-0001" floor="0" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_12_" className="st0" d="M1354.6,608.1v30.6l-73.8,42.6v-30.6L1354.6,608.1z"/>
              <path id="path38_12_" className="st0" d="M1254.7,614.7l4.5,2.6l28.7-16.6l-12.3-7.1l26.9-15.5l12.3,7.1l39.8,23l-73.8,42.6
                l-44.3-25.6L1254.7,614.7z"/>
              <path id="path44_12_" className="st0" d="M1280.8,681.4l-44.3-25.6v-30.6l44.3,25.6V681.4z"/>
            </ModIsometrySvgFlat>
            <g id="C0-Areas" className="isometry__areas">
              <path id="path26_5_" className="st0" d="M1425.3,598l-70.6,40.8v-30.6l70.6-40.8V598z"/>
              <path id="path30_25_" className="st0" d="M1425.3,567.4l-39.8-23l-70.6,40.8l-12.3-7.1l-26.9,15.5l12.3,7.1l-28.7,16.6l-4.5-2.6
                l-19.7-11.4l-1.3-0.7l55.6-32.1l-69.1-39.9l33.1-19.1l69.1,39.9l30.4-17.5l3.5,2.1l20.8-12l61.8,35.7L1425.3,567.4z"/>
              <path id="path32_27_" className="st0" d="M1425.3,598v-30.6l13.7-7.9V590L1425.3,598z"/>
              <path id="path34_26_" className="st0" d="M1236.5,634.8l-1.5-0.9v-30.6l19.7,11.4l-18.2,10.5V634.8z"/>
              <path id="path36_28_" className="st0" d="M1289.3,570.4l-55.6,32.1l1.3,0.7l-18.2,10.5l-70.4-40.6l73.8-42.6L1289.3,570.4z"/>
              <path id="path40_5_" className="st0" d="M1439,559.4l-61.8-35.7l-20.8,12l-3.5-2.1l-30.4,17.5l-69.1-39.9l100.8-58.2l134.4,77.6
                L1439,559.4z"/>
              <path id="path42_11_" className="st0" d="M1439,590v-30.6l49.6-28.6v30.6L1439,590z"/>
              <path id="path46_11_" className="st0" d="M1216.8,644.4l-70.4-40.6v-30.6l70.4,40.6V644.4z"/>
              <path id="path48_11_" className="st0" d="M1235,633.9l-18.2,10.5v-30.6l18.2-10.5V633.9z"/>
              <g id="path28_12_">
                <path className="st0" d="M1425.3,567.4l-70.6,40.8l0,0l-39.8-23l70.6-40.8L1425.3,567.4z"/>
              </g>
            </g>
            <g id="C0-Strokes" className="isometry__strokes">
              <path d="M1675.4,620.4L1494.7,516l-0.3,0.6l-0.3-0.6l-14.8,8.6l-124.7-72l-0.3,0.6l-0.3-0.6l-207.8,120l0.3,0.6h-0.7v30.6h0.7
                l-0.3,0.6l70.4,40.6l0.3-0.6l0.3,0.6l17.9-10.3l0.8,0.5v20.7h0.7l-0.3,0.6l44.3,25.6l0.3-0.6l0.3,0.6l73.8-42.6l36.6-21.1
                l80.1,46.2l0.3-0.6l0.3,0.6l19.2-11.1l14.9,8.6l-18.6,10.7l0.3,0.6l-0.3,0.6l48.2,27.8l0.3-0.6l0.3,0.6l138.7-80.1l-0.3-0.6
                L1675.4,620.4z M1507.8,661.9l0.3-0.6l-16.2-9.4l-0.3,0.6l-0.3-0.6l-19.2,11.1l-79.1-45.7l32.7-18.9l0,0l13.7-7.9l-0.3-0.6
                l0.3,0.6l49.6-28.6l-0.3-0.6h0.7v-30.6h-0.7l0.3-0.6l-8.4-4.8l13.8-8L1673.8,621l-137.4,79.3l-46.9-27l18.6-10.7L1507.8,661.9z
                  M1487.3,530.8l-48.3,27.9l-61.4-35.5l-0.3,0.6l-0.3-0.6l-20.5,11.8l-3.2-1.9l-0.3,0.6l-0.3-0.6l-30.1,17.4l-67.8-39.1l99.5-57.4
                l124.7,72l0,0L1487.3,530.8z M1487.9,561l-48.3,27.9v-29.1l48.3-27.9V561z M1147.7,573.2l72.5-41.9l67.8,39.1l-54.6,31.5l0.3,0.6
                l-0.3,0.6l0.3,0.2l-16.9,9.8L1147.7,573.2z M1254.7,615.4l4.2,2.4l0.3-0.6l0.3,0.6l28.7-16.6l-0.3-0.6l0.3-0.6l-11.3-6.5
                l25.5-14.8l12,6.9l38.8,22.4l-72.5,41.9l-43-24.8L1254.7,615.4z M1354.6,607.4l-38.4-22.2l69.3-40l38.4,22.2L1354.6,607.4z
                  M1437.7,559.4l-12.4,7.2l-39.4-22.8l-0.3,0.6l-0.3-0.6l-70.3,40.6l-12-6.9l-0.3,0.6l-0.3-0.6l-26.9,15.5l0.3,0.6l-0.3,0.6
                l11.3,6.5l-27.4,15.8l-4.2-2.4l0,0l-19.7-11.4l-0.3,0.6l0,0l0.3-0.6l-0.3-0.2l54.6-31.5l-0.3-0.6l0.3-0.6l-68.1-39.3l31.8-18.4
                l68.8,39.7l0.3-0.6l0.3,0.6l30.1-17.4l3.2,1.9l0.3-0.6l0.3,0.6l20.5-11.8L1437.7,559.4z M1425.9,567.7l12.4-7.2v29.1l-12.4,7.2
                V567.7z M1217.4,614.2l16.9-9.8v29.1l-16.9,9.8V614.2z M1235.7,604.4l17.7,10.2l-17.2,9.9l0.3,0.6h-0.7v8.4l-0.2-0.1V604.4z
                  M1237.2,626.3l43,24.8v29.1l-43-24.8v-20.7V626.3z M1281.5,651.2l72.5-41.9v29.1l-72.5,41.9V651.2z M1424.6,568.5v29.1l-33,19.1
                l-25.2-14.5L1424.6,568.5z M1216.1,614.2v29.1l-69.1-39.9v-29.1L1216.1,614.2z M1355.3,608.5l9.7-5.6l25.2,14.5l-34.9,20.2V608.5
                z"/>
            </g>
          </g>
          <g id="C1" className={'isometry__floor ' + (floor === "1" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.09.0102" floor="1" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path42_13_" className="st0" d="M1556.1,575.2l46.4-26.8l72.6,41.9l-138.7,80.1l-4.6-2.7l15.4-8.9l-29.4-17l-15.4,8.9
                l-14.1-8.1l3.3-1.9l16.2-9.4l8,4.6l52.4-30.3l0,0l-27.6-15.9l20.4-11.8L1556.1,575.2z"/>
              <path id="path50_13_" className="st0" d="M1488.2,642.6l14.1,8.1v27.3l-2.8,1.6l-11.3-6.5v-18.7V642.6z"/>
              <path id="path52_13_" className="st0" d="M1494.7,685.7v-3.3l29.4,17v3.3L1494.7,685.7z"/>
              <path id="path54_13_" className="st0" d="M1524.2,699.5l-29.4-17l4.7-2.7l2.8-1.6l15.4-8.9l14,8.1v17.8L1524.2,699.5z"/>
              <path id="path56_13_" className="st0" d="M1531.7,677.3l-14-8.1v-27.3l29.4,17l-15.4,8.9V677.3z"/>
              <path id="path58_13_" className="st0" d="M1536.4,670.4l138.7-80.1V621L1536.4,701V670.4z"/>
              <path id="path60_13_" className="st0" d="M1536.4,670.4V701l-4.6-2.7v-30.6L1536.4,670.4z"/>
              <path id="path62_13_" className="st0" d="M1524.2,699.5l7.6-4.4v3.3l-7.6,4.4V699.5z"/>
              <path id="path64_13_" className="st0" d="M1502.3,678.1v-27.3l15.4-8.9v27.3L1502.3,678.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.09.0101" floor="1" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path22_13_" className="st0" d="M1412.9,608.6v-27.3l27.7,16l-15.4,8.9v9.5L1412.9,608.6z"/>
              <path id="path26_13_" className="st0" d="M1397.4,617.5l15.5-8.9l12.3,7.1v17.8l-7.6,4.4l-27.7-16l4.7-2.7L1397.4,617.5z"/>
              <path id="path28_13_" className="st0" d="M1389.9,621.9l27.7,16v3.3l-27.7-16V621.9z"/>
              <path id="path30_13_" className="st0" d="M1417.6,641.2v-3.3l7.6-4.4v3.3L1417.6,641.2z"/>
              <path id="path32_13_" className="st0" d="M1536.1,510.1l66.3,38.3l-46.4,26.8l-10.4-6l-20.3,11.8l-22-12.7l-25.6,14.8l33.6,19.4
                l-26.8,15.5l0,0l7.1,4.1l-19.5,11.3l-46.8-27l15.4-8.9l-27.7-16L1536.1,510.1z"/>
              <path id="path40_13_" className="st0" d="M1425.2,606.2l46.8,27v30.6l-46.8-27v-3.3v-17.8V606.2z"/>
              <path id="path44_13_" className="st0" d="M1488.2,654.5l-16.2,9.4v-30.6l19.5-11.3v18.7l-3.3,1.9L1488.2,654.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0103" floor="1" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path48_13_" className="st0" d="M1536.1,510.1l-123.3,71.2l-15.5,8.9l-4.6-2.7l14.1-8.2l-27.7-16l-24.5,14.2l-39.8-23
                l64.8-37.4l-25.6-14.8l57.6-33.2l11.5-6.7l55.9,32.3l15.2-8.8L1536.1,510.1z"/>
              <path id="path78_13_" className="st0" d="M1354.6,608.1v-30.6l24.5-14.2v27.3l-21.7,12.5v3.3L1354.6,608.1z"/>
              <path id="path90_13_" className="st0" d="M1385.2,622.5l-27.7-16v-3.3l27.7,16V622.5z"/>
              <path id="path92_13_" className="st0" d="M1385.2,622.5v-3.3l7.6-4.4v3.3L1385.2,622.5z"/>
              <path id="path94_13_" className="st0" d="M1357.5,603.2l21.7-12.5l13.6,7.9v16.3l-7.6,4.4L1357.5,603.2z"/>
              <path id="path96_13_" className="st0" d="M1392.8,598.6l-13.6-7.9v-27.3l27.7,16l-14.1,8.2V598.6z"/>
              <path id="path98_13_" className="st0" d="M1392.8,587.5l4.6,2.7v27.3l-2.8,1.6l-1.8-1v-3.3v-16.3V587.5z"/>
              <path id="path24_13_" className="st0" d="M1412.9,581.3v27.3l-15.5,8.9v-27.3L1412.9,581.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0102" floor="1" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path68_13_" className="st0" d="M1302.1,508.9l-15.1,8.7l-48.7-28.1l116-67l40,23.1l-11.5,6.7l11.5,6.7l17.5,10.1
                l-57.6,33.2l-31.7,18.3L1302.1,508.9z"/>
              <path id="path100_13_" className="st0" d="M1394.2,445.7V459l-11.5-6.7L1394.2,445.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0101" floor="1" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path70_13_" className="st0" d="M1286.9,517.7l20.4,11.7l-18,10.4l25.6,14.8l39.8,23l-39.8,23l-15.4-8.9l-29.4,17l15.4,8.9
                l-4.6,2.7l-134.4-77.6l91.8-53L1286.9,517.7z"/>
              <path id="path72_13_" className="st0" d="M1146.4,573.2v-30.6l134.4,77.6v30.6L1146.4,573.2z"/>
              <path id="path74_13_" className="st0" d="M1354.6,608.1l-36.9,21.3l-2.8-1.6v-27.3l39.8-23V608.1z"/>
              <path id="path76_13_" className="st0" d="M1280.8,650.8v-30.6l4.6-2.7v30.6L1280.8,650.8z"/>
              <path id="path80_13_" className="st0" d="M1270,608.6l29.4-17v27.3l-14,8.1v-9.5L1270,608.6z"/>
              <path id="path82_13_" className="st0" d="M1285.4,627l14-8.1l15.4,8.9l2.8,1.6l4.7,2.7l-29.4,17l-7.6-4.4V627z"/>
              <path id="path84_13_" className="st0" d="M1285.4,648.1v-3.3l7.6,4.4v3.3L1285.4,648.1z"/>
              <path id="path86_13_" className="st0" d="M1322.5,635.5l-29.4,17v-3.3l29.4-17V635.5z"/>
              <path id="path88_13_" className="st0" d="M1314.9,600.5v27.3l-15.4-8.9v-27.3L1314.9,600.5z"/>
            </ModIsometrySvgFlat>
            <g id="C1-Areas" className="isometry__areas">
              <path id="path34_13_" className="st0" d="M1484.5,617.9l26.8-15.5l-33.6-19.4l25.6-14.8l22,12.7l20.3-11.8l10.4,6l4.8,2.8l-20.4,11.8
                l27.6,15.9l-52.4,30.2l-8-4.6l0,0l-16.2-9.4l0,0L1484.5,617.9z"/>
              <path id="path46_13_" className="st0" d="M1491.5,640.7V622l16.2,9.4L1491.5,640.7z"/>
              <path id="path66_13_" className="st0" d="M1379.7,517.1l-64.8,37.4l-25.6-14.8l18-10.4l-20.4-11.7l15.1-8.7l20.4,11.8l31.7-18.3
                L1379.7,517.1z"/>
            </g>
            <g id="C1-Strokes" className="isometry__strokes">
              <path d="M1675.8,590.4h-0.7l0.3-0.6l-72.6-41.9l-66.3-38.3l-41.8-24.1l-0.3,0.6l-0.3-0.6l-14.8,8.6l-55.6-32.1l-0.3,0.6l-0.3-0.6
                l-11.2,6.5l-16.9-9.7v-13h-0.7l0.3-0.6l-40-23.1l-0.3,0.6l-0.3-0.6l-116,67l-91.8,53l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l134.4,77.6
                l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l35.9-20.7l-0.2-0.4l0.2,0.4
                l2.5-1.4l27.4,15.8l0.3-0.6l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.1l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.2-4.2
                l46.5,26.8l0.3-0.6l0.3,0.6l15.2-8.8v17.6h0.7l-0.3,0.6l10.3,5.9l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l29.4,17l0.3-0.6
                l0.3,0.6l7.2-4.2l4.3,2.5l0.3-0.6l0.3,0.6l138.7-80.1l-0.3-0.6h0.7V590.4z M1317.4,630l3.7,2.2l-28.1,16.2l-6.9-4v-17l13.3-7.7
                l15.1,8.7L1317.4,630l0.3-0.6l0,0L1317.4,630z M1479.2,494.8l0.3,0.6l14.8-8.6l40.4,23.3l-122.3,70.6l-15.1,8.7l-3.3-1.9
                l13.1-7.6l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6l-0.3-0.6l-24.2,14l-38.4-22.2l63.8-36.9l-0.3-0.6l0.3-0.6l-24.6-14.2l56.6-32.7
                l11.2-6.5l55.6,32.1L1479.2,494.8z M1285.4,617.5l0.3-0.6l-14.4-8.3l27.4-15.8v25.8l-12.7,7.3v-8.4H1285.4z M1300.1,592.7
                l14.1,8.1v25.8l-14.1-8.1V592.7z M1281.5,620.5l3.3-1.9v8.4v0v17.8v2.9l-3.3,1.9V620.5z M1286.1,646l6.2,3.6v1.7l-6.2-3.6V646z
                  M1302.1,509.7l20,11.6l0.3-0.6l0.3,0.6l31.4-18.1l24.2,14l-63.5,36.7l-24.2-14l17-9.8l-0.3-0.6l0.3-0.6l-19.4-11.2L1302.1,509.7
                z M1385.2,618.5l-26.4-15.2l20.4-11.8l12.9,7.5v15.5L1385.2,618.5z M1392.1,587.5v9.9l-12.3-7.1v-25.8l25.7,14.9l-13.1,7.6
                l0.3,0.6H1392.1z M1355.3,577.9l23.2-13.4v25.8l-21.4,12.3l0.3,0.6h-0.7v2.9l-1.5,0.9V577.9z M1358.1,604.4l26.4,15.2v1.7
                l-26.4-15.2V604.4z M1385.9,619.6l6.2-3.6v1.7l-6.2,3.6V619.6z M1392.8,618.2L1392.8,618.2L1392.8,618.2L1392.8,618.2z
                  M1601.1,548.4l-45.1,26l-10.1-5.8l-0.3,0.6l-0.3-0.6l-20,11.6l-21.7-12.5l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6
                l32.6,18.8l-25.8,14.9l0,0l0,0l0,0l0,0l0.3,0.6l0,0l0,0v0l-0.3,0.6l6.1,3.5l-18.2,10.5l-45.5-26.3l14.4-8.3l-0.3-0.6l0.3-0.6
                l-26.7-15.4l121.9-70.4L1601.1,548.4z M1491.9,621.4L1491.9,621.4l-6-3.5l25.8-14.9l-0.3-0.6l0.3-0.6L1479,583l24.2-14l21.7,12.5
                l0.3-0.6l0.3,0.6l20-11.6l10.1,5.8l0.3-0.6l-0.3,0.6l3.8,2.2l-19.4,11.2l0.3,0.6l-0.3,0.6l26.6,15.4l-51,29.5l-7.6-4.4l0,0l0,0
                l-0.3,0.6l0,0l0.3-0.6L1491.9,621.4z M1398.1,590.6l14.1-8.2v25.8l-14.1,8.2V590.6z M1413.5,582.5l25.7,14.9l-14.4,8.3l0.3,0.6
                h-0.7v8.4l-11-6.3V582.5z M1425.8,633.6v-17.8v-8.4l45.5,26.3v29.1l-45.5-26.3V633.6z M1507.8,631.3L1507.8,631.3L1507.8,631.3
                L1507.8,631.3z M1531.4,667.2l0.3,0.6h-0.7v8.4l-12.7-7.3V643l27.4,15.8L1531.4,667.2z M1503,651.2l14.1-8.1v25.8l-14.1,8.1
                V651.2z M1524.8,699.8l6.2-3.6v1.7l-6.2,3.6V699.8z M1536.4,669.7l-3.3-1.9l14.4-8.3l-0.3-0.6l0.3-0.6l-29.4-17l-0.3,0.6
                l-0.3-0.6l-15.1,8.7l-12.8-7.4l2.3-1.3l-0.3-0.6l0.3,0.6l15.9-9.2l7.6,4.4l0.3-0.6l0.3,0.6l52.4-30.3l-0.3-0.6l0,0l0,0l0,0
                l0.5-0.5l-0.5,0.5l0,0l0.3-0.6l-26.6-15.4l19.4-11.2l-0.3-0.6l0.3-0.6l-3.8-2.2l45.1-26l71.3,41.2L1536.4,669.7z M1384,452.4
                l9.5-5.5v11L1384,452.4z M1382.3,451.8l0.3,0.6l-0.3,0.6l11.5,6.7l0.3-0.6l-0.3,0.6l16.5,9.5l-56.6,32.7l-31.4,18.1l-20-11.6
                l-0.3,0.6l-0.3-0.6l-14.8,8.6l-47.4-27.4l114.6-66.2l38.7,22.3L1382.3,451.8z M1238.2,490.3l48.4,28l0.3-0.6l-0.3,0.6l19.4,11.2
                l-17,9.8l0.3,0.6l-0.3,0.6l25.6,14.8l38.8,22.4l-38.4,22.2l-15.1-8.7l-0.3,0.6h0l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.4,8.3
                l-3.3,1.9l-133.1-76.8L1238.2,490.3z M1280.1,620.5v29.1L1147,572.8v-29.1L1280.1,620.5z M1293.7,649.6l28.1-16.2v1.7l-28.1,16.2
                V649.6z M1317.7,628.7l-2.2-1.3v-26.6l38.4-22.2v29.1L1317.7,628.7z M1393.4,614.9v-16.3v-9.9l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7
                V614.9z M1394.9,619.8L1394.9,619.8l2.8-1.6l15.1-8.7l11.6,6.7v17l-6.9,4l-26.4-15.2L1394.9,619.8z M1390.5,624.8v-1.7l26.4,15.2
                v1.7L1390.5,624.8z M1418.3,638.3l6.2-3.6v1.7l-6.2,3.6V638.3z M1472.6,633.6l18.9-10.9l14.9,8.6l-15.2,8.8l0,0l-3.3,1.9l0.3,0.6
                h-0.7v11.5l-14.9,8.6V633.6z M1488.9,654.5L1488.9,654.5v-10.7l12.8,7.4v26.6l-2.2,1.3l-10.6-6.1V654.5z M1499.5,679.7l0.3,0.6
                l2.8-1.6l0,0l15.1-8.7l13.3,7.7v17l-6.9,4l-28.1-16.2l3.7-2.2L1499.5,679.7z M1523.5,699.8v1.7l-28.1-16.2v-1.7L1523.5,699.8z
                  M1532.4,698v-2.9v-17.8v-8.4l3.3,1.9v29.1L1532.4,698z M1674.4,620.6l-137.4,79.3v-29.1l137.4-79.3V620.6z"/>
            </g>
          </g>
          <g id="C2" className={'isometry__floor ' + (floor === "2" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.09.0202" floor="2" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_14_" className="st0" d="M1463.8,597.9l46.4-26.8l32.4,18.7l25.6-14.8l-27.6-15.9l20.4-11.8l-4.8-2.8l46.4-26.8
                l72.6,41.9l-138.7,80.1l-4.6-2.7l15.4-8.9l-29.4-17l-15.4,8.9L1463.8,597.9z"/>
              <path id="path26_14_" className="st0" d="M1463.8,597.9l38.5,22.3v27.3l-2.8,1.6l-35.7-20.6V597.9z"/>
              <path id="path30_14_" className="st0" d="M1494.7,655.1v-3.3l29.4,17v3.3L1494.7,655.1z"/>
              <path id="path32_14_" className="st0" d="M1524.2,668.9l-29.4-17l4.7-2.7l2.8-1.6l15.4-8.9l14,8.1v17.8L1524.2,668.9z"/>
              <path id="path34_14_" className="st0" d="M1531.7,646.7l-14-8.1v-27.3l29.4,17l-15.4,8.9V646.7z"/>
              <path id="path36_14_" className="st0" d="M1536.4,639.8l138.7-80.1v30.6l-138.7,80.1V639.8z"/>
              <path id="path38_14_" className="st0" d="M1536.4,639.8v30.6l-4.6-2.7v-30.6L1536.4,639.8z"/>
              <path id="path42_14_" className="st0" d="M1502.3,647.5v-27.3l15.4-8.9v27.3L1502.3,647.5z"/>
              <path id="path44_14_" className="st0" d="M1524.2,668.9l7.6-4.4v3.3l-7.6,4.4V668.9z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.09.0201" floor="2" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path22_15_" className="st0" d="M1510.1,571.1l-46.4,26.8l-38.6-22.3l15.4-8.9l-27.7-16l123.3-71.2l0,0l66.3,38.3
                l-46.4,26.8l-10.4-6l-20.3,11.8l-22-12.7l-25.6,14.8L1510.1,571.1z"/>
              <path id="path40_15_" className="st0" d="M1463.8,597.9v30.6l-38.6-22.3v-30.6L1463.8,597.9z"/>
              <path id="path46_15_" className="st0" d="M1417.6,610.6l-27.7-16v-3.3l27.7,16V610.6z"/>
              <path id="path48_15_" className="st0" d="M1417.6,610.6v-3.3l7.6-4.4v3.3L1417.6,610.6z"/>
              <path id="path50_15_" className="st0" d="M1417.6,607.3l-27.7-16l4.7-2.7l2.8-1.6l15.5-8.9l12.3,7.1v17.8L1417.6,607.3z"/>
              <path id="path52_15_" className="st0" d="M1425.2,585.1l-12.3-7.1v-27.3l27.7,16l-15.4,8.9V585.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0203" floor="2" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path64_14_" className="st0" d="M1314.9,524l64.8-37.4l-25.6-14.8l57.6-33.2l11.5-6.7l55.9,32.3l15.2-8.8l41.8,24.1
                l-138.7,80.1l-4.6-2.7l14.1-8.2l-27.7-16l-24.5,14.2L1314.9,524z"/>
              <path id="path70_14_" className="st0" d="M1354.6,577.5v-30.6l24.5-14.2v27.3l-21.7,12.5v3.3L1354.6,577.5z"/>
              <path id="path82_14_" className="st0" d="M1385.2,591.9l-27.7-16v-3.3l27.7,16V591.9z"/>
              <path id="path84_14_" className="st0" d="M1385.2,591.9v-3.3l7.6-4.4v3.3L1385.2,591.9z"/>
              <path id="path86_14_" className="st0" d="M1357.5,572.6l21.7-12.5l13.6,7.9v16.3l-7.6,4.4L1357.5,572.6z"/>
              <path id="path88_14_" className="st0" d="M1392.8,568l-13.6-7.9v-27.3l27.7,16l-14.1,8.2V568z"/>
              <path id="path90_14_" className="st0" d="M1392.8,556.9l4.6,2.7v27.3l-2.8,1.6l-1.8-1v-3.3V568V556.9z"/>
              <path id="path54_15_" className="st0" d="M1412.9,550.7V578l-15.5,8.9v-27.3l138.7-80.1v0L1412.9,550.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0202" floor="2" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path62_14_" className="st0" d="M1302.1,478.3l-15.1,8.7l-48.7-28.1l116-67l40,23.1l-11.5,6.7l11.5,6.7l17.5,10.1
                l-57.6,33.2l-31.7,18.3L1302.1,478.3z"/>
              <path id="path92_14_" className="st0" d="M1394.2,415.1v13.3l-11.5-6.7L1394.2,415.1z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0201" floor="2" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path56_14_" className="st0" d="M1146.4,542.6v-30.6l134.4,77.6v30.6L1146.4,542.6z"/>
              <path id="path58_14_" className="st0" d="M1146.4,511.9l91.8-53l48.7,28.1l20.4,11.8l-18,10.4l25.6,14.8l39.8,23l-39.8,23l-15.4-8.9
                l-29.4,17l15.4,8.9l-4.6,2.7L1146.4,511.9z"/>
              <path id="path66_14_" className="st0" d="M1354.6,577.5l-36.9,21.3l-2.8-1.6v-27.3l39.8-23V577.5z"/>
              <path id="path68_14_" className="st0" d="M1280.8,620.2v-30.6l4.6-2.7v30.6L1280.8,620.2z"/>
              <path id="path72_14_" className="st0" d="M1270,578l29.4-17v27.3l-14,8.1v-9.5L1270,578z"/>
              <path id="path74_14_" className="st0" d="M1285.4,596.4l14-8.1l15.4,8.9l2.8,1.6l4.7,2.7l-29.4,17l-7.6-4.4V596.4z"/>
              <path id="path76_14_" className="st0" d="M1285.4,617.5v-3.3l7.6,4.4v3.3L1285.4,617.5z"/>
              <path id="path78_14_" className="st0" d="M1322.5,604.9l-29.4,17v-3.3l29.4-17V604.9z"/>
              <path id="path80_14_" className="st0" d="M1314.9,569.9v27.3l-15.4-8.9V561L1314.9,569.9z"/>
            </ModIsometrySvgFlat>
            <g id="C2-Areas" className="isometry__areas">
              <path id="path28_14_" className="st0" d="M1542.5,589.8l-32.4-18.7l-32.5-18.7l25.6-14.8l22,12.7l20.3-11.8l10.4,6l4.8,2.8
                l-20.4,11.8l27.6,15.9L1542.5,589.8z"/>
              <path id="path60_14_" className="st0" d="M1307.3,498.8l-20.4-11.8l15.1-8.7l20.4,11.8l31.7-18.3l25.6,14.8l-64.8,37.4l-25.6-14.8
                L1307.3,498.8z"/>
            </g>
            <g id="C2-Strokes" className="isometry__strokes">
              <path d="M1675.8,559.8h-0.7l0.3-0.6l-72.6-41.9l-66.3-38.3l-0.3,0.6l0,0l0.3-0.6l-41.8-24.1l-0.3,0.6l-0.3-0.6l-14.8,8.6
                l-55.6-32.1l-0.3,0.6l-0.3-0.6l-11.2,6.5l-16.9-9.7v-13h-0.7l0.3-0.6l-40-23.1l-0.3,0.6l-0.3-0.6l-116,67l-91.8,53l0.3,0.6h-0.7
                v30.6h0.7l-0.3,0.6l134.4,77.6l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6
                l-3.7-2.2l35.9-20.7l-0.3-0.6l0.3,0.6l2.5-1.4l27.4,15.8l0.3-0.6l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.1l0.3,0.6h-0.7v3.3h0.7
                l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.2-4.2l38.3,22.1l34.7,20l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l29.4,17l0.3-0.6l0.3,0.6
                l7.2-4.2l4.3,2.5l0.3-0.6l0.3,0.6l138.7-80.1l-0.3-0.6h0.7V559.8z M1317.7,598.1l-2.2-1.3v-26.6l38.4-22.2v29.1L1317.7,598.1z
                  M1384,421.8l9.5-5.5v11L1384,421.8z M1392.9,415.1l-10.5,6.1l0.3,0.6l-0.3,0.6l11.5,6.7l0.3-0.6l-0.3,0.6l16.5,9.5l-56.6,32.7
                l-31.4,18.1l-20-11.6l-0.3,0.6l-0.3-0.6l-14.8,8.6l-47.4-27.4l114.6-66.2L1392.9,415.1z M1478.9,464.7l0.3-0.6l0.3,0.6l14.8-8.6
                l40.4,23.3l-137.4,79.3l-3.3-1.9l13.1-7.6l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6l-0.3-0.6l-24.2,14l-38.4-22.2l63.8-36.9l-0.3-0.6
                l0.3-0.6l-24.6-14.2l56.6-32.7l11.2-6.5L1478.9,464.7z M1285.4,586.9l0.3-0.6l-14.4-8.3l27.4-15.8v25.8l-12.7,7.3v-8.4H1285.4z
                  M1300.1,562.1l14.1,8.1v25.8l-14.1-8.1V562.1z M1281.5,589.9l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V589.9z M1286.1,615.4l6.2,3.6v1.7
                l-6.2-3.6V615.4z M1307.6,498.3l-19.4-11.2l13.8-8l20,11.6l0.3-0.6l0.3,0.6l31.4-18.1l24.2,14l-63.5,36.7l-24.2-14l17-9.8
                l-0.3-0.6L1307.6,498.3z M1379.2,560.9l12.9,7.5v15.5l-6.9,4l-26.4-15.2L1379.2,560.9z M1392.1,556.9v9.9l-12.3-7.1v-25.8
                l25.7,14.9l-13.1,7.6l0.3,0.6H1392.1z M1358.1,573.8l26.4,15.2v1.7l-26.4-15.2V573.8z M1385.9,589l6.2-3.6v1.7l-6.2,3.6V589z
                  M1601.1,517.8l-45.1,26l-10.1-5.8l-0.3,0.6l-0.3-0.6l-20,11.6l-21.7-12.5l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6
                l31.5,18.2l-45.1,26l-37.3-21.5l14.4-8.3l-0.3-0.6l0.3-0.6l-26.7-15.4l121.9-70.4L1601.1,517.8z M1398.1,560l14.1-8.2v25.8
                l-14.1,8.2V560z M1413.5,551.8l25.7,14.9l-14.4,8.3l0.3,0.6h-0.7v8.4l-11-6.3V551.8z M1542.5,589.1l-32.1-18.5l0,0l-31.5-18.2
                l24.2-14l21.7,12.5l0.3-0.6l0.3,0.6l20-11.6l10.1,5.8l0.3-0.6l-0.3,0.6l3.8,2.2l-19.4,11.2l0.3,0.6l-0.3,0.6l26.6,15.4
                L1542.5,589.1z M1418.3,607.7l6.2-3.6v1.7l-6.2,3.6V607.7z M1425.8,602.9v-17.8v-8.4l37.3,21.5v29.1l-37.3-21.5V602.9z
                  M1531.4,636.6l0.3,0.6h-0.7v8.4l-12.7-7.3v-25.8l27.4,15.8L1531.4,636.6z M1503,620.5l14.1-8.1v25.8l-14.1,8.1V620.5z
                  M1524.8,669.2l6.2-3.6v1.7l-6.2,3.6V669.2z M1532.4,664.5v-17.8v-8.4l3.3,1.9v29.1l-3.3-1.9V664.5z M1536.4,639.1l-3.3-1.9
                l14.4-8.3l-0.3-0.6l0.3-0.6l-29.4-17l-0.3,0.6l-0.3-0.6l-15.1,8.7l-37.2-21.5l45.1-26l32.1,18.5l0.3-0.6l0.3,0.6l25.6-14.8
                l-0.3-0.6l0.3-0.6l-26.6-15.4l19.4-11.2l-0.3-0.6l0.3-0.6l-3.8-2.2l45.1-26l71.3,41.2L1536.4,639.1z M1238.2,459.7l48.4,28
                l0.3-0.6v0l-0.3,0.6l19.4,11.2l-17,9.8l0.3,0.6l-0.3,0.6l25.6,14.8l38.8,22.4l-38.4,22.2l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17
                l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-133.1-76.8L1238.2,459.7z M1280.1,589.9V619L1147,542.2v-29.1L1280.1,589.9z M1293.7,619
                l28.1-16.2v1.7l-28.1,16.2V619z M1293,617.8l-6.9-4v-17l13.3-7.7l15.1,8.7l0,0l2.8,1.6l0.3-0.6l0,0l-0.3,0.6l3.7,2.2L1293,617.8z
                  M1355.3,547.3l23.2-13.4v25.8l-21.4,12.3l0.3,0.6h-0.7v2.9l-1.5,0.9V547.3z M1393.4,584.3V568v-9.9l3.3,1.9v26.6l-2.2,1.3
                l-1.1-0.7V584.3z M1394.9,589.2L1394.9,589.2l2.8-1.6l15.1-8.7l11.6,6.7v17l-6.9,4l-26.4-15.2L1394.9,589.2z M1416.9,607.7v1.7
                l-26.4-15.2v-1.7L1416.9,607.7z M1464.4,599.1l37.2,21.5v26.6l-2.2,1.3l-35-20.2V599.1z M1499.8,649.7l2.8-1.6l0,0l15.1-8.7
                l13.3,7.7v17l-6.9,4l-28.1-16.2L1499.8,649.7z M1523.5,669.2v1.7l-28.1-16.2V653L1523.5,669.2z M1674.4,590l-137.4,79.3v-29.1
                l137.4-79.3V590z"/>
            </g>
          </g>
          <g id="C3" className={'isometry__floor ' + (floor === "3" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.09.0302" floor="3" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path26_15_" className="st0" d="M1540.5,528.5l20.4-11.8l-4.8-2.8l46.4-26.8l72.6,41.9l-138.7,80.1l-72.6-41.9l46.4-26.8
                l32.4,18.7l25.6-14.8L1540.5,528.5z"/>
              <path id="path28_15_" className="st0" d="M1463.8,567.3l72.6,41.9v30.6l-4.6-2.7v-3.3v-17.8v-6.2l-14-8.1l-15.4-8.9v24l-2.8,1.6
                l-35.7-20.6L1463.8,567.3z"/>
              <path id="path30_15_" className="st0" d="M1494.7,624.5v-3.3l29.4,17v3.3L1494.7,624.5z"/>
              <path id="path32_15_" className="st0" d="M1524.2,638.3l-29.4-17l4.7-2.7l2.8-1.6l15.4-8.9l14,8.1v17.8L1524.2,638.3z"/>
              <path id="path34_15_" className="st0" d="M1531.7,616.1l-14-8.1v-6.2l14,8.1V616.1z"/>
              <path id="path36_15_" className="st0" d="M1536.4,609.2l138.7-80.1v30.6l-138.7,80.1V609.2z"/>
              <path id="path40_16_" className="st0" d="M1502.3,616.9v-24l15.4,8.9v6.2L1502.3,616.9z"/>
              <path id="path42_15_" className="st0" d="M1524.2,638.3l7.6-4.4v3.3l-7.6,4.4V638.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.09.0301" floor="3" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path22_16_" className="st0" d="M1503.3,507l-25.6,14.8l32.5,18.7l-46.4,26.8l-66.3-38.3l138.7-80.1l0,0l66.3,38.3
                l-46.4,26.8l-10.4-6l-20.3,11.8L1503.3,507z"/>
              <path id="path38_15_" className="st0" d="M1463.8,567.3v30.6l-38.6-22.3v-27.3l-12.3-7.1l-15.4-8.9V529L1463.8,567.3z"/>
              <path id="path44_15_" className="st0" d="M1417.6,580l-27.7-16v-3.3l27.7,16V580z"/>
              <path id="path46_16_" className="st0" d="M1417.6,580v-3.3l7.6-4.4v3.3L1417.6,580z"/>
              <path id="path48_16_" className="st0" d="M1417.6,576.7l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1417.6,576.7z"/>
              <path id="path50_16_" className="st0" d="M1425.2,554.5l-12.3-7.1v-6.2l12.3,7.1V554.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0303" floor="3" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path62_15_" className="st0" d="M1314.9,493.4l64.8-37.4l-25.6-14.8l57.6-33.2l11.5-6.7l55.9,32.3l15.2-8.8l41.8,24.1
                L1397.4,529l-32.4-18.7l-10.4,6L1314.9,493.4z"/>
              <path id="path68_15_" className="st0" d="M1354.6,546.9v-30.6l10.4-6v3.3l14.1,8.1v7.8l-21.7,12.5v3.3L1354.6,546.9z"/>
              <path id="path80_15_" className="st0" d="M1385.2,561.3l-27.7-16V542l27.7,16V561.3z"/>
              <path id="path82_15_" className="st0" d="M1357.5,542l21.7-12.5l13.6,7.9v16.3l-7.6,4.4L1357.5,542z"/>
              <path id="path84_15_" className="st0" d="M1379.2,529.5v-7.8l13.6,7.9v7.8L1379.2,529.5z"/>
              <path id="path86_15_" className="st0" d="M1365.1,513.6v-3.3l32.4,18.7v27.3l-2.8,1.6l-1.8-1v-3.3v-16.3v-7.8l-13.6-7.9L1365.1,513.6
                z"/>
              <path id="path88_15_" className="st0" d="M1392.8,556.9l-7.6,4.4V558l7.6-4.4V556.9z"/>
              <path id="path52_16_" className="st0" d="M1412.9,541.2v6.2l-15.4,8.9V529l138.7-80.1l0,0L1397.4,529v3.3L1412.9,541.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0302" floor="3" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path60_15_" className="st0" d="M1302.1,447.7l-15.1,8.7l-48.7-28.1l116-67l40,23.1l-11.5,6.7l11.5,6.7l17.5,10.1
                l-57.6,33.2l-31.7,18.3L1302.1,447.7z"/>
              <path id="path90_15_" className="st0" d="M1394.2,384.5v13.3l-11.5-6.7L1394.2,384.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0301" floor="3" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path54_16_" className="st0" d="M1146.4,511.9v-30.6l134.4,77.6v30.6L1146.4,511.9z"/>
              <path id="path56_15_" className="st0" d="M1146.4,481.3l91.8-53l48.7,28.1l20.4,11.8l-18,10.4l25.6,14.8l39.8,23l-39.8,23l-15.4-8.9
                l-29.4,17l15.4,8.9l-4.6,2.7L1146.4,481.3z"/>
              <path id="path64_15_" className="st0" d="M1354.6,546.9l-36.9,21.3l-2.8-1.6v-27.3l39.8-23V546.9z"/>
              <path id="path66_15_" className="st0" d="M1280.8,589.6v-30.6l4.6-2.7v30.6L1280.8,589.6z"/>
              <path id="path70_15_" className="st0" d="M1270,547.4l29.4-17v27.3l-14,8.1v-9.5L1270,547.4z"/>
              <path id="path72_15_" className="st0" d="M1285.4,565.8l14-8.1l15.4,8.9l2.8,1.6l4.7,2.7l-29.4,17l-7.6-4.4V565.8z"/>
              <path id="path74_15_" className="st0" d="M1285.4,586.9v-3.3l7.6,4.4v3.3L1285.4,586.9z"/>
              <path id="path76_15_" className="st0" d="M1322.5,574.3l-29.4,17V588l29.4-17V574.3z"/>
              <path id="path78_15_" className="st0" d="M1314.9,539.3v27.3l-15.4-8.9v-27.3L1314.9,539.3z"/>
            </ModIsometrySvgFlat>
            <g id="C3-Areas" className="isometry__areas">
              <path id="path24_15_" className="st0" d="M1477.7,521.8l25.6-14.8l22,12.7l20.3-11.8l10.4,6l4.8,2.8l-20.4,11.8l27.6,15.9l-25.6,14.8
                l-32.4-18.7L1477.7,521.8z"/>
              <path id="path58_15_" className="st0" d="M1307.3,468.2l-20.4-11.8l15.1-8.7l20.4,11.8l31.7-18.3l25.6,14.8l-64.8,37.4l-25.6-14.8
                L1307.3,468.2z"/>
            </g>
            <g id="C3-Strokes" className="isometry__strokes">
              <path d="M1675.8,529.1h-0.7l0.3-0.6l-72.6-41.9l-66.3-38.3l-41.8-24.1l-0.3,0.6l-0.3-0.6l-14.8,8.6l-55.6-32.1l-0.3,0.6l-0.3-0.6
                l-11.2,6.5l-16.9-9.7v-13h-0.7l0.3-0.6l-40-23.1l-0.3,0.6l-0.3-0.6l-116,67l-91.8,53l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l134.4,77.6
                l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7V571h-0.7l0.3-0.6l-3.7-2.2l35.9-20.7l2.5-1.4l27.4,15.8
                l0.3-0.6l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.2-4.2l38.3,22.1l34.7,20
                l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l29.4,17l0.3-0.6l0.3,0.6l7.2-4.2l4.3,2.5l0.3-0.6l0.3,0.6l138.7-80.1l-0.3-0.6h0.7
                V529.1z M1478.9,434.1l0.3-0.6l0.3,0.6l14.8-8.6l40.4,23.3l-137.4,79.3l-32-18.5l-0.3,0.6l-0.3-0.6l-10.1,5.8l-38.4-22.2
                l63.8-36.9l-0.3-0.6l0.3-0.6l-24.6-14.2l56.6-32.7l11.2-6.5L1478.9,434.1z M1392.1,536.2l-12.3-7.1v-6.2l12.3,7.1V536.2z
                  M1379.2,530.3l13,7.5v15.5l-6.9,4l-26.4-15.2L1379.2,530.3z M1358.1,543.2l26.4,15.2v1.7l-26.4-15.2V543.2z M1385.9,558.4
                l6.2-3.6v1.7l-6.2,3.6V558.4z M1378.5,522.1v7l-21.3,12.3l0.3,0.6h-0.7v2.9l-1.5,0.9v-29.1l9.1-5.2v2.1h0.7l-0.3,0.6
                L1378.5,522.1z M1285.4,556.3l0.3-0.6l-14.4-8.3l27.4-15.8v25.8l-12.7,7.3v-8.4H1285.4z M1300.1,531.5l14.1,8.1v25.8l-14.1-8.1
                V531.5z M1281.5,559.3l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V559.3z M1286.1,584.8l6.2,3.6v1.7l-6.2-3.6V584.8z M1302.1,448.5l20,11.6
                l0.3-0.6l0.3,0.6l31.4-18.1l24.2,14l-63.5,36.7l-24.2-14l17-9.8l-0.3-0.6l0.3-0.6l-19.4-11.2L1302.1,448.5z M1601.1,487.2
                l-45.1,26l-10.1-5.8l-0.3,0.6l-0.3-0.6l-20,11.6l-21.7-12.5l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6l31.5,18.2l-45.1,26
                l-65-37.5l137.4-79.3L1601.1,487.2z M1542.5,558.5l-32.1-18.5l-31.5-18.2l24.2-14l21.7,12.5l0.3-0.6l0.3,0.6l20-11.6l10.1,5.8
                l3.8,2.2l-19.4,11.2l0.3,0.6l-0.3,0.6l26.6,15.4L1542.5,558.5z M1398.1,533.4l14.1,8.1v5.5l-14.1,8.1V533.4z M1413.5,542.3
                l11,6.3v4.7l-11-6.3V542.3z M1425.8,572.3v-17.8v-6.2h-0.7l0.3-0.6l-12.3-7.1l-15.1-8.7v-1.7l65,37.5v29.1l-37.3-21.5V572.3z
                  M1536.4,608.5l-71.3-41.2l45.1-26l32.1,18.5l0.3-0.6l0.3,0.6l25.6-14.8l-0.3-0.6l0.3-0.6l-26.6-15.4l19.4-11.2l-0.3-0.6l0.3-0.6
                l-3.8-2.2l45.1-26l71.3,41.2L1536.4,608.5z M1393.5,385.6v11l-9.5-5.5L1393.5,385.6z M1382.3,390.6l0.3,0.6l-0.3,0.6l11.5,6.7
                l0.3-0.6l-0.3,0.6l16.5,9.5l-56.6,32.7l-31.4,18.1l-20-11.6l-0.3,0.6l-0.3-0.6l-14.8,8.6l-47.4-27.4l114.6-66.2l38.7,22.3
                L1382.3,390.6z M1238.2,429.1l48.4,28l0.3-0.6l-0.3,0.6l19.4,11.2l-17,9.8l0.3,0.6l-0.3,0.6l25.6,14.8l38.8,22.4l-38.4,22.2
                l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-133.1-76.8L1238.2,429.1z M1280.1,559.3v29.1
                L1147,511.6v-29.1L1280.1,559.3z M1293.7,588.4l28.1-16.2v1.7l-28.1,16.2V588.4z M1293,587.2l-6.9-4v-17l13.3-7.7l15.1,8.7l0,0
                l2.8,1.6l0.3-0.6l0,0l-0.3,0.6l3.7,2.2L1293,587.2z M1317.7,567.5l-2.2-1.3v-26.6l38.4-22.2v29.1L1317.7,567.5z M1393.5,553.6
                v-16.3v-7.8h-0.7l0.3-0.6l-13.6-7.9l-13.8-8v-1.7l31,17.9v2.9v23.7l-2.2,1.3l-1.1-0.7V553.6z M1394.9,558.5l-0.3-0.6
                L1394.9,558.5l2.8-1.6l15.1-8.7l11.6,6.7v17l-6.9,4l-26.4-15.2L1394.9,558.5z M1390.5,563.6v-1.7l26.4,15.2v1.7L1390.5,563.6z
                  M1418.3,577.1l6.2-3.6v1.7l-6.2,3.6V577.1z M1464.4,568.5l71.3,41.2v29.1l-3.3-1.9v-2.9v-17.8v-6.2h-0.7l0.3-0.6l-14-8.1
                l-0.3,0.6l0.3-0.6l-15.4-8.9l-0.3,0.6h-0.7v23.7l-2.2,1.3l-35-20.2V568.5z M1517.1,607.6l-14.1,8.1V594l14.1,8.1V607.6z
                  M1518.4,602.9l12.7,7.3v4.7l-12.7-7.3V602.9z M1524.8,638.6l6.2-3.6v1.7l-6.2,3.6V638.6z M1499.8,619.1L1499.8,619.1l2.8-1.6
                l15.1-8.7l13.3,7.7v17l-6.9,4l-28.1-16.2L1499.8,619.1z M1523.5,638.6v1.7l-28.1-16.2v-1.7L1523.5,638.6z M1674.4,559.4
                l-137.4,79.3v-29.1l137.4-79.3V559.4z"/>
            </g>
          </g>
          <g id="C4" className={'isometry__floor ' + (floor === "4" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.08.0404" floor="4" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_16_" className="st0" d="M1377.6,472.4V503l-23,13.3v-30.6L1377.6,472.4z"/>
              <path id="path32_16_" className="st0" d="M1351.3,441.7l39.8,23l-13.4,7.8l-23,13.3l-39.8-23L1351.3,441.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0403" floor="4" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path22_17_" className="st0" d="M1377.6,503v-30.6l66.3,38.3v30.6L1377.6,503z"/>
              <path id="path26_16_" className="st0" d="M1554.9,477.2l-110.9,64.1v-30.6l110.9-64.1V477.2z"/>
              <path id="path28_16_" className="st0" d="M1443.9,510.7l-66.3-38.3l13.4-7.8l-39.8-23l28.4-16.4v0l-25.6-14.8l69.1-39.9l131.6,76
                L1443.9,510.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0402" floor="4" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path30_16_" className="st0" d="M1423.3,370.7l-69.1,39.9l0,0l-31.7,18.3l-20.4-11.8l-15.1,8.7l-48.7-28.1l116-67
                L1423.3,370.7z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.08.0401" floor="4" building="C" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path34_17_" className="st0" d="M1314.9,462.8l39.8,23l-73.8,42.6l-134.4-77.6l91.8-53l48.7,28.1l20.4,11.8l-18,10.4
                L1314.9,462.8z"/>
              <path id="path36_17_" className="st0" d="M1146.4,481.3v-30.6l134.4,77.6v30.6L1146.4,481.3z"/>
              <path id="path38_17_" className="st0" d="M1354.6,516.3l-36.9,21.3l-2.8-1.6v-24l-15.4,8.9l-14,8.1v6.2V553v3.3l-4.6,2.7v-30.6
                l73.8-42.6V516.3z"/>
              <path id="path42_17_" className="st0" d="M1285.4,556.3V553l7.6,4.4v3.3L1285.4,556.3z"/>
              <path id="path44_17_" className="st0" d="M1322.5,543.6l-29.4,17v-3.3l29.4-17V543.6z"/>
              <path id="path46_18_" className="st0" d="M1322.5,540.4l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L1322.5,540.4z"/>
              <path id="path48_18_" className="st0" d="M1299.4,527.1l-14,8.1v-6.2l14-8.1V527.1z"/>
              <path id="path50_18_" className="st0" d="M1314.9,511.9v24l-15.4-8.9v-6.2L1314.9,511.9z"/>
            </ModIsometrySvgFlat>
            <g id="C4-Areas" className="isometry__areas">
              <path id="path40_17_" className="st0" d="M1289.3,448l18-10.4l-20.4-11.8l15.1-8.7l20.4,11.8l31.7-18.3v0l25.6,14.8l-28.4,16.4
                l-36.4,21L1289.3,448z"/>
            </g>
            <g id="C4-Strokes" className="isometry__strokes">
              <path d="M1555.5,477.2v-30.6h-0.7l0.3-0.6l-131.6-76l-69.1-39.9l-0.3,0.6l-0.3-0.6l-116,67l-91.8,53l0.3,0.6h-0.7v30.6h0.7
                l-0.3,0.6l134.4,77.6l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2
                l35.9-20.7l22.7-13.1l65.9,38.1l0.3-0.6l0.3,0.6l110.9-64.1l-0.3-0.6H1555.5z M1147,481v-29.1l133.1,76.8v29.1L1147,481z
                  M1307.6,438.2l-0.3-0.6l0.3-0.6l-19.4-11.2l13.8-8l20,11.6l0.3-0.6l0.3,0.6l31.4-18.1l24.2,14l-27.4,15.8l-36.1,20.8l-24.2-14
                L1307.6,438.2z M1351.3,442.5l38.4,22.2l-12.4,7.2l-22.7,13.1l-38.4-22.2L1351.3,442.5z M1355.3,486.1l21.7-12.5v29.1l-21.7,12.5
                V486.1z M1378.3,473.6l64.9,37.5v29.1l-64.9-37.5V473.6z M1443.9,509.9l-64.9-37.5l12.4-7.2l-0.3-0.6l0.3-0.6l-38.8-22.4
                l27.4-15.8l-0.3-0.6l0.3-0.6l0,0l0,0l-24.6-14.2l67.8-39.1l130.3,75.2L1443.9,509.9z M1421.9,370.7l-68.1,39.3l0,0l-31.4,18.1
                l-20-11.6l-0.3,0.6l-0.3-0.6l-14.8,8.6l-47.4-27.4l114.6-66.2L1421.9,370.7z M1238.2,398.5l48.4,28l0.3-0.6v0l-0.3,0.6l19.4,11.2
                l-17,9.8l0.3,0.6l-0.3,0.6l25.6,14.8l38.8,22.4l-72.5,41.9l-133.1-76.8L1238.2,398.5z M1286.1,554.1l6.2,3.6v1.7l-6.2-3.6V554.1z
                  M1314.2,513.1v21.7l-14.1-8.1v-5.5L1314.2,513.1z M1286.1,529.3l12.7-7.3v4.7l-12.7,7.3V529.3z M1293.7,557.7l28.1-16.2v1.7
                l-28.1,16.2V557.7z M1293,556.6l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l0.3-0.6l0,0l-0.3,0.6l3.7,2.2L1293,556.6z M1317.7,536.9
                l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.4,8.9l0.3,0.6l-0.3-0.6l-14,8.1l0.3,0.6h-0.7v6.2V553v2.9l-3.3,1.9v-29.1l72.5-41.9v29.1
                L1317.7,536.9z M1444.6,511.1l109.6-63.3v29.1l-109.6,63.3V511.1z"/>
            </g>
          </g>
        </g>
        <g id="D" className={'isometry__building ' + (building === "D" ? 'active' : '')}>
          <g id="D0" className={'isometry__floor isometry__areas ' + (floor === "D0" ? 'active' : '')}>
            <g id="D0-Areas" className="isometry__areas">
              <path id="path84_27_" className="st0" d="M1781.7,1009.3l-68.7-39.7v-30.6l68.7,39.7V1009.3z"/>
              <g id="g98_2_" transform="scale(1.09798)">
                <path id="path100_27_" className="st0" d="M1819.9,879l-133.5,77.1v-27.9l0,0l0,0l4.2-2.4l6.9,4l26.8-15.5v-3l-4.3-2.5l99.9-57.7
                  L1819.9,879z"/>
              </g>
              <g id="g102_3_" transform="scale(1.03053)">
                <path id="path104_27_" className="st0" d="M1796.8,988.9v29.7l-49.4-28.5v-18.7v-11L1796.8,988.9z"/>
              </g>
              <g id="Ebene_234">
                <polyline className="st0" points="1781.7,978.8 1801.2,990 1801.2,1001.3 1797.8,999.1 1781.7,1008.5 1781.7,978.8 				"/>
              </g>
            </g>
            <g id="D0-Strokes" className="isometry__strokes">
              <path d="M1998.9,934.9l33.4-19.3l-0.7-1.2l-143.4,82.8l0.7,1.2l108.6-62.7v29l-145.3,83.9v-29.5h-0.6l0.3-0.6l-50.9-29.4
                l-0.3,0.6h-0.7v10.2l-1-0.6v-10.9h-0.7l0.3-0.6l-16.7-9.6l0,0l-68.7-39.7l-0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l68.7,39.7l0.3-0.6h0.7
                v-29.4l15.4,8.9v10.9h0.7l-0.3,0.6l2,1.1v18.9h0.7l-0.3,0.6l50.9,29.4l0.3-0.6h0l0.3,0.6l146.6-84.6l-0.3-0.6h0.7V934.9z
                  M1801.4,990.9l49.6,28.6v29.1l-49.6-28.6V1001V990.9z M1781,979.1v29.1l-67.4-38.9v-29.1L1781,979.1z"/>
            </g>
          </g>
          <g id="D1" className={'isometry__floor ' + (floor === "1" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.11.0101" floor="1" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g80" transform="scale(1.06734)">
                <path id="path82_17_" className="st0" d="M1808.1,690.4l-19.1,11l17.2,9.9l0,0l0,0l-61.2,35.3l-28.8-16.6l-27.9,16.1l-5.1-2.9
                  l13.3-7.7l-29.3-16.9l-13.3,7.7l-4.3-2.5l14.5-8.3l115.5-66.7l0,0l30.3,17.5l-21.7,12.5L1808.1,690.4z"/>
              </g>
              <path id="path122_16_" className="st0" d="M1760.6,803.1v-30.6l4.6,2.7v27.3l-2.8,1.6L1760.6,803.1z"/>
              <g id="g126" transform="scale(1.00766)">
                <path id="path128_16_" className="st0" d="M1788.2,820.7l-5.4-3.1v-3.3v-16.2v-10.9l5.4,3.1V820.7z"/>
              </g>
              <g id="g130" transform="scale(1.02138)">
                <path id="path132_16_" className="st0" d="M1764.2,809.7v-30l29.2-16.9v30L1764.2,809.7z"/>
              </g>
              <g id="g134" transform="scale(1.00516)">
                <path id="path136_16_" className="st0" d="M1787.3,816.4v3.3l-7.5,4.3v-3.3L1787.3,816.4z"/>
              </g>
              <g id="g138" transform="scale(1.00516)">
                <path id="path140_16_" className="st0" d="M1787.3,816.4l-7.5,4.4l-31.1-18l4.7-2.7l2.8-1.6l14.1-8.1l17,9.8V816.4z"/>
              </g>
              <g id="g142" transform="scale(1.01168)">
                <path id="path144_16_" className="st0" d="M1775.7,794.9l-16.9-9.8v-27l30.9,17.9l-14,8.1L1775.7,794.9z"/>
              </g>
              <g id="g146" transform="scale(1.00167)">
                <path id="path148_16_" className="st0" d="M1754.7,805.6l31.2,18v3.3l-31.2-18V805.6z"/>
              </g>
              <g id="g150" transform="scale(1.03552)">
                <path id="path152_16_" className="st0" d="M1794,796.5l-25.1-14.5v-29.6l29.7,17.1v27.6l-2.8-1.6L1794,796.5z"/>
              </g>
              <path id="path162_16_" className="st0" d="M1765.2,802.5v-27.3l14.2-8.2v27.3L1765.2,802.5z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.10.0102" floor="1" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g96" transform="scale(1.05245)">
                <path id="path98_17_" className="st0" d="M1741.6,621.6l63,36.4l0,0l-117.1,67.6l-26.3-15.2l-14.7,8.5l-13.4-7.7l3.1-1.8l14.3-8.3
                  l1.1-0.6l7.6,4.4L1709,676l-20.9-12.1l19.3-11.2l-9.9-5.7L1741.6,621.6z"/>
              </g>
              <path id="path108_16_" className="st0" d="M1718.7,748.4l14.1,8.1v27.3l-2.8,1.6l-11.3-6.5v-18.7V748.4z"/>
              <path id="path110_16_" className="st0" d="M1725.2,791.5v-3.3l27.7,16v3.3L1725.2,791.5z"/>
              <path id="path112_16_" className="st0" d="M1753,804.2l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1753,804.2z"/>
              <path id="path114_16_" className="st0" d="M1760.6,782l-12.3-7.1v-27.3l27.7,16l-15.4,8.9V782z"/>
              <path id="path120_16_" className="st0" d="M1760.6,803.1l-7.6,4.4v-3.3l7.6-4.4V803.1z"/>
              <path id="path124_16_" className="st0" d="M1732.8,783.8v-27.3l15.4-8.9v27.3L1732.8,783.8z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.10.0101" floor="1" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <path id="path24_17_" className="st0" d="M1722,727.7v18.7l-3.3,1.9v11.9l-16.2,9.4V739L1722,727.7z"/>
              <g id="g104" transform="scale(1.02193)">
                <path id="path106_17_" className="st0" d="M1723.6,675.1l-27-15.6l-25,14.4l31.7,18.3l-26.3,15.2l0,0l8.1,4.7l-19.1,11l-45.8-26.4
                  l15.1-8.7l-28.8-16.6l-15.1,8.7l-4.5-2.6l135.7-78.4l71.1,41l-45.4,26.2l-4.7-2.7L1723.6,675.1z"/>
              </g>
              <path id="path164_16_" className="st0" d="M1655.7,712l46.8,27v30.6l-46.8-27v-3.3v-17.8V712z"/>
              <path id="path166_15_" className="st0" d="M1648.1,747l-29.4-17v-3.3l29.4,17V747z"/>
              <path id="path168_15_" className="st0" d="M1648.1,747v-3.3l7.6-4.4v3.3L1648.1,747z"/>
              <path id="path170_15_" className="st0" d="M1648.1,743.7l-29.4-17l4.7-2.7l2.8-1.6l15.4-8.9l14,8.1v17.8L1648.1,743.7z"/>
              <path id="path172_15_" className="st0" d="M1655.7,721.5l-14-8.1v-27.3l29.4,17l-15.4,8.9V721.5z"/>
              <path id="path174_15_" className="st0" d="M1641.7,713.4l-15.4,8.9V695l15.4-8.9V713.4z"/>
              <path id="path176_15_" className="st0" d="M1626.2,695v27.3l-2.8,1.6l-1.8-1v-30.6L1626.2,695z"/>
            </ModIsometrySvgFlat>
            <g id="D1-Areas" className="isometry__areas">
              <path id="path22_18_" className="st0" d="M1722,746.5v-18.7l15.1,8.7v1.3L1722,746.5z"/>
              <g id="g28" transform="scale(1.0497)">
                <path id="path30_17_" className="st0" d="M1803.6,953.2v3.1l-28.1,16.2v-3.1L1803.6,953.2z"/>
              </g>
              <g id="g32" transform="scale(1.0497)">
                <path id="path34_18_" className="st0" d="M1803.6,953.2l-28.1,16.2l-7.2-4.2v-17l13.3-7.7l14.7,8.5l2.7,1.6L1803.6,953.2z"/>
              </g>
              <g id="g36" transform="scale(1.11352)">
                <path id="path38_18_" className="st0" d="M1824.8,821.7L1696,896.1l-2.5-1.5v-24.5l131.3-75.8V821.7z"/>
              </g>
              <g id="g40" transform="scale(1.15902)">
                <path id="path42_18_" className="st0" d="M1753.2,763.1L1627,835.9l-13.3-7.7l-25.4,14.7l13.3,7.7l-4,2.3l-119.7-69.1l125-72.1
                  l1.5-0.9l2.5,1.5l0,0l11.5,6.6l21.4-12.3l34.3-19.8l23.1,13.3l22.1-12.7l0,0l-27.4-15.8l17.5-10.1l0,0l-13.1-7.5l-17.6,10.2
                  l-15.5-9l-15.9-9.1l17.6-10.2l-18.5-10.7l20-11.5l22.1-12.7l149.9,86.5l-108.2,62.5L1753.2,763.1L1753.2,763.1z M1626.1,764.7
                  l-19,11l-17.5-10.1l0,0l-13.1,7.6l0,0l17.5,10.1l-23.8,13.7l0,0l22,12.7l0,0l55.9-32.3l0,0l0,0L1626.1,764.7L1626.1,764.7z"/>
              </g>
              <g id="g44" transform="scale(1.03054)">
                <path id="path46_19_" className="st0" d="M1662.2,881.5l134.6,77.7v29.7l-134.6-77.7V881.5z"/>
              </g>
              <g id="g48" transform="scale(1.03268)">
                <path id="path50_19_" className="st0" d="M1793.1,986.9v-29.6l4.5-2.6v9.2v17.3v3.2L1793.1,986.9z"/>
              </g>
              <g id="g52" transform="scale(1.03912)">
                <path id="path54_18_" className="st0" d="M1786.4,948.8l-14.9-8.6l28.3-16.4v26.3l-13.5,7.8V948.8z"/>
              </g>
              <g id="g56" transform="scale(1.15902)">
                <path id="path58_17_" className="st0" d="M1838.5,687.4v26.4l-85.3,49.3l-22.9-13.2L1838.5,687.4z"/>
              </g>
              <g id="g76" transform="scale(1.09543)">
                <path id="path78_17_" className="st0" d="M1795.7,740.8l-24.4-14.1l-36.3,21l-34.8-20.1l59.6-34.4l0,0l16.4,9.4l0,0l18.6-10.7l0,0
                  l13.8,8l-18.5,10.7l0,0l0,0l28.9,16.7L1795.7,740.8z"/>
              </g>
              <g id="g88" transform="scale(1.04622)">
                <path id="path90_17_" className="st0" d="M1802.4,926.1v26.1l-14.7-8.5v-26.1L1802.4,926.1z"/>
              </g>
              <g id="g92" transform="scale(1.03615)">
                <path id="path94_17_" className="st0" d="M1791.5,977.8l7.3,4.2v3.2l-7.3-4.2V977.8z"/>
              </g>
              <g id="g100" transform="scale(1.00614)">
                <path id="path102_17_" className="st0" d="M1765.8,694.5l21.9,12.6l-52.1,30.1l-7.9-4.6l-1.1-0.7l-15-8.7l-8.2-4.7l26.7-15.4l0,0
                  l0,0l-32.2-18.6l25.4-14.7l27.4,15.8l20.2-11.7l4.8,2.8l10.4,6L1765.8,694.5z"/>
              </g>
              <g id="g182" transform="scale(1.05307)">
                <path id="path184_14_" className="st0" d="M1804.8,777.7l-23.5,13.6l-12.7-7.3v-27.1L1804.8,777.7z"/>
              </g>
              <g id="g190" transform="scale(1.05754)">
                <path id="path192_14_" className="st0" d="M1727.8,847.3l14.4-8.3l19.2,11.1l0,0l20.8-12l24.1,13.9l-61.3,35.4l-24.1-13.9l26.1-15.1
                  l0,0L1727.8,847.3z"/>
              </g>
            </g>
            <g id="D1-Strokes" className="isometry__strokes">
              <g>
                <path d="M2131.5,827.3v-30.6h-0.7l0.3-0.6l-173.7-100.3l-0.3,0.6l-0.3-0.6l-25.2,14.6l-32-18.5l-0.3,0.6l0,0l0,0v0l0,0v0
                  l0.3-0.6l-66.3-38.3l0,0l-72.6-41.9l-0.3,0.6l-0.3-0.6l-138.7,80.1l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l0.8,0.5l-3.7,2.2l0.3,0.6
                  h-0.7v3.3h0.7l-0.3,0.6l29.4,17l0.3-0.6l0.3,0.6l7.2-4.2l46.5,26.8l0.3-0.6l0.3,0.6l15.2-8.8V779h0.7l-0.3,0.6l10.3,5.9
                  l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6h0l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.2l0.3,0.6l0,0h-0.7v3.3h0.7
                  l-0.3,0.6l31.3,18.1l0.3-0.6h0l0.3,0.6l7.2-4.2l5.1,3l0.3-0.6l0.3,0.6l29.5-17l24.7,14.3l-143.8,83l0.3,0.6l0,0h-0.7v30.6h0.7
                  l-0.3,0.6l138.7,80.1l0.3-0.6h0l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1
                  l142.4-82.2l-0.3-0.6h0.7v-30.2l98.6-56.9l-0.3-0.6H2131.5z M1928.5,736.9l-19.4,11.2l0.3,0.6l-0.3,0.6l17.3,10l-64,36.9
                  l-30.4-17.5l-0.3,0.6h0l-0.3-0.6l-29.5,17l-4.1-2.4l13.2-7.6l-0.3-0.6l0.3-0.6l-31.3-18.1l-0.3,0.6l-0.3-0.6l-13.9,8l-3.3-1.9
                  l14.4-8.3l-0.3-0.6l0,0l0.3,0.6l122.9-71l31,17.9l-22.2,12.8l0.3,0.6l-0.3,0.6L1928.5,736.9z M1788.9,825L1788.9,825L1788.9,825
                  L1788.9,825z M1753,804.2L1753,804.2L1753,804.2L1753,804.2z M1626.9,695.4l14.1-8.1V713l-14.1,8.1V695.4z M1642.3,687.2
                  l27.4,15.8l-14.4,8.3l0.3,0.6h-0.7v8.4l-12.7-7.3V687.2z M1656.3,739.3v-17.8v-8.4l45.5,26.3v29.1l-45.5-26.3V739.3z
                    M1897.9,692.5L1776,762.9l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7l-12.8-7.4l2.3-1.3l15.1-8.7v0l0.8-0.5l7.6,4.4l0.3-0.6
                  l0.3,0.6l52.4-30.3l-0.3-0.6l0.3-0.6l-21-12.1l19.4-11.2l-0.3-0.6l0.3-0.6l-9.4-5.4l45.1-26L1897.9,692.5z M1899.3,692.5
                  L1899.3,692.5L1899.3,692.5L1899.3,692.5L1899.3,692.5z M1759.9,772.5v8.4l-11-6.3v-25.8l25.7,14.9l-14.4,8.3l0.3,0.6H1759.9z
                    M1733.5,756.9l14.1-8.1v25.8l-14.1,8.1V756.9z M1736.5,736.8v0.5l-13.8,7.9v-16.4L1736.5,736.8z M1786.5,680.9l-0.3,0.6
                  l9.4,5.4l-19.3,11.2l0.3,0.6l-0.3,0.6l21,12.1l-51.1,29.5l-7.6-4.4l-0.3,0.6l0.3-0.6l-1.1-0.7v0l-15.1-8.7l-7.2-4.2l25.8-14.9
                  l0,0l0,0l-0.3-0.6l0,0l0.3,0.6l-0.3-0.6l0.6,0h-0.6l0,0l0.1-0.2l0.2-0.4l-31.4-18.1l24.2-14l27.3,15.7l0.3-0.6l0.3,0.6l20-11.6
                  l4.5,2.6L1786.5,680.9L1786.5,680.9z M1753.7,804.6l6.2-3.6v1.7l-6.2,3.6V804.6z M1765.9,775.6l12.9-7.4V794l-12.9,7.4V775.6z
                    M1780,768.2l29.3,16.9l-13.2,7.6l0.3,0.6h-0.7v9.8L1780,794V768.2z M1789.6,825.4l6.3-3.6v1.7l-6.3,3.6V825.4z M1797.1,820.6
                  v-16.4v-9.8l4.1,2.4v29.1l-4.1-2.4V820.6z M1802.6,796.8l28.5-16.4v29.1l-28.5,16.4V796.8z M1927.8,759.3L1927.8,759.3
                  L1927.8,759.3L1927.8,759.3L1927.8,759.3z M1863.2,825.2v-27l36.1,20.8l-23.4,13.5L1863.2,825.2z M1863.8,797l63.9-36.9
                  l17.7,10.2l0.3-0.6l0.3,0.6l20-11.6l13.8,8l-19.3,11.1l0.3,0.6h0l-0.4-0.5l0.3,0.5h-0.6v0.1l0.6,0l0,0l-0.3,0.5l0,0l0,0l0,0l0,0
                  l0,0l30.7,17.7l-24.2,14l-26.4-15.2l-0.3,0.6l-0.3-0.6l-39.4,22.8L1863.8,797z M1786.5,680.2l-4.5-2.6l-0.3,0.6l-0.3-0.6
                  l-20,11.6l-27.3-15.7l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6l31.4,18.1l-25.8,14.9l0.3,0.6l-0.1,0.2l-0.2,0.4l7.2,4.2
                  l-18.2,10.5L1657,712l14.4-8.3l-0.3-0.6l0.3-0.6l-29.4-17l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l137.4-79.3l71.3,41.2
                  L1786.5,680.2z M1625.6,695.4v26.6l-2.2,1.3l-1.1-0.7v-29.1L1625.6,695.4z M1623.7,724.5l2.8-1.6l15.1-8.7l13.3,7.7v17l-6.9,4
                  l-28.1-16.2L1623.7,724.5z M1647.4,744.1v1.7l-28.1-16.2v-1.7L1647.4,744.1z M1648.8,744.1l6.2-3.6v1.7l-6.2,3.6V744.1z
                    M1703.1,739.4l18.2-10.5v17.2l-3,1.7l0.3,0.6h-0.7v11.5l-14.9,8.6V739.4z M1719.4,760.2v-10.7l12.8,7.4v26.6l-2.2,1.3
                  l-10.6-6.1V760.2z M1730.3,786.1l2.8-1.6l15.1-8.7l11.6,6.7v17l-6.9,4l-26.4-15.2L1730.3,786.1z M1752.3,804.6v1.7l-26.4-15.2
                  v-1.7L1752.3,804.6z M1761.2,799.9V782v-8.4l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V799.9z M1762.7,804.8l2.8-1.6l13.9-8l16.4,9.5v15.6
                  l-6.9,4l-30-17.3L1762.7,804.8z M1758.3,808.1l29.9,17.3v1.7l-29.9-17.3V808.1z M1831.7,809.8L1831.7,809.8L1831.7,809.8
                  L1831.7,809.8z M1832.4,780.4l29.4,17v27l-1.9-1.1l-0.3,0.6l-0.3-0.6l-1.4,0.8l-25.4-14.6V780.4z M1857.8,824.8L1857.8,824.8
                  l0.3,0.6l1.4-0.8l2.6,1.5l0,0l0,0l0.3-0.6h0l-0.3,0.6l13.3,7.7l0.3-0.6l0.3,0.6l24.8-14.3l-0.3-0.6l0,0l0.3,0.6l39.4-22.8
                  l26.4,15.2l0.3-0.6l0.3,0.6l25.6-14.8l-0.3-0.6l0,0l0.3,0.6l-0.3-0.6l0.3-0.6l-30.7-17.7l19.3-11.1l-0.3-0.6l0,0l0.4,0.5
                  l-0.3-0.6l0.3-0.6l-15.1-8.7l0,0l0,0l-0.3,0.6l-0.3-0.6l-0.3,0.2l-19.7,11.4l-17.6-10.2l0,0l0,0l0,0l-17.4-10l19.4-11.2
                  l-0.3-0.6l0.3-0.6l-20.4-11.8l22.2-12.8l-0.3-0.6l0,0l0.3,0.6l25.2-14.6l172.4,99.5l-124.4,71.8l0.3,0.6l-0.3,0.6l25.5,14.7
                  l-144.9,83.7l-15.1-8.7l-0.3,0.6h0l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-137.4-79.3l143.8-83L1857.8,824.8z
                    M1851.6,988.5L1851.6,988.5L1851.6,988.5L1851.6,988.5z M1869.6,986.9l-12.7,7.3v-8.4h-0.7l0.3-0.6l-14.4-8.3l27.4-15.8V986.9z
                    M1852.3,988.9l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V988.9z M1857,1014.4l6.2,3.6v1.7l-6.2-3.6V1014.4z M1863.8,1017.6L1863.8,1017.6
                  L1863.8,1017.6L1863.8,1017.6z M1870.3,987.3L1870.3,987.3L1870.3,987.3L1870.3,987.3z M1871,986.9v-25.8l14.1,8.1V995
                  L1871,986.9z M1885.7,968.9L1885.7,968.9L1885.7,968.9L1885.7,968.9z M1713.6,909.6l137.4,79.3v29.1l-137.4-79.3V909.6z
                    M1864.5,1017.9l28.1-16.2v1.7l-28.1,16.2V1017.9z M1863.8,1016.8l-6.9-4v-17.1l13.3-7.7l15.1,8.7l0.3-0.6h0l-0.3,0.6l2.8,1.6
                  l0,0l3.7,2.1L1863.8,1016.8z M1888.6,997.1l-2.2-1.3v-26.5l144.9-83.7v29.1L1888.6,997.1z M2032,883.6l-25.2-14.5l123.4-71.3
                  v29.1L2032,883.6z"/>
                <path d="M1827.1,896.1l-0.3,0.6l0,0l0,0l19.3,11.1l-26.6,15.4l0.3,0.6l-0.2,0.4l-0.1,0.2l25.5,14.7l0.3-0.6l0,0l-0.2,0.6
                  l0.3-0.6l0.3,0.6l0,0l0,0l64.8-37.4l0,0l0,0l-0.3-0.5l0-0.1l0,0l0,0l0,0l0.6,0h-0.7l0,0l0-0.1l0.3-0.5l-25.5-14.7l-0.3,0.6l0,0
                  l0,0l0,0l0.3-0.6l0,0l-0.3,0.6l-0.3-0.6l0,0l0,0l-21.7,12.5l-20-11.5l-0.3,0.6l0,0l0.3-0.6l0,0l-0.3,0.6l-0.3-0.6l0,0l0,0
                  l-15.2,8.8L1827.1,896.1z M1862.8,899L1862.8,899L1862.8,899L1862.8,899l-0.3,0.6L1862.8,899l0.3,0.6l0,0l0,0l21.7-12.5l24.2,14
                  l-63.5,36.7l-24.2-14l26.6-15.4l-0.3-0.6l0.2-0.4l0.1-0.2l-19.3-11.2l13.9-8l20,11.5L1862.8,899z M1847.5,907.8l0.1-0.1
                  L1847.5,907.8L1847.5,907.8z M1827.1,896.1L1827.1,896.1l-0.1,0.3L1827.1,896.1z M1819.9,923.8L1819.9,923.8l-0.1,0.1
                  L1819.9,923.8z"/>
              </g>
            </g>
          </g>
          <g id="D2" className={'isometry__floor ' + (floor === "2" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.12.0202" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g112" transform="scale(1.08737)">
                <path id="path114_17_" className="st0" d="M1642.1,768.7l61-35.2l0,0l113.3,65.4l-25.5,14.7l14.2,8.2l-35.5,20.5l-42.7-24.6
                  l29.8-17.2l-23.5-13.6l-20.3,11.7l-18.7-10.8l-9.6,5.5L1642.1,768.7z"/>
              </g>
              <g id="g120" transform="scale(1.08171)">
                <path id="path122_17_" className="st0" d="M1778.9,846.8l35.7-20.6v8.8v16.5v3l-35.7,20.6V846.8z"/>
              </g>
              <g id="g128" transform="scale(1.09796)">
                <path id="path130_19_" className="st0" d="M1819.9,831.2l-25.3,14.6v-3l25.3-14.6V831.2z"/>
              </g>
              <g id="g132" transform="scale(1.08519)">
                <path id="path134_16_" className="st0" d="M1815.7,852.8v3l-7-4v-3L1815.7,852.8z"/>
              </g>
              <g id="g136" transform="scale(1.09796)">
                <path id="path138_16_" className="st0" d="M1819.9,828.3l-25.3,14.6l-6.9-4v-16.2l11.2-6.5l14.1,8.1l2.6,1.5L1819.9,828.3z"/>
              </g>
              <g id="g140" transform="scale(1.08737)">
                <path id="path142_16_" className="st0" d="M1816.4,824.1l-11.3,6.5v-8.7l-14.2-8.2l25.5-14.7L1816.4,824.1z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.12.0201" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g116_1_" transform="scale(1.06395)">
                <path id="path118_17_" className="st0" d="M1765,835.8l43.6,25.2l-36.2,20.9l-14.5-8.4l-27.7,16l14.5,8.4l-4.4,2.5L1610,825.1
                  l68.3-39.4l43.6,25.2l-4.5,2.6l19.1,11l-25.9,15l24,13.9L1765,835.8z"/>
              </g>
              <g id="g144" transform="scale(1.06395)">
                <path id="path146_16_" className="st0" d="M1808.6,860.9v28.8l-33.6,19.4l-2.7-1.5v-25.7L1808.6,860.9z"/>
              </g>
              <g id="g164" transform="scale(1.0497)">
                <path id="path166_16_" className="st0" d="M1803.6,924v3.1l-28.1,16.2v-3.1L1803.6,924z"/>
              </g>
              <g id="g168" transform="scale(1.0497)">
                <path id="path170_16_" className="st0" d="M1803.6,924l-28.1,16.2l-7.2-4.2v-17l13.3-7.7l14.7,8.5l2.7,1.6L1803.6,924z"/>
              </g>
              <g id="g172" transform="scale(1.03911)">
                <path id="path174_16_" className="st0" d="M1799.9,920.7l-13.5,7.8v-9.2l-14.9-8.6l28.3-16.4V920.7z"/>
              </g>
              <g id="g176" transform="scale(1.03053)">
                <path id="path178_16_" className="st0" d="M1662.2,851.8l134.6,77.7v29.7l-134.6-77.7V851.8z"/>
              </g>
              <g id="g180" transform="scale(1.03267)">
                <path id="path182_14_" className="st0" d="M1793,957.3v-29.6l4.5-2.6v9.2v17.3v3.2L1793,957.3z"/>
              </g>
              <g id="g184" transform="scale(1.04621)">
                <path id="path186_14_" className="st0" d="M1802.4,896.8v26.1l-14.7-8.5v-26.1L1802.4,896.8z"/>
              </g>
              <g id="g188" transform="scale(1.03615)">
                <path id="path190_14_" className="st0" d="M1791.5,948.3l7.3,4.2v3.2l-7.3-4.2V948.3z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0203" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g26" transform="scale(1.12723)">
                <path id="path28_17_" className="st0" d="M1829.1,739.3v2.9l-26.5,15.3l-23.5-13.6l50-28.8v12.4L1829.1,739.3z"/>
              </g>
              <g id="g30" transform="scale(1.12723)">
                <path id="path32_17_" className="st0" d="M1829.1,715l-50,28.8l23.5,13.6l0,0l-4.8,2.8l-13.7-7.9l-27.8,16l13.7,7.9l-4.1,2.4
                  l-13.7-7.9l-109.3-63.1l5.2-3l4.2-2.4l19.3-11.2l-19.3-11.2l35.3-20.4l57.5,33.2l22.7-13.1l51.1,29.5L1829.1,715z"/>
              </g>
              <g id="g34" transform="scale(1.11352)">
                <path id="path36_18_" className="st0" d="M1819.9,769.6l4.9-2.8v27.5l-2.3,1.3l-2.6-1.5V769.6z"/>
              </g>
              <g id="g46" transform="scale(1.04556)">
                <path id="path48_19_" className="st0" d="M1781.3,733l20.8,12l-20.8,12V733z"/>
              </g>
              <g id="g50" transform="scale(1.10391)">
                <path id="path52_18_" className="st0" d="M1793.4,784.6l28.3-16.4V793l-14.4,8.3v-8.6L1793.4,784.6z"/>
              </g>
              <g id="g54" transform="scale(1.1145)">
                <path id="path56_17_" className="st0" d="M1790.3,793.7l14.2-8.2l13.8,8l2.5,1.5l4.3,2.5l-28.1,16.2l-6.8-3.9V793.7z"/>
              </g>
              <g id="g58" transform="scale(1.1145)">
                <path id="path60_17_" className="st0" d="M1797.1,813.6l28.1-16.2v2.9l-28.1,16.2V813.6z"/>
              </g>
              <g id="g66" transform="scale(1.09662)">
                <path id="path68_17_" className="st0" d="M1815.2,800.4l4.2-2.4v8.7v16.2v3l-1.6,0.9l-2.6-1.5V800.4z"/>
              </g>
              <g id="g74" transform="scale(1.1001)">
                <path id="path76_17_" className="st0" d="M1813.7,823.2v-3l6.9,4v3L1813.7,823.2z"/>
              </g>
              <g id="g78" transform="scale(1.11101)">
                <path id="path80_17_" className="st0" d="M1824,771.3v24.6l-13.9-8v-24.6L1824,771.3z"/>
              </g>
              <g id="g70" transform="scale(1.09448)">
                <path id="path72_17_" className="st0" d="M1818.8,801.9v25l-14.1-8.2v-25L1818.8,801.9z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0202" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g22" transform="scale(1.13137)">
                <path id="path24_18_" className="st0" d="M1822.4,739.4v-2.9l7.9,4.6v2.9L1822.4,739.4z"/>
              </g>
              <g id="g160" transform="scale(1.15902)">
                <path id="path162_17_" className="st0" d="M1709.5,635l-13.1-7.5l42.1-24.3l0,0l100.1,57.8l-34.5,19.9l-10-5.8l-25.1,14.5
                  l-49.7-28.7l-27.4-15.8L1709.5,635z"/>
              </g>
              <g id="g220" transform="scale(1.15902)">
                <path id="path222_13_" className="st0" d="M1838.5,687.4l-32.1,18.5l-2.5-1.4v-23.6l34.5-19.9L1838.5,687.4z"/>
              </g>
              <g id="g224" transform="scale(1.14474)">
                <path id="path226_13_" className="st0" d="M1834.3,720.7l-25.4,14.7v-2.9l25.4-14.7V720.7z"/>
              </g>
              <g id="g228" transform="scale(1.14474)">
                <path id="path230_13_" className="st0" d="M1834.3,717.8l-25.4,14.7l-7.9-4.5v-11.6l15.3-8.8l10.1,5.8l2.5,1.4L1834.3,717.8z"/>
              </g>
              <g id="g232" transform="scale(1.13529)">
                <path id="path234_13_" className="st0" d="M1805.9,704.1l25.6-14.8v24.1l-15.4,8.9v-12.3L1805.9,704.1z"/>
              </g>
              <g id="g236" transform="scale(1.1406)">
                <path id="path238_13_" className="st0" d="M1833.1,691.9v24L1823,710v-24L1833.1,691.9z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0201" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g42" transform="scale(1.10567)">
                <path id="path44_18_" className="st0" d="M1720.5,672.4l-36,20.8l-27.8-16.1l-27,15.6l-4.9-2.8l12.8-7.4l-28.3-16.3l-12.8,7.4
                  l-4.2-2.4l14-8.1l111.5-64.4l0,0l29.3,16.9l23.1-13.3l52.2,30.2l-62.5,36.1l-16.3-9.4L1720.5,672.4z"/>
              </g>
              <g id="g62" transform="scale(1.03554)">
                <path id="path64_17_" className="st0" d="M1798.6,740.1v24.3l-4.6,2.6l-25.1-14.5v-29.6L1798.6,740.1z"/>
              </g>
              <g id="g192" transform="scale(1.01168)">
                <path id="path194_14_" className="st0" d="M1758.8,727.9l30.9,17.9l-14,8.1v10.8l-16.9-9.8L1758.8,727.9z"/>
              </g>
              <g id="g196" transform="scale(1.00516)">
                <path id="path198_13_" className="st0" d="M1770.2,759.8l17,9.8v16.3l-7.5,4.4l-31.1-18l4.7-2.7l2.8-1.6L1770.2,759.8z"/>
              </g>
              <g id="g200" transform="scale(1.00516)">
                <path id="path202_13_" className="st0" d="M1779.7,790.3l7.5-4.4v3.3l-7.5,4.3V790.3z"/>
              </g>
              <g id="g204" transform="scale(1.00167)">
                <path id="path206_13_" className="st0" d="M1754.7,775l31.2,18v3.3l-31.2-18V775z"/>
              </g>
              <path id="path208_14_" className="st0" d="M1762.3,773.6l-1.8-1v-30.6l4.6,2.7v27.3L1762.3,773.6z"/>
              <g id="g210_1_" transform="scale(1.00766)">
                <path id="path212_14_" className="st0" d="M1782.8,787.2V784v-16.2v-10.9l5.4,3.1v30.4L1782.8,787.2z"/>
              </g>
              <g id="g214" transform="scale(1.02138)">
                <path id="path216_14_" className="st0" d="M1764.2,779.8v-30l29.2-16.9v30L1764.2,779.8z"/>
              </g>
              <path id="path218_13_" className="st0" d="M1765.2,771.9v-27.3l14.2-8.2v27.3L1765.2,771.9z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.10.0202" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g82" transform="scale(1.05245)">
                <path id="path84_17_" className="st0" d="M1741.6,592.5l63,36.4l0,0l-117.1,67.6l-26.3-15.2l-14.7,8.5l-36.7-21.2l44.1-25.4
                  l30.8,17.8l24.3-14l-20.9-12.1l19.3-11.2l-9.9-5.7L1741.6,592.5z"/>
              </g>
              <path id="path90_18_" className="st0" d="M1694.2,703.6l38.6,22.3v27.3l-2.8,1.6l-35.8-20.6V703.6z"/>
              <path id="path96_17_" className="st0" d="M1725.2,760.9v-3.3l27.7,16v3.3L1725.2,760.9z"/>
              <path id="path98_18_" className="st0" d="M1753,773.6l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1753,773.6z"/>
              <path id="path100_17_" className="st0" d="M1760.6,751.4l-12.3-7.1V717l27.7,16l-15.4,8.9V751.4z"/>
              <path id="path106_18_" className="st0" d="M1760.6,772.5l-7.6,4.4v-3.3l7.6-4.4V772.5z"/>
              <path id="path110_17_" className="st0" d="M1732.8,753.2v-27.3l15.4-8.9v27.3L1732.8,753.2z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.10.0201" floor="2" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g86" transform="scale(1.02193)">
                <path id="path88_17_" className="st0" d="M1703.3,662.3l-45.4,26.2l-37.7-21.8l15.1-8.7l-28.8-16.6l-15.1,8.7l-4.5-2.6l135.7-78.4
                  l71.1,41l-45.4,26.2l-4.7-2.7l-19.9,11.5l-27-15.6l-25,14.4L1703.3,662.3z"/>
              </g>
              <path id="path108_17_" className="st0" d="M1694.2,703.6v30.6l-38.5-22.3v-30.6L1694.2,703.6z"/>
              <path id="path148_17_" className="st0" d="M1648.1,716.4l-29.4-17v-3.3l29.4,17V716.4z"/>
              <path id="path150_16_" className="st0" d="M1648.1,716.4v-3.3l7.6-4.4v3.3L1648.1,716.4z"/>
              <path id="path152_17_" className="st0" d="M1648.1,713.1l-29.4-17l4.7-2.7l2.8-1.6l15.4-8.9l14,8.1v17.8L1648.1,713.1z"/>
              <path id="path154_16_" className="st0" d="M1655.7,690.9l-14-8.1v-27.3l29.4,17l-15.4,8.9V690.9z"/>
              <path id="path156_17_" className="st0" d="M1641.7,682.8l-15.4,8.9v-27.3l15.4-8.9V682.8z"/>
              <path id="path158_16_" className="st0" d="M1626.2,664.4v27.3l-2.8,1.6l-1.8-1v-30.6L1626.2,664.4z"/>
            </ModIsometrySvgFlat>
            <g id="D2-Areas" className="isometry__areas">
              <g id="g38" transform="scale(1.09543)">
                <path id="path40_18_" className="st0" d="M1819.1,699.3l-23.3,13.5l-59.2-34.2l23.3-13.5l16.4,9.5l0,0l18.6-10.7l13.8,8l-18.6,10.7
                  L1819.1,699.3z"/>
              </g>
              <g id="g92_1_" transform="scale(1.00614)">
                <path id="path94_18_" className="st0" d="M1762.2,691.3l-32.3-18.6l-32.2-18.6l25.4-14.7l27.4,15.8l20.2-11.7l4.8,2.8l10.4,6
                  l-20.2,11.7l21.9,12.6L1762.2,691.3z"/>
              </g>
              <g id="g124" transform="scale(1.05754)">
                <path id="path126_16_" className="st0" d="M1806.4,823.1l-30.7,17.7l-30.6,17.7l-24.2-14l26.1-15.1l-19.3-11.1l4.5-2.6l9.9-5.7
                  l19.2,11.1l20.8-12L1806.4,823.1z"/>
              </g>
            </g>
            <g id="D2-Strokes" className="isometry__strokes">
              <path d="M2131.5,766.1h-0.7l0.3-0.6l-116-66.9l0,0l-57.7-33.3l-0.3,0.6l-0.3-0.6l-25.2,14.6l-32-18.5l-0.3,0.6l0,0l0,0v0l0,0v0
                l0.3-0.6l-66.3-38.3l0,0l-72.6-41.9l-0.3,0.6l-0.3-0.6l-138.7,80.1l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l0.8,0.5l-3.7,2.2l0.3,0.6
                h-0.7v3.3h0.7l-0.3,0.6l29.4,17l0.3-0.6l0.3,0.6l7.2-4.2l38.2,22.1l34.8,20.1l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16
                l0.3-0.6h0l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.2l0.3,0.6l0,0h-0.7v3.3h0.7l-0.3,0.6l31.3,18.1l0.3-0.6h0l0.3,0.6l7.2-4.2l5.1,2.9
                l0.3-0.6l0.3,0.6l29.5-17l24.7,14.3l-4.9,2.8l0.3,0.5l0,0l-0.3-0.6l-66.3,38.3v0l-72.6,41.9l0.3,0.6l0,0h-0.7v30.6h0.7l-0.3,0.6
                l138.7,80.1l0.3-0.6h0l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20
                l-0.3-0.6h0l0.3,0.6l38.3-22.1l7.2,4.2l0.3-0.6h0l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1l0.5-0.3l7.2,4.2
                l0.3-0.6h0l0.3,0.6l31.3-18.1l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l1.6-0.9l-0.3-0.6h0.7v-30.2l29.1-16.8l8.7,5l0.3-0.6
                l0.3,0.6l29.1-16.8l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-5.2-3l36.2-20.9l-0.3-0.6h0.7V766.1z M2130.2,796.3l-36.5,21.1l-2.2-1.3
                v-26.6l38.7-22.3V796.3z M1891.9,969.9l-28.1,16.2l-6.9-4v-17.1l13.3-7.7l15.1,8.7l0.3-0.6h0l-0.3,0.6l2.8,1.6l0.3-0.6l0,0
                l-0.3,0.6L1891.9,969.9z M1622.3,662.9l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V662.9z M1932,681.1l25.2-14.6l56.4,32.6l-67.8,39.1
                l-17.6-10.2l-0.3,0.6l-0.3-0.6l-25.6,14.8v0l-39.4,22.8l-30.4-17.6l-0.3,0.6h0l-0.3-0.6l-29.5,17l-4.1-2.4l13.2-7.6l-0.3-0.6
                l0.3-0.6l-31.3-18.1l-0.3,0.6l-0.3-0.6l-13.9,8l-3.3-1.9l14.4-8.3l-0.3-0.6l0,0l0.3,0.6l122.9-71l32,18.5l0.3-0.6L1932,681.1z
                  M2002.8,906.7L2002.8,906.7L2002.8,906.7L2002.8,906.7z M1970.4,925.4L1970.4,925.4L1970.4,925.4L1970.4,925.4z M1862.4,869
                l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14l-31.4,18.2l0.3,0.6l0,0l-0.3-0.6l-32.1,18.5l-24.2-14l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2
                l3.8-2.2l0,0l10.1-5.8L1862.4,869z M1924.3,916L1924.3,916L1924.3,916L1924.3,916z M1785.5,836.7l45.1,26l-3.8,2.2l0.3,0.6
                l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-37.2,21.5l-15.1-8.7l-0.3,0.6h0
                l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-137.4-79.3L1785.5,836.7z M1869.6,956.3l-12.7,7.3v-8.4h-0.7l0.3-0.6
                l-14.4-8.3l27.4-15.8V956.3z M1870.9,930.5l14.1,8.1v25.8l-14.1-8.1V930.5z M1851.6,957.9L1851.6,957.9L1851.6,957.9
                L1851.6,957.9z M1852.3,958.3l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V958.3z M1856.9,983.7l6.2,3.6v1.7l-6.2-3.6V983.7z M1863.8,986.9
                L1863.8,986.9L1863.8,986.9L1863.8,986.9z M1870.3,956.7L1870.3,956.7L1870.3,956.7L1870.3,956.7z M1962.8,893.7l0.3-0.6
                l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4H1962.8z M1975.8,869.9l14.1,8.1v25.8l-14.1-8.1V869.9z M1963.5,922.2l6.2,3.6v1.7
                l-6.2-3.6V922.2z M1990.6,877.7L1990.6,877.7L1990.6,877.7L1990.6,877.7z M1995.3,875l0.3-0.6l-14.4-8.3l29.3-16.9V875l-14.5,8.4
                V875H1995.3z M2011.8,849.2l14.1,8.1v25.8l-14.1-8.1V849.2z M1995.9,903.5l6.2,3.6v1.7l-6.2-3.6V903.5z M2026.5,857L2026.5,857
                L2026.5,857L2026.5,857z M2078.6,809.5l-16.2,9.3V806h-0.7l0.3-0.6l-10.5-6.1l27.1-15.6V809.5z M2080,783.7l10.2,5.9v25.8
                l-10.2-5.9V783.7z M2032,853l-25.2-14.5l54.3-31.4V820v13.3v2.9L2032,853z M2062.4,834.5l7.7,4.4v1.7l-7.7-4.4V834.5z
                  M2079.3,809.9L2079.3,809.9L2079.3,809.9L2079.3,809.9z M2090.8,788.4l-11.2-6.5l-0.3,0.6l-0.3-0.6l-28.7,16.6l-57.2-33l0,0
                l-30.7-17.7l19.3-11.2l-0.3-0.6l0.3-0.6l-14.1-8.2l47.4-27.4l114.6,66.2L2090.8,788.4z M1831.1,778.8l-28.5,16.4v-29.1l28.5-16.4
                V778.8z M1788.9,794.4L1788.9,794.4L1788.9,794.4L1788.9,794.4z M1753,773.6L1753,773.6L1753,773.6L1753,773.6z M1897.9,661.8
                L1776,732.3l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7l-37.3-21.5l45.1-26l32.1,18.5l0.3-0.6l0.3,0.6l25.6-14.8l-0.3-0.6l0.3-0.6
                l-21-12.1l19.4-11.2l-0.3-0.6l0.3-0.6l-9.4-5.4l45.1-26L1897.9,661.8z M1759.9,741.9v8.4l-11-6.3v-25.8l25.7,14.9l-14.4,8.3
                l0.3,0.6H1759.9z M1733.5,726.3l14.1-8.1v25.8l-14.1,8.1V726.3z M1786.5,650.3l-0.3,0.6l9.4,5.4l-19.3,11.2l0.3,0.6l-0.3,0.6
                l21,12.1l-24.2,14l-32.1-18.5l-31.4-18.1l24.2-14l27.3,15.7l0.3-0.6l0.3,0.6l20-11.6l4.5,2.6L1786.5,650.3L1786.5,650.3z
                  M1626.9,664.8l14.1-8.1v25.8l-14.1,8.1V664.8z M1642.3,656.6l27.4,15.8l-14.4,8.3l0.3,0.6h-0.7v8.4l-12.7-7.3V656.6z
                  M1648.8,713.5l6.2-3.6v1.7l-6.2,3.6V713.5z M1656.3,708.7v-17.8v-8.4l37.2,21.5v29.1l-37.2-21.5V708.7z M1753.7,774l6.2-3.6v1.7
                l-6.2,3.6V774z M1765.9,745l12.9-7.4v25.8l-12.9,7.4V745z M1780,737.6l29.3,16.9l-13.2,7.6l0.3,0.6h-0.7v9.8l-15.8-9.1V737.6z
                  M1789.6,794.8l6.3-3.6v1.7l-6.3,3.6V794.8z M1797.1,790v-16.4v-9.8l4.1,2.4v29.1l-4.1-2.4V790z M1863.2,767.5l19.8,11.4
                l-19.8,11.4V767.5z M1945.5,739.6l0.3-0.6l0.3,0.6l0,0l0,0l20-11.5l13.8,8l-19.4,11.2l0.3,0.6l-0.3,0.6l30.7,17.7l-24.2,14
                l-63.5-36.7l24.2-14L1945.5,739.6z M1899.3,661.9L1899.3,661.9L1899.3,661.9L1899.3,661.9L1899.3,661.9z M1786.5,649.6l-4.5-2.6
                l-0.3,0.6l-0.3-0.6l-20,11.6l-27.3-15.7l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6l31.4,18.1l-45.1,26l-37.2-21.5l14.4-8.3
                l-0.3-0.6l0.3-0.6l-29.4-17l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l137.4-79.3l71.3,41.2L1786.5,649.6z M1623.7,693.9
                L1623.7,693.9l2.8-1.6l-0.3-0.6l0.3,0.6l15.1-8.7l13.3,7.7v17l-6.9,4l-28.1-16.2L1623.7,693.9z M1647.4,713.5v1.7l-28.1-16.2
                v-1.7L1647.4,713.5z M1694.9,704.8l37.3,21.5v26.6l-2.2,1.3l-35.1-20.3V704.8z M1730.3,755.5l2.8-1.6l15.1-8.7l11.6,6.7v17
                l-6.9,4l-26.4-15.2L1730.3,755.5z M1752.3,774v1.7l-26.4-15.2v-1.7L1752.3,774z M1761.2,769.3v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3
                l-1.1-0.7V769.3z M1762.7,774.2l2.8-1.6l13.9-8l16.4,9.5v15.6l-6.9,4l-30-17.3L1762.7,774.2z M1758.3,777.5l29.9,17.3v1.7
                l-29.9-17.3V777.5z M1831.7,779.2L1831.7,779.2L1831.7,779.2L1831.7,779.2z M1832.4,749.7l29.4,17v24.4l-4.1,2.3l-25.4-14.7
                V749.7z M1857.8,794.2L1857.8,794.2l0.3,0.6l4.7-2.7l-0.3-0.6h0l0.3,0.6l21.8-12.6l-0.3-0.6l0.3-0.6l-20.8-12l38.4-22.2
                l64.5,37.2l0.3-0.6l0.3,0.6l25.2-14.6l57.2,33l0.3-0.6l0,0l-0.3,0.6l10.5,6.1l-55.3,31.9l0.3,0.6l-0.3,0.6l25.5,14.7l-4.1,2.4
                l-15.1-8.7l-0.3,0.6l-0.3-0.6l-31.3,18.1l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-15.1-8.7v0l-122.3-70.6l4.9-2.8L1857.8,794.2z
                  M1973.8,868.8l-26.7,15.4l0.3,0.6l-0.3,0.6l14.4,8.3l-37.3,21.5l-45.1-26l31.5-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6
                l-0.3-0.6l-21.7,12.5l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8l-45.1-26l65-37.5L1973.8,868.8z M1851,958.3v29.1l-137.4-79.3V879
                L1851,958.3z M1864.5,987.3l28.1-16.2v1.7l-28.1,16.2V987.3z M1888.5,966.4l-2.2-1.3v-26.6l37.2-21.5v29.1L1888.5,966.4z
                  M1924.9,916.4l37.3-21.5v8.4V921v2.9l-37.3,21.5V916.4z M1997.5,912.3l-26.4,15.2v-1.7l26.4-15.2V912.3z M1970.4,924.6l-6.9-4
                v-17.1l11.6-6.7l15.1,8.7l0.3-0.6h0l-0.3,0.6l2.8,1.6l0.3-0.6l0,0l-0.3,0.6l3.7,2.1L1970.4,924.6z M1993.5,905.9l-2.2-1.3v-26.5
                l3.3-1.9v8.4v17.8v2.9L1993.5,905.9z M2003.5,907.1l30-17.3v1.7l-30,17.3V907.1z M2002.8,906l-6.9-4v-17.1l15.2-8.8l15.1,8.7l0,0
                l2.8,1.6v0l3.7,2.2L2002.8,906z M2029.4,885.2l-2.2-1.3v-26.6l4.1-2.4V884L2029.4,885.2z M2061.8,836.6L2061.8,836.6
                L2061.8,836.6L2061.8,836.6z M2099.2,824.6l-27.7,16v-1.7l27.7-16V824.6z M2070.8,837.7l-8.3-4.8v-12.6l16.8-9.7l11.2,6.5l0,0
                l2.8,1.6l0,0l5.2,3L2070.8,837.7z M1851.9,797.6L1851.9,797.6L1851.9,797.6L1851.9,797.6L1851.9,797.6z"/>
            </g>
          </g>
          <g id="D3" className={'isometry__floor ' + (floor === "3" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.12.0302" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g66_1_" transform="scale(1.09448)">
                <path id="path68_18_" className="st0" d="M1692,700.8l126.7,73.2l-60.6,35l-42.4-24.5l29.6-17.1l-23.3-13.5l-20.1,11.6l-18.6-10.7
                  l-9.5,5.5l-42.4-24.5L1692,700.8z"/>
              </g>
              <g id="g70_1_" transform="scale(1.09797)">
                <path id="path72_18_" className="st0" d="M1819.9,800.4v3l-25.3,14.6v-3L1819.9,800.4z"/>
              </g>
              <g id="g74_1_" transform="scale(1.09797)">
                <path id="path76_18_" className="st0" d="M1813,796.4l6.9,4l-25.3,14.6l-6.9-4v-16.2l11.2-6.5L1813,796.4z"/>
              </g>
              <g id="g110" transform="scale(1.09448)">
                <path id="path112_17_" className="st0" d="M1758.2,836.9v-28l60.6-35v3l-14.1,8.1l-11.3,6.5v5.7v16.3v3L1758.2,836.9z"/>
              </g>
              <g id="g122" transform="scale(1.08738)">
                <path id="path124_17_" className="st0" d="M1816.5,796l-11.3,6.5v-5.7l11.3-6.5V796z"/>
              </g>
              <g id="g126_1_" transform="scale(1.08521)">
                <path id="path128_17_" className="st0" d="M1815.7,824.5v3l-7-4v-3L1815.7,824.5z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.12.0301" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g82_1_" transform="scale(1.06396)">
                <path id="path84_18_" className="st0" d="M1765,807l43.6,25.2l-68.3,39.4L1610,796.3l68.3-39.4l43.6,25.2l-4.5,2.6l19.1,11l-25.9,15
                  l24,13.9L1765,807z"/>
              </g>
              <g id="g86_1_" transform="scale(1.06396)">
                <path id="path88_18_" className="st0" d="M1740.3,871.6l68.3-39.4v28.8l-33.6,19.4l-2.7-1.5v-22.6l-14.5,8.4l-13.2,7.6v5.9v16.7v3.1
                  l-4.4,2.5V871.6z"/>
              </g>
              <g id="g94" transform="scale(1.04971)">
                <path id="path96_18_" className="st0" d="M1803.6,894.9v3.1l-28,16.2v-3.1L1803.6,894.9z"/>
              </g>
              <g id="g98" transform="scale(1.04971)">
                <path id="path100_18_" className="st0" d="M1803.6,894.9l-28,16.2l-7.2-4.2v-17l13.3-7.7l14.7,8.5l2.7,1.6L1803.6,894.9z"/>
              </g>
              <g id="g102_1_" transform="scale(1.03912)">
                <path id="path104_18_" className="st0" d="M1799.9,891.2l-13.5,7.8v-6l13.5-7.8V891.2z"/>
              </g>
              <g id="g106" transform="scale(1.03054)">
                <path id="path108_18_" className="st0" d="M1662.2,822.1l134.6,77.7v29.7l-134.6-77.7V822.1z"/>
              </g>
              <g id="g114" transform="scale(1.04622)">
                <path id="path116_16_" className="st0" d="M1802.4,870.7v23l-14.7-8.5v-6L1802.4,870.7z"/>
              </g>
              <g id="g118" transform="scale(1.03616)">
                <path id="path120_17_" className="st0" d="M1791.5,918.8l7.3,4.2v3.2l-7.3-4.2V918.8z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0303" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g26_1_" transform="scale(1.12771)">
                <path id="path28_18_" className="st0" d="M1829.2,712.3v2.9l-27.3,15.8v-1l-22.6-13l49.9-28.8v11.3V712.3z"/>
              </g>
              <g id="g30_1_" transform="scale(1.12771)">
                <path id="path32_18_" className="st0" d="M1779.3,716.9l22.6,13l-36.7,21.2l-123-71l5.2-3l4.2-2.4l19.3-11.1l-19.3-11.1l35.3-20.4
                  l57.5,33.2l22.7-13.1l51,29.5l11.2,6.4L1779.3,716.9z"/>
              </g>
              <g id="g34_1_" transform="scale(1.11352)">
                <path id="path36_19_" className="st0" d="M1787.7,760.7l37.1-21.4v1.1v26.4l-2.3,1.3l-2.6-1.5v-21.6l-13.9,8l-14.2,8.2v5.6v16
                  l-4.2,2.4v-21.6L1787.7,760.7z"/>
              </g>
              <g id="g46_1_" transform="scale(1.04556)">
                <path id="path48_20_" className="st0" d="M1781.3,703.7l20.8,12l-20.8,12V703.7z"/>
              </g>
              <g id="g50_1_" transform="scale(1.1145)">
                <path id="path52_19_" className="st0" d="M1825.1,769.9v2.9l-32.2,18.6v-2.9L1825.1,769.9z"/>
              </g>
              <g id="g54_1_" transform="scale(1.1145)">
                <path id="path56_18_" className="st0" d="M1825.1,769.9l-32.2,18.6l-6.8-3.9l4.2-2.4v-16l14.2-8.2l13.8,8l2.5,1.5L1825.1,769.9z"/>
              </g>
              <g id="g58_1_" transform="scale(1.10391)">
                <path id="path60_18_" className="st0" d="M1821.8,765.3l-14.4,8.3v-5.6l14.4-8.3V765.3z"/>
              </g>
              <g id="g78_1_" transform="scale(1.11101)">
                <path id="path80_18_" className="st0" d="M1824,746.7v21.6l-13.9-8v-5.6L1824,746.7z"/>
              </g>
              <g id="g130_1_" transform="scale(1.09448)">
                <path id="path132_17_" className="st0" d="M1804.7,790.8v-5.7l14.1-8.1v22L1804.7,790.8z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0302" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g22_1_" transform="scale(1.13137)">
                <path id="path24_19_" className="st0" d="M1823.3,712.9V710l7,4.1v2.9L1823.3,712.9z"/>
              </g>
              <g id="g134_1_" transform="scale(1.15902)">
                <path id="path136_17_" className="st0" d="M1709.5,608.6l-13.1-7.5l42.1-24.3l100.1,57.8l-34.5,19.9l-10-5.8l-25.1,14.5l-49.7-28.7
                  l-27.4-15.8L1709.5,608.6z"/>
              </g>
              <g id="g204_1_" transform="scale(1.15902)">
                <path id="path206_14_" className="st0" d="M1838.5,661l-32.1,18.5l-2.5-1.4v-23.6l34.5-19.9L1838.5,661z"/>
              </g>
              <g id="g208" transform="scale(1.14474)">
                <path id="path210_13_" className="st0" d="M1834.3,694l-25.4,14.7v-2.9l25.4-14.7V694z"/>
              </g>
              <g id="g212" transform="scale(1.14474)">
                <path id="path214_14_" className="st0" d="M1834.3,691.1l-25.4,14.7l-6.9-4V689l14.4-8.3l10.1,5.8l2.5,1.4L1834.3,691.1z"/>
              </g>
              <g id="g216" transform="scale(1.13529)">
                <path id="path218_14_" className="st0" d="M1805.9,677.1l25.6-14.8v24.1l-14.5,8.4v-11.3L1805.9,677.1z"/>
              </g>
              <g id="g220_1_" transform="scale(1.1406)">
                <path id="path222_14_" className="st0" d="M1833.1,665.1v24l-10.1-5.8v-24L1833.1,665.1z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0301" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g42_1_" transform="scale(1.10567)">
                <path id="path44_19_" className="st0" d="M1720.5,644.7l-36,20.8l-27.8-16.1l-27,15.6l-37.4-21.6l125.4-72.4l0,0l29.3,16.9
                  l23.1-13.3l52.2,30.2l-44.1,25.5l-18.4,10.6l-16.3-9.4L1720.5,644.7z"/>
              </g>
              <g id="g62_1_" transform="scale(1.03554)">
                <path id="path64_18_" className="st0" d="M1798.6,710.5v24.3l-4.6,2.6l-25.1-14.5v-29.6L1798.6,710.5z"/>
              </g>
              <g id="g176_1_" transform="scale(1.00766)">
                <path id="path178_17_" className="st0" d="M1747.2,709.2v-3.3l41.1,23.7V760l-5.4-3.1v-3.3v-16.2v-7.6l-17-9.8l-14.1-8.1v23.9
                  l-2.8,1.6l-1.8-1V733v-17.7V709.2z"/>
              </g>
              <g id="g182_1_" transform="scale(1.02138)">
                <path id="path184_15_" className="st0" d="M1764.2,749.8v-30l29.2-16.9v30L1764.2,749.8z"/>
              </g>
              <g id="g186_1_" transform="scale(1.00516)">
                <path id="path188_15_" className="st0" d="M1787.3,755.5v3.3l-7.5,4.3v-3.3L1787.3,755.5z"/>
              </g>
              <g id="g190_1_" transform="scale(1.00516)">
                <path id="path192_15_" className="st0" d="M1787.3,755.5l-7.5,4.4l-31.1-18l4.7-2.7l2.8-1.6l14.1-8.1l17,9.8V755.5z"/>
              </g>
              <g id="g194_1_" transform="scale(1.00516)">
                <path id="path196_14_" className="st0" d="M1787.3,739.2l-17-9.8v-7.6l17,9.8V739.2z"/>
              </g>
              <g id="g198_1_" transform="scale(1.00167)">
                <path id="path200_14_" className="st0" d="M1754.7,744.5l31.2,18v3.3l-31.2-18V744.5z"/>
              </g>
              <path id="path202_14_" className="st0" d="M1765.2,741.3v-24l14.2,8.2v7.7L1765.2,741.3z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.10.0302" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g146_1_" transform="scale(1.05245)">
                <path id="path148_18_" className="st0" d="M1684.7,631.8l24.3-14l-20.9-12.1l19.3-11.2l-9.9-5.7l44.1-25.5l63,36.4l0,0l-131.8,76.1
                  l-63-36.4l44.1-25.4L1684.7,631.8z"/>
              </g>
              <path id="path162_18_" className="st0" d="M1694.2,703.6V673l66.3,38.3v3.3l-12.3-7.1l-15.4-8.9v24l-2.8,1.6L1694.2,703.6z"/>
              <path id="path164_17_" className="st0" d="M1725.2,730.3V727l27.7,16v3.3L1725.2,730.3z"/>
              <path id="path166_17_" className="st0" d="M1753,743l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1753,743z"/>
              <path id="path168_16_" className="st0" d="M1760.6,720.8l-12.3-7.1v-6.2l12.3,7.1V720.8z"/>
              <path id="path174_17_" className="st0" d="M1760.6,741.9l-7.6,4.4V743l7.6-4.4V741.9z"/>
              <path id="path180_16_" className="st0" d="M1732.8,722.6v-24l15.4,8.9v6.2L1732.8,722.6z"/>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.10.0301" floor="3" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g138_1_" transform="scale(1.02193)">
                <path id="path140_17_" className="st0" d="M1722.5,539.2l71.1,41l-45.4,26.2l-4.7-2.7l-19.9,11.5l-27-15.6l-25,14.4l31.7,18.3
                  l-45.4,26.2l-71.1-41L1722.5,539.2z"/>
              </g>
              <path id="path150_17_" className="st0" d="M1621.6,631.1l72.6,41.9v30.6l-38.5-22.3v-3.3v-17.8V654l-14-8.1l-15.4-8.9v24l-2.8,1.6
                l-1.8-1L1621.6,631.1z"/>
              <path id="path152_18_" className="st0" d="M1648.1,685.7l-29.4-17v-3.3l29.4,17V685.7z"/>
              <path id="path154_17_" className="st0" d="M1648.1,685.7v-3.3l7.6-4.4v3.3L1648.1,685.7z"/>
              <path id="path156_18_" className="st0" d="M1648.1,682.5l-29.4-17l4.7-2.7l2.8-1.6l15.4-8.9l14,8.1v17.8L1648.1,682.5z"/>
              <path id="path158_17_" className="st0" d="M1655.7,660.3l-14-8.1V646l14,8.1V660.3z"/>
              <path id="path160_17_" className="st0" d="M1641.7,646v6.2l-15.4,8.9v-24L1641.7,646z"/>
            </ModIsometrySvgFlat>
            <g id="D3-Areas" className="isometry__areas">
              <g id="g38_1_" transform="scale(1.09543)">
                <path id="path40_19_" className="st0" d="M1819.1,671.4l-23.3,13.5l-59.2-34.2l23.3-13.5l16.4,9.5l18.6-10.7l13.8,8l-18.6,10.7
                  L1819.1,671.4z"/>
              </g>
              <g id="g90" transform="scale(1.05755)">
                <path id="path92_17_" className="st0" d="M1775.7,811.9l-30.6,17.7l-24.2-14l26.1-15.1l-19.3-11.1l4.5-2.6l9.9-5.7l19.3,11.1
                  l20.8-12l24.2,14L1775.7,811.9z"/>
              </g>
              <g id="g142_1_" transform="scale(1.00614)">
                <path id="path144_17_" className="st0" d="M1750.6,624.9l20.2-11.7l4.8,2.8l10.4,6l-20.2,11.7l21.9,12.6l-25.4,14.7l-32.3-18.6
                  l-32.2-18.6l25.4-14.7L1750.6,624.9z"/>
              </g>
            </g>
            <g id="D3-Strokes" className="isometry__strokes">
              <path d="M2131.5,735.5h-0.7l0.3-0.6l-116-66.9l0,0l-57.7-33.3l-0.3,0.6l-0.3-0.6l-25.2,14.6l-32-18.5l-0.3,0.6l0,0l0,0v0l0,0
                l0.3-0.6l-66.3-38.3l0,0l-72.6-41.9l-0.3,0.6l-0.3-0.6l-138.7,80.1l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l0.8,0.5l-3.7,2.2l0.3,0.6
                h-0.7v3.3h0.7l-0.3,0.6l29.4,17l0.3-0.6l0.3,0.6l7.2-4.2l38.2,22.1l34.8,20.1l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16
                l0.3-0.6h0l0.3,0.6l7.2-4.2l0.5,0.3l-3.7,2.2l0.3,0.6l0,0h-0.7v3.3h0.7l-0.3,0.6l31.3,18.1l0.3-0.6h0l0.3,0.6l7.2-4.2l5.1,2.9
                l0.3-0.6l0.3,0.6l29.5-17l24.7,14.3l-4.9,2.8l0,0l-66.3,38.3v0l-72.6,41.9l0.3,0.6l0,0h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1
                l0.3-0.6h0l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6h0l0.3,0.6l29.4-17l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20l-0.3-0.6h0
                l0.3,0.6l38.3-22.1l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l0,0l35.9-20.7l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l1.6-0.9l-0.3-0.6
                h0.7v-29l30.2-17.4l7.6,4.4l0.3-0.6l0.3,0.6l29.1-16.8l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-5.2-3l36.2-20.9l-0.3-0.6h0.7V735.5z
                  M2129.5,735.5l-38.7,22.3l-11.2-6.5l-0.3,0.6l-0.3-0.6l-28.7,16.6l-57.2-33l0,0l-30.7-17.7l19.3-11.2l-0.3-0.6l0.3-0.6
                l-14.1-8.2l47.4-27.4L2129.5,735.5z M2090.2,759v25.8l-10.2-5.9v-25.8L2090.2,759z M2062.8,776l0.3-0.6l-11.6-6.7l27.1-15.6v25.8
                l-15.1,8.7V776H2062.8z M1961,717.2l-0.3,0.6l30.7,17.7l-24.2,14l-63.5-36.7l24.2-14l17.7,10.2l0.3-0.6l0.3,0.6l20-11.5l13.8,8
                l-19.4,11.2L1961,717.2z M1899.3,631.3L1899.3,631.3L1899.3,631.3L1899.3,631.3z M1899.2,631.3L1899.2,631.3L1899.2,631.3
                L1899.2,631.3L1899.2,631.3L1899.2,631.3z M1897.9,631.2l-137.4,79.3l-65-37.5l45.1-26l32.1,18.5l0.3-0.6l0.3,0.6l25.6-14.8
                l-0.3-0.6l0.3-0.6l-21-12.1l19.4-11.2l-0.3-0.6l0.3-0.6l-9.4-5.4l45.1-26L1897.9,631.2z M1759.9,719.7l-11-6.3v-4.7l11,6.3V719.7
                z M1795.6,625.8l-19.3,11.2l0.3,0.6l-0.3,0.6l21,12.1l-24.2,14l-32.1-18.5l-31.4-18.1l24.2-14l27.3,15.7l0.3-0.6l0.3,0.6l20-11.6
                l4.5,2.6l0.3-0.6l0,0l-0.3,0.6L1795.6,625.8z M1647.4,684.6l-28.1-16.2v-1.7l28.1,16.2V684.6z M1655,681l-6.2,3.6v-1.7l6.2-3.6
                V681z M1655,677.7l-6.9,4l-28.1-16.2l3.7-2.2l-0.3-0.6l0.3,0.6l2.8-1.6l15.1-8.7l13.3,7.7V677.7z M1626.9,659.9v-21.7l14.1,8.1
                v5.5L1626.9,659.9z M1655,659.1l-12.7-7.3v-4.7l12.7,7.3V659.1z M1693.5,702.5l-37.2-21.5v-2.9v-17.8V654h-0.7l0.3-0.6l-14-8.1
                l0,0l-15.4-8.9l-0.3,0.6h-0.7v23.7l-2.2,1.3l-1.1-0.7v-29.1l71.3,41.2V702.5z M1622.9,631.1l137.4-79.3l71.3,41.2l-45.1,26
                l-4.5-2.6l-0.3,0.6l-0.3-0.6l-20,11.6l-27.3-15.7l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6l31.4,18.1l-45.1,26
                L1622.9,631.1z M1694.9,703.2v-29.1l65,37.5v1.7l-11.3-6.5l-15.4-8.9l-0.3,0.6h-0.7v23.7l-2.2,1.3L1694.9,703.2z M1747.6,707.9
                v5.5l-14.1,8.1v-21.7L1747.6,707.9z M1752.3,745.1l-26.4-15.2v-1.7l26.4,15.2V745.1z M1753,743L1753,743L1753,743L1753,743z
                  M1753,742.3l-26.4-15.2l3.7-2.2l2.8-1.6l15.1-8.7l11.6,6.7v17L1753,742.3z M1753.7,743.4l6.2-3.6v1.7l-6.2,3.6V743.4z
                  M1780,726.6l15.8,9.1v6.1l-15.8-9.1V726.6z M1778.7,725.9v6.9l-12.9,7.4v-21.7L1778.7,725.9z M1788.2,765.9l-29.9-17.3v-1.7
                l29.9,17.3V765.9z M1788.9,763.8L1788.9,763.8L1788.9,763.8L1788.9,763.8z M1788.9,763l-30-17.3l3.7-2.2l2.8-1.6l13.9-8l16.4,9.5
                V759L1788.9,763z M1795.8,762.3l-6.3,3.6v-1.7l6.3-3.6V762.3z M1801.2,764.7l-4.1-2.4v-2.9V743v-7.7h-0.7l0.3-0.6l-17.1-9.9l0,0
                l-14.2-8.2l-0.3,0.6h-0.7v23.7l-2.2,1.3l-1.1-0.7v-2.9v-17.8v-6.2v-2.1l40,23.1V764.7z M1831.1,748.2l-28.5,16.4v-29.1l28.5-16.4
                V748.2z M1831.7,748.6L1831.7,748.6L1831.7,748.6L1831.7,748.6z M1831.7,718L1831.7,718l-0.3-0.6l-29.5,17l-40-23.1l137.4-79.3
                l32,18.5l0.3-0.6l0.3,0.6l25.2-14.6l56.4,32.6l-47.8,27.6l0,0l-20,11.6l-17.7-10.2l-0.3,0.6l-0.3-0.6l-25.6,14.8l0,0l-39.4,22.8
                l-30.4-17.6L1831.7,718z M1863.2,759.7v-22.8l19.8,11.4L1863.2,759.7z M1832.4,748.2v-29.1l29.4,17v24.4l-4.1,2.3L1832.4,748.2z
                  M1858.1,764.2l-0.3-0.6l0,0L1858.1,764.2l4.7-2.7l-0.3-0.6h0l0.3,0.6l21.8-12.6l-0.3-0.6l0.3-0.6l-20.8-12l38.4-22.2l64.5,37.2
                l0.3-0.6l0.3,0.6l25.2-14.6l57.2,33l0.3-0.6l0,0l-0.3,0.6l11.6,6.7l-55.3,31.9l0.3,0.6l-0.3,0.6l24.5,14.1l-40,23.1L1853.2,767
                L1858.1,764.2z M1990.6,847.1L1990.6,847.1L1990.6,847.1L1990.6,847.1z M1851,956.8l-137.4-79.3v-29.1l137.4,79.3V956.8z
                  M1851.6,926.6l-137.4-79.3l71.3-41.2l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6l-0.3,0.6l25.6,14.8
                l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26L1851.6,926.6z M1832.3,832.7l10.1-5.8l20,11.6l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14l-31.4,18.2
                l0.3,0.6l0,0l-0.3-0.6l-32.1,18.5l-24.2-14l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2L1832.3,832.7L1832.3,832.7z M1863.2,958.5
                l-6.2-3.6v-1.7l6.2,3.6V958.5z M1863.9,956.3L1863.9,956.3L1863.9,956.3L1863.9,956.3z M1892.6,942.2l-28.1,16.2v-1.7l28.1-16.2
                V942.2z M1892,939.4l-28.1,16.2l-6.9-4v-17.1l13.3-7.7l15.1,8.7l0.3-0.6h0l-0.3,0.6l2.8,1.6l0.3-0.6l0,0l-0.3,0.6L1892,939.4z
                  M1857,933v-4.7l12.7-7.3v4.7L1857,933z M1870.3,926.1L1870.3,926.1L1870.3,926.1L1870.3,926.1z M1871,925.7v-5.5l14.1-8.1v21.7
                L1871,925.7z M1923.6,915.6l-35,20.2l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.4,8.9l0.3,0.6h0l-0.3-0.6l-14,8.1l0.3,0.6h-0.7v6.2V952
                v2.9l-3.3,1.9v-29.1l71.3-41.2V915.6z M1924.3,884.6l-45-26l31.5-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5
                l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8l-45.1-26l65-37.5l137.4,79.3L1924.3,884.6z M1962.2,866.4v6.2v17.8v2.9l-37.3,21.5v-29.1
                l65-37.5v1.7l-15.1,8.7l0.3,0.6h0l-0.3-0.6l-12.3,7.1l0.3,0.6H1962.2z M1989.9,851.5v21.7l-14.1-8.1v-5.5L1989.9,851.5z
                  M1975.2,865.5L1975.2,865.5L1975.2,865.5L1975.2,865.5z M1974.5,860.4v4.7l-11,6.3v-4.7L1974.5,860.4z M1962.9,893.7
                L1962.9,893.7L1962.9,893.7L1962.9,893.7z M1969.8,896.9l-6.2-3.6v-1.7l6.2,3.6V896.9z M1970.5,894l-6.9-4v-17l11.6-6.7l15.1,8.7
                l0.3-0.6h0l-0.3,0.6l6.6,3.8L1970.5,894z M1997.5,881.7l-26.4,15.2v-1.7l26.4-15.2V881.7z M2033.4,860.9l-34.6,20v-1.7l34.6-20
                V860.9z M2032.8,858.1l-34.6,20l-6.2-3.6l3.6-2.1l-0.3-0.6h0.7v-17.4l15.2-8.8l15.1,8.7l0,0l2.8,1.6v0L2032.8,858.1z
                  M1995.9,852.8v-4.7l14.5-8.4v4.7L1995.9,852.8z M2011.8,844.4v-5.5l14.1-8.1v21.7L2011.8,844.4z M2031.3,824.4v29l-1.9,1.1
                l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.4,8.9l0,0l-15.8,9.2l0.3,0.6h-0.7v6.2v17.4l-3.3,1.9v-22.9v-2.9L2031.3,824.4L2031.3,824.4z
                  M2062.2,806.2l-29.5,17v0h-0.7l0.3-0.6l-24.5-14.1l54.3-31.4v11.6v14.5V806.2z M2062.8,806.6L2062.8,806.6L2062.8,806.6
                L2062.8,806.6z M2070.1,810l-6.6-3.8v-1.7l6.6,3.8V810z M2070.8,807.1l-7.3-4.2v-13.8l15.8-9.1l11.2,6.5l0,0l2.8,1.6l0,0l5.2,3
                L2070.8,807.1z M2093.7,787.6L2093.7,787.6l-0.1,0.1L2093.7,787.6z M2099.2,794l-27.7,16v-1.7l27.7-16V794z M2130.2,765.7
                l-36.5,21.1l-2.2-1.3V759l38.7-22.3V765.7z"/>
            </g>
          </g>
          <g id="D4" className={'isometry__floor ' + (floor === "4" ? 'active' : '')}>
            <ModIsometrySvgFlat flat="71553.11.0403" floor="4" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g26_2_" transform="scale(1.12771)">
                <path id="path28_19_" className="st0" d="M1829.2,685.2v2.9l-113.4,65.5v-27.1l113.4-65.5v1.8v9.5V685.2z"/>
              </g>
              <g id="g34_2_" transform="scale(1.12771)">
                <path id="path36_20_" className="st0" d="M1683.9,643.9l35.3-20.4l25.2,14.5L1767,625l61.3,35.4l0.9,0.5l-113.4,65.5l-100.4-58
                  l32-18.5l23.5-13.6L1683.9,643.9z"/>
              </g>
              <g id="g50_2_" transform="scale(1.06886)">
                <path id="path52_20_" className="st0" d="M1810.3,795.1l-105.9-61.2v-28.6l105.9,61.2V795.1z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0402" floor="4" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g22_2_" transform="scale(1.13137)">
                <path id="path24_20_" className="st0" d="M1823.3,685.9V683l7,4.1v2.9L1823.3,685.9z"/>
              </g>
              <g id="g46_2_" transform="scale(1.15902)">
                <path id="path48_21_" className="st0" d="M1709.5,582.2l-13.1-7.5l42.1-24.3l100.1,57.8l-59.6,34.4l-59.6-34.4l-27.4-15.8
                  L1709.5,582.2z"/>
              </g>
              <g id="g54_2_" transform="scale(1.15902)">
                <path id="path56_19_" className="st0" d="M1838.5,634.6l-32.1,18.5l-2.5-1.4v-20.7l-10,5.8l-14.2,8.2v-1.8l-0.9-0.5l59.6-34.4V634.6
                  z"/>
              </g>
              <g id="g58_2_" transform="scale(1.14474)">
                <path id="path60_19_" className="st0" d="M1834.3,667.2l-25.4,14.7V679l25.4-14.7V667.2z"/>
              </g>
              <g id="g62_2_" transform="scale(1.14474)">
                <path id="path64_19_" className="st0" d="M1834.3,664.4l-25.4,14.7l-6.9-4v-12.7l14.4-8.3l10.1,5.8l2.5,1.4L1834.3,664.4z"/>
              </g>
              <g id="g66_2_" transform="scale(1.13529)">
                <path id="path68_19_" className="st0" d="M1831.5,659.4l-14.5,8.4v-9.4l14.5-8.4V659.4z"/>
              </g>
              <g id="g70_2_" transform="scale(1.1406)">
                <path id="path72_19_" className="st0" d="M1823,656.4V647l10.1-5.8v21.1L1823,656.4z"/>
              </g>
            </ModIsometrySvgFlat>
            <ModIsometrySvgFlat flat="71553.11.0401" floor="4" building="D" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
              <g id="g30_2_" transform="scale(1.10567)">
                <path id="path32_19_" className="st0" d="M1770.1,546.8l52.2,30.2l-44.1,25.5l-18.4,10.6l-16.3-9.4l-23.1,13.3l33,19l-36,20.8
                  l-13.3-7.7l-55.7-32.1L1770.1,546.8z"/>
              </g>
              <g id="g38_2_" transform="scale(1.04556)">
                <path id="path40_20_" className="st0" d="M1776.8,701.1l-33.5-19.3v-29.3l58.9,34L1776.8,701.1z"/>
              </g>
            </ModIsometrySvgFlat>
            <g id="D4-Areas" className="isometry__areas">
              <g id="g42_2_" transform="scale(1.09543)">
                <path id="path44_20_" className="st0" d="M1759.9,609.3l16.4,9.5l18.6-10.7l13.8,8l-18.6,10.7l28.9,16.7l-23.3,13.5l-25.9-15
                  l-33.3-19.2L1759.9,609.3z"/>
              </g>
            </g>
            <g id="D4-Strokes" className="isometry__strokes">
              <path d="M2131.5,735.5v-30.6h-0.7l0.3-0.6l-116-66.9l0,0l-57.7-33.3l-0.3,0.6l-0.3-0.6l-134.4,77.6l0.3,0.6h-0.7v30.6h0.7
                l-0.3,0.6l34,19.6l-35.1,20.2l0.3,0.6h-0.6v30.6h0.7l-0.3,0.6l113.2,65.4l0.3-0.6h0l0.3,0.6l127.6-73.6l7.6,4.4l0.3-0.6l0.3,0.6
                l29.1-16.8l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-5.2-3l36.2-20.9l-0.3-0.6H2131.5z M2080,738.3l10.2-5.9v21.7l-10.2-5.9V738.3z
                  M2078.6,748.3l-15.1,8.7v-9.2l15.1-8.7V748.3z M1934.9,819.2L1934.9,819.2L1934.9,819.2L1934.9,819.2z M1935.6,819.6l126.6-73.1
                v0.9v10.7v14.5v2.9l-126.6,73.1V819.6z M2063.5,773.8l6.6,3.8v1.7l-6.6-3.8V773.8z M2061.8,744l-68.8-39.7l-0.3,0.6l0,0l0.3-0.6
                l-30.7-17.7l19.3-11.2l-0.3-0.6l0.3-0.6l-14.1-8.2l47.4-27.4l114.6,66.2L2061.8,744z M1938.7,703.2L1938.7,703.2l0.3-0.6
                l-35.4-20.5l24.2-14l17.7,10.2l0.3-0.6l0.3,0.6l20-11.6l13.8,8l-19.4,11.2l0.3,0.6l-0.3,0.6l30.7,17.7l-24.2,14l-28.1-16.2
                L1938.7,703.2z M1965.8,665.5l0.3,0.6l0,0L1965.8,665.5l-20,11.6l-17.6-10.2l-0.3,0.6l-0.3-0.6l-25.6,14.8l0.3,0.6l-0.3,0.6
                l35.4,20.5l-38.4,22.2l-14.3-8.3l0,0l-60.5-34.9l133.1-76.8l56.4,32.6L1965.8,665.5z M1823.4,683.3l59.5,34.4l-25.2,14.5
                l-34.3-19.8V683.3z M1857.8,733L1857.8,733l0.3,0.6l26.2-15.1l14.3,8.3l0.3-0.6l0.3,0.6l39.4-22.8l28.1,16.2l0.3-0.6l0.3,0.6
                l25.2-14.6l68.8,39.7l0.3-0.6l0,0l-0.3,0.6l0,0l-126.6,73.1L1823,753.8l35.1-20.2L1857.8,733z M1822.4,755l111.9,64.6v29.1
                l-111.9-64.6V755z M2062.8,776L2062.8,776L2062.8,776L2062.8,776z M2099.2,763.4l-27.7,16v-1.7l27.7-16V763.4z M2070.8,776.5
                l-7.3-4.2v-13.8l15.8-9.1l11.2,6.5l0,0l2.8,1.6l0,0l5.2,3L2070.8,776.5z M2093.7,756.2l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-11.5,6.7
                l0,0l-15.5,8.9v-0.9h-0.7l0.3-0.6l0,0l67.1-38.7v29.1L2093.7,756.2z"/>
            </g>
          </g>
        </g>
        <g id="E" className={'isometry__building ' + (building === "E" ? 'active' : '')}>
      <g id="E0" className={'isometry__floor ' + (floor === "0" ? 'active' : '')}>
       <ModIsometrySvgFlat flat="71553.14.0003" floor="0" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path62_19_" className="st0" d="M1069.7,1311.2l-123.3-71.2l0,0l41.8-24.1l15.2,8.8l31.5-18.2l16.2,9.4l3.3,1.9l-1.1,0.7
           l0,0l49.6,28.6l0,0l0,0l64.8,37.4l-39.8,23l-25.8-14.9l-27.7,16l15.4,8.9l-4.6,2.7L1069.7,1311.2z"/>
         <path id="path76_21_" className="st0" d="M1125.1,1336.4l-27.7,16v-3.3l27.7-16V1336.4z"/>
         <path id="path78_19_" className="st0" d="M1127.9,1338.1l-2.8-1.6v-3.3l-23-13.3v-27.3l25.8,14.9V1338.1z"/>
         <path id="path88_21_" className="st0" d="M1097.3,1349.2v3.3l-7.6-4.4v-3.3L1097.3,1349.2z"/>
         <path id="path90_20_" className="st0" d="M1102.1,1319.9l23,13.3l-27.7,16l-7.6-4.4V1327L1102.1,1319.9z"/>
         <path id="path92_20_" className="st0" d="M1102.1,1319.9l-12.3,7.1v-9.5l-15.4-8.9l27.7-16V1319.9z"/>
         <path id="path94_20_" className="st0" d="M1085.1,1320.1l4.6-2.7v30.6l-1.8,1l-2.8-1.6V1320.1z"/>
         <path id="path56_21_" className="st0" d="M1085.1,1347.5l-15.4-8.9v-27.3l15.4,8.9V1347.5z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0002" floor="0" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path80_20_" className="st0" d="M1127.9,1307.5l39.8-23l0,0l33.1-19.1l36.5,21l-15.6,9l72.4,41.8l-57.3,33.1l-5.4-3.1
           l15.4-8.9l-31.3-18.1l-15.4,8.9l-4.6-2.7l15.4-8.9l-27.7-16l-15.4,8.9L1127.9,1307.5z"/>
         <path id="path82_18_" className="st0" d="M1127.9,1338.1v-30.6l39.8,23v27.3l-2.8,1.6L1127.9,1338.1z"/>
         <path id="path98_19_" className="st0" d="M1183.1,1321.5l27.7,16l-15.4,8.9v9.5l-12.3-7.1V1321.5z"/>
         <path id="path100_20_" className="st0" d="M1183.1,1348.8l12.3,7.1v17.8l-7.6,4.4l-27.7-16l4.7-2.7l2.8-1.6L1183.1,1348.8z"/>
         <path id="path102_18_" className="st0" d="M1160.1,1362.1l27.7,16v3.3l-27.7-16V1362.1z"/>
         <path id="path106_19_" className="st0" d="M1167.7,1357.7v-27.3l15.4-8.9v27.3L1167.7,1357.7z"/>
         <path id="path108_20_" className="st0" d="M1294.1,1367.8l-64.9,37.5v-3.3l7.6-4.4v-27.3l57.3-33.1V1367.8z"/>
         <path id="path110_18_" className="st0" d="M1236.8,1397.6l-7.6,4.4l-36.7-21.2l4.8-2.7l2.8-1.6l15.4-8.9l15.9,9.2v17.8L1236.8,1397.6
           z"/>
         <path id="path112_19_" className="st0" d="M1229.2,1402v3.3l-36.7-21.2v-3.3L1229.2,1402z"/>
         <path id="path114_18_" className="st0" d="M1231.3,1367.1l5.4,3.1v27.3l-5.4-3.1v-17.8V1367.1z"/>
         <path id="path116_18_" className="st0" d="M1246.7,1358.3l-15.4,8.9v9.5l-15.9-9.2v-27.3L1246.7,1358.3z"/>
         <path id="path118_18_" className="st0" d="M1215.5,1340.2v27.3l-15.4,8.9v-27.3L1215.5,1340.2z"/>
         <path id="path120_19_" className="st0" d="M1200.1,1349.1v27.3l-2.8,1.6l-1.8-1v-3.3v-17.8v-9.5L1200.1,1349.1z"/>
         <path id="path122_18_" className="st0" d="M1187.8,1378.1l7.6-4.4v3.3l-7.6,4.4V1378.1z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.13.0001" floor="0" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path28_20_" className="st0" d="M1018.8,1358.4l-46.4-26.8l32.5-18.7l-72.8-42l-7.5,4.3l0,0l-0.4,0.2l-19.5-11.3l41.8-24.1
           l0,0l123.3,71.2l-27.7,16l15.4,8.9L1018.8,1358.4z"/>
         <path id="path46_20_" className="st0" d="M1018.8,1389v-30.6l38.6-22.3v30.6L1018.8,1389z"/>
         <path id="path50_20_" className="st0" d="M1092.7,1355.1l-27.7,16v-3.3l27.7-16V1355.1z"/>
         <path id="path52_21_" className="st0" d="M1065,1367.8v3.3l-7.6-4.4v-3.3L1065,1367.8z"/>
         <path id="path54_19_" className="st0" d="M1092.7,1351.8l-27.7,16l-7.6-4.4v-17.8l12.3-7.1l15.4,8.9l2.8,1.6L1092.7,1351.8z"/>
         <path id="path58_18_" className="st0" d="M1069.7,1311.2v27.3l-12.3,7.1v-9.5l-15.4-8.9L1069.7,1311.2z"/>
         <path id="path60_20_" className="st0" d="M904.6,1283v-18.7l19.5,11.3l-16.2,9.4L904.6,1283z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.13.0002" floor="0" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path26_18_" className="st0" d="M940,1350.4l32.4-18.7l46.4,26.8l-39.6,22.9l-15.4-8.9l-28.4,16.4l15.4,8.9l-4.6,2.7
           l-138.7-80.1l80.9-46.7l16.2,9.4l3.3,1.9l-0.7,0.4l0,0l-7.6,4.4l26.8,15.5l-4.7,2.7l20.4,11.8l-27.6,15.9L940,1350.4z"/>
         <path id="path32_20_" className="st0" d="M979.2,1381.3l39.6-22.9v30.6l-36.8,21.2l-2.8-1.6V1381.3z"/>
         <path id="path34_19_" className="st0" d="M986.8,1413v3.3l-28.4,16.4v-3.3L986.8,1413z"/>
         <path id="path36_21_" className="st0" d="M986.8,1413l-28.4,16.4l-7.6-4.4v-17.8l13-7.5l15.4,8.9l2.8,1.6L986.8,1413z"/>
         <path id="path38_19_" className="st0" d="M963.8,1399.7l-13,7.5v-9.5l-15.4-8.9l28.4-16.4V1399.7z"/>
         <path id="path40_21_" className="st0" d="M963.8,1399.7v-27.3l15.4,8.9v27.3L963.8,1399.7z"/>
         <path id="path42_19_" className="st0" d="M807.5,1320.3l138.7,80.1v30.6l-138.7-80.1V1320.3z"/>
         <path id="path44_21_" className="st0" d="M946.2,1431v-30.6l4.6-2.7v30.6L946.2,1431z"/>
         <path id="path48_22_" className="st0" d="M950.8,1425l7.6,4.4v3.3l-7.6-4.4V1425z"/>
       </ModIsometrySvgFlat>
       <g id="E0-Areas" className="isometry__areas">
         <path id="path24_11_" className="st0" d="M907.9,1284.9L907.9,1284.9l16.2-9.4l0,0l0.4-0.2l7.5-4.3l72.8,42l-32.5,18.7l-32.4,18.7
           l-25.6-14.8l27.6-15.9l-20.4-11.8l4.7-2.7l-26.8-15.5l7.6-4.4L907.9,1284.9z"/>
         <path id="path64_6_" className="st0" d="M1102.8,1247l-49.6-28.6l1.1-0.7l0,0l16.2-9.4l8.7-5l25.9,14.9l-12.8,7.4l23.7,13.7l12.3-7.1
           l31.7,18.3l69.1-39.9l0,0l33.1,19.1l-47.8,27.6l108.9,62.9l-13.7,7.9l-72.4-41.8l-36.5-21l-33.1,19.1L1102.8,1247z"/>
         <path id="path66_8_" className="st0" d="M1323.4,1350.9l-13.7,7.9v-30.6l13.7-7.9V1350.9z"/>
         <path id="path68_8_" className="st0" d="M1323.4,1320.3l-108.9-62.9l47.8-27.6l0,0l108.9,62.9L1323.4,1320.3z"/>
         <path id="path70_8_" className="st0" d="M1160.1,1250.6l-31.7-18.3l-12.3,7.1l-23.7-13.7l12.8-7.4l-25.9-14.9l-8.7,5l-19.5-11.3
           l77.3-44.6l100.8,58.2L1160.1,1250.6z"/>
         <path id="path72_5_" className="st0" d="M1309.7,1328.2v30.6l-15.6,9v-30.6L1309.7,1328.2z"/>
         <path id="path74_8_" className="st0" d="M1323.4,1350.9v-30.6l47.8-27.6v30.6L1323.4,1350.9z"/>
         <path id="path104_5_" className="st0" d="M1221.6,1295.4l15.6-9l72.4,41.8l-15.6,9L1221.6,1295.4z"/>
         <path id="path124_5_" className="st0" d="M1051.1,1197.1l19.5,11.3l-16.2,9.4l-3.3-1.9L1051.1,1197.1z"/>
       </g>
       <g id="E0-Strokes" className="isometry__strokes">
         <path d="M1715.8,1225.5l0.3-0.6l-138.7-80.1l-0.3,0.6l-0.3-0.6l-79.4,45.8l0.3,0.6l-0.3,0.6l18.6,10.7l-16.4,9.5l-19.2-11.1
           l-0.3,0.6l-0.3-0.6l-83.2,48l0.3,0.6l-0.3,0.6l14.6,8.4l-48.8,28.2l-99.5-57.5l-33.1-19.1l-100.8-58.2l-0.3,0.6l-0.3-0.6
           l-77.3,44.6l0.3,0.6h-0.7v17.6l-15.2-8.8l-0.3,0.6l-0.3-0.6l-31.2,18l-14.8-8.6l-0.3,0.6l-0.3-0.6l-41.8,24.1l0.3,0.5l0,0.1h0
           l-0.3-0.6l-41.8,24.1l0.3,0.6h-0.7v17.6l-15.2-8.8l-0.3,0.6l-0.3-0.6l-80.9,46.7l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1
           l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l28.4-16.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l35.8-20.6l38.3-22.1
           l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4
           l0,0l35.9,20.7l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.3-4.2l0.5,0.3l-3.8,2.2l0.3,0.6h-0.7v3.3h0.7
           l-0.3,0.6l36.7,21.2l0.3-0.6l0.3,0.6l53.6-31l98.6,56.9l0.3-0.6l0.3,0.6l173.7-100.3l-0.3-0.6l0.3-0.6l-10.3-6l170.3-98.3
           L1715.8,1225.5z M1544.5,1324.4l-0.3,0.6l10.3,6l-172.4,99.5l-97.6-56.3l9.9-5.7l15.6-9l13.7-7.9l47.8-27.6l-0.3-0.6h0.7v-30.6
           h-0.7l0.3-0.6l-8-4.6l49.2-28.4l-0.3-0.6l0.3-0.6l-14.6-8.4l81.9-47.3l19.2,11.1l0.3-0.6l0.3,0.6l17.8-10.2l-0.3-0.6l0.3-0.6
           l-18.6-10.7l78-45.1l137.4,79.3l-170.3,98.3L1544.5,1324.4z M986.1,1415.9l-27.1,15.6v-1.7l27.1-15.6V1415.9z M888.4,1274.3
           l15.9,9.2l2.3,1.3l-7.3,4.2l0.3,0.6l-0.3,0.6l25.8,14.9l-3.7,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6l-0.3,0.6
           l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-38.3,22.1l-15.1-8.7l-0.3,0.6l-0.3-0.6l-28.4,16.4l0.3,0.6l-0.3,0.6l14.4,8.3
           l-3.3,1.9l-137.4-79.3L888.4,1274.3z M1003.3,1224.7l0.3,0.6l31.2-18l15.9,9.2l0.2-0.3l-0.2,0.3l2.3,1.3l-0.1,0.1l0.3,0.6
           l-0.3,0.6l49.6,28.6l0,0l0,0l0,0l0,0l0,0l63.8,36.8l-38.4,22.2l-25.5-14.7l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l14.4,8.3
           l-3.3,1.9l-15.1-8.7L947.7,1240l40.4-23.3l14.8,8.6L1003.3,1224.7z M950.8,1397.7l0.3-0.6l-14.4-8.3l26.4-15.2v25.8l-11.6,6.7
           v-8.4H950.8z M964.5,1373.5l14.1,8.1v25.8l-14.1-8.1V1373.5z M951.5,1426.2l6.2,3.6v1.7l-6.2-3.6V1426.2z M946.4,1240.8
           l121.9,70.4l-26.7,15.4l0.3,0.6l-0.3,0.6l14.4,8.3l-37.3,21.5l-45.1-26l31.5-18.2l-0.3-0.6l0.3-0.6l-72.8-42l-0.3,0.6l-0.3-0.6
           l-7.5,4.3l0,0l0,0l0,0l-0.1,0.1l-18.2-10.5L946.4,1240.8z M1057.4,1336.1l0.3-0.6l-14.4-8.3l25.7-14.8v25.8l-10.9,6.3v-8.4
           H1057.4z M1070.4,1312.4l14.1,8.1v25.8l-14.1-8.1V1312.4z M940,1349.6l-24.2-14l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2l3.7-2.2
           l-0.3-0.6l0.3-0.6l-25.8-14.9l6.6-3.8l0,0l0.7-0.4l0,0l16.2-9.4l-0.2-0.3l0,0l0.2,0.3l0,0l0,0l0.4-0.2l7.2-4.2l71.4,41.2
           l-31.5,18.2L940,1349.6z M924.2,1275.5L924.2,1275.5l0.1,0.2l0,0L924.2,1275.5z M924.2,1275.5L924.2,1275.5L924.2,1275.5
           L924.2,1275.5z M924.2,1275.5L924.2,1275.5L924.2,1275.5L924.2,1275.5L924.2,1275.5z M1019.5,1358.8l37.3-21.5v8.4v17.8v2.9
           l-37.3,21.5V1358.8z M1092.1,1225.2l0.3,0.6l-0.3,0.6l23.7,13.7l0.3-0.6l0.3,0.6l12-6.9l31.4,18.1l0.3-0.6l0.3,0.6l68.8-39.7
           l31.8,18.4l-46.8,27l0.3,0.6l-0.3,0.6l107.9,62.3l-12.4,7.2l-72.1-41.6l-36.5-21l-0.3,0.6l-0.3-0.6l-32.8,18.9l-64.5-37.2
           l-48.6-28l0.1-0.1l-0.3-0.6l0.3,0.6l16.2-9.4l0,0l8.3-4.8l24.6,14.2L1092.1,1225.2z M1369.9,1292.7l-46.5,26.8l-107.5-62.1
           l46.5-26.8L1369.9,1292.7z M1200.7,1349.5l14.1-8.1v25.8l-14.1,8.1V1349.5z M1216.1,1341.3l29.3,16.9l-14.4,8.3l0.3,0.6h-0.7v8.4
           l-14.5-8.4V1341.3z M1232,1376.7v-8.4l4.1,2.4v25.8l-4.1-2.4V1376.7z M1236.8,1369.5l-4.1-2.4l14.4-8.3l-0.3-0.6l0.3-0.6
           l-31.3-18.1l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l14.4-8.3l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6l-0.3-0.6l-15.1,8.7l-38.4-22.2
           l38.8-22.4l-0.3-0.6l0,0l0,0l0.3,0.5l32.8-18.9l35.1,20.3l-14.6,8.4l0.3,0.6l-0.3,0.6l71.4,41.2L1236.8,1369.5z M1194.7,1346.4
           v8.4l-11-6.3v-25.8l25.7,14.9l-14.4,8.3l0.3,0.6H1194.7z M1168.3,1330.8l14.1-8.1v25.8l-14.1,8.1V1330.8z M1167.7,1284.5
           L1167.7,1284.5L1167.7,1284.5L1167.7,1284.5z M1308.3,1328.2l-14.3,8.2l-71.1-41l14.3-8.2L1308.3,1328.2z M1294.7,1337.6
           l14.3-8.2v29.1l-14.3,8.2V1337.6z M1310.3,1328.6l12.4-7.2v29.1l-12.4,7.2V1328.6z M1089.8,1317.5l0.3-0.6l-14.4-8.3l25.7-14.9
           v25.8l-11,6.3v-8.4H1089.8z M1123.7,1333.1l-26.4,15.2l-6.9-4v-17l11.6-6.7L1123.7,1333.1z M1090.4,1345.9l6.2,3.6v1.7l-6.2-3.6
           V1345.9z M1098,1349.5l26.4-15.2v1.7l-26.4,15.2V1349.5z M1125.1,1333.1l0.3-0.6l-22.7-13.1v-25.8l24.5,14.1v29.1l-1.5-0.9v-2.9
           H1125.1z M1188.5,1378.5l6.3-3.6v1.7l-6.3,3.6V1378.5z M1293.4,1338.4v29.1l-10.2,5.9l-25.2-14.5L1293.4,1338.4z M1370.5,1322.9
           l-46.5,26.8v-29.1l46.5-26.8V1322.9z M1227.9,1210.7l-67.8,39.1l-31.4-18.1l-0.3,0.6l-0.3-0.6l-12,6.9l-22.3-12.9l11.8-6.8
           l-0.3-0.6l0.3-0.6l-25.9-14.9l-0.3,0.6l-0.3-0.6l-8.3,4.8l-18.2-10.5l76-43.9L1227.9,1210.7z M1069.3,1208.4l-14.9,8.6l-2.7-1.5
           v-17.2L1069.3,1208.4z M922.8,1275.5l-14.9,8.6l-2.6-1.5v-17.2L922.8,1275.5z M945.5,1400.7v29.1l-137.4-79.3v-29.1L945.5,1400.7
           z M946.9,1400.7l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V1400.7z M958.4,1428.6l-6.9-4v-17l12.3-7.1l15.1,8.7l2.8,1.6l0.3-0.6l0,0
           l-0.3,0.6l3.7,2.2L958.4,1428.6z M982.1,1409.5l-2.2-1.3v-26.6l38.3-22.1v29.1L982.1,1409.5z M1058.1,1366.4v-1.7l6.2,3.6v1.7
           L1058.1,1366.4z M1065.7,1368.2l26.4-15.2v1.7l-26.4,15.2V1368.2z M1065,1367.1l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6l3.7,2.2
           L1065,1367.1z M1088,1348.3l-2.2-1.3v-26.6l3.3-1.9v8.4v17.8v2.9L1088,1348.3z M1128.6,1308.6l38.4,22.2v26.6l-2.2,1.3
           l-36.3-20.9V1308.6z M1165.1,1359.9l2.8-1.6l15.1-8.7l11.7,6.7v17l-6.9,4l-26.4-15.2L1165.1,1359.9z M1187.1,1378.5v1.7
           l-26.4-15.2v-1.7L1187.1,1378.5z M1196.1,1373.7v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V1373.7z M1197.5,1378.6l2.8-1.6
           l15.1-8.7l15.2,8.8v17.4h0.7l-0.3,0.6l4.4,2.6l-6.3,3.6l-35.4-20.4L1197.5,1378.6z M1228.5,1402.4v1.7l-35.4-20.4v-1.7
           L1228.5,1402.4z M1229.9,1402.4l7.3-4.2l-0.3-0.6h0.7v-26.9l19.2-11.1l25.2,14.5l-52,30V1402.4z"/>
       </g>
     </g>
     <g id="E1" className={'isometry__floor ' + (floor === "1" ? 'active' : '')}>
       <ModIsometrySvgFlat flat="71553.13.0102" floor="1" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path34_20_" className="st0" d="M940,1319.7l46.4,26.8l-7.2,4.2l-15.4-8.9l-28.4,16.4l15.4,8.9l-4.6,2.7l-138.7-80.1
           l72.6-41.9l0,0l46.4,26.8l-4.8,2.8l20.4,11.8l-27.6,15.9L940,1319.7z"/>
         <path id="path36_22_" className="st0" d="M979.2,1350.7l7.2-4.2v30.6l-4.4,2.5l-2.8-1.6V1350.7z"/>
         <path id="path40_22_" className="st0" d="M979.2,1350.7v27.3l-15.4-8.9v-27.3L979.2,1350.7z"/>
         <path id="path42_20_" className="st0" d="M963.8,1341.8v27.3l-13,7.5v-9.5l-15.4-8.9L963.8,1341.8z"/>
         <path id="path44_22_" className="st0" d="M950.8,1376.6l13-7.5l15.4,8.9l2.8,1.6l4.7,2.7l-28.4,16.4l-7.6-4.4V1376.6z"/>
         <path id="path46_21_" className="st0" d="M958.4,1398.8l28.4-16.4v3.3l-28.4,16.4V1398.8z"/>
         <path id="path48_23_" className="st0" d="M807.5,1289.7l138.7,80.1v30.6l-138.7-80.1V1289.7z"/>
         <path id="path50_21_" className="st0" d="M946.2,1400.4v-30.6l4.6-2.7v30.6L946.2,1400.4z"/>
         <path id="path54_20_" className="st0" d="M950.8,1394.4l7.6,4.4v3.3l-7.6-4.4V1394.4z"/>
       </ModIsometrySvgFlat>
       <g id="E1-Areas" className="isometry__areas">
         <path id="path26_19_" className="st0" d="M1167.7,1253.9l-64.8-37.4l25.6-14.8l31.7,18.3l20.4-11.8l15.1,8.7l-20.4,11.8v0l18,10.4
           L1167.7,1253.9z"/>
         <path id="path32_21_" className="st0" d="M1428.9,1293.8l17.9-10.4l0.1,0l-0.1,0l0.1,0l-65.3-37.7l15.1-8.7l15.6-9l-15.6-9l83.2-48
           l17.7,10.2l1.8,1.1l-7.9,4.6l72.7,42l64.8-37.4l-25.5-14.8l-27.6,15.9l-20.4-11.8l67.8-39.2l92.3,53.3l-111.2,64.2l-15.4-8.9
           l-27.7,16l15.4,8.9l-4.6,2.7l-15.5-8.9l-27.7,16l23.7,13.7l3.1,1.8l-173.7,100.3l-125.4-72.4l-19.9,11.5l-5.4-3.1l15.4-8.9
           l-31.3-18.1l-15.4,8.9l-4.6-2.7l15.4-8.9l-27.7-16l-15.4,8.9l-64.3-37.1l-27.7,16l14.1,8.2l-4.6,2.7l-15.4-8.9l-27.7,16l15.4,8.9
           l-71,41l-46.4-26.8l64.9-37.4l-25.6-14.8l-22,12.7l-20.4-11.8l-10.4,6l-46.4-26.8l0,0l108.1-62.4l15.2,8.8l125.1-72.2l169.9,98.1
           l-45.4,26.2l41.5,24l62.1,35.9l25.6,14.8l31.7-18.3l20.3,11.8l15.1-8.7L1428.9,1293.8z M1180.5,1208.2l-20.4,11.8l-31.7-18.3
           l-25.6,14.8l0,0l0,0l64.8,37.4l0,0l25.6-14.8v0l-18-10.4l20.4-11.8L1180.5,1208.2z"/>
         <path id="path38_20_" className="st0" d="M921.7,1277.3l4.8-2.8l10.4-6l20.4,11.8l22-12.7l25.6,14.8l-64.9,37.4l-25.6-14.8l27.6-15.9
           L921.7,1277.3z"/>
         <path id="path52_22_" className="st0" d="M986.4,1377.1v-30.6l71-41v30.6L986.4,1377.1z"/>
         <path id="path56_22_" className="st0" d="M1564.3,1228.7l-72.7-42l7.9-4.6l17.8-10.2l5.5-3.2l26.8,15.5l6-3.5l20.4,11.8l27.6-15.9
           l25.5,14.8L1564.3,1228.7z"/>
         <path id="path58_19_" className="st0" d="M1555.7,1180.7l-6,3.5l-26.8-15.5l-5.5,3.2l-19.6-11.3l79.4-45.8l46.4,26.8L1555.7,1180.7z"
           />
         <path id="path60_21_" className="st0" d="M1434.1,1314.3l-20.3-11.8l-31.7,18.3l-25.6-14.8l26.7-15.4l-39.8-23l38.1-22l65.3,37.7
           l-17.9,10.4l20.3,11.8L1434.1,1314.3z"/>
         <path id="path62_20_" className="st0" d="M1715.8,1225.5l-108.4,62.6l-2.8-1.6v-27.3l111.2-64.2V1225.5z"/>
         <path id="path64_21_" className="st0" d="M1555.8,1330.9l-173.7,100.3v-30.6l173.7-100.3v23v3.3V1330.9z"/>
         <path id="path66_19_" className="st0" d="M1576.8,1305.7l-1.8,1l-2.8-1.6v-27.3l4.6-2.7v9.5v17.8V1305.7z"/>
         <path id="path68_21_" className="st0" d="M1256.7,1358.8l-19.9,11.5v-30.6l19.9-11.5V1358.8z"/>
         <path id="path70_19_" className="st0" d="M1164.8,1328.8l-39.8-23v-3.3l-21.7-12.5v-27.3l64.3,37.1v27.3L1164.8,1328.8z"/>
         <path id="path72_21_" className="st0" d="M1236.8,1370.3l-5.4-3.1v-30.6l5.4,3.1V1370.3z"/>
         <path id="path74_19_" className="st0" d="M1382.1,1431.2l-125.4-72.4v-30.6l125.4,72.4V1431.2z"/>
         <path id="path76_22_" className="st0" d="M1197.2,1347.4l-1.8-1v-30.6l4.6,2.7v27.3L1197.2,1347.4z"/>
         <path id="path78_20_" className="st0" d="M1069.7,1280.6l15.4,8.9v27.3l-15.4-8.9V1280.6z"/>
         <path id="path80_21_" className="st0" d="M1195.4,1343.1v3.3l-7.6,4.4v-3.3L1195.4,1343.1z"/>
         <path id="path82_19_" className="st0" d="M1210.8,1306.9l-15.4,8.9v9.5l-12.3-7.1v-27.3L1210.8,1306.9z"/>
         <path id="path84_21_" className="st0" d="M1215.5,1309.6l31.3,18.1l-15.4,8.9v9.5l-15.9-9.2L1215.5,1309.6z"/>
         <path id="path86_19_" className="st0" d="M1200.1,1318.5l15.4-8.9v27.3l-15.4,8.9V1318.5z"/>
         <path id="path88_22_" className="st0" d="M1085.1,1289.5l4.6-2.7v30.6l-1.8,1l-2.8-1.6V1289.5z"/>
         <path id="path90_21_" className="st0" d="M1396.8,1218.9l15.6,9l-15.6,9V1218.9z"/>
         <path id="path92_21_" className="st0" d="M1089.8,1286.9l-14.1-8.2l27.7-16v27.3l-13.6,7.9V1286.9z"/>
         <path id="path94_21_" className="st0" d="M1042,1296.6l27.7-16v27.3l-12.3,7.1v-9.5L1042,1296.6z"/>
         <path id="path96_21_" className="st0" d="M1167.7,1299.8l15.4-8.9v27.3l-15.4,8.9V1299.8z"/>
         <path id="path98_20_" className="st0" d="M1589.1,1250.2l15.4,8.9v27.3l-15.4-8.9V1250.2z"/>
         <path id="path100_21_" className="st0" d="M1561.4,1266.2l27.7-16v27.3l-12.3,7.1v-9.5L1561.4,1266.2z"/>
         <path id="path102_19_" className="st0" d="M1556.7,1268.9l15.5,8.9v27.3l-15.5-8.9V1268.9z"/>
         <path id="path104_21_" className="st0" d="M1529,1284.9l27.7-16v27.3l-4.1,2.4L1529,1284.9z"/>
         <path id="path106_20_" className="st0" d="M1183.1,1318.2l12.3,7.1v17.8l-7.6,4.4l-27.7-16l4.7-2.7l2.8-1.6L1183.1,1318.2z"/>
         <path id="path108_21_" className="st0" d="M1215.5,1336.9l15.9,9.2v17.8l-7.6,4.4l-31.3-18.1l4.7-2.7l2.8-1.6L1215.5,1336.9z"/>
         <path id="path110_19_" className="st0" d="M1231.3,1363.9v3.3l-7.6,4.4v-3.3L1231.3,1363.9z"/>
         <path id="path112_20_" className="st0" d="M1223.8,1371.5l-31.3-18.1v-3.3l31.3,18.1V1371.5z"/>
         <path id="path114_19_" className="st0" d="M1187.8,1347.5v3.3l-27.7-16v-3.3L1187.8,1347.5z"/>
         <path id="path116_19_" className="st0" d="M1057.4,1315l12.3-7.1l15.4,8.9l2.8,1.6l4.7,2.7l-27.7,16l-7.5-4.4V1315z"/>
         <path id="path118_19_" className="st0" d="M1089.8,1297.9l13.6-7.9l21.7,12.5l-27.7,16l-7.6-4.4L1089.8,1297.9z"/>
         <path id="path120_20_" className="st0" d="M1089.8,1317.5v-3.3l7.6,4.4v3.3L1089.8,1317.5z"/>
         <path id="path122_19_" className="st0" d="M1125.1,1302.5v3.3l-27.7,16v-3.3L1125.1,1302.5z"/>
         <path id="path124_20_" className="st0" d="M1057.4,1336.1v-3.3l7.5,4.4v3.3L1057.4,1336.1z"/>
         <path id="path126_18_" className="st0" d="M1092.7,1321.2v3.3l-27.7,16v-3.3L1092.7,1321.2z"/>
         <path id="path130_21_" className="st0" d="M1576.8,1284.6l12.3-7.1l15.4,8.9l2.8,1.6l4.7,2.7l-27.7,16l-7.6-4.4L1576.8,1284.6z"/>
         <path id="path132_20_" className="st0" d="M1576.8,1305.7v-3.3l7.6,4.4v3.3L1576.8,1305.7z"/>
         <path id="path134_18_" className="st0" d="M1612.1,1290.8v3.3l-27.7,16v-3.3L1612.1,1290.8z"/>
         <path id="path136_20_" className="st0" d="M1552.7,1298.5l4.1-2.4l15.5,8.9l2.8,1.6l4.7,2.7l-24,13.8v-23L1552.7,1298.5z"/>
         <path id="path138_18_" className="st0" d="M1579.7,1312.7l-24,13.8v-3.3l24-13.8V1312.7z"/>
         <path id="path140_20_" className="st0" d="M1356.5,1306.1l-62.1-35.9l26.7-15.4l18.7,10.8l3.7,2.1l39.8,23L1356.5,1306.1z"/>
         <path id="path142_18_" className="st0" d="M1252.9,1246.2l45.4-26.2l41.5,24l-18.7,10.8l-26.7,15.4L1252.9,1246.2z"/>
         <path id="path144_20_" className="st0" d="M1497.7,1160.6l19.6,11.3l-17.8,10.2l-1.8-1.1V1160.6z"/>
         <path id="path148_21_" className="st0" d="M1339.8,1244v21.6l-18.7-10.8L1339.8,1244z"/>
       </g>
       <g id="E1-Strokes" className="isometry__strokes">
         <g>
           <path d="M1716.5,1194.9h-0.7l0.3-0.6l-92.3-53.3l-46.4-26.8l-0.3,0.6l-0.3-0.6l-79.4,45.8l0.3,0.6h-0.7v19.3l-16.7-9.7l-0.3,0.6
             l-0.3-0.6l-83.2,48l0.3,0.6h-0.7v17.7l-14.8,8.5l0.3,0.6l0,0l-0.4-0.5l0,0l-37.8,21.8l-3-1.7V1244h-0.7l0.3-0.6l-41.5-24
             l-169.9-98.1l-0.3,0.6l-0.3-0.6l-124.7,72l-14.8-8.6l-0.3,0.6l-0.3-0.6l-108.1,62.4l0.3,0.6h0l-0.3-0.6l-72.6,41.9l0.3,0.6h-0.7
             v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l28.4-16.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6
             l-3.7-2.2l3.4-1.9l-0.3-0.6l0.3,0.6l70.7-40.8l7.2,4.2l0.3-0.6h0l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1
             l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l38.4,22.2l-3.7,2.1l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.2-4.2
             l0.5,0.3l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l31.3,18.1l0.3-0.6l0.3,0.6l7.2-4.2l5.1,3l0.3-0.6l0.3,0.6l19.5-11.3
             l125.1,72.2l0.3-0.6l0.3,0.6l173.7-100.3l-0.3-0.6h0.7v-4l23.6-13.6l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1l0.5-0.3l7.2,4.2
             l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.1l107.4-62l-0.3-0.6h0.7V1194.9z M1715.1,1225.1l-107.7,62.2
             l-2.2-1.3v-26.6l109.9-63.4V1225.1z M982.1,1378.9l-2.2-1.3v-26.6l5.9-3.4v29.1L982.1,1378.9z M986.1,1385.3l-27.1,15.6v-1.7
             l27.1-15.6V1385.3z M957.3,1280.3l0.3,0.6l21.7-12.5l24.2,14L940,1319l-24.2-14l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2l3.8-2.2
             l10.1-5.8l20,11.6L957.3,1280.3z M1497.7,1181.1L1497.7,1181.1l-0.1,0.2L1497.7,1181.1z M1577.1,1115.6l45.1,26l-66.8,38.6
             l0.3,0.6l-0.3-0.6l-5.7,3.3l-26.5-15.3l-0.3,0.6l-0.3-0.6l-5.2,3l-18.2-10.5L1577.1,1115.6z M1516,1171.9l-16.4,9.5l-1.1-0.7
             v-19L1516,1171.9z M1627.8,1191.3l-63.5,36.7l-71.4-41.2l6.9-4l-0.3-0.6l0,0l0.3,0.6l17.8-10.2l-0.3-0.6l0.3,0.6l5.2-3
             l26.5,15.3l0.3-0.6l0.3,0.6l5.7-3.3l20,11.6l0.3-0.6l0.3,0.6l27.3-15.7L1627.8,1191.3z M1603.9,1285.3l-14.1-8.1v-25.8l14.1,8.1
             V1285.3z M1588.5,1277.1l-11,6.3v-8.4h-0.7l0.3-0.6l-14.4-8.3l25.7-14.9V1277.1z M1571.5,1303.9l-14.1-8.2V1270l14.1,8.2V1303.9
             z M1556.1,1295.8l-3.4,2l-22.3-12.9l25.7-14.9V1295.8z M1555.1,1330.6l-172.4,99.5V1401l172.4-99.5v21.8v3.3V1330.6z
              M1237.4,1340.1l18.5-10.7v29.1l-18.5,10.7V1340.1z M1257.3,1329.4l124.1,71.6v29.1l-124.1-71.6V1329.4z M1214.8,1336.5
             l-14.1,8.1v-25.8l14.1-8.1V1336.5z M1224.4,1368.6l6.2-3.6v1.7l-6.2,3.6V1368.6z M1231,1336l0.3,0.6h-0.7v8.4l-14.5-8.4v-25.8
             l29.3,16.9L1231,1336z M1195.1,1315.2l0.3,0.6h-0.7v8.4l-11-6.3V1292l25.7,14.9L1195.1,1315.2z M1182.4,1317.8l-14.1,8.1v-25.8
             l14.1-8.1V1317.8z M1188.5,1347.9l6.2-3.6v1.7l-6.2,3.6V1347.9z M1123.7,1302.5l-26.4,15.2l-6.9-4v-15.5l12.9-7.5L1123.7,1302.5
             z M1102.7,1289.6l-12.3,7.1v-9.9h-0.7l0.3-0.6l-13.1-7.6l25.7-14.9V1289.6z M1090.4,1315.3l6.2,3.6v1.7l-6.2-3.6V1315.3z
              M1098,1318.9l26.4-15.2v1.7l-26.4,15.2V1318.9z M1043.3,1296.6l25.7-14.8v25.8l-10.9,6.3v-8.4h-0.7l0.3-0.6L1043.3,1296.6z
              M1084.5,1315.7l-14.1-8.1v-25.8l14.1,8.1V1315.7z M1069.7,1308L1069.7,1308L1069.7,1308L1069.7,1308z M1058.1,1334l6.2,3.6v1.7
             l-6.2-3.6V1334z M1065,1337.2L1065,1337.2L1065,1337.2L1065,1337.2z M950.8,1367.1l0.3-0.6l-14.4-8.3l26.4-15.2v25.8l-11.6,6.7
             v-8.4H950.8z M964.5,1342.9l14.1,8.1v25.8l-14.1-8.1V1342.9z M946.9,1370.1l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V1370.1z
              M951.5,1395.6l6.2,3.6v1.7l-6.2-3.6V1395.6z M880.1,1247.8L880.1,1247.8L880.1,1247.8L880.1,1247.8L880.1,1247.8z
              M880.1,1247.8L880.1,1247.8L880.1,1247.8L880.1,1247.8z M880.1,1248.5l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4
             l0.3,0.6l-0.3,0.6l25.6,14.8l45.4,26.2l-5.9,3.4l-15.1-8.7l-0.3,0.6l-0.3-0.6l-28.4,16.4l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9
             l-137.4-79.3L880.1,1248.5z M945.5,1370.1v29.1l-137.4-79.3v-29.1L945.5,1370.1z M958.4,1398l-6.9-4v-17l12.3-7.1l15.1,8.7
             l2.8,1.6l0.3-0.6l0,0l-0.3,0.6l3.7,2.2L958.4,1398z M987.1,1346.9l69.7-40.2v8.4v17.8v2.9l-69.7,40.2V1346.9z M1092,1324.1
             l-26.4,15.2v-1.7l26.4-15.2V1324.1z M1065,1336.5l-6.9-4v-17l11.6-6.7l15.1,8.7l0.3-0.6l-0.3,0.6l2.8,1.6l3.7,2.1L1065,1336.5z
              M1088,1317.7l-2.2-1.3v-26.6l3.3-1.9v9.9v16.3v2.9L1088,1317.7z M1125.1,1302.5L1125.1,1302.5l0.3-0.6l-21.3-12.3v-25.8
             l62.9,36.3v26.6l-2.2,1.3l-39.1-22.6v-2.9H1125.1z M1164.8,1328.8l0.3,0.6l2.8-1.6l15.1-8.7l11.7,6.7v17l-6.9,4l-26.4-15.2
             l3.7-2.1L1164.8,1328.8z M1187.2,1347.9v1.7l-26.4-15.2v-1.7L1187.2,1347.9z M1196.1,1343.1v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3
             l-1.1-0.7V1343.1z M1197.5,1348l2.8-1.6l15.1-8.7l15.2,8.8v17l-6.9,4l-29.9-17.3L1197.5,1348z M1223.1,1368.6v1.7l-29.9-17.3
             v-1.7L1223.1,1368.6z M1232,1366.8v-2.9v-17.8v-8.4l4.1,2.4v29.1L1232,1366.8z M1579.1,1312.4l-22.6,13.1v-1.7l22.6-13.1V1312.4
             z M1556.5,1322.1v-21.8h-0.7l0.3-0.6l-2.1-1.2l2.7-1.6l15.1,8.7l2.8,1.6l3.7,2.1L1556.5,1322.1z M1575,1306l-2.2-1.3v-26.6
             l3.3-1.9v8.4v17.8v2.9L1575,1306z M1577.5,1305.3v-1.7l6.2,3.6v1.7L1577.5,1305.3z M1611.5,1293.7l-26.4,15.2v-1.7l26.4-15.2
             V1293.7z M1584.4,1306l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6l0,0l3.7,2.1L1584.4,1306z M1322.5,1254.8l16.7-9.6v19.3
             L1322.5,1254.8z M1254.2,1246.2l44.1-25.5l40.2,23.2l-17.7,10.2l-26.4,15.2L1254.2,1246.2z M1321.1,1255.5l18.4,10.6l3.7,2.1
             l38.8,22.4l-25.4,14.7l-60.8-35.1L1321.1,1255.5z M1357.9,1306.1l25.7-14.9l-0.3-0.6l0.3-0.6l-38.8-22.4l36.8-21.2l63.9,36.9
             l-16.9,9.8l0.3,0.6l0,0l0,0l0,0l-0.3,0.6l19.3,11.2l-13.8,8l-20-11.6l-0.3,0.6l-0.3-0.6l-31.4,18.1L1357.9,1306.1z
              M1397.4,1235.8v-15.7l13.6,7.9L1397.4,1235.8z M1381.6,1245.7l-0.1-0.2L1381.6,1245.7L1381.6,1245.7z M1003.7,1194.7l124.7-72
             L1297,1220l-44.4,25.7l0.3,0.6l-0.3,0.6l41.5,24l0.3-0.6l0,0l-0.3,0.6l62.1,35.9l25.6,14.8l0.3-0.6l0.3,0.6l31.4-18.1l20,11.6
             l0.3-0.6l0.3,0.6l15.1-8.7l-0.3-0.6l0.3-0.6l-19.3-11.2l16.9-9.8l0,0l0,0l0,0l0,0l0,0l-0.2-0.4l-0.1-0.1h0l0,0l0,0l0.3-0.6
             l-64.3-37.1l14.1-8.1l15.6-9l-0.3-0.6l0.3-0.6l-14.6-8.4l81.9-47.3l17.4,10.1l0,0l0.8,0.5l-7,4l0.3,0.6l-0.3,0.6l72.7,42
             l0.3-0.6l0.3,0.6l64.8-37.4l-0.3-0.6l0.3-0.6L1604,1176l-0.3,0.6l-0.3-0.6l-27.3,15.7l-19-11l66.5-38.4l91,52.5l-109.9,63.4
             l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6
             l-0.3,0.6l23.7,13.7l0,0l2.1,1.2l-172.4,99.5l-125.1-72.2l-0.3,0.6l-0.3-0.6l-19.5,11.3l-4.1-2.4l14.4-8.3l-0.3-0.6l0.3-0.6
             l-31.3-18.1l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l14.4-8.3l-0.3-0.6l0.3-0.6l-27.7-16l-0.3,0.6l-0.3-0.6l-15.1,8.7l-63.9-36.9
             l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l13.1,7.6l-3.3,1.9l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6
             l14.4,8.3l-69.7,40.2l-45.1-26l63.9-36.9l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5l-20-11.6l-0.3,0.6l-0.3-0.6
             l-10.1,5.8l-45.1-26l106.8-61.6l14.8,8.6l0.3-0.6L1003.7,1194.7z M880.1,1247.7L880.1,1247.7L880.1,1247.7L880.1,1247.7z"/>
           <path d="M1102.6,1216.8l-0.1,0.2l0,0l0,0l0,0l0,0l0,0l0,0l64.8,37.4l0,0l0,0l0.3-0.6l0,0l0.3,0.5l25.6-14.8l-0.3-0.6l0.3-0.6
             l0,0l0,0l-17-9.8l19.4-11.2l-0.3-0.6l0.3-0.6l-15.1-8.7l-0.3,0.6l-0.3-0.6l-20,11.6l-31.4-18.1l-0.3,0.6l-0.3-0.6l-25.6,14.8
             l0.2,0.4l0.1,0.1l0,0h-0.6v0l0.6,0L1102.6,1216.8z M1102.8,1216.5L1102.8,1216.5L1102.8,1216.5L1102.8,1216.5z M1167.7,1253.9
             L1167.7,1253.9L1167.7,1253.9L1167.7,1253.9z M1160.1,1220l0.3,0.6l20-11.6l13.8,8l-19.4,11.2l0.3,0.6l-0.3,0.6l0,0l0,0l17,9.8
             l-24.2,14l-63.5-36.7l24.2-14l31.4,18.1L1160.1,1220z"/>
         </g>
       </g>
     </g>
     <g id="E2" className={'isometry__floor ' + (floor === "2" ? 'active' : '')}>
       <ModIsometrySvgFlat flat="71553.16.0202" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path66_20_" className="st0" d="M1538.8,1183.4l25.6,14.8l32.5-18.7l46.4,26.8l-38.6,22.3l-15.4-8.9l-27.7,16l-123.3-71.2
           l0,0l66.3-38.3l46.4,26.8l-10.4,6l20.4,11.8L1538.8,1183.4z"/>
         <path id="path82_20_" className="st0" d="M1643.2,1206.2v30.6l-35.7,20.6l-2.8-1.6v-27.3L1643.2,1206.2z"/>
         <path id="path84_22_" className="st0" d="M1612.1,1260.2v3.3l-27.7,16v-3.3L1612.1,1260.2z"/>
         <path id="path86_20_" className="st0" d="M1612.1,1260.2l-27.7,16l-7.6-4.4V1254l12.3-7.1l15.4,8.9l2.8,1.6L1612.1,1260.2z"/>
         <path id="path88_23_" className="st0" d="M1589.1,1246.9l-12.3,7.1v-9.5l-15.4-8.9l0,0l27.7-16V1246.9z"/>
         <path id="path92_22_" className="st0" d="M1584.4,1279.5l-7.6-4.4v-3.3l7.6,4.4V1279.5z"/>
         <path id="path94_22_" className="st0" d="M1604.6,1228.5v27.3l-15.4-8.9v-27.3L1604.6,1228.5z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.16.0201" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path62_21_" className="st0" d="M1504.5,1126.1l72.6-41.9l138.7,80.1l-4.6,2.7l-15.4-8.9l-29.4,17l15.4,8.9l-38.6,22.3
           l-46.4-26.8l32.4-18.7l-25.5-14.8l-27.6,15.9l-20.4-11.8l-4.8,2.8L1504.5,1126.1z"/>
         <path id="path68_22_" className="st0" d="M1643.2,1206.2l38.6-22.3v30.6l-38.6,22.3V1206.2z"/>
         <path id="path70_20_" className="st0" d="M1718.7,1201.9l-29.4,17v-3.3l29.4-17V1201.9z"/>
         <path id="path72_22_" className="st0" d="M1689.3,1215.6v3.3l-7.6-4.4v-3.3L1689.3,1215.6z"/>
         <path id="path74_20_" className="st0" d="M1718.7,1198.6l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L1718.7,1198.6z"/>
         <path id="path76_23_" className="st0" d="M1695.7,1185.4l-14,8.1v-9.5l-15.4-8.9l29.4-17V1185.4z"/>
         <path id="path78_21_" className="st0" d="M1711.2,1194.3l-15.4-8.9V1158l15.4,8.9V1194.3z"/>
         <path id="path80_22_" className="st0" d="M1711.2,1194.3v-27.3l4.6-2.7v30.6l-1.8,1L1711.2,1194.3z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0203" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path110_20_" className="st0" d="M1381.6,1215.1l39.8,23l-64.9,37.4l25.6,14.8l-69.1,39.9l-56.3-32.5l-19.9,11.5l-5.4-3.1
           l15.4-8.9l-31.3-18.1l-15.4,8.9l-4.6-2.7l15.4-8.9l119-68.7l0,0l32.4,18.7L1381.6,1215.1z"/>
         <path id="path112_21_" className="st0" d="M1236.8,1309.1l19.9-11.5v30.6l-19.9,11.5V1309.1z"/>
         <path id="path116_20_" className="st0" d="M1231.3,1305.9l5.4,3.1v30.6l-5.4-3.1v-3.3v-17.8V1305.9z"/>
         <path id="path118_20_" className="st0" d="M1256.7,1297.6l56.3,32.5v30.6l-56.3-32.5V1297.6z"/>
         <path id="path122_20_" className="st0" d="M1223.8,1340.9l-31.3-18.1v-3.3l31.3,18.1V1340.9z"/>
         <path id="path124_21_" className="st0" d="M1223.8,1340.9v-3.3l7.6-4.4v3.3L1223.8,1340.9z"/>
         <path id="path126_19_" className="st0" d="M1223.8,1337.6l-31.3-18.1l4.7-2.7l2.8-1.6l15.4-8.9l15.9,9.2v17.8L1223.8,1337.6z"/>
         <path id="path128_21_" className="st0" d="M1231.3,1315.4l-15.9-9.2V1279l31.3,18.1l-15.4,8.9L1231.3,1315.4z"/>
         <path id="path132_21_" className="st0" d="M1215.5,1279v27.3l-15.4,8.9v-27.3L1215.5,1279z"/>
         <path id="path134_19_" className="st0" d="M1200.1,1287.9v27.3l-2.8,1.6l-1.8-1v-3.3v-17.8v-9.5L1200.1,1287.9z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0202" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path114_20_" className="st0" d="M1313,1330.1l69.1-39.9l31.7-18.3l20.3,11.8l15.1-8.7l48.8,28.1l-36.7,21.2l-10.2-5.9
           l-28.4,16.4l10.2,5.9l-50.9,29.4L1313,1330.1z"/>
         <path id="path130_22_" className="st0" d="M1313,1360.7v-30.6l69.1,39.9v30.6L1313,1360.7z"/>
         <path id="path166_20_" className="st0" d="M1498.1,1303.1v30.6l-33.9,19.5l-2.8-1.6v-27.3L1498.1,1303.1z"/>
         <path id="path178_20_" className="st0" d="M1382.1,1400.6V1370l50.9-29.4v30.6L1382.1,1400.6z"/>
         <path id="path180_20_" className="st0" d="M1461.4,1324.2v27.3l-10.2-5.9v-27.3L1461.4,1324.2z"/>
         <path id="path182_17_" className="st0" d="M1422.7,1334.7l28.4-16.4v27.3l-18.2,10.5v-15.5L1422.7,1334.7z"/>
         <path id="path184_19_" className="st0" d="M1433,1356.2l18.2-10.5l10.2,5.9l2.8,1.6l5.9,3.4l-28.4,16.4l-8.7-5L1433,1356.2z"/>
         <path id="path186_17_" className="st0" d="M1433,1371.2v-3.3l8.7,5v3.3L1433,1371.2z"/>
         <path id="path188_19_" className="st0" d="M1441.7,1373l28.4-16.4v3.3l-28.4,16.4V1373z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0201" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path90_22_" className="st0" d="M1561.4,1235.6L1561.4,1235.6l15.4,8.9l-4.6,2.7l-15.5-8.9l-27.7,16l23.7,13.7l3.1,1.8
           l-57.7,33.3l-48.8-28.1l-20.3-11.7l18-10.4l-25.6-14.8l-39.8-23l15.1-8.7l15.6-9l-15.6-9l41.4-23.9L1561.4,1235.6z"/>
         <path id="path162_21_" className="st0" d="M1498.1,1303.1l57.7-33.3v30.6l-57.7,33.3V1303.1z"/>
         <path id="path168_20_" className="st0" d="M1529,1254.3l27.7-16v27.3l-4.1,2.4L1529,1254.3z"/>
         <path id="path170_19_" className="st0" d="M1552.7,1267.9l4.1-2.4l15.5,8.9l2.8,1.6l4.7,2.7l-24,13.8v-23L1552.7,1267.9z"/>
         <path id="path172_20_" className="st0" d="M1576.8,1275.1l-1.8,1l-2.8-1.6v-27.3l4.6-2.7v9.5v17.8V1275.1z"/>
         <path id="path174_20_" className="st0" d="M1579.8,1282.1l-24,13.8v-3.3l24-13.8V1282.1z"/>
         <path id="path176_19_" className="st0" d="M1556.7,1265.6v-27.3l15.5,8.9v27.3L1556.7,1265.6z"/>
         <path id="path164_21_" className="st0" d="M1396.8,1188.3l15.6,9l-15.6,9V1188.3z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0202" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path100_22_" className="st0" d="M1127.9,1276.8v-30.6l39.8,23v27.3l-2.8,1.6L1127.9,1276.8z"/>
         <path id="path140_21_" className="st0" d="M1175.2,1198.1l20.4-11.8l48.7-28.1l0,0l85.5,49.3l-119,68.7l-27.7-16l-15.4,8.9l-39.8-23
           l39.8-23l0,0l25.6-14.8l0,0L1175.2,1198.1z"/>
         <path id="path152_22_" className="st0" d="M1160.1,1304.2v-3.3l27.7,16v3.3L1160.1,1304.2z"/>
         <path id="path154_20_" className="st0" d="M1187.8,1316.9l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1187.8,1316.9z"/>
         <path id="path156_22_" className="st0" d="M1195.4,1294.7l-12.3-7.1v-27.3l27.7,16l-15.4,8.9V1294.7z"/>
         <path id="path158_20_" className="st0" d="M1167.7,1296.5v-27.3l15.4-8.9v27.3L1167.7,1296.5z"/>
         <path id="path160_21_" className="st0" d="M1187.8,1316.9l7.6-4.4v3.3l-7.6,4.4V1316.9z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0203" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path22_22_" className="st0" d="M1069.7,1250L1069.7,1250l-123.3-71.2l0,0l0,0l41.8-24.1l15.2,8.8l55.9-32.3l0,0l69.1,39.9
           l-25.5,14.7l0,0l0,0l64.8,37.4l-39.8,23l-24.5-14.2l-27.7,16l14.1,8.2l-4.6,2.7L1069.7,1250z"/>
         <path id="path32_22_" className="st0" d="M1085.1,1286.3l-15.4-8.9V1250l15.4,8.9V1286.3z"/>
         <path id="path36_23_" className="st0" d="M1085.1,1286.2L1085.1,1286.2l0-27.3l4.6-2.7v11v16.3v3.3l-1.8,1L1085.1,1286.2z"/>
         <path id="path96_22_" className="st0" d="M1125.1,1275.2l-27.7,16v-3.3l27.7-16V1275.2z"/>
         <path id="path98_21_" className="st0" d="M1127.9,1276.8l-2.8-1.6v-3.3l-21.7-12.5v-27.3l24.5,14.2V1276.8z"/>
         <path id="path102_20_" className="st0" d="M1097.3,1288v3.3l-7.6-4.4v-3.3L1097.3,1288z"/>
         <path id="path104_22_" className="st0" d="M1103.4,1259.4l21.7,12.5l-27.7,16l-7.6-4.4v-16.3L1103.4,1259.4z"/>
         <path id="path106_21_" className="st0" d="M1103.4,1259.4l-13.6,7.9v-11l-14.1-8.2l27.7-16V1259.4z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0201" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path136_21_" className="st0" d="M1059.3,1131.2l69.1-39.9l50.9,29.4l-10.2,5.9l10.2,5.9l18.2,10.5l10.2-5.9l36.7,21.2l0,0
           l-48.7,28.1l-15.1-8.7l-20.4,11.8l-31.7-18.3L1059.3,1131.2z"/>
         <path id="path146_19_" className="st0" d="M1179.3,1120.7v11.8l-10.2-5.9L1179.3,1120.7z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.13.0202" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path42_21_" className="st0" d="M972.4,1270.4l46.4,26.8l-38.5,22.3l-15.4-8.9l-29.4,17l15.4,8.9l-4.6,2.7l-138.7-80.1
           l72.6-41.9l46.4,26.8l-4.8,2.8l20.4,11.8l-27.6,15.9l25.6,14.8L972.4,1270.4z"/>
         <path id="path46_22_" className="st0" d="M1018.8,1297.2v30.6l-35.7,20.6l-2.8-1.6v-27.3L1018.8,1297.2z"/>
         <path id="path48_24_" className="st0" d="M987.8,1351.2v3.3l-29.4,17v-3.3L987.8,1351.2z"/>
         <path id="path50_22_" className="st0" d="M987.8,1351.2l-29.4,17l-7.6-4.4V1346l14-8.1l15.4,8.9l2.8,1.6L987.8,1351.2z"/>
         <path id="path52_23_" className="st0" d="M964.8,1337.9l-14,8.1v-9.5l-15.4-8.9l29.4-17V1337.9z"/>
         <path id="path54_21_" className="st0" d="M807.5,1259.1l138.7,80.1v30.6l-138.7-80.1V1259.1z"/>
         <path id="path56_23_" className="st0" d="M946.2,1369.8v-30.6l4.6-2.7v30.6L946.2,1369.8z"/>
         <path id="path58_20_" className="st0" d="M980.3,1319.5v27.3l-15.4-8.9v-27.3L980.3,1319.5z"/>
         <path id="path60_22_" className="st0" d="M950.8,1363.8l7.6,4.4v3.3l-7.6-4.4V1363.8z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.13.0201" floor="2" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path26_20_" className="st0" d="M1092.7,1293.9l-27.7,16v-3.3l27.7-16V1293.9z"/>
         <path id="path28_22_" className="st0" d="M1065,1306.6v3.3l-7.6-4.4v-3.3L1065,1306.6z"/>
         <path id="path30_20_" className="st0" d="M1092.7,1290.6l-27.7,16l-7.6-4.4v-17.8l12.3-7.1l15.4,8.9l0,0l2.8,1.6L1092.7,1290.6z"/>
         <path id="path34_21_" className="st0" d="M1069.7,1250v27.3l-12.3,7.1v-9.5l-15.4-8.9L1069.7,1250z"/>
         <path id="path38_21_" className="st0" d="M1057.4,1302.3v3.3l-38.6,22.3v-30.6l38.6-22.3v9.5V1302.3z"/>
         <path id="path40_23_" className="st0" d="M1057.4,1274.9l-38.6,22.3l-46.4-26.8l32.5-18.7l-25.6-14.8l-22,12.7l-20.4-11.8l-10.4,6
           l-46.4-26.8l66.3-38.3l0,0l123.3,71.2l-27.7,16L1057.4,1274.9z"/>
       </ModIsometrySvgFlat>
       <g id="E2-Areas" className="isometry__areas">
         <path id="path44_23_" className="st0" d="M1004.9,1251.7l-32.5,18.7l-32.4,18.7l-25.6-14.8l27.6-15.9l-20.4-11.8l4.8-2.8l10.4-6
           l20.4,11.8l22-12.7L1004.9,1251.7z"/>
         <path id="path64_22_" className="st0" d="M1555.7,1150.1l20.4,11.8l27.6-15.9l25.5,14.8l-32.4,18.7l-32.5,18.7l-25.6-14.8l22-12.7
           l-20.4-11.8l10.4-6L1555.7,1150.1z"/>
         <path id="path120_21_" className="st0" d="M1382.1,1290.2l-25.6-14.8l64.9-37.4l25.6,14.8l-18,10.4l20.3,11.7l-15.1,8.7l-20.3-11.8
           L1382.1,1290.2z"/>
         <path id="path138_19_" className="st0" d="M1180.5,1177.6l15.1,8.7l-20.4,11.8l0,0l18,10.4l-25.6,14.8l-64.8-37.4l25.5-14.7l0,0
           l31.7,18.3L1180.5,1177.6z"/>
       </g>
       <g id="E2-Strokes" className="isometry__strokes">
         <path d="M1719.4,1198.6h-0.7l0.3-0.6l-3.7-2.2l0.8-0.5l-0.3-0.6h0.7v-30.6h-0.7l0.3-0.6l-138.7-80.1l-0.3,0.6l-0.3-0.6
           l-72.6,41.9l-66.3,38.3l-41.4,23.9l0.3,0.6h-0.7v17.6l-14.8,8.6l-19.1,11l-32-18.5l-0.3,0.6l0,0l0.3-0.6l-85.5-49.3l-0.3,0.6h0
           l0,0v0h0v0l0.3-0.6l-36.7-21.2l-0.3,0.6l-0.3-0.6l-9.9,5.7l-17.5-10.1v-11.4h-0.7l0.3-0.6l-50.9-29.4l-0.3,0.6l-0.3-0.6
           l-69.1,39.9l0.3,0.6l0,0l-0.3-0.6l-55.6,32.1l-14.8-8.6l-0.3,0.6l-0.3-0.6l-41.8,24.1l0.3,0.4l0,0l0,0l-0.2-0.4l-66.3,38.3l0,0
           l-72.6,41.9l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7
           v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20l38.3-22.1l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2
           l0.4-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4l0,0l35.9,20.7l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6
           l0.3,0.6l7.3-4.2l0.5,0.3l-3.7,2.2l0,0l0,0l0,0l0.1,0.1l0.2,0.4h-0.6v3.3h0.7l-0.3,0.6l31.3,18.1l0.3-0.6l0.3,0.6l7.2-4.2l5.1,3
           l0.3-0.6l0.3,0.6l19.5-11.3l56,32.3l0,0l69.1,39.9l0.3-0.6l0.3,0.6l50.6-29.2l8.4,4.8l0.3-0.6l0.3,0.6l28.4-16.4l-0.3-0.6h0.7
           v-3.3h-0.7l0.3-0.6l-4.9-2.8l32.9-19l57.7-33.3l-0.3-0.6h0.7v-4l23.6-13.6l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l0.5-0.3
           l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20.1l38.2-22.1l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17
           l-0.3-0.6h0.7V1198.6z M1178.6,1131.3l-8.2-4.7l8.2-4.7V1131.3z M1168.7,1126l0.3,0.6l-0.3,0.6l10.2,5.9l18.2,10.5l0.3-0.6
           l0.3,0.6l9.9-5.7l35.3,20.4l-47.4,27.4l-14.8-8.6l-0.3,0.6l-0.3-0.6l-20,11.6l-31.4-18.1l-68.1-39.3l67.8-39.1l49.5,28.6
           L1168.7,1126z M1090.4,1283.2v-15.5l12.9-7.5l20.4,11.8l-26.4,15.2L1090.4,1283.2z M1102.7,1259l-12.3,7.1v-9.9h-0.7l0.3-0.6
           l-13.1-7.6l25.7-14.9V1259z M1098,1288.3l26.4-15.2v1.7l-26.4,15.2V1288.3z M1125.1,1271.9l0.3-0.6L1104,1259v-25.8l23.2,13.4
           v29.1l-1.5-0.9v-2.9H1125.1z M1059.3,1131.2L1059.3,1131.2L1059.3,1131.2L1059.3,1131.2L1059.3,1131.2z M1128.4,1171.8l31.4,18.1
           l0.3-0.6l0.3,0.6l20-11.6l13.8,8l-19.4,11.2l0.3,0.6l-0.3,0.6l17,9.8l-24.2,14l-63.5-36.7L1128.4,1171.8z M1244.3,1158.3
           L1244.3,1158.3L1244.3,1158.3L1244.3,1158.3L1244.3,1158.3L1244.3,1158.3L1244.3,1158.3z M1167.7,1223.3L1167.7,1223.3
           L1167.7,1223.3L1167.7,1223.3z M1168,1223.9l-0.3-0.6l0,0l0.1,0.1L1168,1223.9l25.6-14.8l-0.3-0.6l0.3-0.6l-17-9.8l19.4-11.2
           l48.4-27.9l84.2,48.6l-117.7,67.9l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7l-38.4-22.2L1168,1223.9z M1168.3,1269.6l14.1-8.1v25.8
           l-14.1,8.1V1269.6z M1183.7,1261.4l25.7,14.9l-14.4,8.3l0.3,0.6h-0.7v8.4l-11-6.3V1261.4z M1412.7,1197.9l-0.3-0.6l0.3-0.6
           l-14.6-8.4l40-23.1l122.9,71l14.4,8.3l-3.3,1.9l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l23.7,13.7l0,0l2.1,1.2
           l-56.4,32.6l-48.4-28l-0.3,0.6l0,0l0.3-0.6l-19.3-11.2l17-9.8l-0.3-0.6l0.3-0.6l-25.6-14.8l-38.8-22.4l14.1-8.2L1412.7,1197.9z
            M1710.5,1193.1l-14.1-8.1v-25.8l14.1,8.1V1193.1z M1695.1,1159.2v25.8l-12.7,7.3v-8.4h-0.7l0.3-0.6l-14.4-8.3L1695.1,1159.2z
            M1555.7,1150.9l20,11.6l0.3-0.6l0.3,0.6l27.3-15.7l24.2,14l-31.4,18.1l-32.1,18.5l-24.2-14l21-12.1l-0.3-0.6l0.3-0.6l-19.4-11.2
           l9.4-5.4l-0.3-0.6l0.3,0.6L1555.7,1150.9z M1549.5,1152.9l-9.4,5.4l0.3,0.6l-0.3,0.6l19.4,11.2l-21,12.1l0.3,0.6l-0.3,0.6
           l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45,26l-37.3,21.5l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.4,15.8l-121.9-70.4l65-37.5
           L1549.5,1152.9z M1576.8,1244.5l0.3-0.6l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4H1576.8z M1589.8,1220.7l14.1,8.1v25.8l-14.1-8.1
           V1220.7z M1552.7,1267.2l-22.3-12.9l25.7-14.9v25.8L1552.7,1267.2z M1557.4,1239.4l14.1,8.2v25.8l-14.1-8.2V1239.4z M1433,1340.6
           l0.3-0.6l-9.2-5.3l26.4-15.2v25.8l-16.8,9.7v-14.4H1433z M1451.8,1319.5l8.9,5.1v25.8l-8.9-5.1V1319.5z M1461.4,1323.5l-9.9-5.7
           l-0.3,0.6l-0.3-0.6l-28.4,16.4l0.3,0.6l-0.3,0.6l9.2,5.3l-49.6,28.6l-67.8-39.1l68.1-39.3l31.4-18.1l20,11.6l0.3-0.6l0.3,0.6
           l14.8-8.6l47.4,27.4L1461.4,1323.5z M1421.4,1238.8l24.2,14l-17,9.8l0.3,0.6l-0.3,0.6l19.3,11.2l-13.8,8l-20-11.6l-0.3,0.6
           l-0.3-0.6l-31.4,18.1l-24.2-14L1421.4,1238.8z M1231,1305.4l0.3,0.6h-0.7v8.4l-14.5-8.4v-25.8l29.3,16.9L1231,1305.4z
            M1200.7,1288.3l14.1-8.1v25.8l-14.1,8.1V1288.3z M1224.4,1338l6.2-3.6v1.7l-6.2,3.6V1338z M1232,1333.3v-17.8v-8.4l4.1,2.4v29.1
           l-4.1-2.4V1333.3z M1237.4,1309.5l18.5-10.7v29.1l-18.5,10.7V1309.5z M1257.3,1298.8l55,31.7v29.1l-55-31.8V1298.8z
            M1313.7,1331.3l67.8,39.1v29.1l-67.8-39.1V1331.3z M1382.7,1370.4l49.6-28.6v14.4v11.8v2.9l-49.6,28.6V1370.4z M1433.6,1369.1
           l7.4,4.3v1.7l-7.4-4.3V1369.1z M1577.5,1273l6.2,3.6v1.7l-6.2-3.6V1273z M1643.8,1206.6l37.2-21.5v8.4v17.8v2.9l-37.2,21.5
           V1206.6z M1682.4,1212.4l6.2,3.6v1.7l-6.2-3.6V1212.4z M1689.3,1214.9l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l3.7,2.2
           L1689.3,1214.9z M1714,1195.1l-2.2-1.3v-26.6l3.3-1.9v29.1L1714,1195.1z M1711.2,1166.2l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17
           l0.3,0.6l-0.3,0.6l14.4,8.3l-37.2,21.5l-45-26l31.4-18.1l-0.3-0.6l0.3-0.6l-25.5-14.8l-0.3,0.6l-0.3-0.6l-27.3,15.7l-20-11.6
           l-0.3,0.6l-0.3-0.6l-4.5,2.6l-45.1-26l71.3-41.2l137.4,79.3L1711.2,1166.2z M1411,1197.3l-13.6,7.9v-15.7L1411,1197.3z
            M1361.9,1226.9l0.3-0.6l0.3,0.6l19.1-11l38.4,22.2l-63.9,36.9l0.3,0.6l-0.3,0.6l24.6,14.2l-67.8,39.1l-56-32.3l-0.3,0.6
           l-0.3-0.6l-19.5,11.3l-4.1-2.4l14.4-8.3l-0.3-0.6l0.3-0.6l-31.3-18.1l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l14.4-8.3l118.7-68.5
           L1361.9,1226.9z M1102.8,1185.9L1102.8,1185.9L1102.8,1185.9L1102.8,1185.9z M950.8,1336.5l0.3-0.6l-14.4-8.3l27.4-15.8v25.8
           l-12.7,7.3v-8.4H950.8z M965.5,1311.7l14.1,8.1v25.8l-14.1-8.1V1311.7z M946.9,1339.5l3.3-1.9v8.4v17.8v2.9l-3.3,1.9V1339.5z
            M951.5,1365l6.2,3.6v1.7l-6.2-3.6V1365z M915.8,1274.4l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2l3.8-2.2l10.1-5.8l20,11.6l0.3-0.6
           l0.3,0.6l21.7-12.5l24.2,14l-31.5,18.2l-32.1,18.5L915.8,1274.4z M1084.5,1285.1l-14.1-8v-25.9l14.1,8.1V1285.1z M1057.4,1274.9
           l0.3-0.6l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4H1057.4z M946.4,1179.6l121.9,70.4l-26.7,15.4l0.3,0.6l-0.3,0.6l14.4,8.3
           l-37.3,21.5l-45.1-26l31.5-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6l-21.7,12.5l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8
           l-45.1-26L946.4,1179.6z M1019.5,1297.6l37.3-21.5v8.4v17.8v2.9l-37.3,21.5V1297.6z M880.1,1217.9l45.1,26l-3.8,2.2l0.3,0.6
           l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-37.2,21.5l-15.1-8.7l-0.3,0.6
           l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.4,8.3l-3.3,1.9l-137.4-79.3L880.1,1217.9z M945.5,1339.5v29.1l-137.4-79.3v-29.1
           L945.5,1339.5z M987.2,1354.1l-28.1,16.2v-1.7l28.1-16.2V1354.1z M958.4,1367.4l-6.9-4v-17l13.3-7.7l15.1,8.7l0,0l2.8,1.6
           l3.7,2.2L958.4,1367.4z M983.1,1347.7l-2.2-1.3v-26.6l37.2-21.5v29.1L983.1,1347.7z M1058.1,1305.2v-1.7l6.2,3.6v1.7
           L1058.1,1305.2z M1065.7,1307l26.4-15.2v1.7l-26.4,15.2V1307z M1065,1305.9l-6.9-4v-17l11.6-6.7l16.2,9.3l0,0l1.8,1l3.7,2.2
           L1065,1305.9z M1088,1287.1l-1.4-0.8l0,0l-0.2-0.1l-0.6-0.3v-26.5l3.3-1.9v9.9v16.3v2.9L1088,1287.1z M1090.4,1286.5v-1.7
           l6.2,3.6v1.7L1090.4,1286.5z M1128.6,1247.4l38.4,22.2v26.6l-2.2,1.3l-36.3-20.9V1247.4z M1165.1,1298.7l2.8-1.6l15.1-8.7
           l11.7,6.7v17l-6.9,4l-26.4-15.2L1165.1,1298.7z M1160.7,1303.8v-1.7l26.4,15.2v1.7L1160.7,1303.8z M1188.5,1317.3l6.3-3.6v1.7
           l-6.3,3.6V1317.3z M1196.1,1312.5v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V1312.5z M1197.5,1317.4l2.8-1.6l15.1-8.7l15.2,8.8
           v17l-6.9,4l-29.9-17.3L1197.5,1317.4z M1223.1,1338v1.7l-29.9-17.3v-1.7L1223.1,1338z M1442.3,1373.4l27.1-15.6v1.7l-27.1,15.6
           V1373.4z M1441.7,1372.2l-8-4.6v-11l17.5-10.1l9.9,5.7l0.3-0.6l-0.3,0.6l2.8,1.6l4.9,2.8L1441.7,1372.2z M1464.2,1352.4l-2.2-1.3
           v-26.6l35.4-20.4v29.1L1464.2,1352.4z M1555.1,1299.9l-56.4,32.6v-29.1l56.4-32.6v21.8v3.3V1299.9z M1556.5,1293.1l22.6-13.1v1.7
           l-22.6,13.1V1293.1z M1556.5,1291.6v-21.8h-0.7l0.3-0.6l-2.1-1.2l2.7-1.6l15.1,8.7l2.8,1.6l3.7,2.2L1556.5,1291.6z M1575,1275.4
           l-2.2-1.3v-26.6l3.3-1.9v8.4v17.8v2.9L1575,1275.4z M1585.1,1276.6l26.4-15.2v1.7l-26.4,15.2V1276.6z M1584.4,1275.4l-6.9-4v-17
           l11.6-6.7l15.1,8.7l2.8,1.6l3.7,2.2L1584.4,1275.4z M1607.4,1256.7l-2.2-1.3v-26.6l37.3-21.5v29.1L1607.4,1256.7z M1718.1,1201.5
           l-28.1,16.2v-1.7l28.1-16.2V1201.5z M1329.8,1207.6L1329.8,1207.6L1329.8,1207.6L1329.8,1207.6L1329.8,1207.6z M1059.3,1131.2
           L1059.3,1131.2L1059.3,1131.2L1059.3,1131.2L1059.3,1131.2L1059.3,1131.2z M1003.3,1163.5l0.3,0.6l55.6-32.1l67.8,39.1
           l-24.5,14.2l0,0l0,0l0,0l0,0l0.3,0.5l0,0h-0.6v0l0.6,0l-0.1,0.2l-0.2,0.4l0,0l0,0l0,0l0,0l0,0l63.8,36.8l-38.4,22.2l-24.2-14
           l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l13.1,7.6l-3.3,1.9l-15.1-8.7l0,0l-122.3-70.6l40.4-23.3l14.8,8.6L1003.3,1163.5z
            M946.4,1178.8L946.4,1178.8L946.4,1178.8L946.4,1178.8L946.4,1178.8z M1192.3,1319.2l0.2,0.4h0L1192.3,1319.2z"/>
       </g>
     </g>
     <g id="E3" className={'isometry__floor ' + (floor === "3" ? 'active' : '')}>
       <ModIsometrySvgFlat flat="71553.16.0302" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path58_21_" className="st0" d="M1596.8,1148.8l46.4,26.8l-38.6,22.3l-15.4-8.9l-27.7,16l-123.3-71.2l66.3-38.3l46.4,26.8
           l-10.4,6l20.4,11.8l-22,12.7l25.6,14.8L1596.8,1148.8z"/>
         <path id="path76_20_" className="st0" d="M1643.2,1175.6v30.6l-35.7,20.6l-2.8-1.6v-27.3L1643.2,1175.6z"/>
         <path id="path78_18_" className="st0" d="M1612.1,1229.6v3.3l-27.7,16v-3.3L1612.1,1229.6z"/>
         <path id="path80_23_" className="st0" d="M1612.1,1229.6l-27.7,16l-7.6-4.4v-17.8l12.3-7.1l15.4,8.9l2.8,1.6L1612.1,1229.6z"/>
         <path id="path82_21_" className="st0" d="M1589.1,1216.3l-12.3,7.1v-9.5l-15.4-8.9l27.7-16V1216.3z"/>
         <path id="path84_23_" className="st0" d="M1584.4,1248.9l-7.6-4.4v-3.3l7.6,4.4V1248.9z"/>
         <path id="path88_20_" className="st0" d="M1604.6,1197.9v27.3l-15.4-8.9V1189L1604.6,1197.9z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.16.0301" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path56_20_" className="st0" d="M1504.5,1095.5l72.6-41.9l138.7,80.1l-4.6,2.7l-15.4-8.9l-29.4,17l15.4,8.9l-38.6,22.3
           l-46.4-26.8l32.4-18.7l-25.5-14.8l-27.6,15.9l-20.4-11.8l-4.8,2.8L1504.5,1095.5z"/>
         <path id="path60_23_" className="st0" d="M1643.2,1175.6l38.6-22.3v30.6l-38.6,22.3V1175.6z"/>
         <path id="path64_23_" className="st0" d="M1718.7,1171.3l-29.4,17v-3.3l29.4-17V1171.3z"/>
         <path id="path66_21_" className="st0" d="M1689.3,1185v3.3l-7.6-4.4v-3.3L1689.3,1185z"/>
         <path id="path68_23_" className="st0" d="M1718.7,1168l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L1718.7,1168z"/>
         <path id="path70_21_" className="st0" d="M1695.7,1154.7l-14,8.1v-9.5l-15.4-8.9l29.4-17V1154.7z"/>
         <path id="path72_23_" className="st0" d="M1711.2,1163.7l-15.4-8.9v-27.3l15.4,8.9V1163.7z"/>
         <path id="path74_21_" className="st0" d="M1711.2,1163.7v-27.3l4.6-2.7v30.6l-1.8,1L1711.2,1163.7z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0303" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path108_23_" className="st0" d="M1381.6,1184.5l39.8,23l-64.9,37.4l25.6,14.8l-69.1,39.9l-56.3-32.5l-19.9,11.5l-5.4-3.1
           l15.4-8.9l-31.3-18.1l-15.4,8.9l-4.6-2.7l15.4-8.9l119-68.7l0,0l32.4,18.7L1381.6,1184.5z"/>
         <path id="path110_21_" className="st0" d="M1236.8,1278.5l19.9-11.5v30.6l-19.9,11.5V1278.5z"/>
         <path id="path114_21_" className="st0" d="M1231.3,1275.3l5.4,3.1v30.6l-5.4-3.1v-3.3v-17.8V1275.3z"/>
         <path id="path116_21_" className="st0" d="M1256.7,1267l56.3,32.5v30.6l-56.3-32.5V1267z"/>
         <path id="path120_22_" className="st0" d="M1223.8,1310.3l-31.3-18.1v-3.3l31.3,18.1V1310.3z"/>
         <path id="path122_21_" className="st0" d="M1223.8,1310.3v-3.3l7.6-4.4v3.3L1223.8,1310.3z"/>
         <path id="path124_22_" className="st0" d="M1223.8,1307l-31.3-18.1l4.7-2.7l2.8-1.6l15.4-8.9l15.9,9.2v17.8L1223.8,1307z"/>
         <path id="path126_20_" className="st0" d="M1231.3,1284.8l-15.9-9.2v-27.3l31.3,18.1l-15.4,8.9L1231.3,1284.8z"/>
         <path id="path130_23_" className="st0" d="M1215.5,1248.4v27.3l-15.4,8.9v-27.3L1215.5,1248.4z"/>
         <path id="path132_22_" className="st0" d="M1200.1,1257.3v27.3l-2.8,1.6l-1.8-1v-3.3v-17.8v-9.5L1200.1,1257.3z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0302" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path112_22_" className="st0" d="M1313,1299.5l69.1-39.9l31.7-18.3l20.3,11.8l15.1-8.7l48.8,28.1l-36.7,21.2l-10.2-5.9
           l-28.4,16.4l10.2,5.9l-50.9,29.4L1313,1299.5z"/>
         <path id="path128_22_" className="st0" d="M1313,1330.1v-30.6l69.1,39.9v30.6L1313,1330.1z"/>
         <path id="path154_21_" className="st0" d="M1498.1,1272.5v30.6l-33.9,19.5l-2.8-1.6v-27.3L1498.1,1272.5z"/>
         <path id="path176_20_" className="st0" d="M1382.1,1370v-30.6l50.9-29.4v30.6L1382.1,1370z"/>
         <path id="path178_21_" className="st0" d="M1461.4,1293.6v27.3l-10.2-5.9v-27.3L1461.4,1293.6z"/>
         <path id="path180_21_" className="st0" d="M1422.7,1304.1l28.4-16.4v27.3l-18.2,10.5V1310L1422.7,1304.1z"/>
         <path id="path182_18_" className="st0" d="M1433,1325.6l18.2-10.5l10.2,5.9l2.8,1.6l5.9,3.4l-28.4,16.4l-8.7-5L1433,1325.6z"/>
         <path id="path184_20_" className="st0" d="M1433,1340.6v-3.3l8.7,5v3.3L1433,1340.6z"/>
         <path id="path186_18_" className="st0" d="M1441.7,1342.4l28.4-16.4v3.3l-28.4,16.4V1342.4z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0301" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path86_21_" className="st0" d="M1437.2,1133.8l123.3,71.2l15.4,8.9l-4.6,2.7l-15.5-8.9l-27.7,16l23.7,13.7l3.1,1.8
           l-57.7,33.3l-48.8-28.1l-20.3-11.8l18-10.4l-25.6-14.8l-39.8-23l15.1-8.7l15.6-9l-15.6-9L1437.2,1133.8z"/>
         <path id="path150_21_" className="st0" d="M1498.1,1272.5l57.7-33.3v30.6l-57.7,33.3V1272.5z"/>
         <path id="path152_23_" className="st0" d="M1396.8,1157.7l15.6,9l-15.6,9V1157.7z"/>
         <path id="path156_23_" className="st0" d="M1529,1223.7l27.7-16v27.3l-4.1,2.4L1529,1223.7z"/>
         <path id="path158_21_" className="st0" d="M1552.7,1237.3l4.1-2.4l15.5,8.9l2.8,1.6l4.7,2.7l-24,13.8v-23L1552.7,1237.3z"/>
         <path id="path160_22_" className="st0" d="M1576.8,1244.5l-1.8,1l-2.8-1.6v-27.3l4.6-2.7v9.5v17.8V1244.5z"/>
         <path id="path162_22_" className="st0" d="M1579.8,1251.5l-24,13.8v-3.3l24-13.8V1251.5z"/>
         <path id="path164_22_" className="st0" d="M1556.7,1235v-27.3l15.5,8.9v27.3L1556.7,1235z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0303" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path22_23_" className="st0" d="M1069.7,1246.7v-6.2l15.4-8.9v24L1069.7,1246.7z"/>
         <path id="path28_23_" className="st0" d="M1085.1,1255.6L1085.1,1255.6l0-24l0,0v-3.3l0,0l32.4-18.7v3.3l-14.1,8.1l-13.6,7.9v7.8
           v16.3v3.3l-1.8,1L1085.1,1255.6z"/>
         <path id="path90_19_" className="st0" d="M1125.1,1244.6l-27.7,16v-3.3l27.7-16V1244.6z"/>
         <path id="path92_19_" className="st0" d="M1127.9,1246.2l-2.8-1.6v-3.3l-21.7-12.5v-7.8l14.1-8.1v-3.3l10.4,6V1246.2z"/>
         <path id="path94_19_" className="st0" d="M1003.3,1132.9l55.9-32.3l0,0l69.1,39.9l-25.5,14.7l0,0l0,0l64.8,37.4l-39.8,23l-10.4-6
           l-32.4,18.7l-138.7-80.1l0,0l0,0l41.8-24.1L1003.3,1132.9z"/>
         <path id="path100_23_" className="st0" d="M1103.4,1228.8l21.7,12.5l-27.7,16l-7.6-4.4v-16.3L1103.4,1228.8z"/>
         <path id="path102_21_" className="st0" d="M1103.4,1221v7.8l-13.6,7.9v-7.8L1103.4,1221z"/>
         <path id="path106_22_" className="st0" d="M1097.3,1260.6l-7.6-4.4v-3.3l7.6,4.4V1260.6z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0302" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path98_22_" className="st0" d="M1127.9,1246.2v-30.6l39.8,23v27.3l-2.8,1.6L1127.9,1246.2z"/>
         <path id="path144_22_" className="st0" d="M1175.2,1167.5l20.4-11.8l0,0l48.7-28.1l0,0l85.5,49.3l-119,68.7l-27.7-16l-15.4,8.9
           l-39.8-23l39.8-23l0,0l25.6-14.8L1175.2,1167.5z"/>
         <path id="path166_21_" className="st0" d="M1160.1,1273.6v-3.3l27.7,16v3.3L1160.1,1273.6z"/>
         <path id="path168_21_" className="st0" d="M1187.8,1286.3l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1187.8,1286.3z"/>
         <path id="path170_20_" className="st0" d="M1195.4,1264.1l-12.3-7.1v-27.3l27.7,16l-15.4,8.9V1264.1z"/>
         <path id="path172_21_" className="st0" d="M1167.7,1265.9v-27.3l15.4-8.9v27.3L1167.7,1265.9z"/>
         <path id="path174_21_" className="st0" d="M1187.8,1286.3l7.6-4.4v3.3l-7.6,4.4V1286.3z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0301" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path134_20_" className="st0" d="M1059.3,1100.6l69.1-39.9l50.9,29.4l-10.2,5.9l10.2,5.9l18.2,10.5l10.2-5.9l36.7,21.2l0,0
           l-48.7,28.1l-15.1-8.7v0l-20.4,11.8L1059.3,1100.6z"/>
         <path id="path140_22_" className="st0" d="M1179.3,1090.1v11.8l-10.2-5.9L1179.3,1090.1z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.13.0302" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path38_22_" className="st0" d="M972.4,1239.8l46.4,26.8l-72.6,41.9l-138.7-80.1l72.6-41.9l46.4,26.8l-4.8,2.8l20.4,11.8
           l-27.6,15.9l25.6,14.8L972.4,1239.8z"/>
         <path id="path42_22_" className="st0" d="M1018.8,1266.6v30.6l-35.7,20.6l-2.8-1.6v-24l-15.4,8.9l-14,8.1v6.2v17.8v3.3l-4.6,2.7
           v-30.6L1018.8,1266.6z"/>
         <path id="path44_24_" className="st0" d="M987.8,1320.6v3.3l-29.4,17v-3.3L987.8,1320.6z"/>
         <path id="path46_23_" className="st0" d="M987.8,1320.6l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L987.8,1320.6z"/>
         <path id="path48_25_" className="st0" d="M964.8,1307.3l-14,8.1v-6.2l14-8.1V1307.3z"/>
         <path id="path50_23_" className="st0" d="M807.5,1228.5l138.7,80.1v30.6l-138.7-80.1V1228.5z"/>
         <path id="path52_24_" className="st0" d="M980.3,1292.1v24l-15.4-8.9v-6.2L980.3,1292.1z"/>
         <path id="path54_14_" className="st0" d="M950.8,1333.2l7.6,4.4v3.3l-7.6-4.4V1333.2z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.13.0301" floor="3" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path24_24_" className="st0" d="M1069.7,1240.5v6.2l-12.3,7.1v-6.2L1069.7,1240.5z"/>
         <path id="path26_21_" className="st0" d="M1069.7,1246.7l15.4,8.9l0,0l2.8,1.6l4.8,2.7l-27.7,16l-7.6-4.4v-17.8L1069.7,1246.7z"/>
         <path id="path30_21_" className="st0" d="M1065,1276l27.7-16v3.3l-27.7,16V1276z"/>
         <path id="path32_23_" className="st0" d="M1065,1276v3.3l-7.6-4.4v-3.3L1065,1276z"/>
         <path id="path34_22_" className="st0" d="M1057.4,1271.7v3.3l-38.6,22.3v-30.6l66.3-38.3v3.3l0,0l-15.4,8.9l-12.3,7.1v6.2
           L1057.4,1271.7z"/>
         <path id="path36_24_" className="st0" d="M1085.1,1228.3l-66.3,38.3l-46.4-26.8l32.5-18.7l-25.6-14.8l-22,12.7l-20.4-11.8l-10.4,6
           l-46.4-26.8l66.3-38.3l0,0L1085.1,1228.3L1085.1,1228.3L1085.1,1228.3z"/>
       </ModIsometrySvgFlat>
       <g id="E3-Areas" className="isometry__areas">
         <path id="path40_24_" className="st0" d="M1004.9,1221.1l-32.5,18.7l-32.4,18.7l-25.6-14.8l27.6-15.9l-20.4-11.8l4.8-2.8l10.4-6
           l20.4,11.8l22-12.7L1004.9,1221.1z"/>
         <path id="path62_18_" className="st0" d="M1629.2,1130.1l-32.4,18.7l-32.5,18.7l-25.6-14.8l22-12.7l-20.4-11.8l10.4-6l4.8-2.8
           l20.4,11.8l27.6-15.9L1629.2,1130.1z"/>
         <path id="path118_21_" className="st0" d="M1382.1,1259.6l-25.6-14.8l64.9-37.4l25.6,14.8l-18,10.4l20.3,11.8l-15.1,8.7l-20.3-11.8
           L1382.1,1259.6z"/>
         <path id="path142_20_" className="st0" d="M1128.4,1140.5l31.7,18.3l0,0l20.4-11.8l0,0l15.1,8.7l-20.4,11.8l18,10.4l-25.6,14.8
           l-64.8-37.4L1128.4,1140.5L1128.4,1140.5z"/>
       </g>
       <g id="E3-Strokes" className="isometry__strokes">
         <path d="M1719.4,1171.3v-3.3h-0.7l0.3-0.6l-3.7-2.2l0.8-0.5l-0.3-0.6h0.7v-30.6h-0.7l0.3-0.6l-138.7-80.1l-0.3,0.6l-0.3-0.6
           l-72.6,41.9l-66.3,38.3l-41.4,23.9l0.3,0.6h-0.7v17.7l-14.8,8.6l-19.1,11l-32-18.5l-0.3,0.6l0,0l0.3-0.6l-85.5-49.3l-0.3,0.6l0,0
           l0,0l0.3-0.6l-36.7-21.2l-0.3,0.6l-0.3-0.6l-9.9,5.7l-17.5-10.1v-11.4h-0.7l0.3-0.6l-50.9-29.4l-0.3,0.6l-0.3-0.6l-69.1,39.9
           l0.3,0.6l0,0l-0.3-0.6l-55.6,32.1l-14.8-8.6l-0.3,0.6l-0.3-0.6l-41.8,24.1l0.2,0.4l0.1,0.1l0,0l-0.2-0.4l-66.3,38.3l0,0
           l-72.6,41.9l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.7,80.1l0.3-0.6l0.3,0.6l4.3-2.5l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7
           v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20l38.3-22.1l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.8-2.2
           l0.4-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.4-15.8l2.5,1.4l35.9,20.7l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6
           l7.3-4.2l0.5,0.3l-3.7,2.2l0,0l0,0l0,0l0.1,0.1l0.2,0.4h-0.6v3.3h0.7l-0.3,0.6l31.3,18.1l0.3-0.6l0.3,0.6l7.2-4.2l5.1,2.9
           l0.3-0.6l0.3,0.6l19.5-11.3l56,32.3l0,0l69.1,39.9l0.3-0.6l0.3,0.6l50.6-29.2l8.4,4.8l0.3-0.6l0.3,0.6l28.4-16.4l-0.3-0.6h0.7
           v-3.3h-0.7l0.3-0.6l-4.9-2.8l32.9-19l57.7-33.3l-0.3-0.6h0.7v-4l23.6-13.6l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l0.5-0.3
           l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20.1l38.2-22.1l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17
           l-0.3-0.6H1719.4z M1178.6,1100.7l-8.2-4.7l8.2-4.7V1100.7z M1177.9,1090.1l-9.2,5.3l0.3,0.6l-0.3,0.6l10.2,5.9l18.2,10.5
           l0.3-0.6l0.3,0.6l9.9-5.7l35.3,20.4l-47.4,27.4l-14.8-8.6l-0.3,0.6l-0.3-0.6l-20,11.6l-31.4-18.1l0,0l-68.1-39.3l67.8-39.1
           L1177.9,1090.1z M1361.9,1196.2l0.3-0.6l0.3,0.6l19.1-11l38.4,22.2l-63.9,36.9l0.3,0.6l-0.3,0.6l24.6,14.2l-67.8,39.1l-56-32.3
           l-0.3,0.6l-0.3-0.6l-19.5,11.3l-4.1-2.4l14.4-8.3l-0.3-0.6l0.3-0.6l-31.3-18.1l-0.3,0.6l-0.3-0.6l-15.1,8.7l-3.3-1.9l14.4-8.3
           l118.7-68.5L1361.9,1196.2z M1714.5,1133.7l-3.3,1.9l-15.1-8.7l-0.3,0.6l-0.3-0.6l-29.4,17l0.3,0.6l-0.3,0.6l14.4,8.3l-37.2,21.5
           l-45-26l31.4-18.1l-0.3-0.6l0.3-0.6l-25.5-14.8l-0.3,0.6l-0.3-0.6l-27.3,15.7l-20-11.6l-0.3,0.6l-0.3-0.6l-4.5,2.6l-45.1-26
           l71.3-41.2L1714.5,1133.7z M946.4,1149l137.4,79.3l-65,37.5l-45.1-26l31.5-18.2l-0.3-0.6l0.3-0.6l-25.6-14.8l-0.3,0.6l-0.3-0.6
           l-21.7,12.5l-20-11.6l-0.3,0.6l-0.3-0.6l-10.1,5.8l-45.1-26L946.4,1149z M915.8,1243.8l26.6-15.4l-0.3-0.6l0.3-0.6l-19.4-11.2
           l3.8-2.2l10.1-5.8l20,11.6l0.3-0.6l0.3,0.6l21.7-12.5l24.2,14l-31.5,18.2l-32.1,18.5L915.8,1243.8z M1019.5,1267l65-37.5v1.7
           l-15.1,8.7l0.1,0.2h0l-0.1-0.2l-12.3,7.1l0.3,0.6h-0.7v6.2v17.8v2.9l-37.3,21.5V1267z M1058.1,1248l10.9-6.3v4.7l-10.9,6.3V1248z
            M1070.4,1240.9l14.1-8.1v21.8l-14.1-7.8V1240.9z M1058.1,1272.8l6.2,3.6v1.7l-6.2-3.6V1272.8z M1085.1,1231.6L1085.1,1231.6
           L1085.1,1231.6L1085.1,1231.6L1085.1,1231.6z M1244.3,1127.7L1244.3,1127.7L1244.3,1127.7L1244.3,1127.7L1244.3,1127.7
           L1244.3,1127.7z M1168.3,1239l14.1-8.1v25.8l-14.1,8.1V1239z M1183.7,1230.8l25.7,14.9l-14.4,8.3l0.3,0.6h-0.7v8.4l-11-6.3
           V1230.8z M1210.8,1244.9l-27.4-15.8l-0.3,0.6l-0.3-0.6l-15.1,8.7l-38.4-22.2l38.8-22.4l-0.3-0.6l0,0l0.1,0.1l0.3,0.5l25.6-14.8
           l-0.3-0.6l0.3-0.6l-17-9.8l19.4-11.2l48.4-27.9l84.2,48.6L1210.8,1244.9z M1167.7,1192.7L1167.7,1192.7L1167.7,1192.7
           L1167.7,1192.7z M1160.4,1159.4l20-11.6l13.8,8l-19.4,11.2l0.3,0.6l-0.3,0.6l17,9.8l-24.2,14l-63.5-36.7l24.2-14l31.4,18.1l0,0
           l0,0l0.3-0.6L1160.4,1159.4z M1059.3,1100.6L1059.3,1100.6L1059.3,1100.6L1059.3,1100.6L1059.3,1100.6z M1102.8,1155.2
           L1102.8,1155.2L1102.8,1155.2L1102.8,1155.2z M1090.4,1229.3l12.3-7.1v6.2l-12.3,7.1V1229.3z M1097.3,1256.6l-6.9-4v-15.5l13-7.5
           l20.3,11.7L1097.3,1256.6z M1090.4,1254.1l6.2,3.6v1.7l-6.2-3.6V1254.1z M1098,1257.7l26.4-15.2v1.7l-26.4,15.2V1257.7z
            M1125.1,1241.3L1125.1,1241.3l0.3-0.6l-21.3-12.3v-7l13.8-8l-0.3-0.6h0.7v-2.1l9.1,5.2v29.1l-1.5-0.9v-2.9H1125.1z
            M1200.7,1257.6l14.1-8.1v25.8l-14.1,8.1V1257.6z M1216.1,1249.5l29.3,16.9l-14.4,8.3l0.3,0.6h-0.7v8.4l-14.5-8.4V1249.5z
            M1224.4,1307.4l6.2-3.6v1.7l-6.2,3.6V1307.4z M1232,1302.7v-17.8v-8.4l4.1,2.4v29.1l-4.1-2.4V1302.7z M1412.7,1167.3l-0.3-0.6
           l0.3-0.6l-14.6-8.4l40-23.1l122.9,71l14.4,8.3l-3.3,1.9l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.7,16l0.3,0.6l-0.3,0.6l23.7,13.7l0,0
           l2.1,1.2l-56.4,32.6l-48.4-28l-0.3,0.6l0,0l0.3-0.6l-19.3-11.2l17-9.8l-0.3-0.6l0.3-0.6l-25.6-14.8l-38.8-22.4l14.1-8.2
           L1412.7,1167.3z M1575.7,1131.8l0.3-0.6l0.3,0.6l27.3-15.7l24.2,14l-31.4,18.1l-32.1,18.5l-24.2-14l21-12.1l-0.3-0.6l0.3-0.6
           l-19.4-11.2l9.4-5.4l-0.3-0.6l0.3,0.6l4.5-2.6L1575.7,1131.8z M1710.5,1162.5l-14.1-8.1v-25.8l14.1,8.1V1162.5z M1695.1,1128.6
           v25.8l-12.7,7.3v-8.4h-0.7l0.3-0.6l-14.4-8.3L1695.1,1128.6z M1549.5,1122.3l-9.4,5.4l0.3,0.6l-0.3,0.6l19.4,11.2l-21,12.1
           l0.3,0.6l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45,26l-37.3,21.5l-15.1-8.7l-0.3,0.6l-0.3-0.6l-27.4,15.8l-121.9-70.4
           l65-37.5L1549.5,1122.3z M1576.8,1213.9l0.3-0.6l-14.4-8.3l25.7-14.9v25.8l-11,6.3v-8.4H1576.8z M1589.8,1190.1l14.1,8.1v25.8
           l-14.1-8.1V1190.1z M1552.7,1236.5l-22.3-12.9l25.7-14.9v25.8L1552.7,1236.5z M1557.4,1208.8l14.1,8.2v25.8l-14.1-8.2V1208.8z
            M1461.4,1292.9l-9.9-5.7l-0.3,0.6l-0.3-0.6l-28.4,16.4l0.3,0.6l-0.3,0.6l9.2,5.3l-49.6,28.6l-67.8-39.1l68.1-39.3l31.4-18.1
           l20,11.6l0.3-0.6l0.3,0.6l14.8-8.6l47.4,27.4L1461.4,1292.9z M1433,1310l0.3-0.6l-9.2-5.3l26.4-15.2v25.8l-16.8,9.7V1310H1433z
            M1451.8,1288.9l8.9,5.1v25.8l-8.9-5.1V1288.9z M1421.4,1208.2l24.2,14l-17,9.8l0.3,0.6l-0.3,0.6l19.3,11.2l-13.8,8l-20-11.6
           l-0.3,0.6l-0.3-0.6l-31.4,18.1l-24.2-14L1421.4,1208.2z M1257.3,1268.1l55,31.7v29.1l-55-31.7V1268.1z M1382.7,1339.8l49.6-28.6
           v14.4v11.8v2.9l-49.6,28.6V1339.8z M1433.6,1338.5l7.4,4.3v1.7l-7.4-4.3V1338.5z M1577.5,1242.4l6.2,3.6v1.7l-6.2-3.6V1242.4z
            M1643.8,1176l37.2-21.5v8.4v17.8v2.9l-37.2,21.5V1176z M1682.4,1181.8l6.2,3.6v1.7l-6.2-3.6V1181.8z M1689.3,1184.3l-6.9-4v-17
           l13.3-7.7l15.1,8.7l2.8,1.6l3.7,2.2L1689.3,1184.3z M1714,1164.5l-2.2-1.3v-26.6l3.3-1.9v29.1L1714,1164.5z M1411,1166.7
           l-13.6,7.9v-15.7L1411,1166.7z M1003,1133.4l0.3-0.6l0.3,0.6l55.6-32.1l67.8,39.1l-24.5,14.2l0,0l0,0l0,0l0.2,0.3l0.1,0.2l0,0
           h-0.6v0l0.6,0l-0.2,0.3l-0.1,0.2l0,0l0,0l0,0l0,0l0,0l0,0l63.8,36.8l-38.4,22.2l-10.1-5.8l-0.3,0.6l-0.3-0.6l-32,18.5
           l-137.4-79.3l40.4-23.3L1003,1133.4z M880.1,1187.3l45.1,26l-3.8,2.2l0.3,0.6l-0.3,0.6l19.4,11.2l-26.6,15.4l0.3,0.6l-0.3,0.6
           l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45.1,26l-71.3,41.2l-137.4-79.3L880.1,1187.3z M945.5,1308.9v29.1l-137.4-79.3v-29.1
           L945.5,1308.9z M951.5,1334.3l6.2,3.6v1.7l-6.2-3.6V1334.3z M951.5,1309.5l12.7-7.3v4.7l-12.7,7.3V1309.5z M965.5,1301.4
           l14.1-8.1v21.7l-14.1-8.1V1301.4z M987.2,1323.5l-28.1,16.2v-1.7l28.1-16.2V1323.5z M958.4,1336.8l-6.9-4v-17l13.3-7.7l15.1,8.7
           l2.8,1.6l0,0l3.7,2.2L958.4,1336.8z M983.1,1317.1l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.4,8.9l0.3,0.6l-0.3-0.6l-14,8.1l0.3,0.6
           h-0.7v6.2v17.8v2.9l-3.3,1.9v-29.1l71.3-41.2v29.1L983.1,1317.1z M1065.7,1276.4l26.4-15.2v1.7l-26.4,15.2V1276.4z M1065,1275.3
           l-6.9-4v-17l10.9-6.3v0h0.7l15.9,8.8l0,0l2,1.2l3.8,2.2L1065,1275.3z M1088,1256.5l-2.2-1.3v-23.6h-0.6l0,0h0.7v-2.9l31-17.9v1.7
           l-13.8,8l-13.6,7.9l0.3,0.6h-0.7v7.8v16.3v2.9L1088,1256.5z M1128.6,1216.8l38.4,22.2v26.6l-2.2,1.3l-36.3-20.9V1216.8z
            M1165.1,1268.1l2.8-1.6l15.1-8.7l11.7,6.7v17l-6.9,4l-26.4-15.2L1165.1,1268.1z M1160.7,1273.2v-1.7l26.4,15.2v1.7
           L1160.7,1273.2z M1188.5,1286.7l6.3-3.6v1.7l-6.3,3.6V1286.7z M1196.1,1281.9v-17.8v-8.4l3.3,1.9v26.6l-2.2,1.3l-1.1-0.7V1281.9z
            M1197.5,1286.8l2.8-1.6l15.1-8.7l15.2,8.8v17l-6.9,4l-29.9-17.3L1197.5,1286.8z M1223.1,1307.4v1.7l-29.9-17.3v-1.7
           L1223.1,1307.4z M1237.4,1278.9l18.5-10.7v29.1l-18.5,10.7V1278.9z M1313.7,1329.7v-29.1l67.8,39.1v29.1L1313.7,1329.7z
            M1442.3,1342.8l27.1-15.6v1.7l-27.1,15.6V1342.8z M1441.7,1341.6l-8-4.6v-11l17.5-10.1l9.9,5.7l0.3-0.6l-0.3,0.6l2.8,1.6
           l4.9,2.8L1441.7,1341.6z M1464.2,1321.8l-2.2-1.3V1294l35.4-20.4v29.1L1464.2,1321.8z M1555.1,1269.3l-56.4,32.6v-29.1l56.4-32.6
           v21.8v3.3V1269.3z M1579.1,1251.2l-22.6,13.1v-1.7l22.6-13.1V1251.2z M1556.5,1260.9v-21.8h-0.7l0.3-0.6l-2.1-1.2l2.7-1.6
           l15.1,8.7l2.8,1.6l3.7,2.2L1556.5,1260.9z M1575,1244.8l-2.2-1.3v-26.6l3.3-1.9v8.4v17.8v2.9L1575,1244.8z M1585.1,1246
           l26.4-15.2v1.7l-26.4,15.2V1246z M1584.4,1244.8l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6l3.7,2.2L1584.4,1244.8z M1607.4,1226.1
           l-2.2-1.3v-26.6l37.3-21.5v29.1L1607.4,1226.1z M1690,1185.4l28.1-16.2v1.7l-28.1,16.2V1185.4z M1329.8,1177L1329.8,1177
           L1329.8,1177L1329.8,1177L1329.8,1177z M1059.3,1100.6L1059.3,1100.6L1059.3,1100.6L1059.3,1100.6L1059.3,1100.6L1059.3,1100.6z
            M946.3,1148.1L946.3,1148.1L946.3,1148.1L946.3,1148.1L946.3,1148.1z M1192.3,1288.6l0.2,0.4h0L1192.3,1288.6z"/>
       </g>
     </g>
     <g id="E4" className={'isometry__floor ' + (floor === "4" ? 'active' : '')}>
       <ModIsometrySvgFlat flat="71553.16.0402" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path44_25_" className="st0" d="M1576.8,1183.3l-138.7-80.1l66.3-38.3l46.4,26.8l-10.4,6l20.4,11.8l-22,12.7l25.6,14.8
           l32.5-18.7l46.4,26.8L1576.8,1183.3z"/>
         <path id="path50_24_" className="st0" d="M1576.8,1183.3l66.3-38.3v30.6l-35.7,20.6l-2.8-1.6v-24l-15.4,8.9l-12.3,7.1L1576.8,1183.3z
           "/>
         <path id="path52_25_" className="st0" d="M1612.1,1199v3.3l-27.7,16v-3.3L1612.1,1199z"/>
         <path id="path54_22_" className="st0" d="M1612.1,1199l-27.7,16l-7.6-4.4v-17.8l12.3-7.1l15.4,8.9l2.8,1.6L1612.1,1199z"/>
         <path id="path56_24_" className="st0" d="M1589.1,1185.7l-12.3,7.1v-6.2l12.3-7.1V1185.7z"/>
         <path id="path58_22_" className="st0" d="M1584.4,1218.3l-7.6-4.4v-3.3l7.6,4.4V1218.3z"/>
         <path id="path64_24_" className="st0" d="M1604.6,1170.5v24l-15.4-8.9v-6.2L1604.6,1170.5z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.16.0401" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path48_26_" className="st0" d="M1576,1100.7l-20.4-11.8l-4.8,2.8l-46.4-26.8l72.6-41.9l138.7,80.1l-72.6,41.9l-46.4-26.8
           l32.4-18.7l-25.5-14.8L1576,1100.7z"/>
         <path id="path62_22_" className="st0" d="M1643.2,1175.6V1145l72.6-41.9v30.6l-1.8,1l-2.8-1.6v-24l-15.4,8.9l-14,8.1v6.2v17.8v3.3
           L1643.2,1175.6z"/>
         <path id="path66_22_" className="st0" d="M1718.7,1140.7l-29.4,17v-3.3l29.4-17V1140.7z"/>
         <path id="path68_24_" className="st0" d="M1689.3,1154.4v3.3l-7.6-4.4v-3.3L1689.3,1154.4z"/>
         <path id="path70_22_" className="st0" d="M1718.7,1137.4l-29.4,17l-7.6-4.4v-17.8l14-8.1l15.4,8.9l2.8,1.6L1718.7,1137.4z"/>
         <path id="path72_24_" className="st0" d="M1695.7,1124.1l-14,8.1v-6.2l14-8.1V1124.1z"/>
         <path id="path74_22_" className="st0" d="M1695.7,1124.1v-6.2l15.4-8.9v24L1695.7,1124.1z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0403" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path30_22_" className="st0" d="M1195.4,1224l134.4-77.6l32.4,18.7l19.4-11.2l39.8,23l-64.9,37.4l25.6,14.8l-69.1,39.9
           l-56.3-32.5l-19.9,11.5L1195.4,1224z"/>
         <path id="path32_24_" className="st0" d="M1236.8,1247.9l19.9-11.5v30.6l-19.9,11.5V1247.9z"/>
         <path id="path36_25_" className="st0" d="M1195.4,1224l41.4,23.9v30.6l-5.4-3.1v-3.3v-17.8v-6.2l-15.9-9.2l-15.4-8.9v24l-2.8,1.6
           l-1.8-1v-3.3v-17.8v-6.3V1224z"/>
         <path id="path38_23_" className="st0" d="M1256.7,1236.4l56.3,32.5v30.6l-56.3-32.5V1236.4z"/>
         <path id="path76_24_" className="st0" d="M1223.8,1279.7l-31.3-18.1v-3.3l31.3,18.1V1279.7z"/>
         <path id="path78_22_" className="st0" d="M1223.8,1279.7v-3.3l7.6-4.4v3.3L1223.8,1279.7z"/>
         <path id="path80_24_" className="st0" d="M1223.8,1276.4l-31.3-18.1l4.7-2.7l2.8-1.6l15.4-8.9l15.9,9.2v17.8L1223.8,1276.4z"/>
         <path id="path82_22_" className="st0" d="M1231.3,1254.2l-15.9-9.2v-6.2l15.9,9.2V1254.2z"/>
         <path id="path86_22_" className="st0" d="M1215.5,1238.8v6.2l-15.4,8.9v-24L1215.5,1238.8z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0402" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path34_23_" className="st0" d="M1313,1268.9l69.1-39.9l31.7-18.3l20.3,11.8l15.1-8.7l48.8,28.1l-36.7,21.2l-10.2-5.9
           l-28.4,16.4l10.2,5.9l-50.9,29.4L1313,1268.9z"/>
         <path id="path84_24_" className="st0" d="M1313,1299.5v-30.6l69.1,39.9v30.6L1313,1299.5z"/>
         <path id="path110_22_" className="st0" d="M1498.1,1241.9v30.6l-33.9,19.5l-2.8-1.6V1263L1498.1,1241.9z"/>
         <path id="path122_22_" className="st0" d="M1382.1,1339.4v-30.6l50.9-29.4v30.6L1382.1,1339.4z"/>
         <path id="path124_23_" className="st0" d="M1461.4,1263v27.3l-10.2-5.9v-27.3L1461.4,1263z"/>
         <path id="path126_21_" className="st0" d="M1422.7,1273.5l28.4-16.4v27.3l-18.2,10.5v-15.5L1422.7,1273.5z"/>
         <path id="path128_23_" className="st0" d="M1433,1294.9l18.2-10.5l10.2,5.9l2.8,1.6l5.9,3.4l-28.4,16.4l-8.7-5L1433,1294.9z"/>
         <path id="path130_24_" className="st0" d="M1433,1310v-3.3l8.7,5v3.3L1433,1310z"/>
         <path id="path132_23_" className="st0" d="M1441.7,1311.8l28.4-16.4v3.3l-28.4,16.4V1311.8z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0401" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path42_23_" className="st0" d="M1421.4,1176.8l-39.8-23l15.1-8.7l15.6-9l-15.6-9l41.4-23.9l138.7,80.1l-32.4,18.7l2.8,1.6
           l5.4,3.1l3.1,1.8l-57.7,33.3l-48.8-28.1l-20.3-11.8l18-10.4L1421.4,1176.8z"/>
         <path id="path60_24_" className="st0" d="M1576.8,1183.3v3.3v6.2v17.8v3.3l-1.8,1l-2.8-1.6v-24l-15.5,8.9l-9.4,5.5l-2.8-1.6
           L1576.8,1183.3z"/>
         <path id="path106_23_" className="st0" d="M1498.1,1241.9l57.7-33.3v30.6l-57.7,33.3V1241.9z"/>
         <path id="path108_24_" className="st0" d="M1396.8,1127.1l15.6,9l-15.6,9V1127.1z"/>
         <path id="path112_23_" className="st0" d="M1579.8,1220.9l-24,13.8v-3.3l24-13.8V1220.9z"/>
         <path id="path114_22_" className="st0" d="M1579.8,1217.7l-24,13.8v-23l-3.1-1.8l4.1-2.3l15.5,8.9l2.8,1.6L1579.8,1217.7z"/>
         <path id="path116_22_" className="st0" d="M1556.7,1204.4l-4.1,2.3l-5.4-3.1l9.4-5.5V1204.4z"/>
         <path id="path118_22_" className="st0" d="M1556.7,1204.4v-6.2l15.5-8.9v24L1556.7,1204.4z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0403" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path22_24_" className="st0" d="M1059.3,1070l69.1,39.9l-25.6,14.8l0,0l28.4,16.4l-39.8,23l13.4,7.8l-66.3,38.3l-110.9-64.1
           L1059.3,1070z"/>
         <path id="path90_23_" className="st0" d="M1104.9,1171.7v30.6l-66.3,38.3V1210L1104.9,1171.7z"/>
         <path id="path120_23_" className="st0" d="M1038.6,1240.6l-110.9-64.1v-30.6l110.9,64.1V1240.6z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0402" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path28_24_" className="st0" d="M1175.2,1136.9l20.4-11.8l48.7-28.1l0,0l85.5,49.4l-134.4,77.6l-67.5-39l39.7-23l25.6-14.8
           v0L1175.2,1136.9z"/>
         <path id="path94_23_" className="st0" d="M1127.9,1185l67.5,39v3.3l-12.3-7.1l-15.4-8.9v24l-2.8,1.6l-36.9-21.3L1127.9,1185z"/>
         <path id="path96_24_" className="st0" d="M1160.1,1243v-3.3l27.7,16v3.3L1160.1,1243z"/>
         <path id="path98_23_" className="st0" d="M1187.8,1255.7l-27.7-16l4.7-2.7l2.8-1.6l15.4-8.9l12.3,7.1v17.8L1187.8,1255.7z"/>
         <path id="path100_24_" className="st0" d="M1195.4,1233.5l-12.3-7.1v-6.3l12.3,7.1V1233.5z"/>
         <path id="path102_22_" className="st0" d="M1195.4,1254.6l-7.6,4.4v-3.3l7.6-4.4V1254.6z"/>
         <path id="path104_24_" className="st0" d="M1167.7,1235.3v-24l15.4,8.9v6.3L1167.7,1235.3z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.14.0401" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path24_25_" className="st0" d="M1128.4,1109.9l-69.1-39.9l0,0l69.1-39.9l116,67l0,0l-48.7,28.1l-15.1-8.7l-20.4,11.8
           L1128.4,1109.9z"/>
       </ModIsometrySvgFlat>
        <ModIsometrySvgFlat flat="71553.14.0404" floor="4" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
        <path id="path88_24_" className="st0" d="M1127.9,1215.6l-23-13.3v-30.6l23,13.3V1215.6z"/>
         <path id="path92_23_" className="st0" d="M1091.5,1164l39.8-23l36.4,21l-39.7,23l-23-13.3L1091.5,1164z"/>
       </ModIsometrySvgFlat>
       <g id="E4-Areas" className="isometry__areas">
         <path id="path26_22_" className="st0" d="M1180.5,1116.4l15.1,8.7l-20.4,11.8v0l18,10.4l-25.6,14.8l-36.4-21l-28.4-16.4l25.6-14.8
           l0,0l31.7,18.3L1180.5,1116.4z"/>
         <path id="path40_25_" className="st0" d="M1382.1,1229l-25.6-14.8l64.9-37.4l25.6,14.8l-18,10.4l20.3,11.8l-15.1,8.7l-20.3-11.8
           L1382.1,1229z"/>
         <path id="path46_24_" className="st0" d="M1564.3,1136.9l-25.6-14.8l22-12.7l-20.4-11.8l10.4-6l4.8-2.8l20.4,11.8l27.6-15.9
           l25.5,14.8l-32.4,18.7L1564.3,1136.9z"/>
       </g>
       <g id="E4-Strokes" className="isometry__strokes">
         <path d="M1719.4,1137.4h-0.7l0.3-0.6l-3.7-2.2l0.8-0.5l-0.3-0.6h0.7V1103h-0.7l0.3-0.6l-138.7-80.1l-0.3,0.6l-0.3-0.6l-72.6,41.9
           l-66.3,38.3l-41.4,23.9l0.3,0.6h-0.7v17.7l-14.8,8.6l-19.1,11l-32-18.5l-85.5-49.4l-116-67l-0.3,0.6l-0.3-0.6l-69.1,39.9
           l-131.6,76l0.3,0.6H927v30.6h0.7l-0.3,0.6l110.9,64.1l0.3-0.6l0.3,0.6l65.9-38.1l22.7,13.1l0.3-0.6h0l-0.3,0.6l35.9,20.7
           l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6l27.7,16l0.3-0.6l0.3,0.6l7.3-4.2l0.5,0.3l-3.7,2.2l0.3,0.6h-0.7v3.3h0.7l-0.3,0.6
           l31.3,18.1l0.3-0.6l0.3,0.6l7.2-4.2l5.1,3l0.3-0.6l0.3,0.6l19.5-11.3l56,32.3l0,0l69.1,39.9l0.3-0.6l0.3,0.6l50.6-29.2l8.4,4.8
           l0.3-0.6l0.3,0.6l28.4-16.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-4.9-2.8l32.9-19l57.7-33.3l-0.3-0.6h0.7v-4l23.6-13.6l-0.3-0.6h0.7
           v-3.3h-0.7l0.3-0.6l-3.7-2.2l0.5-0.3l7.2,4.2l0.3-0.6l0.3,0.6l27.7-16l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-3.7-2.2l34.7-20.1
           l38.2-22.1l7.2,4.2l0.3-0.6l0.3,0.6l29.4-17l-0.3-0.6h0.7V1137.4z M1397.4,1128.2l13.6,7.9l-13.6,7.9V1128.2z M1128.4,1030.8
           L1243,1097l-47.4,27.4l-14.8-8.6l-0.3,0.6l-0.3-0.6l-20,11.6l-31.4-18.1l-68.1-39.3L1128.4,1030.8z M1175.2,1136.9l-0.3,0.6l0,0
           l0,0l17,9.8l-24.2,14l-36.1-20.9l-27.4-15.8l24.2-14l31.4,18.1l0.3-0.6l0.3,0.6l20-11.6l13.8,8l-19.4,11.2L1175.2,1136.9z
            M1038,1239.4l-109.6-63.3v-29.1l109.6,63.3V1239.4z M1038.6,1209.2L929,1145.9l130.2-75.2l67.8,39.1l-24.6,14.2l0.3,0.6
           l-0.3,0.6l27.4,15.8l-38.8,22.4l0.3,0.6l-0.3,0.6l12.4,7.2L1038.6,1209.2z M1104.2,1202l-64.9,37.5v-29.1l64.9-37.5V1202z
            M1127.3,1214.5l-21.7-12.5v-29.1l21.7,12.5V1214.5z M1127.9,1184.2l-22.7-13.1l0,0l-12.4-7.2l38.4-22.2l35.1,20.3L1127.9,1184.2
           z M1127.9,1185L1127.9,1185L1127.9,1185L1127.9,1185z M1187.1,1257.8l-26.4-15.2v-1.7l26.4,15.2V1257.8z M1194.7,1254.2l-6.3,3.6
           v-1.7l6.3-3.6V1254.2z M1194.7,1250.9l-6.9,4l-26.4-15.2l3.7-2.2l0,0l2.8-1.6l15.1-8.7l11.7,6.7V1250.9z M1168.3,1234.1v-21.7
           l14.1,8.1v5.5L1168.3,1234.1z M1194.7,1232.4l-11-6.3v-4.7l11,6.3V1232.4z M1194.7,1226.1l-11.3-6.5l-15.4-8.9l-0.3,0.6h-0.7
           v23.7l-2.2,1.3l-36.2-20.9v-29.1l66.2,38.2V1226.1z M1195.4,1223.2l-66.2-38.2l38.7-22.4l25.6-14.8l-0.3-0.6l0.3-0.6l0,0l0,0
           l-17-9.8l19.4-11.2l48.4-28l84.1,48.6L1195.4,1223.2z M1223.1,1278.5l-29.9-17.3v-1.7l29.9,17.3V1278.5z M1230.7,1274.9l-6.2,3.6
           v-1.7l6.2-3.6V1274.9z M1230.7,1271.7l-6.9,4l-29.9-17.3l3.7-2.2l2.8-1.6l15.1-8.7l15.2,8.8V1271.7z M1200.7,1252.8v-21.7
           l14.1,8.1v5.5L1200.7,1252.8z M1230.7,1253.1l-14.5-8.4v-4.7l14.5,8.4V1253.1z M1236.1,1277.3l-4.1-2.4v-2.9v-17.8v-6.2h-0.7
           l0.3-0.6l-15.9-9.2l-15.4-8.9l-0.3,0.6h-0.7v23.7l-2.2,1.3l-1.1-0.7v-2.9v-17.8v-6.3v-2.1l40,23.1V1277.3z M1256,1266.6
           l-18.5,10.7v-29.1l18.5-10.7V1266.6z M1312.3,1298.4l-55-31.7v-29.1l55,31.7V1298.4z M1257,1235.8l-0.3,0.6l-0.3-0.6l-19.5,11.3
           l-40-23.1l133.1-76.8l32,18.5l0.3-0.6l0.3,0.6l19.1-11l38.4,22.2l-63.9,36.9l0.3,0.6l-0.3,0.6l24.6,14.2l-67.8,39.1L1257,1235.8z
            M1428.9,1202l-0.3,0.6l19.3,11.2l-13.8,8l-20-11.6l-0.3,0.6l-0.3-0.6l-31.4,18.1l-24.2-14l63.5-36.7l24.2,14l-17,9.8
           L1428.9,1202z M1381.4,1338.3l-67.8-39.1v-29.1l67.8,39.1V1338.3z M1382.1,1308l-67.8-39.1l68.1-39.3l31.4-18.1l20,11.6l0.3-0.6
           l0.3,0.6l14.8-8.6l47.4,27.4l-35.4,20.4l-9.9-5.7l-0.3,0.6l-0.3-0.6l-28.4,16.4l0.3,0.6l-0.3,0.6l9.2,5.3L1382.1,1308z
            M1460.7,1263.4v25.8l-8.9-5.1v-25.8L1460.7,1263.4z M1432.3,1294.9v11.8v2.9l-49.6,28.6v-29.1l49.6-28.6V1294.9z M1424.1,1273.5
           l26.4-15.2v25.8l-16.8,9.7v-14.4h-0.7l0.3-0.6L1424.1,1273.5z M1441,1313.9l-7.4-4.3v-1.7l7.4,4.3V1313.9z M1469.4,1298.3
           l-27.1,15.6v-1.7l27.1-15.6V1298.3z M1468.7,1295.4l-27.1,15.6l-8-4.6v-11l17.5-10.1l9.9,5.7l0.3-0.6l-0.3,0.6l2.8,1.6l0.3-0.6
           l-0.3,0.6L1468.7,1295.4z M1497.4,1272.1l-33.2,19.2l-2.2-1.3v-26.6l35.4-20.4V1272.1z M1498.1,1241.1l-48.4-28l-0.3,0.6l0,0
           l0.3-0.6l-19.3-11.2l17-9.8l-0.3-0.6l0.3-0.6l-25.6-14.8l-38.8-22.4l14.1-8.2l15.6-9l-0.3-0.6l0.3-0.6l-14.6-8.4l40-23.1
           l137.4,79.3l-31.4,18.1l0.3,0.6l-0.3,0.6l2.8,1.6l5.4,3.1l0,0l2.1,1.2L1498.1,1241.1z M1572.2,1189.2l-0.3-0.6l-15.5,8.9
           l-9.1,5.3l-1.5-0.9l30.4-17.5v2.1v6.2v17.8v2.9l-1.1,0.7l-2.2-1.3v-23.7H1572.2z M1571.5,1190.4v21.7l-14.1-8.2v-5.4
           L1571.5,1190.4z M1556.1,1199.3v4.7l-3.4,2l-4-2.3L1556.1,1199.3z M1555.1,1231.5v3.3v4l-56.4,32.6v-29.1l56.4-32.6V1231.5z
            M1579.1,1220.5l-22.6,13.1v-1.7l22.6-13.1V1220.5z M1578.4,1217.7l-22,12.7v-21.8h-0.7l0.3-0.6l-2.1-1.2l2.7-1.6l15.1,8.7
           l2.8,1.6L1578.4,1217.7z M1576.8,1182.5l-137.4-79.3l65-37.5l45.1,26l-9.4,5.4l0.3,0.6l-0.3,0.6l19.4,11.2l-21,12.1l0.3,0.6
           l-0.3,0.6l25.6,14.8l0.3-0.6l0.3,0.6l32.1-18.5l45,26L1576.8,1182.5z M1550.9,1091.7l0.3,0.6l4.5-2.6l20,11.6l0.3-0.6l0.3,0.6
           l27.3-15.7l24.2,14l-31.4,18.1l-32.1,18.5l-24.2-14l21-12.1l-0.3-0.6l0.3-0.6l-19.4-11.2l9.4-5.4L1550.9,1091.7z M1577.5,1186.9
           l11-6.3v4.7l-11,6.3V1186.9z M1583.7,1217.1l-6.2-3.6v-1.7l6.2,3.6V1217.1z M1611.5,1201.9l-26.4,15.2v-1.7l26.4-15.2V1201.9z
            M1610.8,1199l-26.4,15.2l-6.9-4v-17l11.6-6.7l15.1,8.7l2.8,1.6L1610.8,1199z M1589.8,1185.3v-5.5l14.1-8.1v21.7L1589.8,1185.3z
            M1642.5,1175.2l-35.1,20.3l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-15.4,8.9l-11.3,6.5v-1.7l65-37.5V1175.2z M1643.2,1144.2l-45-26
           l31.4-18.1l-0.3-0.6l0.3-0.6l-25.5-14.8l-0.3,0.6l-0.3-0.6l-27.3,15.7l-20-11.6l-0.3,0.6l-0.3-0.6l-4.5,2.6l-45.1-26l71.3-41.2
           l137.4,79.3L1643.2,1144.2z M1681.1,1126v6.2v17.8v2.9l-37.2,21.5v-29.1l71.3-41.2v29.1l-1.1,0.7l-2.2-1.3V1109h-0.7l-0.3-0.6
           l-15.4,8.9l-14,8.1l0.3,0.6H1681.1z M1710.5,1110.2v21.7l-14.1-8.1v-5.5L1710.5,1110.2z M1695.1,1119.1v4.7l-12.7,7.3v-4.7
           L1695.1,1119.1z M1688.6,1156.5l-6.2-3.6v-1.7l6.2,3.6V1156.5z M1689.3,1153.6l-6.9-4v-17l13.3-7.7l15.1,8.7l2.8,1.6l3.7,2.2
           L1689.3,1153.6z M1718.1,1140.3l-28.1,16.2v-1.7l28.1-16.2V1140.3z"/>
       </g>
     </g>
     <g id="E5" className={'isometry__floor ' + (floor === "5" ? 'active' : '')}>
      <ModIsometrySvgFlat flat="71553.15.0501" floor="5" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path30_24_" className="st0" d="M1555.8,1177.9v30.6l-57.7,33.3v-30.6L1555.8,1177.9z"/>
         <path id="path32_26_" className="st0" d="M1555.8,1177.9l-57.7,33.3l-48.8-28.1l-20.3-11.8l18-10.4l-25.6-14.8l-36.5,21l-39.8-23
           l17-9.8l59.2-34.2L1555.8,1177.9z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0502" floor="5" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path26_24_" className="st0" d="M1313,1238.3l69.1-39.9l0,0l31.7-18.3l20.3,11.8l15.1-8.7l48.8,28.1l-116,67L1313,1238.3z"
           />
         <path id="path28_26_" className="st0" d="M1313,1268.9v-30.6l69.1,39.9v30.6L1313,1268.9z"/>
         <path id="path34_25_" className="st0" d="M1498.1,1211.2v30.6l-33.9,19.5l-2.8-1.6v-24l-10.2,5.9l-18.2,10.5v12.2v11.8v3.3
           l-50.9,29.4v-30.6L1498.1,1211.2z"/>
         <path id="path38_25_" className="st0" d="M1461.4,1235.7v24l-10.2-5.9v-12.2L1461.4,1235.7z"/>
         <path id="path40_27_" className="st0" d="M1451.1,1241.6v12.2l-18.2,10.5v-12.2L1451.1,1241.6z"/>
         <path id="path42_25_" className="st0" d="M1433,1264.3l18.2-10.5l10.2,5.9l2.8,1.6l5.9,3.4l-28.4,16.4l-8.7-5L1433,1264.3z"/>
         <path id="path44_27_" className="st0" d="M1433,1279.4v-3.3l8.7,5v3.3L1433,1279.4z"/>
         <path id="path46_26_" className="st0" d="M1441.7,1281.2l28.4-16.4v3.3l-28.4,16.4V1281.2z"/>
       </ModIsometrySvgFlat>
       <ModIsometrySvgFlat flat="71553.15.0503" floor="5" building="E" entries={this.props.entries} activeFlatEntry={this.props.activeFlatEntry} onFlatClick={this.props.onFlatClick}>
         <path id="path22_26_" className="st0" d="M1313,1268.9l-138.4-79.9v-30.6l138.4,79.9V1268.9z"/>
         <path id="path24_27_" className="st0" d="M1289.2,1092.3l73,42.2l-17,9.8v0l0,0l39.8,23l-28.4,16.4l0,0v0l25.6,14.8l-69.1,39.9
           l-138.4-79.9L1289.2,1092.3z"/>
       </ModIsometrySvgFlat>
       <g id="E5-Areas" className="isometry__areas">
         <path id="path36_27_" className="st0" d="M1446.9,1161l-18,10.4l20.3,11.8l-15.1,8.7l-20.3-11.8l-31.7,18.3l-25.6-14.8l28.4-16.4v0
           l36.5-21L1446.9,1161z"/>
       </g>
       <g id="E5-Strokes" className="isometry__strokes">
         <path className="st2" d="M1556.5,1177.9h-0.7l0.3-0.6l-134.4-77.6l-0.3,0.6l-0.3-0.6l-58.8,34l-72.7-42l-0.3,0.6l-0.3-0.6
           l-114.5,66.1l0.3,0.6h-0.7v30.6h0.7l-0.3,0.6l138.4,79.9l0,0l69.1,39.9l0.3-0.6l0.3,0.6l50.6-29.2l8.4,4.8l0.3-0.6l0.3,0.6
           l28.4-16.4l-0.3-0.6h0.7v-3.3h-0.7l0.3-0.6l-4.9-2.8l32.9-19l57.7-33.3l-0.3-0.6h0.7V1177.9z M1289.2,1093.1l71.7,41.4l-16,9.3
           l0.3,0.6l-0.3,0.6l0,0l0,0l38.8,22.4l-27.4,15.8l0.3,0.6l-0.3,0.6l0,0l0,0l24.6,14.2l-67.8,39.1l-137-79.1L1289.2,1093.1z
            M1428.6,1171.9l19.3,11.2l-13.8,8l-20-11.6l-0.3,0.6l-0.3-0.6l-31.4,18.1l-24.2-14l27.4-15.8l-0.3-0.6l0,0l0.3,0.6l36.1-20.8
           l24.2,14l-17,9.8l0.3,0.6L1428.6,1171.9z M1496.7,1211.2l-114.6,66.2l-67.8-39.1l68.1-39.3l31.4-18.1l20,11.6l0.3-0.6l0.3,0.6
           l14.8-8.6L1496.7,1211.2z M1554.5,1177.9l-56.4,32.6l-48.4-28l-0.3,0.6l0,0l0.3-0.6l-19.3-11.2l17-9.8l-0.3-0.6l0.3-0.6
           l-25.6-14.8l-0.3,0.6l-0.3-0.6l-36.1,20.8l-38.4-22.2l16-9.3l58.8-34L1554.5,1177.9z M1312.3,1238.7v29.1l-137-79.1v-29.1
           L1312.3,1238.7z M1313.7,1268.5v-29.1l67.8,39.1v29.1L1313.7,1268.5z M1433.6,1252.5l16.8-9.7v10.7l-16.8,9.7V1252.5z
            M1460.7,1236.9v21.7l-8.9-5.1V1242L1460.7,1236.9z M1433.6,1279v-1.7l7.4,4.3v1.7L1433.6,1279z M1442.3,1281.6l27.1-15.6v1.7
           l-27.1,15.6V1281.6z M1441.7,1280.4l-8-4.6v-11l17.5-10.1l9.9,5.7l0.3-0.6l-0.3,0.6l2.8,1.6l4.9,2.8L1441.7,1280.4z
            M1464.2,1260.6l-2.2-1.3v-23.7h-0.7l-0.3-0.6l-10.2,5.9l0,0l-18.2,10.5l0.3,0.6h-0.7v12.2v11.8v2.9l-49.6,28.6v-29.1l114.6-66.2
           v29.1L1464.2,1260.6z M1555.1,1208.1l-56.4,32.6v-29.1l56.4-32.6V1208.1z"/>
       </g>
     </g>
   </g>
   </svg>
    )
  }
}
