import * as React from "react";
import {
  addClassToElement,
  addCurrentLangRecursive,
  removeClassFromElement,
} from "../common/Utils";
import { tags } from "../vo/Tags";
import ModBlockVimeo from "./blocks/ModBlockVimeo";
import ModBlockSnippet from "./blocks/ModBlockSnippet";
import CmsController from "../control/CmsController";
import { NavigationNode, Overlay } from "../interfaces/Interfaces";
import { getCurrentLanguageOrFallBackByPath } from "../common/Languages";
import { RouterLocationExtended } from "../control/CmsRouter";

export interface ModModalViewProps {
  onClose: () => void;
  caasHelper: CmsController;
  navigationTree: NavigationNode;
  location: RouterLocationExtended;
}

export interface ModModalViewState {
  overlayContent: Overlay;
}

export default class ModModalView extends React.Component<
  ModModalViewProps,
  ModModalViewState
> {
  constructor(props) {
    super(props);

    this.state = {
      overlayContent: null,
    };
    this.onClose = this.onClose.bind(this);
    this.fetchOverlayContentById = this.fetchOverlayContentById.bind(this);
  }

  onMouseDown(event) {
    event.preventDefault();
  }

  componentDidMount() {
    this.fetchOverlayContentById(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchOverlayContentById(nextProps);
  }

  fetchOverlayContentById(props: ModModalViewProps) {
    const { caasHelper, location } = props;

    const currentLanguage = getCurrentLanguageOrFallBackByPath(
      location.pathname
    );
    if (location.query && location.query.has("overlay")) {
      caasHelper
        .fetchOverlay(location.query.get("overlay"))
        .then((result) => {
          addCurrentLangRecursive(result, currentLanguage);
          this.setState({
            overlayContent: result,
          });
        })
        .catch((error) => {
          this.setState({
            overlayContent: null,
          });
        });
    } else {
      this.setState({
        overlayContent: null,
      });
    }
  }

  onClose() {
    if (this.props.onClose) {
      if (typeof window !== "undefined") {
        let body = document.getElementsByTagName("BODY")[0];
        removeClassFromElement(body, "overflow-hidden");
      }
      this.props.onClose();
    }
  }

  render() {
    const {} = this.props;

    let className = "hidden";
    let content = null;
    if (this.state.overlayContent) {
      className = "visible";
      const typeKey = Object.keys(this.state.overlayContent.content)[0];
      const blockData = this.state.overlayContent.content[typeKey];
      switch (
        typeKey
        // case tags.blockVimeo:
        //   content = <ModBlockVimeo content={blockData} />
        //   break
        // case tags.blockSnippet:
        //   content = <ModBlockSnippet content={blockData} />;
        //   break;
      ) {
      }
    }

    if (typeof window !== "undefined") {
      let body = document.getElementsByTagName("BODY")[0];
      if (className === "hidden") {
        removeClassFromElement(body, "overflow-hidden");
      } else {
        addClassToElement(body, "overflow-hidden");
      }
    }

    return (
      <div id="modal" className={className}>
        <div className="modal-close-button-wrapper">
          <button
            className="no-border"
            onClick={this.onClose}
            onMouseDown={this.onMouseDown}
          >
            <img
              src={require("static/img/svg/but_close.svg")}
              alt="icon close"
            />
          </button>
        </div>
        <div className="modal-content">{content}</div>
      </div>
    );
  }
}
